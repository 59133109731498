// this stuff is for inlining the icons...Ideally a webpack loader is setup that allows 
// us to point to the actual SVG on the filesystem instead of a JS file that returns the SVG source
import facebookIcon from './facebook.icon';
import linkedInIcon from './linkedin.icon';
import twitterIcon from './twitter.icon';
import instagramIcon from './instagram.icon';
import youtubeIcon from './youtube.icon';

function getLocale() {
  return {
    connectWithUs: 'Suivez-nous',
    copyright: '© Soci&eacute;t&eacute; canadienne&nbsp;des&nbsp;postes',
    canada: 'Le site officiel du gouvernement du Canada',
    ariaLandmarkLabel: 'Navigation dans le pied de page',
    ariaContentInfoLandmarkLabel: 'Informations sur le contenu',
    support: 'Soutien',
    corporate: 'Postes Canada',
    blogs: 'Blogues',
    newWindow: 'Ouvrira une nouvelle fen&ecirc;tre',
    feedback: [
      {
        name: 'R&eacute;troaction',
        url: 'https://evaluation.canadapost-postescanada.ca/jfe/form/SV_71iOFlig0vNugpn?Q_lang=FR',
        iconClass: 'feedback',
      },
      {
        name: 'R&eacute;troaction sur l&rsquo;accessibilit&eacute;',
        url: '/scp/fr/notre-entreprise/a-notre-sujet/developpement-durable/accessibilite/procede-de-retroaction-sur-laccessibilite.page#contactinformation',
        iconClass: 'accessibility',
      }
    ],
    socialMediaURLs: [
      { 
        name: 'Facebook',
        url: 'https://www.facebook.com/postescanada',
        icon: 'assets/cpc/img/logos/facebook.svg',
        iconSVG: facebookIcon
      },
      { 
        name: 'Twitter',
        url: 'https://twitter.com/postescanada',
        icon: 'assets/cpc/img/logos/twitter.svg',
        iconSVG: twitterIcon
      },
      { 
        name: 'Instagram',
        url: 'https://www.instagram.com/canadapostagram/?hl=fr',
        icon: 'assets/cpc/img/logos/instagram.svg',
        iconSVG: instagramIcon
      },
      { 
        name: 'LinkedIn',
        url: 'http://www.linkedin.com/company/canada-post?trk=company_name',
        icon: 'assets/cpc/img/logos/linkedin.svg',
        iconSVG: linkedInIcon
      },
      { 
        name: 'Youtube',
        url: 'https://www.youtube.com/user/postescanadapost',
        icon: 'assets/cpc/img/logos/youtube.svg',
        iconSVG: youtubeIcon
      }
    ]
  };
}

export default getLocale;