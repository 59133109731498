/* eslint no-console: 0 */
/* eslint no-new: 0 */
/* eslint no-param-reassign: 0 */
/* eslint max-len: 0 */
/* eslint import/no-duplicates: 0 */
/* eslint no-unused-expressions: 0 */

/**
 * Support to initiate modals via HTML attributes. In this mode, the modal HTML is a template. When
 * a modal is instantiated from a template, the template HTML is not removed from the DOM. THe template
 * HTML is copied, template text set, and then populated into the modal, finally events are wired up. This
 * allows modal templates to be re-used.  
 * 
 * When creating a modal template, avoid hard-coding HTML id's into the template, 
 * otherwise duplicate DOM id's will exist. 
 * 
 * Templates should be defined with script type="text/template" to avoid being treated as part of the DOM, and therefore
 * avoid issues like duplicate DOM element id's, etc. 
 * 
 * @usage
 * 
 * via HTML attributes: 
    <script type="text/template" class="cpc-modal__template" id="modal-from-spec-2" data-cpc-modal-options='{"title":"Ship a parcel online","footer":false,"stickyFooter":false,"autoOpen":false,"closeLabel":"Close","closeMethods":["overlay","escape"],"cssClass":["custom-class-1","custom-class-2"]}'>
      <modal-body>
      <div class="row">
      <div class="large-6 cpc-modal__fluid-gutter-m columns">
      <p>Modal just with body</p>
      <ul>
        <li>Save on shipping and sending direct mail to your customers</li>
        <li>Get access to easy-to-use online shipping and direct marketing tools</li>
        <li>Enjoy pickup and return privileges</li>
        <li>Pay online by credit card or your account</li>
      </ul>

      <div class="show-for-medium-up">
      <p>
      <a href="#"class="button">Sign up</a>
      </p>
      
      <p>Already a Solutions for Small Business member? <a href="#">Sign in</a> to ship your parcel online</p>
      </div>

      </div>
      <div class="large-6 columns show-for-large-up cpc-modal__fluid-gutter-m bkg-container">
      <div class="modal-bkg" style="background-image:url(https://dev10.canadapost-postescanada.ca/cpc/assets/cpc/uploads/photography/shipping/canada/woman-choosing-shipping-options.jpg);"></div>
      </div>
      </div>
      </modal-body>
    </script>
 * 
 *   Footer:
 *     create a <modal-footer>...</modal-footer> element in your div
 */
import { load } from 'recaptcha-v3';
import ModalConstructor from './modal.component';
import authModule from '../../../../utils/auth.module';
import { getCookie, setCookie } from '../../../../utils/cookies';
import initializeTableComponent from '../Table-CG/table.component';

const captchaKey = authModule.getCAPTCHKey();
const $ = window.jQuery;
const modalAnimationTransitionTiming = 250;
const signInModalId = '#sign-in-modal';

const focusableElementsString = `
a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]
`;

function isUx() {
  const hostname = window.location.hostname;
  return /ux/.test(hostname);
}

/**
 * 
 * @param {*} initOptions - isCommonComponent: true | false
 */
function initializeModals(initOptions) {
  const allModalInitiators = Array.prototype.slice.call(document.querySelectorAll('[data-cpc-modal-id]'));
  const modals = [];
  
  allModalInitiators.forEach((initiator) => {
    const modalId = initiator.getAttribute('data-cpc-modal-id');
    const suppressModalOnAuth = !!initiator.getAttribute('data-cpc-modal-suppress-when-sso');
    const isAuthenticated = authModule.getIsAuthenticated();

    if (suppressModalOnAuth && isAuthenticated) return;

    const modalLookup = document.querySelector(modalId);

    if (!modalLookup) {
      console.error(`Modal not properly configured - unable to find modal template in page with id ${modalId}`);
      return;
    }

    const modalEle = modalLookup.tagName === 'SCRIPT' ? document.createRange().createContextualFragment(modalLookup.innerHTML) : modalLookup;
    
    let modal = null;

    initiator.addEventListener('click', clickHandler.bind(this));

    function followHref(e) {
      window.location = e.target.href || e.target.parentNode.href;
    }

    function followModal() {
    // Do not create new modal for sign-in if it already exists - keep event listeners
      const modalOptions = JSON.parse(modalLookup.getAttribute('data-cpc-modal-options'));
      if (modalOptions) {
        const modalInDOM = document.getElementById(`${modalOptions.id}`);
        if (modalInDOM) {
          modal = modalOptions.id ? modals.find(ele => ele.modal.modal.id === modalOptions.id) : null;
          modal.focusedElementBeforeModal = initiator;
    
          // To force the scale transition so that it looks like the modal has opened again
          modalInDOM.removeAttribute('style');
          modalInDOM.classList.remove('tingle-modal--visible');
          modalInDOM.setAttribute('aria-hidden', false);
          modalInDOM.setAttribute('tabindex', '0');
          document.body.classList.add('tingle-enabled');
    
          // Defer execution of adding the tingle-modal--visible attribute to give
          // the opportunity to animate the scale in effect, and defer input focus
          // as it seems to be needed in order for focus
          setTimeout(() => {
            modalInDOM.classList.add('tingle-modal--visible');
            setTimeout(() => { 
              const focusableInputs = modalInDOM.querySelectorAll(focusableElementsString);
              const inputToFocus = focusableInputs.length > 1 ? focusableInputs[1] : focusableInputs[0];
              if (inputToFocus) inputToFocus.focus();
            }, modalAnimationTransitionTiming);
          }, 0);
          return;
        } 

        if (initOptions && initOptions.isCommonComponent) {
          modalOptions.cssClass = modalOptions.cssClass || [];
          modalOptions.cssClass.push('cpc-component');
        }
        modal = new ModalConstructor(initiator, modalOptions);
        modal.setContentFromDOM(modalEle.querySelector('.cpc-modal-template-modal-body').cloneNode(true));
        const header = modalEle.querySelector('.cpc-modal-template-m-sticky-header');
        if (header) {
          modal.setMobileCloseBtnFromDOM(header.cloneNode(true));
        }
        const footer = modalEle.querySelector('.cpc-modal-template-modal-footer');
        if (footer) {
          modal.setFooterFromDOM(footer.cloneNode(true));
        }
        modal.open();
        modals.push(modal);
        initializeTableComponent();
      }
    }  
    
    function clickHandler(e) {
      e.preventDefault();
      e.stopPropagation();

      if (e.currentTarget.getAttribute('data-cpc-modal-id') !== signInModalId) {
        followModal();
      } else if (!getCookie('hc')) {
        load(captchaKey).then((recaptcha) => {
          recaptcha.execute('submit').then((token) => {
            $.ajax({
              url: '/cwc/components/rs/cwc/msform/auth',
              type: 'POST',
              data: `{ captchaResponse: ${token} }`,
              dataType: 'json',
              contentType: 'application/vnd.cpc.cwc-v1+json;',
              success: cb
            });
          }).catch(err => console.error(err));
        }).catch(err => console.error(err));
      } else if (Number(getCookie('hc')) > 0.6) {
        followModal();
      } else {
        followHref(e);
      }

      function cb(data) {
        isUx() ? setCookie('hc', 0.7) : setCookie('hc', parseFloat(data.score));
        if (Number(getCookie('hc')) > 0.6) {
          followModal();
        } else {
          followHref(e);
        }
      } 
    }
  });
}

export default initializeModals;
