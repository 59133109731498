function getLocale() {
  return {
    closeLabel: 'Close Sign in or Register for an account window',
    signInHeader: 'Access your account',
    signInButton: 'Sign in',
    signUp: 'Register now',
    username: 'Username',
    rememberUsername: 'Remember my username on this device',
    password: 'Password', // deepcode ignore NoHardcodedPasswords: password string
    forgotPasswordUsernameLabel: 'Username',
    forgotPasswordPasswordLabel: 'Password',
    forgot: 'Forgot',
    or: 'or',
    rightArea: { 
      default: { 
        createAccount: 'Register online',
        signUpText: 'Create an online account to save tracked items, unlock valuable discounts for your business, and more.',
        signUpPoint1: 'Access your personalized dashboard in a few easy steps! ',
        // signUpPoint2: 'Use our online shipping and marketing tools',
        // signUpPoint3: 'Access your Dashboard',
      },
      ship_online: {
        createAccount: 'New to shipping online?',
        signUpText: 'Create an online account to use Ship Online, save your tracked items, get bills and statements through epost and access your personalized Dashboard. Fast and easy in a few simple steps!'
      },
      shipping_manager: {
        createAccount: 'Register for an online account',
        signUpText: 'Business customers get access to valuable mailing tools and discounts through their online business profile.'
      },
      biz: { 
        createAccount: 'Register for an online account',
        signUpText: 'Register if you\'re new to Solutions for Small Business to access valuable tools and discounts or if you\'re already a cardholder but haven\'t set up your online account yet.'
      }
    },
    leftArea: { 
      default: { 
        title: 'Access your account',
        footnote: ''
      },
      ship_online: {
        title: 'Ready to ship?',
        overview: 'Sign in to your Canada Post or epost<sup>TM</sup> account and you’re ready to create a shipping label at home. Use Ship Online to send a parcel anywhere in Canada.',
        footnote: ''
      },
      biz: { 
        title: 'Access your account',
        overview: 'Sign in if you’re a Solutions for Small Business* member and you’ve already set up your online account.',
        footnote: '*formerly VentureOne™'
      },
      commercial: {
        title: 'Sign in to learn more',
        overview: 'Customized Mail Forwarding information is accessible only to commercial customers.',
        footnote: ''
      },
      shipping_manager: {
        title: 'Access your account',
        overview: 'Access your online Canada Post business profile.',
        footnote: ''
      },
      tools: {
        title: 'Access your account from Tools',
        overview: 'Sign in if you’re a Solutions for Small Business* member and your online account is set up.',
        footnote: '*formerly VentureOne™'
      }
    },
    dontHaveAccount: 'Don\'t have an account?',
    registerNow: 'Register now.'
  };
}

export default getLocale;