/* eslint no-console: 0 */
/* eslint no-new: 0 */

/**
 * language toggle functionality
 *
 * @example
 *
 * Events will be wired up to the lang toggle button.
 */

import DOMPurify from 'dompurify';
import { getLANG, setLANG, deleteLANG } from '../../../../utils/language.data';
import langSelectorHtml from './lang.selector.html';
import * as Store from '../../../../utils/data-store';
import DomainParser from '../../../../utils/domain-utils';
import { getUrlParameterValue } from '../../../../utils/url-utils';
import cookiePolicy from '../../../../utils/cookie.policy';


const blurOverlayID = 'cpc-lang-selector-overlay-blur';
const langSelectorOverlayID = 'cpc-lang-selector-overlay';
const engLangSelectorID = 'engLangSelector';
const freLangSelectorID = 'freLangSelector';
const govLogoID = 'canada-gov-logo';
const shopRegEx = /(sandbox\.mozu\.com|\.mozu\.com|boutique|store)/i;

// for first-time visitors (i.e. when lang cookie does not exist)
function initializeLangSelector() {
  const overlayBlur = document.createElement('div');
  overlayBlur.setAttribute('id', blurOverlayID);
  overlayBlur.innerHTML = document.body.innerHTML;
  document.body.innerHTML = '';
  document.body.appendChild(overlayBlur);
  const overlay = document.createElement('div');
  overlay.setAttribute('id', langSelectorOverlayID);
  overlay.innerHTML = langSelectorHtml;
  // .replace(/\$LANG_TOGGLE_COPYRIGHT_YEAR/, (new Date().getYear() + 1900));
  document.body.appendChild(overlay);
  // document.body.classList.add('no-scroll');
  // document.getElementsByTagName('html')[0].classList.add('no-scroll');
  const govLogo = document.getElementById(govLogoID);
  govLogo.addEventListener('keydown', (e) => {
    const key = e.which || e.keyCode;
    if (!e.shiftKey && key === 9) {
      e.preventDefault();
      document.getElementById('engLangSelector').focus();
    }
  });

  const engLangSelector = document.getElementById(engLangSelectorID);
  engLangSelector.addEventListener('keydown', (e) => {
    const key = e.which || e.keyCode;
    if (e.shiftKey && key === 9) {
      e.preventDefault();
      govLogo.focus();
    }
  });

  engLangSelector.addEventListener('click', (e) => {
    e.preventDefault();
    if (getLANG() === 'e') {
      removeLangSelectorOverlay();
      cookiePolicy();
    } else if (getLANG() === 'f') {
      switchLanguage();
    } else {
      window.location.reload(true);
    }
  });

  document.getElementById(freLangSelectorID).addEventListener('click', (e) => {
    e.preventDefault();
    if (getLANG() === 'f') {
      removeLangSelectorOverlay();
      cookiePolicy();
    } else if (getLANG() === 'e') {
      switchLanguage();
    } else {
      window.location.reload(true);
    }
  });

  window.addEventListener('focus', setEngBtnFocus);

  setEngBtnFocus();
  engLangSelector.blur();
}

function setEngBtnFocus() {
  document.getElementById(engLangSelectorID).focus();
}

function removeLangSelectorOverlay() {
  const langSelectorOverlay = document.getElementById(langSelectorOverlayID);
  langSelectorOverlay.parentElement.removeChild(langSelectorOverlay);
  document.getElementById(blurOverlayID).removeAttribute('id');
  // document.body.classList.remove('no-scroll');
  // document.getElementsByTagName('html')[0].classList.remove('no-scroll');
  window.removeEventListener('focus', setEngBtnFocus);
}

function switchLanguage() {
  document.querySelector('.language-toggle a').click();
}

function initializeLangToggle(lang) {
  const langSelector = '.language-toggle';
  const langSelectorMobile = '.language-toggle-mobile';
  const langToggleBtn = document.querySelector(langSelector);
  const langToggleMobileBtn = [...document.querySelectorAll(langSelectorMobile)];

  if (langToggleBtn && langToggleMobileBtn) {
    langToggleBtn.addEventListener('click', (e) => {
      e.preventDefault();
      langToggle(lang);
    });

    langToggleMobileBtn.forEach(elem =>
      elem.addEventListener('click', (e) => {
        e.preventDefault();
        langToggle(lang);
      })
    );
  }


  function langToggle(curLang) {
    const replaceLang = curLang === 'en' ? 'fr' : 'en';
    const url = DOMPurify.sanitize(window.location.href.split('#')[0]);
    const curDomain = DOMPurify.sanitize(window.location.host);
    const eShopSiteSwitch = '/cp/api/misc/initiateSiteSwitch';
    if (shopRegEx.test(curDomain)) {
      window.location.assign(`${eShopSiteSwitch}`);
    } else {
      deleteLANG(curLang.charAt(0));
      setLANG(replaceLang.charAt(0));
      if (!removeLangQueryString()) {
        // deepcode ignore OR: <please specify a reason of ignoring this>
        window.location.assign(getReplacedUrl(url, curDomain, curLang, replaceLang));
      }
    }
  }

  function removeLangQueryString() {
    const ecidParam = window.location.href.split('ecid=')[1];
    const langParam = window.location.href.split('LOCALE=')[1];
    if (langParam || (langParam && ecidParam)) {
      const newUrl = window.location.href.split('?')[0];
      const hash = window.location.hash;
      window.location.href = hash ? newUrl + hash : newUrl;
      return true;
    }
    return false;
  }
}

/**
 * By the given URL, searching the language information of 'curLang', if found, then replacing it
 * with the 'replaceLang'
 *
 * @param {*} url - the URL has language information
 * @param {*} curDomain - the domain to be replaced
 * @param {*} curLang - The language to be replaced
 * @param {*} replaceLang - The language to use for replacing
 * @return - Return the new created URL with different language indicator.
 */
function getReplacedUrl(url, curDomain, curLang, replaceLang) {
  const cwc = Store.getCwc();
  const metaToggleUrl = document.querySelector('meta[name="toggleUrl"]');
  const cpProtocol = window.location.protocol;
  const toggleLang = true;

  
  const currentUrl = DOMPurify.sanitize(window.location.href);
  const cpDomain = new DomainParser(currentUrl, replaceLang).getCanadaPostDomain(
    toggleLang
  );

  const isLocalHost = new DomainParser(currentUrl).isLocalhost();
  const queryParamRegEx = new RegExp('\\?q=');
  const isSearchPageQuery = queryParamRegEx.test(currentUrl);
  const queryParam = (isSearchPageQuery && url.split('?').length > 0) ? `?q=${getUrlParameterValue('q')}` : '';


  if (metaToggleUrl && metaToggleUrl.content !== '') {
    if (isLocalHost) {
      return `${cpProtocol}//${curDomain}${metaToggleUrl.content}`;
    }
  
    return `${cpProtocol}//${cpDomain}${metaToggleUrl.content}${queryParam}`;
  }

  if (cwc && cwc.cpcApp.languageToggleStrategy) {
    return cwc.cpcApp.languageToggleStrategy(url, curLang, replaceLang);
  }

  // The default language toggle replacer
  const rLang = `\\/${curLang}([\\/|#|?])|\\/${curLang}$|(language=|langue=)${curLang}|(locale=)${curLang}_ca`;
  
  const absUrl = url.replace(new RegExp(rLang, 'g'), (match, p1, p2, p3) => {
    if (p3 !== undefined && p3 === 'locale=') {
      // This is the case(for snapship)
      //  - 'http://domain/foo?locale=en_ca' or
      //  - 'http://domain/foo?locale=en_ca&bar=baz'
      return `${p3}${replaceLang}_ca`;
    }

    if (p2 !== undefined && (p2 === 'language=' || p2 === 'langue=')) {
      // This is the case(for myprofile)
      //  - 'http://domain/foo?language=en' or
      //  - 'http://domain/foo?language=en&bar=baz'
      return `${p2}${replaceLang}`;
    }

    // This is the case (using URL path)
    //  - 'http://domain/foo/en/bar' or
    //  - 'http://domain/foo/en#/bar' or
    //  - 'http://domain/foo/en#bar' or
    //  - 'http://domain/foo/en?p=1' or
    //  - 'http://domain/foo/en/ or
    //  - 'http://domain/foo/en

    return `/${replaceLang}${p1 !== undefined ? p1 : ''}`;
  });

  return absUrl.replace(curDomain, cpDomain);
}

export { initializeLangSelector, initializeLangToggle, getReplacedUrl };
