// this stuff is for inlining the icons...Ideally a webpack loader is setup that allows 
// us to point to the actual SVG on the filesystem instead of a JS file that returns the SVG source
function getLocale() {
  return {
    ariaLandmarkLabel: 'Quick links and shipping tools',
    feedback: 'Feedback',
    closeLabel: 'Close',
    closeIcon: 'cpc-tb--icon-expand-toggle',
    links: [ 
      {
        label: 'Track',
        url: '/track-reperage/en',
        icon: 'icon-track',
        hiddenMobile: false
      },
      {
        label: 'Find a postal code',
        url: '/cpc/en/tools/find-a-postal-code.page',
        icon: 'icon-postal-code',
        hiddenMobile: false
      },
      {
        label: 'Find a rate',
        url: '/cpc/en/tools/find-a-rate.page',
        icon: 'icon-find-rate',
        hiddenMobile: true
      },
      {
        label: 'Find a post office',
        url: '/cpc/en/tools/find-a-post-office.page',
        icon: 'icon-find-po',
        hiddenMobile: false
      },
      {
        label: 'Support',
        url: '/cpc/en/support.page',
        icon: 'icon-support chat-link',
        hiddenMobile: false
      },
      /* {
        label: 'Feedback',
        // eslint-disable-next-line no-script-url
        template: `
        <li id="feedback-tab-mobile-2" class="show-for-small-only">
        <a href="#" data-cpc-modal-id="#qualtricsFeedbackModal">
          <div class="cpc-tb--icon cpc-tb--icon-feedback"></div><span class="tool-description">Feedback</span>
        </a>
        </li>        
        `
      },
      }, */
      {
        label: 'More tools',
        url: '/cpc/en/tools.page',
        icon: 'icon-more-tools',
        hiddenMobile: false
      }
    ],
    homepageLinks: [ 
      {
        label: 'Track',
        url: '/track-reperage/en',
        icon: 'icon-track',
        hiddenMobile: false
      },
      {
        label: 'Find a postal code',
        url: '/cpc/en/tools/find-a-postal-code.page',
        icon: 'icon-postal-code',
        hiddenMobile: false
      },
      {
        label: 'Find a rate',
        url: '/cpc/en/tools/find-a-rate.page',
        icon: 'icon-find-rate',
        hiddenMobile: false
      },
      {
        label: 'Find a post office',
        url: '/cpc/en/tools/find-a-post-office.page',
        icon: 'icon-find-po',
        hiddenMobile: false
      },
      {
        label: 'Forward your mail',
        url: '/tools/eCOA/Web/SignIn.aspx?LOCALE=en',
        icon: 'icon-mail-forwarding',
        hiddenMobile: false
      },
      {
        label: 'ePost',
        url: '/cpc/en/personal/receiving/manage-mail/epost.page',
        icon: 'icon-epost',
        hiddenMobile: false
      }
    ]
  };
}

export default getLocale;