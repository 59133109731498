
/**
 * Due to having two official domains for CPC, canadapost.ca and postescanada.ca
 * specific cookie values are being stored globally so they can be persisted when switching languages
 */

import { getCookie, setCookie } from '../utils/cookies';
// import { getPageLanguage } from '../utils/language.data';
import { getUrlHashValue, removeHashParamater } from '../utils/url-utils';


const CANADAPOSTDOMAIN = '.canadapost.ca';
const POSTESCANADADOMAIN = '.postescanada.ca';

// list of cookie names that we want to store
const cookieNames = ['shoppingcartqty'];

/**
 * Creating stored cookies object from list of cookie names arrays
 * it looks up if the document cookie exist first and, if it does, sets it to the Store
 */
const storedCookiesObj = cookieNames.reduce((o, key) =>
  Object.assign(o, filterCookie(key)), {});

// function setLocalStorageCookieValues() {
//   cookieNames.map(cookieName =>
//     localStorage.setItem([cookieName], getCookie(cookieName)));
// }

/**
 *  This filter method is just working for the eShop qty cart scenario by looking
 *  up if the hashQty exists needs to be implement for any application 
 * @param {*} key 
 */

function filterCookie(key) {
  const hashQtyValue = getUrlHashValue('qty');
  if (hashQtyValue) {
    return { [key]: hashQtyValue };
  } else if (document.cookie.indexOf(`${key}=`) > -1) {
    return { [key]: getCookie(key) };
  }
  return false;
}

function getStoredCookies() {
  return storedCookiesObj;
}

function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

/**
 * @param {object} cookiesStore 
 * Syncs cookies for both canadapost.ca and postescanada.ca domains
 * 
 * Need to implement cookie options for every cookie name 
 * applying shoppingcartqty only so hardcoded option is fine for now
 */
function syncAllCPCCookies(cookiesStore) {
  // const domain = getPageLanguage() === 'en' ? CANADAPOSTDOMAIN : POSTESCANADADOMAIN;
  if (!isEmpty(cookiesStore)) {
    const expiredays = 9999;
    // eslint-disable-next-line no-restricted-syntax
    for (const [cookieName, cookieVal] of Object.entries(cookiesStore)) {
      // const currentCookieVal = getCookie(cookieName);

      if (cookieName === 'shoppingcartqty' && cookieVal != null && cookieVal !== '') {
        setCookie(cookieName, cookieVal, expiredays, false, CANADAPOSTDOMAIN);
        setCookie(cookieName, cookieVal, expiredays, false, POSTESCANADADOMAIN);
        removeHashParamater('qty');
      }
    }
  }
}
export { getStoredCookies, syncAllCPCCookies }; 
