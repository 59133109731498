export default {
  personal: {
    receiving: 'Receiving',
    sending: 'Sending',
    moneyGovServices: 'Money services',
    stampsCoins: 'Collectible stamps and coins'
  },
  business: {
    shipping: 'Shipping',
    marketing: 'Marketing',
    eCommerce: 'E-commerce',
    smallBusiness: 'Small business',
    postalServices: 'Postal services'
  }
};