/* eslint no-console: 0 */
/* eslint no-new: 0 */

/**
 * Global Table JS for mobile usability. This is not wrapped as a jQuery widget, 
 * and HTML is rendered from within the corresponding LiveSite component.
 * 
 * @example
 * 
 * Just ensure that the toolbar HTML is in the DOM. 
 * Events will be wired up to the toolbar.
 */
import getENLocale from './table.component.locale.en';
import getFRLocale from './table.component.locale.fr';
import { getPageLanguage } from '../../../../utils/language.data';

// document.addEventListener('DOMContentLoaded', initializeComponent);

function initializeTableComponent() {
  const mobileTablesContainer = document.querySelectorAll('.cpc-table--mobile-container');
  
  if (mobileTablesContainer.length === 0) { 
    // this particular page isn't using tables. Avoid any further processing
    return;
  }

  Array.prototype.slice.call(mobileTablesContainer).forEach((mobileContainer) => {
    const table = mobileContainer.querySelector('table');
    let tableColumns = table.querySelectorAll('thead tr > *').length - 1;
    if (tableColumns < 0) {
      // Potentially a mobile table scenario where rows became columns. 
      // Table structure slightly different as a result...
      tableColumns = table.querySelectorAll('tbody > tr:first-child > *').length - 1;
    }
    if (tableColumns > 1) {
      const prevLink = mobileContainer.querySelector('.cpc-table--prev-link');
      const nextLink = mobileContainer.querySelector('.cpc-table--next-link');
      if (prevLink) prevLink.addEventListener('click', prevLinkClick);
      if (nextLink) nextLink.addEventListener('click', nextLinkClick);
      mobileContainer.querySelector('.cpc-table--mobile-navs').classList.remove('hide');

      const locale = getPageLanguage() === 'fr' ? getFRLocale() : getENLocale();
      prevLink.innerHTML = locale.prev;
      nextLink.innerHTML = locale.next;
    }

    function prevLinkClick(evt) {
      evt.preventDefault();
      const showClass = getTableShowClass();
      const showClassIndexMatch = showClass.match(/\d+$/);
      const curShowClassIndex = +showClassIndexMatch[0];
      let nextShowClassIndex = curShowClassIndex - 1;
      nextShowClassIndex = nextShowClassIndex < 2 ?
        tableColumns + 1 : nextShowClassIndex;
      table.classList.remove(showClass);
      table.classList.remove('next');
      table.classList.remove('prev');
      table.classList.add(`out-${curShowClassIndex}`);
      table.classList.add(`show-${nextShowClassIndex}`);
      table.classList.add('prev');
      setTimeout(() => { 
        table.classList.remove('prev');
        table.classList.remove(`out-${curShowClassIndex}`);
      }, 500);
    }

    function nextLinkClick(evt) {
      evt.preventDefault();
      const showClass = getTableShowClass();
      const showClassIndexMatch = showClass.match(/\d+$/);
      const curShowClassIndex = +showClassIndexMatch[0];
      let nextShowClassIndex = curShowClassIndex + 1;
      nextShowClassIndex = nextShowClassIndex > tableColumns + 1
        ? 2 : nextShowClassIndex;
      table.classList.remove(showClass);
      table.classList.remove('next');
      table.classList.remove('prev');
      table.classList.add(`out-${curShowClassIndex}`);
      table.classList.add(`show-${nextShowClassIndex}`);
      table.classList.add('next');
      setTimeout(() => { 
        table.classList.remove('next');
        table.classList.remove(`out-${curShowClassIndex}`);
      }, 500);
    }

    function getTableShowClass() {
      let showClass = '';
      Array.prototype.slice.call(table.classList).forEach((entry) => { 
        const match = entry.match(/^show-/);
        if (match) showClass = entry;
      });
      return showClass;
    }
  });
}

export default initializeTableComponent;
