/**
 * Utility functions to help with generating SSO links. Used by the SSO modal window component, 
 * and SSO link generator module
 * 
 * Usage examples: 
 * 
 * ssoLinkMap - supported SSO options. Available options:
 * 
 * srcUrl 
 *    - where do we send the user if they cancel
 *    - default = window.location.href 
 *  
 *  targetUrl
 *    - where do we send the user after signup / signin (required). Supported URL tokens:
 * 
 *    - $DOMAIN_URL$ - this token generates a dev/stg environment 
 *      specific canadapost.ca sub-domain part. I.e. on prod, this would be
 *      'www.', on stg12, this would be 'stg12.'
 * 
 *    - $EST_SUBDOMAIN$ - for est.canadapost-postescanada.ca URLs, this token will generate dev/stg environment
 *      specific est sub-domain URLs
 * 
 *    - $SUBDOMAIN$ - same as $EST_SUBDOMAIN$ (retained for backward compatibility)
 * 
 *    - $CPC_LANG$ - generates 'en' for English locale pages, 
 *      'fr' for French locale pages
 * 
 *    - $SHIPPING_LOCALE$ - generates 'en_CA' for English locale pages,
 *      'fr_CA' for Frech locale pages
 * 
 *  businessUrl, personalUrl, templateId, manifestId, sourceApp
 * 
 *  stepupType options 
 *    - this is the SSO URL part that renders such as 
 *      stepupId=smb_mode1,consumer,commercial_link,smb_link, or
 *      stepupId=consumer, etc. 
 *    - this allows you to control some sign up flow options
 *    
 *    renderSMBMode: 
 *      0 = off
 *      1 = renders 'smb_mode1' as a stepupId (default)
 *      2 = renders 'smb_mode2' as a stepupId
 *    renderConsumer:
 *      false = not rendered (default)
 *      true = renders 'consumer' as a stepupId
 *    renderCommercialLink: 
 *      false = not rendered (default)
 *      true = renders 'commercial_link" as a stepupId
 *    renderSMBLink: 
 *      false = not rendered (ddfault) 
 *      true = renders 'smb_link' as a stepupId
 * const ssoLinkDefault = { 
 *   srcUrl: window.location.href,
 *   renderSMBMode: '1',
 *   renderConsumer: false,
 *   renderCommercialLink: false,
 *   renderSMBLink: false,
 *   templateId: 'cpcapps',
 *   manifestId: 'bizSecurity',
 *   sourceApp: `${SSOLocale.SMB}`
 * };
 * 
 */
import SSOLocale from './sso-link.locale';
import authModule from '../auth.module';
import {
  getPageLanguage
} from '../language.data';
import {
  getStore,
  storeKeys
} from '../data-store';


const lang = getPageLanguage();
const loginRoot = `${authModule.getSSODomain()}/lfe-cap/${lang}/${SSOLocale.login}`;
let registerRoot = `${authModule.getSSODomain()}/pfe-pap/${lang}/${SSOLocale.registration}`;
const forgotUsernameRoot = `${authModule.getSSODomain()}/lfe-cap/${lang}/${SSOLocale.forgotUsername}`;
const forgotPasswordRoot = `${authModule.getSSODomain()}/lfe-cap/${lang}/${SSOLocale.forgotPassword}`;

const domainRoot = authModule.getDomain();
const linkParser = document.createElement('a');

const localeMap = {
  en: 'en_CA',
  fr: 'fr_CA'
};

/**
 * All available SSO links
 */
const ssoLinkMap = {
  default: {
    srcUrl: window.location.href,
    renderSMBMode: '1',
    renderConsumer: true,
    renderCommercialLink: true,
    renderSMBLink: true,
    templateId: 'cpcapps',
    manifestId: getManifestId,
    renderStepup: `${SSOLocale.true}`,
  },
  'default-with-consumer': {
    renderConsumer: true,
  },
  biz: {
    srcUrl: window.location.href,
    renderConsumer: false,
    renderCommercialLink: true,
    renderSMBLink: true,
    manifestId: 'bizSecurity',
    renderStepup: `${SSOLocale.true}`,
    sourceApp: `${SSOLocale.SMB}`,
    routeRegistration: `${SSOLocale.business}`,
  },
  personal: {
    srcUrl: window.location.href,
    renderConsumer: true,
    renderCommercialLink: false,
    renderSMBLink: false,
    templateId: 'cpcapps',
    manifestId: getManifestId,
    renderStepup: `${SSOLocale.true}`,
    routeRegistration: `${SSOLocale.personal}`,
  },
  pup: {
    srcUrl: window.location.href,
    renderSMBMode: '1',
    renderConsumer: false,
    renderCommercialLink: true,
    renderSMBLink: true,
    manifestId: 'bizSecurity',
    renderStepup: `${SSOLocale.true}`,
    sourceApp: 'PUP',
    routeRegistration: `${SSOLocale.business}`,
  },
  biz_smb_mode1: {
    srcUrl: window.location.href,
    renderSMBMode: '1',
    renderConsumer: false,
    renderCommercialLink: true,
    renderSMBLink: true,
    manifestId: 'bizSecurity',
    renderStepup: `${SSOLocale.true}`,
    sourceApp: `${SSOLocale.SMB}`,
    routeRegistration: `${SSOLocale.business}`,
  },
  smb_mode1: {
    srcUrl: window.location.href,
    renderSMBMode: '1',
    renderConsumer: false,
    renderCommercialLink: false,
    renderSMBLink: true,
    manifestId: 'bizSecurity',
    renderStepup: `${SSOLocale.true}`,
    sourceApp: `${SSOLocale.SMB}`,
    routeRegistration: `${SSOLocale.business}`,
  },
  biz_smb_mode2: {
    srcUrl: window.location.href,
    renderSMBMode: '2',
    renderConsumer: false,
    renderCommercialLink: true,
    renderSMBLink: true,
    manifestId: 'bizSecurity',
    renderStepup: `${SSOLocale.true}`,
    sourceApp: `${SSOLocale.SMB}`,
    routeRegistration: `${SSOLocale.business}`,
  },
  smb_mode2: {
    srcUrl: window.location.href,
    renderSMBMode: '2',
    renderConsumer: false,
    renderCommercialLink: false,
    renderSMBLink: true,
    manifestId: 'bizSecurity',
    renderStepup: `${SSOLocale.true}`,
    sourceApp: `${SSOLocale.SMB}`,
    routeRegistration: `${SSOLocale.business}`,
  },
  commercial: {
    srcUrl: window.location.href,
    renderSMBMode: '0',
    renderConsumer: false,
    renderCommercialLink: true,
    renderSMBLink: false,
    manifestId: 'bizSecurity',
    renderStepup: `${SSOLocale.true}`,
  },
  epost: {
    srcUrl: window.location.href,
    renderSMBMode: '0',
    renderConsumer: true,
    renderCommercialLink: false,
    renderSMBLink: false,
    templateId: 'cpcapps',
    manifestId: 'epostCreate',
    sourceApp: `${SSOLocale.EPOST}`,
    renderStepup: `${SSOLocale.true}`,
    routeRegistration: `${SSOLocale.personal}`,
  },
  automatic_tracking: {
    srcUrl: window.location.href,
    renderSMBMode: '0',
    renderConsumer: true,
    renderCommercialLink: false,
    renderSMBLink: false,
    sourceApp: `${SSOLocale.AUTOMATIC_TRACKING}`,
    renderStepup: `${SSOLocale.true}`,
    routeRegistration: `${SSOLocale.personal}`,
  },
  connect: {
    srcUrl: window.location.href,
    renderSMBMode: '0',
    renderConsumer: true,
    renderCommercialLink: true,
    renderSMBLink: true,
    templateId: 'cpcapps',
    manifestId: getManifestId,
    renderStepup: `${SSOLocale.true}`,
    routeRegistration: `${SSOLocale.personal}`,
  },
  flex: {
    srcUrl: window.location.href,
    renderSMBMode: '0',
    renderConsumer: false,
    renderCommercialLink: false,
    renderSMBLink: false,
    templateId: 'dtpoSignup',
    manifestId: 'dtpoSignup',
    sourceApp: 'FLEX',
    renderStepup: false,
    routeRegistration: `${SSOLocale.personal}`,
  },
  shipping: {
    renderConsumer: false,
    manifestId: 'bizSecurity',
    sourceApp: `${SSOLocale.SMB}`,
    businessUrl: `https://${authModule.getESTSubdomain()}est-oee.canadapost-postescanada.ca/esto-oee/app/shippingtools-outilsexpedition?locale=${localeMap[lang]}`
  },
  dashboard: {
    targetUrl: `${domainRoot}/dash-tableau/${lang}`,
    businessUrl: `${domainRoot}/dash-tableau/${lang}?${SSOLocale.signIn}=${SSOLocale.true}`,
    renderConsumer: false,
    renderCommercialLink: false,
    renderSMBLink: false,
  },
  ship_online: {
    srcUrl: window.location.href,
    renderSMBMode: '0',
    renderConsumer: true,
    renderCommercialLink: false,
    renderSMBLink: false,
    templateId: 'cpcapps',
    manifestId: 'consrSecurity',
    sourceApp: `${SSOLocale.ShipOnline}`,
    renderStepup: `${SSOLocale.true}`,
    routeRegistration: `${SSOLocale.personal}`,
  },
  shipping_manager: {
    srcUrl: window.location.href,
    renderSMBMode: '1',
    renderConsumer: false,
    renderCommercialLink: false,
    renderSMBLink: true,
    manifestId: 'bizSecurity',
    renderStepup: `${SSOLocale.true}`,
    sourceApp: `${SSOLocale.SMB}`,
    routeRegistration: `${SSOLocale.business}/${SSOLocale.existing}`,
  },
  rso: {
    srcUrl: window.location.href,
    renderSMBMode: '1',
    renderConsumer: true,
    renderCommercialLink: true,
    renderSMBLink: true,
    templateId: 'cpcapps',
    manifestId: getManifestId,
    renderStepup: `${SSOLocale.true}`,
    sourceApp: `${SSOLocale.RSO}`,
  },
  sms: {
    srcUrl: window.location.href,
    renderSMBMode: '1',
    renderConsumer: true,
    renderCommercialLink: true,
    renderSMBLink: true,
    sourceApp: `${SSOLocale.SMS}`,
    renderStepup: `${SSOLocale.true}`,
  },
  'smb-link': {
    // For arbitrary URLs provided in the href of an anchor tag
  }
};

/**
 * 
 * @param {*} ssoOptions - Options for rendering an SSO login link:
 *   type: One of the the following: 
 *      default - see ssoLinkMap.default
 *      dashboard - see ssoLinkMap.dashboard
 *   targetHref: destination after successful sign in / sign up.
 *      - Can be a relative or absolute URL
 *   targetHref can include the following tokens: 
 *      - SUBDOMAIN - renders environment specific EST subdomain
 */
function createLoginLink(options, targetHref) {
  return createLink(options, targetHref, loginRoot);
}

/**
 * 
 * @param {*} ssoOptions - Options for rendering an SSO register link:
 *   type: One of the the following: 
 *      default - see ssoLinkMap.default
 *      dashboard - see ssoLinkMap.dashboard
 *   targetHref: destination after successful sign in / sign up.
 *      - Can be a relative or absolute URL
 *   targetHref can include the following tokens: 
 *      - SUBDOMAIN - renders environment specific EST subdomain
 */
function createRegisterLink(options, targetHref) {
  if (options.type === 'default-with-consumer') {
    registerRoot = `${authModule.getSSODomain()}/pfe-pap/${lang}/${SSOLocale.registration}`;
  }
  return createLink(options, targetHref, registerRoot);
}

function createForgotUsernameLink(options, targetHref) {
  return createLink(options, targetHref, forgotUsernameRoot, false);
}

function createForgotPasswordLink(options, targetHref) {
  options.renderStepup = false;
  return createLink(options, targetHref, forgotPasswordRoot, false);
}

function createLink(options, targetHref, linkRoot, renderStepup = true) {
  const mapEntry = ssoLinkMap[options.type];

  if (!mapEntry) {
    console.error(`Unknown SSO link id. Id provided was ${options.type}`);
    return null;
  }

  const ssoOptions = Object.assign({}, ssoLinkMap.default, mapEntry);
  if (options.businessUrl && options.businessUrl !== 'undefined') {
    ssoOptions.businessUrl = options.businessUrl;
  }
  if (options.businessUrl === 'undefined') {
    ssoOptions.businessUrl = '';
  }
  if (options.manifestId) {
    ssoOptions.manifestId = options.manifestId;
  }
  ssoOptions.renderStepup = renderStepup;
  if (targetHref && targetHref !== '#') {
    ssoOptions.targetUrl = getAbsoluteUrl(targetHref);
  }

  return generateSSOLink(ssoOptions, linkRoot);
}

function getAbsoluteUrl(url) {
  const parsedUrl = url
    .replace(/\$SUBDOMAIN\$/gi, authModule.getESTSubdomain())
    .replace(/\$EST_SUBDOMAIN\$/gi, authModule.getESTSubdomain())
    .replace(/\$EPOST_DOMAIN\$/gi, authModule.getEpostDomain())
    .replace(/\$DOMAIN_URL\$/gi, authModule.getDomain())
    .replace(/\$CSRF_TOKEN\$/gi, authModule.getCSRFToken())
    .replace(/\$CPC_LANG\$/gi, lang);
  linkParser.href = parsedUrl;
  return linkParser.href;
}


function generateSSOLink(ssoOptions, linkRoot = loginRoot) {
  let sourceApp = !ssoOptions.sourceApp ? '' : `&${SSOLocale.sourceApp}=${ssoOptions.sourceApp}`;
  const routeRegistration = !ssoOptions.routeRegistration ? '' : `/${ssoOptions.routeRegistration}`;
  const businessUrl = !ssoOptions.businessUrl ? '' : `&${SSOLocale.businessUrl}=${encodeURIComponent(ssoOptions.businessUrl)}`;
  const personalUrl = !ssoOptions.personalUrl ? '' : `&${SSOLocale.personalUrl}=${encodeURIComponent(ssoOptions.personalUrl)}`;
  const sourceUrl = `&${SSOLocale.sourceUrl}=${encodeURIComponent(ssoOptions.srcUrl)}`;
  const targetUrl = `&${SSOLocale.targetUrl}=${encodeURIComponent(ssoOptions.targetUrl || ssoOptions.srcUrl)}`;
  if (linkRoot === registerRoot) {
    sourceApp = !ssoOptions.sourceApp ? '' : `${SSOLocale.sourceApp}=${ssoOptions.sourceApp}`;
    return `${linkRoot}${routeRegistration}?${sourceApp}${sourceUrl}${targetUrl}${businessUrl}${personalUrl}`;
  }

  return `${linkRoot}?${generateStepUpURLParam(ssoOptions)}${sourceApp}${sourceUrl}${targetUrl}${businessUrl}${personalUrl}`;
}

function generateStepUpURLParam(ssoOptions) {
  const params = [];
  if (!ssoOptions.renderStepup) return '';

  if (ssoOptions.renderSMBMode !== '0') {
    params.push(`${SSOLocale.smb_mode}${ssoOptions.renderSMBMode}`);
  }
  if (ssoOptions.renderConsumer) params.push(`${SSOLocale.consumer}`);
  if (ssoOptions.renderCommercialLink) params.push(`${SSOLocale.commercial_link}`);
  if (ssoOptions.renderSMBLink) params.push(`${SSOLocale.smb_link}`);

  const ret = params.length === 0 ? '' : `${SSOLocale.stepupId}=${params.join()}`;
  return ret;
}

function getManifestId() {
  // This is for the global signin - previously there was a specific business flow
  // which leveraged the 'bizSecurity' parameter
  // return getStore(storeKeys.cwc) ? 'cpgSecurity' : 'bizSecurity';
  return getStore(storeKeys.cwc) ? 'cpgSecurity' : 'cpgSecurity';
}

export {
  createLoginLink,
  createRegisterLink,
  createForgotUsernameLink,
  createForgotPasswordLink,
  getAbsoluteUrl
};