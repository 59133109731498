/* eslint no-nested-ternary:0 */
/* eslint no-underscore-dangle: 0 */

import templateHtml from './mobile-nav.component.html';
import cartIcon from '../cart.icon';
import getShoppingCartQuantity from '../../../../../utils/cart.data';
import authModule from '../../../../../utils/auth.module';
import setLocaleEn from '../locale_en';
import setLocaleFr from '../locale_fr';
import { getPageLanguage } from '../../../../../utils/language.data';
import DomainParser from '../../../../../utils/domain-utils';
import { getStore, storeKeys } from '../../../../../utils/data-store';
import {
  createSsoSignInLink,
  createSsoSignUpLink,
  createSsoForgotUsernameLink,
  createSsoForgotPasswordLink,
  getModalCopy,
} from '../../Sign-In/sign-in.component';

const logoRelUrl = 'assets/cpc/img/logos/cpc-logo.svg';
const hamburgerRelUrl = 'assets/cpc/img/icons/hamburger.svg';
const searchRelUrl = 'assets/cpc/img/icons/search.svg';
const cpDomain = new DomainParser(window.location.href).getCanadaPostDomainUrl('');
const lang = getPageLanguage();
const headerText = lang === 'fr' ? setLocaleFr() : setLocaleEn();
const hostname = window.location.hostname;

const domainParser = new DomainParser(window.location.href);
const assetSchemeAndRoot = `https://${domainParser.getCanadaPostDomain()}`;
const isEstore = () => /(sandbox\.mozu\.com|\.mozu\.com|boutique|store)/i.test(hostname);


function getPrimaryNav(navType, currentNodePage) {
  const kiboAuthParam = '?auth=1';
  let menuHtml = '';
  let isChildClass = 'menu-hide';
  let overviewLabel = '';
  let lvl1Nav = '';

  
  const nav = getStore(storeKeys.nav) ? getStore(storeKeys.nav) : null;
 

  let navNodeRoot = nav.nodes.find(ele => 
    (ele.label ? ele.label.toLowerCase() === navType.toLowerCase() : false)
  );

  if (navType === 'campaign') {
    navNodeRoot = currentNodePage !== '' ? navNodeRoot.nodes.find(ele => ele.label === currentNodePage) : '';
    // return navNodeRoot;
  }

  if (!navNodeRoot.nodes && !Array.isArray(navNodeRoot.nodes)) {
    return false;
  }

  navNodeRoot.nodes.forEach((node1) => {
    let lvl2Nav = '';
    let parentLink = '';
   
    if (node1.nodes) {
      for (let node2Ctr = 0; node2Ctr < node1.nodes.length; node2Ctr += 1) {
        const node2 = node1.nodes[node2Ctr];

        let lvl3Nav = '';
        parentLink = '';
        isChildClass = 'menu-hide';
        if (node2.nodes) {
          for (let node3Ctr = 0; node3Ctr < node2.nodes.length; node3Ctr += 1) {
            const node3 = node2.nodes[node3Ctr];

            let lvl4Nav = '';
            parentLink = '';
            isChildClass = 'menu-hide';
            if (node3.nodes) {
              for (
                let node4Ctr = 0;
                node4Ctr < node3.nodes.length;
                node4Ctr += 1
              ) {
                const node4 = node3.nodes[node4Ctr];

                let lvl5Nav = '';
                parentLink = '';
                isChildClass = 'menu-hide';
                if (node4.nodes) {
                  for (
                    let node5Ctr = 0;
                    node5Ctr < node4.nodes.length;
                    node5Ctr += 1
                  ) {
                    const node5 = node4.nodes[node5Ctr];

                    let lvl6Nav = '';
                    parentLink = '';
                    isChildClass = 'menu-hide';
                    if (node5.nodes) {
                      for (
                        let node6Ctr = 0;
                        node6Ctr < node5.nodes.length;
                        node6Ctr += 1
                      ) {
                        const node6 = node5.nodes[node6Ctr];
                        lvl6Nav += templateHtml.mainBusinessNav.lvl6({
                          link: getNodeLink(node6),
                          label: node6.label,
                          linktarget6: getNodeTarget(node6),
                          isParentClass: parentLink,
                          isChildClass,
                          linkAlt: node6.target === '_blank'
                            ? headerText.opensInNewTab
                            : '',
                        });

                        parentLink = 'parent-link';
                        isChildClass = 'menu-item-level';
                      }
                    }

                    lvl5Nav += templateHtml.mainBusinessNav.lvl5({
                      lvl5Link: getNodeLink(node5),
                      lvl5Label: node5.label,
                      lvl5Target: getNodeTarget(node5),
                      isParentClass: parentLink,
                      isChildClass,
                      lvl6: lvl6Nav,
                      linkAlt: node5.target === '_blank'
                        ? headerText.opensInNewTab
                        : '',
                    });

                    parentLink = 'parent-link';
                    isChildClass = 'menu-item-level';
                  }
                }
              
             
                lvl4Nav += templateHtml.mainBusinessNav.lvl4({
                  lvl4Link: getNodeLink(node4),
                  lvl4Label: node4.label,
                  lvl4Description: node4.description === undefined ? '' : node4.description,
                  lvl4Target: getNodeTarget(node4),
                  lvl3Label: node3.label,
                  isParentClass: parentLink,
                  isChildClass,
                  lvl5: lvl5Nav,
                  linkAlt: node4.target === '_blank'
                    ? headerText.opensInNewTab
                    : '',
                });

                parentLink = 'parent-link';
                isChildClass = 'menu-item-level';
              }
            }
              
            lvl3Nav += templateHtml.mainBusinessNav.lvl3({
              lvl3Label: node3.label,
              lvl3Link: getNodeLink(node3),
              lvl3Description: node3.description === undefined ? '' : node3.description,
              lvl3Target: getNodeTarget(node3),
              lvl2Label: node2.label,
              isParentClass: parentLink,
              isChildClass,
              lvl4: lvl4Nav,
              linkAlt: node3.target === '_blank'
                ? headerText.opensInNewTab
                : '',
            });

            parentLink = 'parent-link';
            isChildClass = 'menu-item-level';
          }
        }

        lvl2Nav += templateHtml.mainBusinessNav.lvl2({
          lvl2Link: getNodeLink(node2),
          lvl2Label: node2.label,
          lvl2Description: node2.description === undefined ? '' : node2.description,
          lvl2Target: getNodeTarget(node2),
          isParentClass: parentLink,
          isChildClass,
          lvl3: lvl3Nav,
          linkAlt: node2.target === '_blank'
            ? headerText.opensInNewTab
            : '',
        });

        parentLink = 'parent-link';
        isChildClass = 'menu-item-level';
      }
    } else {
      isChildClass = 'menu-hide';
    }
    
    lvl1Nav += templateHtml.mainBusinessNav.lvl1({
      lvl1Link: getNodeLink(node1),
      isParentClass: parentLink,
      lvl1Label: node1.label,
      isChildClass,
      lvl1Description: node1.description === undefined ? '' : node1.description,
      lvl1Target: getNodeTarget(node1),
      lvl2: lvl2Nav,
      linkAlt: node1.target === '_blank'
        ? headerText.opensInNewTab
        : '',
    
    });
    return lvl1Nav;
  });


  if (!navNodeRoot) return '';

  switch (navType) {
    case 'business':
      overviewLabel = headerText.businessOverview;
      break;
    case 'personal':
      overviewLabel = headerText.personalOverview;
      break;
    case 'our company':
      overviewLabel = headerText.ourcompanyOverview;
      break;
    case 'shop':
      overviewLabel = headerText.eshopOverview;
      break;
    default:
      overviewLabel = headerText.personalOverview;
  }
  if (navType === 'campaign') {
    menuHtml += templateHtml.mainBusinessNav.campaignLvl0({
      isParentClass: 'parent-link',
      lvl0Description: navNodeRoot.description,
      lvl0Target: 'target' in navNodeRoot && navNodeRoot.target !== undefined ? navNodeRoot.target : '',
      lvl0Label: navNodeRoot.label,
      lvl0Link: getNodeLink(navNodeRoot),
      lvl1: lvl1Nav,
      parentLabel: navNodeRoot.label,
      overviewLabel,
      ctaHide: isMainMenuLinks() ? '' : 'hide',
      cta: getCampaignCtaMenu('mainMenu')
    });

    return menuHtml;
  }

  menuHtml += templateHtml.mainBusinessNav.lvl0({
    isParentClass: 'parent-link',
    lvl0Description: navNodeRoot.description,
    lvl0Target: 'target' in navNodeRoot && navNodeRoot.target !== undefined ? navNodeRoot.target : '',
    lvl0Label: navNodeRoot.label,
    lvl0Link: getNodeLink(navNodeRoot),
    lvl1: lvl1Nav,
    parentLabel: navNodeRoot.label,
    overviewLabel
  });

  return menuHtml;

  
  function getNodeLink(node) {
    if (navType.toLowerCase() === 'shop' || navType.toLowerCase() === 'boutique') {
      const storeDomain = authModule.getStoreDomain(lang);
      let nodeLink = storeDomain + node.link;
      if ('overViewContent' in node && node.overViewContent.link !== undefined) {
        nodeLink = storeDomain + node.overViewContent.link;
      }
      if (authModule.getIsAuthenticated()) nodeLink += kiboAuthParam;
      return nodeLink;
    }   
    return node.target === '_blank' ? node.link : cpDomain + node.link;
  }

  function getNodeTarget(node) {
    if (navType.toLowerCase() === 'shop' || navType.toLowerCase() === 'boutique') {
      return '';
    }   
    return 'target' in node && node.target !== undefined ? node.target : '';
  }
}

function isMainMenuLinks() {
  const mainMenuElem = document.querySelector('.cl-top-section_anchors');
  const mainMenuAnchor = mainMenuElem.querySelector('.cl-top-section_nav-item');
  return mainMenuElem.children.length !== 0 && mainMenuAnchor.innerHTML !== '';
}

function getUtilityNav() {
  let utilityMenu = '';
  const nav = getStore(storeKeys.nav) ? getStore(storeKeys.nav) : null;
  nav.nodes
    .find(ele => ele.label === 'Utility')
    .nodes.forEach((node1) => {
      utilityMenu += templateHtml.utilityNav.lvl1({
        url: node1.target === '_blank' ? node1.link : cpDomain + node1.link,
        label: node1.label,
      });
    });
  return utilityMenu;
}

function getPersonalBusinessNode(navType) {
  const nav = getStore(storeKeys.nav) || null;
  let label = '';
  let personalBusinessNode = [];

  if (nav) {
    personalBusinessNode = nav.nodes.find(
      ele => ele.label === 'PersonalBusiness'
    );
    personalBusinessNode = personalBusinessNode.nodes.find(
      ele => ele.label.toLowerCase() === navType
    );
    label =
      personalBusinessNode && 'label' in personalBusinessNode
        ? personalBusinessNode.label
        : '';
  }

  return { label };
}

const cartLink = getCartLink();
function getCartLink() {
  const cartQty = getShoppingCartQuantity();
  const cartLinkRes =
    cartQty > 0
      ? templateHtml.utilityNav.cart({
        lvl1Url: cpDomain + headerText.cartURL,
        cartQty,
        cartLabel: headerText.cartLabel,
        cartSvg: cartIcon,
      })
      : '';

  return cartLinkRes;
}

function getSignedInNav() {
  let signedInNav = '';
  let additionalItems = '';
  const userType = getUserType();
  if (userType === 'business') {
    additionalItems = `<li><a href="${cpDomain}${headerText.linkBusinessSettings}" class="parent-link">${headerText.businessSettings}</a></li><li><a href="${cpDomain}${headerText.linkInvoices}" class="parent-link">${headerText.invoices}</a></li>`;
  }

  signedInNav = templateHtml.signedInNav({
    welcome: headerText.welcome,
    userName: authModule.getIsAuthenticated() ? authModule.getSSOUserName() : '',
    cpcLang: lang,
    cpDomain,
    navDashboard: headerText.dashboard,
    navProfile: headerText.myprofile,
    additionalItems,
    mysupport: headerText.mysupport,
    linkMySupport: headerText.linkMySupport,
    signOut: headerText.signOut,
  });

  return signedInNav;
}

function getLogInMenu() {
  const nav = getStore(storeKeys.nav) ? getStore(storeKeys.nav) : null;
  const mode = 'default-with-consumer';
  const modalCopy = getModalCopy(mode);


  if (isEstore()) {
    const eStoreSubDomain = hostname.split('.')[0];

    switch (eStoreSubDomain) {
      case 'shop':
      case 't29025-s47547':
        headerText.kiboSignIn = '/shop-login';
        break;

      case 'boutique':
      case 't29025-s47548':
        headerText.kiboSignIn = '/boutique-login';
        break;

      case 't28065-s45123':
        headerText.kiboSignIn = '/shop-qa-login';
        break;

      case 't28065-s45124':
        headerText.kiboSignIn = '/boutique-qa-login';
        break;

      case 't29011-s47513':
        headerText.kiboSignIn = '/shop-sit-login';
        break;

      case 't29011-s47514':
        headerText.kiboSignIn = '/boutique-sit-login';
        break;

      case 't28112-s45215':
        headerText.kiboSignIn = '/shop-demo-login';
        break;

      case 't28112-s45216':
        headerText.kiboSignIn = '/boutique-demo-login';
        break;

      case 't28166-s45346':
        headerText.kiboSignIn = '/shop-dev-login';
        break;
        
      case 't28166-s45347':
        headerText.kiboSignIn = '/boutique-dev-login';
        break;
      
      case 't30296-s50851':
        headerText.kiboSignIn = '/shop-stg-login';
        break;
      
      case 't30296-s50852':
        headerText.kiboSignIn = '/boutique-stg-login';
        break;
          
      // file deepcode ignore DuplicateCaseBody: fallback
      default:
        headerText.kiboSignIn = '/shop-login';
        break;
    }

    return templateHtml.myAccountKibo.lvl1({
      signInLink: assetSchemeAndRoot + headerText.kiboSignIn,
      signInText: headerText.myAccountTab,
    });
  }

  return templateHtml.logInNav({
    createAccount: headerText.createAccount,
    forgot: headerText.forgot,
    forgotPasswordUsernameLabel: headerText.forgotPasswordUsernameLabel,
    forgotPasswordLabel: headerText.forgotPasswordLabel,
    signInButton: headerText.signInButton,
    signInHeader: headerText.signInHeader,
    signInLabel: nav.signInLabel,
    password: headerText.password,
    username: headerText.username,
    rememberUsername: headerText.rememberUsername,
    ssoSignInLink: createSsoSignInLink(mode, modalCopy),
    ssoSignUpLink: createSsoSignUpLink(mode, modalCopy),
    ssoForgotPasswordLink: createSsoForgotPasswordLink(mode, modalCopy),
    ssoForgotUsernameLink: createSsoForgotUsernameLink(mode, modalCopy),
    signUp: headerText.signUp,
    signUpText: headerText.signUpText,
    or: headerText.or,
    cpcLang: lang
  });
}
// TODO: generate a list instead of manually picking personnal business nodes
function getMainMenuNav(siteMapRoot, currentPage) {
  if (siteMapRoot === 'campaign') {
    if (isMainMenuLinks()) {
      return templateHtml.campaignMenu({
        cpDomain,
        cpcLang: lang,
        campaignNav: currentPage !== '' ? getCampaignLinksID() + getPrimaryNav('campaign', currentPage) : getCampaignLinksID(),
      });
    }
    return '';
  }
  return templateHtml.mainMenuNav({
    cpDomain,
    cpcLang: lang,
    personal: lang === 'fr' ? getPersonalBusinessNode('personnel').label : getPersonalBusinessNode('personal').label,
    business: lang === 'fr' ? getPersonalBusinessNode('entreprise').label : getPersonalBusinessNode('business').label, 
    ourCompany: lang === 'fr' ? getPersonalBusinessNode('notre entreprise').label : getPersonalBusinessNode('our company').label,
    store: lang === 'fr' ? getPersonalBusinessNode('boutique').label : getPersonalBusinessNode('shop').label,
    support: headerText.support,
    supportUrl: headerText.supportUrl
  });
}

function getQuickLinkNav() {
  return templateHtml.quickLinkMenu({
    cpDomain,
    cpcLang: lang,
    track: headerText.track,
    eShop: headerText.eShop,
    findPostalCode: headerText.findPostalCode,
    findPostalCodeURL: headerText.findPostalCodeURL,
    findRate: headerText.findRate,
    findRateURL: headerText.findRateURL,
    seeMoreTools: headerText.seeMoreTools,
    moreToolsURL: headerText.moreToolsURL
  });
}

function getCampaignCtaMenu(lvl) {
  const topSectionCta1 = document.querySelector('.cl-cta1');
  let resHtml = '';
  if (topSectionCta1) {
    const topSectionCta1Anchor = topSectionCta1.querySelector('a');
    const cta1AnchorClass = lvl === 'mainMenu' ? '' : 'button secondary';
    resHtml = `<li><a href="${topSectionCta1Anchor.href}" class="${cta1AnchorClass}"><span>${topSectionCta1.innerText}</span></a></li>`;
  }
  return resHtml;
}

function getCampaignLinksID() {
  const campaignSectionAnchors = [...document.querySelectorAll('.cl-top-section_anchors li a[id]')];
  if (!campaignSectionAnchors) return ''; 
  let campaignLinksHtml = '';
  campaignSectionAnchors.forEach((node) => {
    campaignLinksHtml += `<li><a id="${node.id}" href="${node.href}"> ${node.innerText}</a></li>`;
  });
  return campaignLinksHtml;
}

function getCampaignUtilsMenu() {
  const langElem = document.querySelectorAll('.language-toggle, .lang-toggle');
  const visitElem = document.querySelector('.visit-link');
  const langToggleElem = document.querySelector('.language-toggle');
  const langToggleClass = langToggleElem ? 'language-toggle-mobile' : 'lang-toggle';
  const langHtml = typeof langElem[0] !== 'undefined' ? `<li class="${langToggleClass}">${langElem[0].innerHTML}</li>` : '';
  const visitHtml = visitElem ? `<li>${visitElem.innerHTML}</li>` : '';
  return `${langHtml} ${visitHtml}`;
}

function getCampaignHeader(siteMapRoot) {
  const topSectionCta2 = document.querySelector('.cl-cta2 a');
  const topSectionTitleAnchor = document.querySelector('.cl-top-section_title a');

  if (siteMapRoot !== 'campaign') return '';
  if (topSectionCta2) {
    const topSectionDataRevealId = topSectionCta2.getAttribute('data-reveal-id');
    return {
      CtaText: topSectionCta2.innerText,
      CtaLink: topSectionCta2.href,
      campaignCtaRevealId: topSectionDataRevealId ? `data-reveal-id='${topSectionDataRevealId}' ` : '',
      campaignLandingPageLink: topSectionTitleAnchor.href

    };
  }
  return {
    campaignLandingPageLink: topSectionTitleAnchor.href
  };
}


function renderMobileNav(base, siteMapRoot, currentPage) {
  let globalNavHtml = templateHtml;
  let businessMenu = '';
  let personalMenu = '';
  let aboutusMenu = '';
  let eshopMenu = '';
  let utilityMenu = '';
  let mainMenuNav = '';
  let quickLinkMenu = '';
  let signedInMenu = '';
  let logInMenu = '';
  let campaignCtaMenu = '';
  let campaignUtilsMenu = '';


  const nav = getStore(storeKeys.nav) ? getStore(storeKeys.nav) : null;

  if (nav) {
    businessMenu = lang === 'fr' ? getPrimaryNav('entreprise') : getPrimaryNav('business'); 
    personalMenu = lang === 'fr' ? getPrimaryNav('personnel') : getPrimaryNav('personal');
    aboutusMenu = lang === 'fr' ? getPrimaryNav('notre entreprise') : getPrimaryNav('our company');
    utilityMenu = getUtilityNav();
    eshopMenu = lang === 'fr' ? getPrimaryNav('boutique') : getPrimaryNav('shop'); 
    mainMenuNav = getMainMenuNav(siteMapRoot, currentPage);
    quickLinkMenu = getQuickLinkNav();
    signedInMenu = getSignedInNav();
    logInMenu = getLogInMenu();
    campaignCtaMenu = getCampaignCtaMenu();
    campaignUtilsMenu = getCampaignUtilsMenu();
  }


  globalNavHtml = globalNavHtml.mobileContainer({
    mobileHeader: renderMobileHeader(siteMapRoot),
    mobileBody: renderMobileBody(siteMapRoot)
  });

  function renderMobileHeader(sublevels) {
    let mobileHeader = '';

    if (sublevels === true) {
      mobileHeader += templateHtml.header.lvl0({
        hamburgerUrl: nav.assetBaseUrl + hamburgerRelUrl,
        close: headerText.closeLabel,
        myAccountURL: authModule.getIsAuthenticated() ? authModule.getLogoutLink() : '#',
        cpDomain,
        cpcLang: lang,
        searchURL: assetSchemeAndRoot + nav.assetBaseUrl + searchRelUrl,
        loginBack: headerText.backLabel,
        signOut: headerText.signOut,
        signInRegister: headerText.signInRegister,
        hideLinks: siteMapRoot === 'campaign' ? 'hide' : '',
        showLinks: siteMapRoot === 'campaign' ? '' : 'hide',
        showCtaCampaignLinks: siteMapRoot === 'campaign' && document.querySelector('.cl-cta2 a') ? '' : 'hide',
        campaignCtaText: getCampaignHeader(siteMapRoot).CtaText,
        campaignCtaURL: getCampaignHeader(siteMapRoot).CtaLink,
        campaignCtaRevealId: getCampaignHeader(siteMapRoot).campaignCtaRevealId,
        campaignLandingPageURL: getCampaignHeader(siteMapRoot).campaignLandingPageLink,
      });

      mobileHeader += templateHtml.header.lvl1({
        mainMenu: headerText.mainMenu
      });
      mobileHeader += templateHtml.header.lvl2({
        backLabel: headerText.backLabel,
        backMainMenu: headerText.backMainMenu
      });
      return mobileHeader;
    }

    mobileHeader += templateHtml.header.main({
      hamburgerUrl: nav.assetBaseUrl + hamburgerRelUrl,
      myAccountURL: authModule.getIsAuthenticated() ? authModule.getLogoutLink() : '#',
      logoAltText: headerText.logoAltText,
      logoURL: assetSchemeAndRoot + nav.assetBaseUrl + logoRelUrl,
      cartLink,
      cpDomain,
      cpcLang: lang,
      searchAriaLabel: headerText.searchAriaLabel,
      searchLabel: headerText.searchLabel,
      searchText: headerText.searchText,
      searchURL: assetSchemeAndRoot + nav.assetBaseUrl + searchRelUrl,
      hideLinks: siteMapRoot === 'campaign' ? 'hide' : '',
      showLinks: siteMapRoot === 'campaign' ? '' : 'hide',
      showCtaCampaignLinks: siteMapRoot === 'campaign' && document.querySelector('.cl-cta2 a') ? '' : 'hide',
      campaignCtaText: getCampaignHeader(siteMapRoot).CtaText,
      campaignCtaURL: getCampaignHeader(siteMapRoot).CtaLink,
      campaignCtaRevealId: getCampaignHeader(siteMapRoot).campaignCtaRevealId,
      campaignLandingPageURL: getCampaignHeader(siteMapRoot).campaignLandingPageLink,
    });

    return mobileHeader;
  }


  function showCampaignCtas() {
    return siteMapRoot === 'campaign' && document.querySelector('.cl-cta1 a') && !isMainMenuLinks() ? '' : 'hide';
  }
  function showCampaignLinks(smRoot) {
    if (smRoot !== 'campaign') return '';
    return smRoot === 'campaign' && isMainMenuLinks() ? '' : 'hide';
  }

  function renderMobileBody() {
    let mobileBody = '';
    const subLevels = true;

    mobileBody += templateHtml.body.main({
      signedInMenu, 
      personalMenu,
      businessMenu,
      aboutusMenu,
      eshopMenu,
      quickLinkMenu,
      cpDomain,
      cpcLang: lang,
      langToggle: headerText.lang_toggle,
      utilityAriaRoleLabel: nav.utilityAriaRoleLabel,
      utilityMenu,
      mainMenuNav,
      logInMenu,
      mobileHeader: renderMobileHeader(subLevels),
      campaignCtaMenu,
      campaignUtilsMenu,
      hideMenu: siteMapRoot === 'campaign' ? 'hide' : '',
      showMenu: siteMapRoot === 'campaign' ? '' : 'hide',
      showCampaignCtas: showCampaignCtas(),
      showCampaignLinks: showCampaignLinks(siteMapRoot)
      
    });
    return mobileBody;
  }
  return globalNavHtml;
}

function getUserType() {
  const href = window.location.href;
  const ssoGroupMetaTag = document.head.querySelector('[name=sso-groups]');
  if (ssoGroupMetaTag) {
    if (ssoGroupMetaTag.content) {
      if (ssoGroupMetaTag.content.toLowerCase().includes('business')) {
        return 'business';
      } 
      return 'consumer';
    } 
    return 'consumer';
  } 
  if (
    href.includes('dash-tableau') &&
    window.__CPC__().customer.accountGroup
  ) {
    if (
      window.__CPC__().customer.accountGroup.toLowerCase() === 'zcss' ||
      window.__CPC__().customer.accountGroup.toLowerCase() === 'zbrs'
    ) {
      return 'business';
    }
    return 'consumer';
  } 
  return 'consumer'; 
}

export default renderMobileNav;
