/* eslint no-console: 0 */
/* eslint no-new: 0 */
/* eslint no-undef: 0 */

import templateHtml from './search.component.html';
import setLocaleEn from './locale_en';
import setLocaleFr from './locale_fr';
import globals from '../../../../utils/globals';
import DomainParser from '../../../../utils/domain-utils';
import ResizeHandler from '../../../../../cpc/utils/resize-handler';

const cpDomain = new DomainParser(window.location.href).getCanadaPostDomainUrl('');
const ptaSearchLengthTrigger = 400;


function initializeSearch(lang, initOptions) {
  // const $ = window.jQuery;
  const mobileWidth = globals.mobileUpperBound;
  const rootContext = lang === 'en' ? 'cpc' : 'scp';
  const searchPath = lang === 'en' ? 'search/search.page' : 'recherche/recherche.page';
  const searchPageURL = `/${rootContext}/${lang}/${searchPath}`;
  const hostname = window.location.hostname;
  const isShop = /(sandbox\.mozu\.com|\.mozu\.com|boutique|store)/i.test(hostname);
  // const shopSearchEvent = new CustomEvent('shopSearchFired');

  let searchModalInput;

  const nav = lang === 'fr' ? setLocaleFr({
    cpDomain
  }) : setLocaleEn({
    cpDomain
  });

  const searchModal = document.createElement('section');
  const cpcShopSearchModalId = 'searchModal';
  const cpcSearchModalId = 'cpcSearchModal';
  const bodyElement = document.querySelector('body');
  const searchContainer = document.querySelector('.utility-business-nav-search');

  if (!searchContainer) return;
  const searchBtnElement = document.querySelector('.utility-business-nav-search').querySelector('a');
  const searchBtnElementMobile = document.querySelector('.menu-search');

  searchModal.setAttribute('id', cpcSearchModalId);
  if (initOptions && initOptions.isCommonComponent) {
    searchModal.classList.add(globals.cwc.componentClassName);
  }

  searchModal.innerHTML = templateHtml.searchTemplate(nav);

  bodyElement.appendChild(searchModal);
  const searchModalElement = document.getElementById(`${cpcSearchModalId}`);
  const shopSearchModalElement = document.getElementById(`${cpcShopSearchModalId}`);


  // top bar section
  if (!searchBtnElement || !searchBtnElementMobile) return;
  searchBtnElement.addEventListener('click', showSearchClick);
  searchBtnElement.addEventListener('keydown', showSearchKeyPress);
  searchBtnElementMobile.addEventListener('click', showSearchClick);
  searchBtnElementMobile.addEventListener('keydown', showSearchKeyPress);


  // SearchModal
  const cpcSearchModal = document.getElementById('cpcSearchModal');
  cpcSearchModal.addEventListener('click', hideSearchClick);
  cpcSearchModal.addEventListener('keydown', (e) => {
    const key = e.which || e.keyCode;
    if (key === 27) {
      hideSearchClick();
    }
  });

  const searchPopup = document.getElementById('searchPopup');
  searchPopup.addEventListener('click', cancelHideSearchClick);

  // shift-tab traversal 
  searchPopup.addEventListener('keydown', (e) => {
    const key = e.which || e.keyCode;
    if (key === 9 && e.shiftKey) {
      if (window.Foundation.utils.is_medium_up()) {
        if (e.target === searchModalClose) {
          searchModalBtn.focus();
        } else if (e.target === searchModalInput) {
          e.preventDefault();
          lastSearchModalQuickLink.focus();
        }
      } else if (e.target === searchModalClose) {
        e.preventDefault();
        lastSearchModalQuickLink.focus();
      }
    }
  });

  const searchModalClose = document.getElementById('searchModalClose');
  searchModalClose.addEventListener('click', hideSearchClick);
  searchModalClose.addEventListener('keydown', hideSearchKeyPress);
  
  if (isShop) {
    searchModalInput = document.getElementById('globalSearchInput');
  } else {
    searchModalInput = document.getElementById('searchModalInput');
  }

  searchModalInput.addEventListener('keydown', searchKeyPress);

  const searchModalLabelError = document.getElementById('searchModalLabelError');
  searchModalLabelError.addEventListener('click', hideInputError);
  searchModalLabelError.addEventListener('keydown', hideInputErrorKeyPress);
  // searchModal errorLabel Text
  initializeSearchErrorText();

  // searchModalBtn
  const searchModalBtn = document.getElementById('searchModalBtn');

  initSearchModalBtn();
  setResizeHandler();

  searchModalBtn.addEventListener('click', () => {
    performSearch(false);
  });
  searchModalBtn.addEventListener('keydown', performSearchKeypress);


  searchModalInput.addEventListener('keyup', (e) => {
    if (!e.target.value && window.Foundation.utils.is_small_only()) {
      searchModalBtn.classList.remove('show');
      searchModalBtn.tabIndex = -1;
    } else {
      searchModalBtn.classList.add('show');
      searchModalBtn.tabIndex = 0;
    }
    if (e.target.value) {
      hideInputError();
    }
  });


  const searchModalResults = document.getElementById('searchModalResults');

  // SearchQuickLinks
  const searchModalQuickLinksList = document.getElementById('searchModalQuickLinks');
  const searchModalQuickLinksList2 = document.getElementById('searchModalQuickLinks2');
  const quicklinks = nav.nodes[0].nodes;
  const quickListArr = [];

  quicklinks.map(items =>
    quickListArr.push(templateHtml.searchTemplateList(items))

  );

  /** List has been split in half for specific ordering */
  const halfQuickLinks = Math.floor(quicklinks.length / 2);
  const firstHalfQuickLinks = quickListArr.slice(0, halfQuickLinks);
  const secondHalfQuickLinks = quickListArr.slice(halfQuickLinks, halfQuickLinks.length);

  searchModalQuickLinksList.innerHTML = firstHalfQuickLinks.join('');
  searchModalQuickLinksList2.innerHTML = secondHalfQuickLinks.join('');

  // tab traversal
  const searchModalQuickLinks = document.querySelectorAll('.search-modal-link');
  const lastSearchModalQuickLink = searchModalQuickLinks[(searchModalQuickLinks.length - 1)];

  lastSearchModalQuickLink.addEventListener('keydown', (e) => {
    const key = e.which || e.keyCode;
    if (key === 9 && !e.shiftKey) {
      e.preventDefault();
      if (window.Foundation.utils.is_medium_up()) {
        searchModalInput.focus();
      } else {
        searchModalClose.focus();
      }
    }
  });

  function initSearchModalBtn() {
    const searchModalBtnTxt = nav.searchLabel;
    const searchModalInputContainer = document.getElementById('searchModalInputContainer');
    const searchModalParent = searchModalInputContainer.parentNode;

    if (window.Foundation.utils.is_medium_up()) {
      searchModalParent.removeChild(searchModalClose);
      searchModalParent.insertBefore(searchModalClose, searchModalInputContainer.nextSibling);
      searchModalBtn.innerHTML = searchModalBtnTxt;
    } else {
      searchModalParent.removeChild(searchModalClose);
      searchModalParent.insertBefore(searchModalClose, searchModalInputContainer);
      searchModalBtn.innerHTML = '';
      searchModalBtn.tabIndex = -1;
    }
  }

  function setResizeHandler() {
    const resizeHandler = new ResizeHandler();
    resizeHandler.add(initSearchModalBtn);
    resizeHandler.add(initializeSearchErrorText);
  }

  function showSearchClick(e) {
    if (isShop) {
      e.preventDefault();
      jQuery(shopSearchModalElement).modal('show');
    } else {
      e.preventDefault();
      searchModalElement.classList.add('show');
      searchBtnElement.style.display = 'none';
      searchBtnElement.setAttribute('aria-hidden', true);
      searchPopup.setAttribute('aria-hidden', false);
      searchBtnElementMobile.style.display = 'none';
      bodyElement.classList.add('no-scroll');
      bodyElement.classList.add('tingle-enabled');
      const navIcon = document.getElementById('trigger');
      if (navIcon) {
        navIcon.classList.remove('active');
      }
  
      // screen reader may require a delay
      setTimeout(() => {
        searchModalInput.focus();
      }, 500);
    }
  }
  function showSearchKeyPress(e) {
    const key = e.which || e.keyCode;
    if (key === 13) {
      searchBtnElement.click();
    } else if (key === 27) {
      hideSearchClick();
    }
  }

  function hideSearchClick() {
    searchModalElement.classList.remove('show');
    searchBtnElement.removeAttribute('style');
    searchBtnElementMobile.removeAttribute('style');
    bodyElement.classList.remove('no-scroll');
    bodyElement.classList.remove('tingle-enabled');
    searchModalInput.value = '';
    searchModalResults.innerHTML = '';
    searchModalBtn.classList.remove('show');
    searchBtnElement.removeAttribute('aria-hidden');
    hideInputError();
    // screen reader may require a delay
    if (searchBtnElement.getAttribute('data-activated-via') === 'keyboard') {
      setTimeout(() => {
        searchBtnElement.focus();
      }, 500);
    }
  }

  function cancelHideSearchClick(e) {
    e.stopPropagation();
  }

  function hideSearchKeyPress(e) {
    const key = e.which || e.keyCode;
    if (key === 13) {
      searchModalClose.click();
    } else if (key === 27) {
      hideSearchClick();
    } else if (key === 9 && e.shiftKey) {
      e.preventDefault();
      const lastTabindex = searchModalElement.querySelectorAll('[tabindex]');
      lastTabindex[lastTabindex.length - 1].focus();
    }
  }

  function searchKeyPress(e) {
    const key = e.which || e.keyCode;
    if (searchModalInput.value.trim() !== '' || key === 13 || key === 27) {
      if (key !== 13 && key !== 27) {
        searchModalBtn.classList.add('show');
      }

      if (key === 13) {
        performSearch(false);
      } else if (key === 27) {
        hideSearchClick();
      } else if (searchModalInput.value.trim().length > ptaSearchLengthTrigger) {
        performSearch(true);
      } else {
        searchModalResults.innerHTML = '';
        hideInputError();
      }
    } else {
      searchModalBtn.classList.remove('show');
    }
  }

  // const searchElem = document.querySelector('cpc-search');
  // /**
  //  * Sets search attributes with params
  //  * to local storage
  //  * @param {string} searchElement
  //  * @param {object} searchParamsObj
  //  */
  // function setSearchParams(searchParamsObj) {
  //   Object.keys(searchParamsObj).forEach((key) => {
  //     localStorage.setItem(key, searchParamsObj[key]);
  //   });
  // }

  /**
   * get search params and assigns it to the cpc-search component attributes
   */
  

  function performSearch(doSearch) {
    const searchInput = document.querySelector('input#searchModalInput');
    const searchInputVal = searchInput.value;
    const segmentIdElem = document.querySelector("[name='segmentid']");
    const segmentIdElemContent = (segmentIdElem !== null && segmentIdElemContent !== '') ? segmentIdElem.getAttribute('content') : false;
    const fidVal = segmentIdElemContent ? `&fid=${segmentIdElemContent}` : '';

  
    if (doSearch) {
      window.location.href = `${cpDomain}${searchPageURL}?q=${searchInputVal}${fidVal}`;
    } else if ((document.body.clientWidth > mobileWidth && !doSearch)) {
      if (searchModalInput.value.trim() !== '') {
        // setSearchParams(searchParams);
        window.location.href = `${cpDomain}${searchPageURL}?q=${searchInputVal}${fidVal}`;
      } else {
        showError();
      }
    } else {
      const searchEle = document.activeElement;
      if (searchEle !== searchModalBtn) {
        // setSearchParams(searchParams);
        if (searchModalInput.value.trim() !== '') {
          window.location.href = `${cpDomain}${searchPageURL}?q=${searchInputVal}${fidVal}`;
        } else {
          showError();
        }
      } else {
        searchModalInput.value = '';
        searchModalResults.innerHTML = '';
        searchModalInput.focus();
        searchModalBtn.classList.remove('show');
        searchModalBtn.tabIndex = -1;
        hideInputError();
      }
    }
  }

  function performSearchKeypress(e) {
    const key = e.which || e.keyCode;
    if (key === 13) {
      searchModalBtn.click();
    } else if (key === 27) {
      hideSearchClick();
    }
  }

  function showError() {
    searchModalInput.placeholder = '';
    searchModalLabelError.classList.add('show');
    searchModalInput.classList.add('search-input-error');
  }

  function hideInputError() {
    searchModalInput.placeholder = nav.searchText;
    searchModalLabelError.classList.remove('show');
    searchModalInput.classList.remove('search-input-error');
  }

  function hideInputErrorKeyPress() {
    hideInputError();
  }

  function initializeSearchErrorText() {
    if (searchModalLabelError) {
      if (window.Foundation.utils.is_medium_up()) {
        searchModalLabelError.innerHTML = nav.searchErrorText;
      } else {
        searchModalLabelError.innerHTML = nav.searchErrorTextMobile;
      }
    }
  }
}
  

export default initializeSearch;