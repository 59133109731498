/* eslint no-console: 0 */
/* eslint-disable  */
/* eslint no-new: 0 */
import { toolbarTemplate, toolbarLinks } from './toolbar.component.html';
import getENLocale from './toolbar.locale.en';
import getFRLocale from './toolbar.locale.fr';
import globals from '../../../../utils/globals';
import DomainParser from '../../../../utils/domain-utils';
import PageUtils from '../../../../utils/page-utils';

const regexLib = { 
  questionMarkAtEnd: /\?$/
};
const domainParser = new DomainParser(window.location.href);
const pageObj = new PageUtils(window.location.href);

/**
 * Global Toolbar JS for usability and accessibility. HTML is rendered on the front end for this
 * component at this time. There is no integration with the current LiveSite component datums.
 * 
 * @example
 * 
 * No need to instantiate. This is consumed by the header component at this time. 
 */
function getHtml(lang) {
  const locale = lang === 'fr' ? getFRLocale() : getENLocale();
  let toolbarSelector = 'links';
  let links = '';
  if (isHomepage()) {
    toolbarSelector = 'homepageLinks';   
  }
  

  locale[toolbarSelector].forEach((node) => { 
    let visibilityClass = node.hiddenMobile ? ' class="hide-for-small-only"' : '';
    if (node.mobileOnly) visibilityClass = ' class="show-for-small-only"';

    if (node.template) {
      links += node.template;
    } else { 
      links += toolbarLinks(
        visibilityClass, 
        domainParser.getCanadaPostDomainUrl(node.url.replace(regexLib.questionMarkAtEnd, '')),
        node.icon, 
        node.label);
    }
  });

  return toolbarTemplate(locale.ariaLandmarkLabel, links, locale.feedback, locale.closeLabel);
}

function isHomepage() {
  return ((pageObj.isCanadaPostHomepage) || (document.body.classList.contains('cpc-homepage')));
}

function initializeComponent() {
  const tb = document.querySelector('.cpc-tb');
  const bottomTb = document.querySelector('.cpc-tb--bottom-tools');
  const tbOuter = document.querySelector('.cpc-tb--outer');
  const openCloseToggle = document.querySelector('.cpc-tb--toggler');
  const tbAriaParent = tb.parentElement;

  // CSS transition speed, used for add / removal of some post animation classes
  const animationDurationMillis = 500;
  
  if (!tb) { 
    // this particular page isn't using the toolbar. Avoid any further processing
    return;
  }

  Array.prototype.slice.call(tbOuter.querySelectorAll('a,[tabindex]')).forEach((ele) => {
    ele.addEventListener(globals.events.focus, tbFocus);
    ele.addEventListener(globals.events.blur, tbBlur);
  });

  // todo - not sure why mouse events are firing excessively/seemingly redundantly
  setupMouseListeners(true);
  openCloseToggle.addEventListener(globals.events.click, openCloseToggleClick);

  // Always make feedback second last icon
  // except the homepage where it is removed
  // IA-3768 Remove Feedback Access from Side Nav Bar (D/T/M)
  // Feedback icon removed from the toolbar so this functionally is not needed

  // const mobileTb = document.querySelector('.cpc-tb--mobile').querySelector('ul');
  // const mobileTools = mobileTb.querySelectorAll('li');
  // if (isHomepage()) {
    // mobileTb.removeChild(mobileTools[mobileTools.length - 1]);
  // } else {
    // mobileTb.appendChild(mobileTools[mobileTools.length - 2]);
  // }

  function setupMouseListeners(isEnabled) {
    tb[isEnabled ? globals.events.addEventListener : globals.events.removeEventListener](
      globals.events.mouseEnter, tbMouseOver);
    tb[isEnabled ? globals.events.addEventListener : globals.events.removeEventListener](
      globals.events.mouseLeave, tbMouseOut);
    bottomTb[isEnabled ? globals.events.addEventListener : 
      globals.events.removeEventListener](globals.events.mouseEnter, tbMouseOver);
    bottomTb[isEnabled ? globals.events.addEventListener : 
      globals.events.removeEventListener](globals.events.mouseLeave, tbMouseOut);   
  }

  function tbMouseOut() { 
    toggleVisible(false);
  }

  function tbMouseOver() { 
    toggleVisible(true);
  }

  function openCloseToggleClick(evt) {
    evt.preventDefault();
    const isTbOpen = tb.classList.contains('cpc-tb--open');
    let timeoutEvt = null;

    if (!isTbOpen) {
      toggleVisible(true);
    } else { 
      // Disable mouse listeners when user clicks close, otherwise there
      // is some funky usability issues where even a slight mouse movement 
      // immediately after clicking close will cause a mouse hover event to 
      // re-open the menu...
      setupMouseListeners(false);
      toggleVisible(false);

      // Add event listeners back after the expected animation transition duration
      if (timeoutEvt) clearTimeout(timeoutEvt);
      timeoutEvt = setTimeout(() => {
        setupMouseListeners(true);
      }, animationDurationMillis / 2);
    }
  }

  function tbFocus() { 
    toggleVisible(true);
  }
  
  function tbBlur(evt) { 
    const isNewFocusInToolbar = tbOuter.contains(evt.relatedTarget);

    if (!isNewFocusInToolbar) { 
      toggleVisible(false);
    }
  }

  // Add classes for animations and add accessibility attributes 
  // as needed when opening / closing toolbar
  function toggleVisible(isOpen) { 
    const constants = { 
      open: 'cpc-tb--open',
      add: 'add',
      remove: 'remove'
    };
    
    tb.classList[isOpen ? constants.add : constants.remove](constants.open);
    tbAriaParent.setAttribute('aria-expanded', isOpen);
    bottomTb.classList[isOpen ? constants.add : constants.remove](constants.open);
  }
}

/**
 * Returns true if a toolbar is present in the DOM
 */
function isToolbarDefined() {
  return !!document.querySelector('.cpc-tb--outer');
}

export { initializeComponent, getHtml, isToolbarDefined };