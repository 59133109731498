import DomainParser from './domain-utils';
import { getStore, storeKeys } from './data-store';

/**
 * <body> tag manipulations
 */
class BodyUtils {
  constructor() { 
    this.body = document.querySelector('body');
  }

  /**
   * Invoke to set an environment into the body tag, and an application class for CWC purposes
   */
  initialize() {
    setEnvironmentClass.call(this);
    setApplicationClass.call(this);
  }
}

export default BodyUtils;

// 
// Private implementation - just an idea for encapsulation
//
function setEnvironmentClass() {
  const domainParser = new DomainParser(window.location.href);
  let subdomain = domainParser.getCanadaPostSubdomain();
  // IA-3772
  const acceptedSubdomains = [ 
    'www',
    'stg14',
    'stg13',
    'stg12',
    'stg10',
    'dev14',
    'dev13',
    'dev12',
    'dev10'
  ];
  // default to www if not in list.
  if (acceptedSubdomains.indexOf(subdomain) === -1) {
    subdomain = 'www';
  }
  this.body.classList.add(subdomain);   
}

function setApplicationClass() {
  const cwcStore = getStore(storeKeys.cwc);
  if (cwcStore && cwcStore.cpcApp.appId) { 
    this.body.classList.add(cwcStore.cpcApp.appId);   
  }
}