/* eslint no-console: 0 */
/**
 * This sign in module handles desktop signin when initiated from the primary navigation. It also
 * handles transition functions in that scenario between desktop and mobile. 
 * 
 * This module repurposes the desktop sign in for other scenarios such as CTAs that are supposed
 * to launch modals when users are not authenticated. 
 */
import './sign-in.locale.en';
import './sign-in.locale.fr';
import { initializeComponent, getHtmlTemplate, fillTemplate } from './sign-in.component';

export { initializeComponent, getHtmlTemplate, fillTemplate };