/* eslint no-unused-expressions: 0 */
/* eslint func-names: 0 */
/* eslint no-param-reassign: 0 */
/* eslint no-multi-assign: 0 */
/* eslint prefer-rest-params: 0 */
/* eslint no-underscore-dangle: 0 */
/* eslint no-undef: 0 */
/* eslint camelcase: 0 */

/* Facebook Pixel Code */

// !(function (f, b, e, v, n, t, s) {
//   if (f.fbq) return; n = f.fbq = function () {
//     n.callMethod ?
//       n.callMethod(...arguments) : n.queue.push(arguments);
//   };
//   if (!f._fbq)f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
//   n.queue = []; t = b.createElement(e); t.async = !0; t.className = 'optanon-category-C0004'; t.type = 'text/plain';
//   t.src = v; s = b.getElementsByTagName(e)[0];
//   s.parentNode.insertBefore(t, s);
// }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js', 'type', 'text/plain', 'class', 'optanon-category-C0004'));
// fbq('init', '614267586032718'); 
// fbq('track', 'PageView');

/* End Facebook Pixel Code */


/* Twitter Pixel Code */
// const twitterLangId = (document.querySelector('html').getAttribute('lang') === 'fr' ? 'ny0qk' : 'ny0qm');
// !(function (e, t, n, s, u, a) {
//   e.twq || (s = e.twq = function () { s.exe ? s.exe(...arguments) : s.queue.push(arguments); }
//   , s.version = '1.1', s.queue = [], u = t.createElement(n), u.type = 'text/plain', u.async = !0, u.src = '//static.ads-twitter.com/uwt.js', u.className = 'optanon-category-C0004', a = t.getElementsByTagName(n)[0], a.parentNode.insertBefore(u, a));
// }(window, document, 'script', 'https://static.ads-twitter.com/uwt.js'));
// // Insert Twitter Pixel ID and Standard Event data below
// twq('init', twitterLangId);
// twq('track', 'PageView');

/* End Twitter Pixel Code */


/* LinkedIn Pixel Code */ 

// _linkedin_partner_id = '9198';
// window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
// window._linkedin_data_partner_ids.push(_linkedin_partner_id);

// (function () { const s = document.getElementsByTagName('script')[0]; const b = document.createElement('script'); b.type = 'text/plain'; b.async = true; b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js'; b.className = 'optanon-category-C0004'; s.parentNode.insertBefore(b, s); }());

/* End LinkedIn Pixel Code */