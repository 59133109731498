/* eslint no-underscore-dangle: 0 */

import { primaryNav as templateHtml, templates } from './header.component.html';
import { getHtml as getToolbarHtml } from '../Toolbar-CG/toolbar.module';
import {
  getHtmlTemplate as getSignInDesktopHtmlTemplate,
  fillTemplate as SignInDesktopHtmlTemplate,
} from '../Sign-In/sign-in.module';
// import { getContextPath } from '../../../../utils/url-utils';
import { getLANG } from '../../../../utils/language.data';
import authModule from '../../../../utils/auth.module';
import { getStore, storeKeys } from '../../../../utils/data-store';
import DomainParser from '../../../../utils/domain-utils';
import cartIcon from './cart.icon';
import getShoppingCartQuantity from '../../../../utils/cart.data';
import setLocaleEn from './locale_en';
import setLocaleFr from './locale_fr';

const logoRelUrl = 'assets/cpc/img/logos/cpc-main-logo$LOGO_LOCALE$.svg';
const cpContext = getLANG() === 'f' ? '/scp' : '/cpc';

const RegexLib = {
  questionMarkAtEnd: /\?$/,
  doubleQuote: /"/g,
  cpc_scp: /\/(scp|cpc)/,
};

const constants = {
  active: 'active',
  fr: 'fr',
};
const cpDomain = new DomainParser(window.location.href).getCanadaPostDomainUrl(
  ''
);
// const subDomain = new DomainParser(window.location.href).getCanadaPostSubdomain();
// CCOFURL-1526: temporary domain logic for the french URL project
// when all the WL apps will have implemented both domains then checking the root context will no longer be necessary
function getCPDomainURL() {
  /* const rootContext = getContextPath(link);
  if (rootContext === '/cpc' || rootContext === '/scp') {
    return new DomainParser(window.location.href).getCanadaPostDomainUrl('');
  }
  return `https://${subDomain}.canadapost-postescanada.ca`; */

  return new DomainParser(window.location.href).getCanadaPostDomainUrl('');
}

/**
 * Renders the desktop header HTML
 * @param {*} lang
 * @param {*} base
 * @param {*} siteMapRoot
 */
function getHeaderHtml(lang, base, siteMapRoot) {
  const options = base.options;
  const locale = lang === constants.fr ? setLocaleFr() : setLocaleEn();
  const highlightType = siteMapRoot;

  let globalNavHtml = templateHtml;
  let businessMenu = '';
  let utilityMenu = '';
  let personalBusinessMenu = '';
  const languageLink =
    templates.utilityNav.languageToggle(lang === constants.fr ? 'en' : 'fr', locale.lang_toggle);
  // const shopLink = lang === constants.fr ? '/shop-magasin/magasin.jsf' : '/shop-magasin/shop.jsf';

  const headerText = lang === constants.fr ? setLocaleFr() : setLocaleEn();

  const nav = getStore(storeKeys.nav) ? getStore(storeKeys.nav) : null;
  // const assetSchemeAndRoot = `https://${cpDomain}`;
  if (nav) {
    if (!options.suppressProductNav) {
      businessMenu = templates.mainBusinessNav.productNav(
        headerText.productionNavigationAriaLabel,
        getBusinessMenu(nav, siteMapRoot, locale)
      );
    }

    utilityMenu = getUtilityNav(
      nav,
      base.el.getAttribute('data-current-page') || ''
    );
    personalBusinessMenu = getPersonalBusinessMenu(
      nav,
      highlightType,
      base.el.getAttribute('data-current-page') || '',
      lang
    );
  }

  const signedInNav = getsignedInNav(headerText, lang);

  const cartQty = getShoppingCartQuantity();

  // cartLink is specific to new eStore
  const cartLink = cartQty > 0 ?
    templates.utilityNav.cart(authModule.getDomain(), headerText.cartURL, cartQty, locale.cartLabel, cartIcon) : '';

  // temporarly forcing the domain to be canadapost.ca until eShop will use both official domains 
  const desktopSignInHtml = SignInDesktopHtmlTemplate(getSignInDesktopHtmlTemplate(),
    'default-with-consumer');


  const utilityBusinessNav = templates.mainBusinessNav.utilityBusinessNav({

    suppressHeaderSticky: isSuppressHeaderSticky(),
    suppressProductNav: options.suppressProductNav,
    stickyUtilityBusinessNavOptions: options.suppressProductNav
      ? 'data-options="is_hover: false"'
      : '',
    cpcLang: lang,
    logoUrl: `${cpDomain}/cpc/${logoRelUrl.replace(
      /\$LOGO_LOCALE\$/g,
      lang === constants.fr ? `-${lang}` : ''
    )}`, // temporarly forcing it to be /cpc/ until postescanada has been implemented
    logoAltText: headerText.logoAltText,
    personalBusinessNav: personalBusinessMenu,
    cartIndicator: cartLink,
    searchLabel: headerText.searchLabel,
    domainRoot: cpDomain,
    homepageUrl: getHomepageUrl(lang),
  });

  globalNavHtml = globalNavHtml({
    mainNavigationAriaLabel: headerText.mainNavigationAriaLabel,
    utilityNav: utilityMenu,
    languageSelector: languageLink,
    signedInNav,
    cpcLang: lang,
    mainBusinessNav: businessMenu,
    close: headerText.closeLabel,
    signinDesktopComponent: desktopSignInHtml,
    toolbarComponent: options.suppressToolbar ? '' : getToolbarHtml(lang),
    suppressProductNav: options.suppressProductNav,
    utilityBusinessNav,
  });
  return globalNavHtml;
}


/**
 * Renders the Campaign desktop header HTML
 * 
 * @param {*} base
 * @param {*} siteMapRoot
 */

function getCampaignHeaderHTML(base) {
  const topCampaignSection = document.querySelector('.cl-top-section_anchors');
  const currentCampaingLinksElem = topCampaignSection.querySelector('li a');
  const currentCampaingLinks = (currentCampaingLinksElem && topCampaignSection.querySelector('li a').innerText !== '') ? topCampaignSection.innerHTML : '';
  let campaignSubMenu = '';
  // const languageLink =
  //   templates.utilityNav.languageToggle(lang === constants.fr ? 'en' : 'fr', locale.lang_toggle);
  const nav = getStore(storeKeys.nav) ? getStore(storeKeys.nav) : null;
  const currentPage = base.el.getAttribute('data-current-page') || '';

  if (topCampaignSection && currentPage !== '') {
    campaignSubMenu = getCampaignSubMenu(
      currentPage, nav);
    topCampaignSection.innerHTML = `${currentCampaingLinks}${campaignSubMenu}`;
  }
}

function getHomepageUrl(lang) {
  return `${cpDomain}${cpContext}/${lang}?name=tgt`;
}

function getsignedInNav(headerText, lang) {
  const hostname = window.location.hostname;
  let dashBoardLink = lang === 'en' ?
    'dash2-tableau2/en/ui/home' :
    'dash2-tableau2/fr/iu/accueil';


  if (authModule.getIsAuthenticated()) {
    let additionalItems = '';
    let classes = '';
    const userType = getUserType();

    if (userType === 'business') {
      additionalItems = `<li><a href="${cpDomain}${headerText.linkBusinessSettings}">${headerText.businessSettings}</a></li><li><a href="${cpDomain}${headerText.linkInvoices}">${headerText.invoices}</a></li>`;
      classes = 'business';
      dashBoardLink = `dash-tableau/${lang}`;
    }

    return templates.authUserNav.lvl2(cpDomain, authModule.getSSOUserName(), dashBoardLink, lang,
      headerText.dashboard, headerText.myprofile, additionalItems, headerText.mysupport,
      headerText.linkMySupport, authModule.getLogoutLink(),
      headerText.signOut, classes);
  }
  if (isEstore()) {
    const eStoreSubDomain = hostname.split('.')[0];

    switch (eStoreSubDomain) {
      case 'store':
      case 't29025-s47547':
        return templates.authUserNav.shop(
          headerText.signInRegister,
          `${cpDomain}/shop-login`
        );

      case 'boutique':
      case 't29025-s47548':
        return templates.authUserNav.shop(
          headerText.signInRegister,
          `${cpDomain}/boutique-login`
        );

      case 't28065-s45123':
        return templates.authUserNav.shop(
          headerText.signInRegister,
          `${cpDomain}/shop-qa-login`
        );

      case 't28065-s45124':
        return templates.authUserNav.shop(
          headerText.signInRegister,
          `${cpDomain}boutique-qa-login`
        );

      case 't29011-s47513':
        return templates.authUserNav.shop(
          headerText.signInRegister,
          `${cpDomain}/shop-sit-login`
        );

      case 't29011-s47514':
        return templates.authUserNav.shop(
          headerText.signInRegister,
          `${cpDomain}/boutique-sit-login`
        );

      case 't28112-s45215':
        return templates.authUserNav.shop(
          headerText.signInRegister,
          `${cpDomain}/shop-demo-login`
        );

      case 't28112-s45216':
        return templates.authUserNav.shop(
          headerText.signInRegister,
          `${cpDomain}/boutique-demo-login`
        );

      case 't28166-s45346':
        return templates.authUserNav.shop(
          headerText.signInRegister,
          `${cpDomain}/shop-dev-login`
        );

      case 't28166-s45347':
        return templates.authUserNav.shop(
          headerText.signInRegister,
          `${cpDomain}/boutique-dev-login`
        );

      case 't30296-s50851':
        return templates.authUserNav.shop(
          headerText.signInRegister,
          `${cpDomain}/shop-stg-login`
        );

      case 't30296-s50852':
        return templates.authUserNav.shop(
          headerText.signInRegister,
          `${cpDomain}/boutique-stg-login`
        );

      // deepcode ignore DuplicateCaseBody: switch fallback
      default:
        return templates.authUserNav.shop(
          headerText.signInRegister,
          `${cpDomain}/shop-login`
        );
    }
  }

  return templates.authUserNav.lvl1(headerText.signInRegister,
    `${authModule.getSSODomain()}${headerText.linkSignIn}`);
}


function isEstore() {
  const hostname = window.location.hostname;
  return /(stg1\.mozu\.com|sandbox\.mozu\.com|\.mozu\.com|boutique|store)/i.test(hostname);
}

/**
 *
 * @param {*} currentPage : data-current-page value of header - represents
 *            what top level nav item to highlight - personal | business | tools
 * @param {*} nav
 * @param {*} highlightType : home | personal - represents current sitemap  node and is used
 *            to highlight Personal or Business implicitly, unless currentPage is specified. Note
 *            that currentPage must be used to highlight elements if they are not defined in sitemap
 */
function getPersonalBusinessMenu(nav, highlightType, currentPage, lang) {
  let personalBusinessMenu = '';
  nav.nodes
    .find(ele => ele.label === 'PersonalBusiness')
    .nodes.forEach((node1) => {
      const isNodeActive =
        (currentPage && node1.description === currentPage) ||
        (!currentPage &&
          highlightType &&
          node1.linkCMSPage === highlightType.replace(/[^\w*]\s*/g, '-'));
      const isMyAccountNode = node1.description === 'my-account';
      const isEshopNode = node1.description === 'store';
      const homepage1 = getHomepageUrl(lang);
      const homepage2 = `${homepage1}/home.page`;
      const current = window.location.href;
      const kiboAuthParam = '?auth=1';

      if (isMyAccountNode) {
        const dashboardCPdomain = getCPDomainURL();
        personalBusinessMenu += templates.utilityNav.myAccount(
          getDashboardLink(dashboardCPdomain, lang),
          isNodeActive || getAppId() === 'dashboard' ? 'class="active"' : '',
          node1.label
        );
      } else {
        personalBusinessMenu += templates.utilityNav.lvl1(
          isEshopNode ? getCPDomainURL() + node1.link + (authModule.getIsAuthenticated() ? kiboAuthParam : '') : getCPDomainURL(),
          isEshopNode ? '' : node1.link.replace(RegexLib.questionMarkAtEnd, ''),
          isNodeActive && current !== homepage1 && current !== homepage2
            ? 'class="active"'
            : '',
          node1.label
        );
      }
    });
  return personalBusinessMenu;
}

function getDashboardLink(domainRoot, cpcLang) {
  return `${domainRoot}/dash2-tableau2/${cpcLang}`;
}

function getAppId() {
  const component = document.querySelector('cpc-header');
  return component && component.getAttribute('data-app-id')
    ? component.getAttribute('data-app-id')
    : false;
}

function getUtilityNav(nav, currentPage) {
  let utilityMenu = '';
  nav.nodes
    .find(ele => ele.label === 'Utility')
    .nodes.forEach((node1) => {
      let utilityClass = '';
      const isNodeActive =
        currentPage &&
        node1.description &&
        node1.description.toLowerCase() === currentPage.toLowerCase();
      const isEshopNode = node1.description === 'store';
      utilityClass += node1.link.indexOf('/support/') !== -1 ? 'chat-link' : '';
      utilityClass += isNodeActive ? ' active' : '';
      utilityMenu += templates.utilityNav.lvl1(
        isEshopNode ? getCPDomainURL() + node1.link : getCPDomainURL(),
        isEshopNode ? '' : node1.link.replace(RegexLib.questionMarkAtEnd, ''),
        `class="${utilityClass}"`,
        node1.label
      );
    });
  return utilityMenu;
}

function getCampaignSubMenu(currentPage, nav) {
  let campaignSubMenu = '';

  const campaignNodeRoot = nav.nodes.find(ele => ele.label === 'Campaign');
  const campaignPageNodes = campaignNodeRoot.nodes.find(ele => ele.label === currentPage);

  if (campaignPageNodes) {
    campaignPageNodes.nodes.forEach((node1) => {
      const escapedLabel = node1.label.replace(RegexLib.doubleQuote, '&quot;');
      let activeClass = '';
      let lvl2Html = '';
      let lvl3 = '';
      let hasSubLevel = false;

      if (node1.linkCMSPage && node1.linkCMSPage === nav.activePage) {
        activeClass = constants.active;
      }

      if (node1.nodes) {
        hasSubLevel = true;
        for (let node2Ctr = 0; node2Ctr < node1.nodes.length; node2Ctr += 1) {
          const node2 = node1.nodes[node2Ctr];
          const pattern = new RegExp(`^${node1.linkCMSPage}`, 'i');
          const lvl2Link = node2.link
            ? node2.link.replace(RegexLib.questionMarkAtEnd, '')
            : node2.overViewContent.link;

          if (
            !!node2.linkCMSPage &&
            node2.linkCMSPage === nav.activePage &&
            pattern.test(node2.linkCMSPage)
          ) {
            activeClass = constants.active;
          }

          lvl3 += templates.campaignNav.lvl3(
            lvl2Link.replace(RegexLib.questionMarkAtEnd, ''),
            node2.label,
            node2.target === undefined ? '' : node2.target,
            node2.label.replace(RegexLib.doubleQuote, '&quot;')
          );
        }
      }

      lvl2Html += templates.campaignNav.lvl2(lvl3);
      if (hasSubLevel) {
        campaignSubMenu += templates.campaignNav.lvl1({
          isActiveClass: activeClass,
          lvl1LabelEscaped: escapedLabel,
          lvl1Label: node1.label,
          lvl1Link: node1.link,
          lvl2: lvl2Html,
        });
      } else {
        campaignSubMenu += templates.campaignNav.lvl0({
          isActiveClass: activeClass,
          lvl1LabelEscaped: escapedLabel,
          lvl1Label: node1.label,
          lvl1Link: node1.link,
        });
      }
    });
  }

  return campaignSubMenu;
}


function getBusinessMenu(nav, siteMapRoot, locale) {
  const businessNav = nav.nodes.find(
    ele => ele.label.toLowerCase() === siteMapRoot
  );
  let businessMenu = '';
  businessNav.nodes.forEach((node1) => {
    const lvl1Link = node1.link
      ? node1.link.replace(RegexLib.questionMarkAtEnd, '')
      : node1.overViewContent.link;

    const escapedLabel = node1.label.replace(RegexLib.doubleQuote, '&quot;');
    let lvl2Html = '';
    let lvl3 = '';
    let activeClass = '';
    if (node1.linkCMSPage && node1.linkCMSPage === nav.activePage) {
      activeClass = constants.active;
    }
    if (node1.nodes) {
      for (let node2Ctr = 0; node2Ctr < node1.nodes.length; node2Ctr += 1) {
        const node2 = node1.nodes[node2Ctr];
        const pattern = new RegExp(`^${node1.linkCMSPage}`, 'i');
        const lvl2Link = node2.link
          ? node2.link.replace(RegexLib.questionMarkAtEnd, '')
          : node2.overViewContent.link;

        if (
          !!node2.linkCMSPage &&
          node2.linkCMSPage === nav.activePage &&
          pattern.test(node2.linkCMSPage)
        ) {
          activeClass = constants.active;
        }

        let lvl4 = '';
        if (node2.nodes) {
          for (let node3Ctr = 0; node3Ctr < node2.nodes.length; node3Ctr += 1) {
            const node3 = node2.nodes[node3Ctr];
            const lvl3Link = node3.link
              ? node3.link.replace(RegexLib.questionMarkAtEnd, '')
              : node3.overViewContent.link;

            lvl4 += templates.mainBusinessNav.lvl4(
              node3.target === '_blank' && lvl3Link.indexOf('http') > 0
                ? ''
                : getCPDomainURL(),
              lvl3Link.replace(RegexLib.questionMarkAtEnd, ''),
              node3.label,
              node3.target === undefined ? '' : node3.target
            );
            if (
              !!node3.linkCMSPage &&
              node3.linkCMSPage === nav.activePage &&
              pattern.test(node3.linkCMSPage)
            ) {
              activeClass = constants.active;
            }

            if (node3.nodes) {
              for (
                let node4Ctr = 0;
                node4Ctr < node3.nodes.length;
                node4Ctr += 1
              ) {
                const node4 = node3.nodes[node4Ctr];
                if (
                  !!node4.linkCMSPage &&
                  node4.linkCMSPage === nav.activePage &&
                  pattern.test(node4.linkCMSPage)
                ) {
                  activeClass = constants.active;
                }
              }
            }
          }
        }
        lvl3 += templates.mainBusinessNav.lvl3(
          node2.target === '_blank' && node2.link.indexOf('http') >= 0
            ? ''
            : getCPDomainURL(),
          lvl2Link.replace(RegexLib.questionMarkAtEnd, ''),
          node2.label,
          node2.target === undefined ? '' : node2.target,
          lvl4
        );
      }
    }
    lvl2Html += templates.mainBusinessNav.lvl2(lvl3);
    businessMenu += templates.mainBusinessNav.lvl1({
      isActiveClass: activeClass,
      lvl1LabelEscaped: escapedLabel,
      lvl1Label: node1.label,
      closeLabel: locale.closeLabel,
      overview: locale.overview,
      lvl1Link,
      lvl1Description: node1.description || node1.label,
      hidelink: node1.link === 'none' ? 'hide' : '',
      showlink: node1.link === 'none' ? '' : 'hide',
      lvl1Target: node1.target === undefined ? '' : node1.target,
      lvl2: lvl2Html,
      domainRoot: getCPDomainURL(),
    });
  });
  return businessMenu;
}


// TODO: if cpc.bundle.js using shared CWC, then we won't need to have ths method. We can
// TODO: directly using cwc.isSuppressHeaderStick()
function isSuppressHeaderSticky() {
  return isCwc() ? getStore(storeKeys.cwc).isSuppressHeaderSticky() : false;
}


// TODO: if cpc.bundle.js using shared CWC, then we won't need to have ths method.
function isCwc() {
  return getStore(storeKeys.cwc);
}

function getUserType() {
  const href = window.location.href;
  const ssoGroupMetaTag = document.head.querySelector('[name=sso-groups]');
  if (ssoGroupMetaTag) {
    if (ssoGroupMetaTag.content) {
      if (ssoGroupMetaTag.content.toLowerCase().includes('business')) {
        return 'business';
      }
      return 'consumer';
    }
    return 'consumer';
  }
  if (
    href.includes('dash-tableau') &&
    // eslint-disable-next-line no-underscore-dangle
    window.__CPC__().customer.accountGroup
  ) {
    if (
      // eslint-disable-next-line no-underscore-dangle
      window.__CPC__().customer.accountGroup.toLowerCase() === 'zcss' ||
      // eslint-disable-next-line no-underscore-dangle
      window.__CPC__().customer.accountGroup.toLowerCase() === 'zbrs'
    ) {
      return 'business';
    }
    return 'consumer';
  }
  return 'consumer';
}

export { getHeaderHtml, getCampaignHeaderHTML };