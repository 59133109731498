export default items => `
<h3 class="right-area-heading">${items.createAccount}</h3>
<p class="signup-text">
  ${items.signUpText}
</p>

<!--Leaving list items for possible future implementation-->
<!--<ul class="visible-for-large-up">-->
  <!--<li>${items.signUpPoint1}</li>-->
  <!--<li>${items.signUpPoint2}</li>-->
  <!--<li>${items.signUpPoint3}</li>-->
<!--</ul>-->
<p class="signup-text visible-for-large-up">
  ${items.signUpPoint1}
</p>

<p class="sign-in-up-buttons">
  <a role="button" tabindex="0" href="${items.ssoRegisterLink}" class="sso_link button nomargin" tabindex="-1">${items.signUp}</a>
</p>
`;