const primaryNav = items => `
<div class="noindex">
<nav role="navigation" aria-label="${items.mainNavigationAriaLabel}" class="${items.suppressProductNav ? 'cpc-nav-suppress-product-nav' : ''}">
<div class='top-bar micro-business-nav show-for-large-up' data-topbar>
  <section class='top-bar-section'>
    <ul class='right'>
      ${items.utilityNav}
      ${items.languageSelector}
      <li class="top-bar-separator"></li>
      ${items.signedInNav}
    </ul>
  </section>
</div>
${items.utilityBusinessNav}
</nav>
${items.mainBusinessNav}
<script type="text/template" class="cpc-modal__template noindex" id="sign-in-modal" data-cpc-modal-options='{"preserveOnClose": "true", "id": "signinFormDesktop", "title":" ","autoOpen":false,"closeLabel":"${items.close}","closeMethods":["overlay","escape"],"cssClass":["sign-in-modal"]}'>
${items.signinDesktopComponent}
</script>
${items.toolbarComponent}
`;

const templates = { 
  mainBusinessNav: {
    productNav: (productNavAriaLabel, businessNav) => `
      <div class='main-business-nav show-for-large-up'>
        <section class='top-bar-section'>
          <div class='contain-to-grid sticky'>
            <nav class='top-bar' data-topbar data-options='sticky_on: [medium,large];is_hover: false' aria-label="${productNavAriaLabel}">
              <ul>
                ${businessNav}
              </ul>
            </nav>
          </div>
        </section>
      </div>
    `,
    utilityBusinessNav: items => `
    <div class='${items.suppressProductNav ? 'sticky ' : ''} utility-business-nav-sticky-container'>
      <div class='top-bar utility-business-nav show-for-large-up' ${items.suppressHeaderSticky ? '' : 'data-topbar'} ${items.stickyUtilityBusinessNavOptions}>
        <ul class='title-area'>
          <li>
            <a href='${items.homepageUrl}'><img src='${items.logoUrl}' alt='${items.logoAltText}' /></a>
          </li>
          <li class='toggle-topbar menu-icon'><a href='#'><span>Menu</span></a></li>
        </ul>
        <section class='top-bar-section'>
          <ul class='left'>
            ${items.personalBusinessNav}
          </ul>
          <ul class='right utility-business-nav-search'>
            <li role="search" aria-label="${items.searchLabel}" class="cpc-nav--search-container"><a id='searchBtn' href='#' class='btn btn-search' role='button'>${items.searchLabel}</a></li>
            ${items.cartIndicator}
          </ul>
        </section>
      </div>
    </div>
    `,
    lvl1: items => `
          <li class='has-dropdown'>
            <a href='#' role="button" aria-expanded="false" class="${items.isActiveClass}" data-title="${items.lvl1LabelEscaped}">${items.lvl1Label}<span class="cpc-nav--primary-chevron"></span></a>
            <ul class="dropdown mega-nav">
              <div class='row mega-nav-background-container'>
                <div class='large-12 columns'>
                <div class='row'>
                <div class='large-12 columns'>

                  <li class='menu-item-detail'>
                    <a class="close-mega-nav" href="#" aria-label="Close"></a>
                    <h3 class='title ${items.hidelink}'><a href='${items.domainRoot}${items.lvl1Link}' target='${items.lvl1Target}' class='l1-title'>${items.lvl1LabelEscaped}</a>
                    <div class='l1-title'>${items.lvl1Description}</div>
                    </h3>
                    <h3 class='title ${items.showlink}'><div class='l1-title'>${items.lvl1Description}</div></h3>
                  </li>
                  <li class='menu-item-secondary'>
                    <div class="row">
                      ${items.lvl2}
                    </div>
                  </li>

                </div>
                </div>
                </div>
              </div>
            </ul>
          </li>
    `,
    lvl2: lvl3 => `
          <!--<div class="medium-4 columns">-->
            <ul>
              <div class='mega-nav--grid'>
                <div class="mega-nav--grid-sizer"></div>
                ${lvl3}
              </div>
            </ul>
          <!--</div>-->
    
    `,
    lvl3: (domainRoot, lvl3Link, lvl3Label, linktarget, lvl4) => `
          <li class='mega-nav--grid-item'>
            <a href='${domainRoot}${lvl3Link}' class='title' target='${linktarget}'>${lvl3Label}</a>
            ${lvl4.trim() === '' ? '' : `<ul class='menu-item-secondary-group'>${lvl4}</ul>`}
          </li>
    `,
    lvl4: (domainRoot, lvl4Link, lvl4Label, linktarget) => `
          <li><a href='${domainRoot}${lvl4Link}' target='${linktarget}'>${lvl4Label}</a></li>
    `
  },
  utilityNav: { 
    lvl1: (domainRoot, lvl1Url, isActiveClass, lvl1Label) => `
      <li><a href='${domainRoot}${lvl1Url}' ${isActiveClass} >${lvl1Label}</a></li>
    `,
    myAccount: (lvl1Url, isActiveClass, lvl1Label, signinAriaLabel) => `
      <li aria-label="${signinAriaLabel}"><a href='${lvl1Url}' data-cpc-modal-id="#sign-in-modal" data-cpc-modal-suppress-when-sso="true" ${isActiveClass} >${lvl1Label}</a></li>
    `,
    languageToggle: (lang, lvl1Label) => `
      <li class="language-toggle"><a href='#' lang="${lang}">${lvl1Label}</a></li>
    `,
    cart: (domainRoot, lvl1Url, cartQty, cartLabel, cartSvg) => `
      <li class="cpc-nav--cart-container"><a href='${domainRoot}${lvl1Url}' class="cart"><span class="cart-qty-indicator">${cartQty}</span> <span class="cart-icon" aria-label="${cartLabel}" role="img">${cartSvg}</span></a></li>
    `    
  },
  authUserNav: {
    lvl1: (signInLabel, signInLink) => `
      <li class="sign-in toggle-signin-trigger" role="signin" aria-label="${signInLabel}"><a href='${signInLink}' data-cpc-modal-id="#sign-in-modal" id="signinModalLarge" role="button">${signInLabel}</a></li>
    `,
    lvl2: (domainRoot, userName, dashBoardLink, cpcLang, navDashboard, navMyProfile, additionalItems, mysupport, linkMySupport, logout, signOut, classes) => `
      <li class="auth-link user-nav-wrapper">
        <a class='sso-username' href='#' aria-expanded='false'>${userName}</a>
        <ul class='sso-user-nav' ${classes}>
          <li><a href='${domainRoot}/${dashBoardLink}'>${navDashboard}</a></li>
          <li><a href='${domainRoot}/pfe2-pap2/${cpcLang}'>${navMyProfile}</a></li>
          ${additionalItems}
          <li><a href='${domainRoot}${linkMySupport}'>${mysupport}</a></li>
        </ul>
      </li>
      <li class="auth-link user-nav-wrapper">
        <a class='sso-signout' href='${logout}'>${signOut}</a>
      </li>
      <li class="auth-link sign-out"></li>
    `,
    shop: (signInLabel, signInLink) => `
      <li class="sign-in toggle-signin-trigger" role="signin" aria-label="${signInLabel}"><a href='${signInLink}' role="button">${signInLabel}</a></li>
    `,
  },
  campaignNav: {
    lvl0: items => `
    <li>
    <a class='cl-top-section_nav-item ${items.isActiveClass}' href="${items.lvl1Link}"  data-title="${items.lvl1Label}" >${items.lvl1LabelEscaped}</span>
    </a>
    </li>
    `,
    lvl1: items => `
    <li>
    <button class='cl-top-section_nav-item has-dropdown ${items.isActiveClass}' href="${items.lvl1Link}"  data-title="${items.lvl1Label}" data-dropdown="linksHover" data-options="is_hover:false;" data-activated-via="mouse">${items.lvl1LabelEscaped}<span class="cl-dropdown-chevron"></span>
    
    </button>
    </li>
    <ul id="linksHover" class="[mega] f-dropdown" data-dropdown-content>
    ${items.lvl2}
    </ul>
    `,
    lvl2: lvl3 => `
    <li>${lvl3}</li>`,
    
    lvl3: (lvl3Link, lvl3Label, linktarget, lvl3LabelEscape) => `
      <a href="${lvl3Link}" tabindex="-1" target='${linktarget}' data-content="${lvl3Label}">${lvl3LabelEscape}</a>
    `,
  
  }

};

export { primaryNav, templates };