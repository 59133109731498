/**
 * Simple API to get/set username, abstracts away from direct access to the persistence layer
 *
 * @example
 * 
 */
import { getCookie, setCookie, getRootDomain, syncAllLangCookies } from './cookies';

const cookieName = 'LANG';

function setLANG(lang) {
  setCookie(cookieName, lang, 9999, false, 
    getRootDomain(window.location));
  syncAllLangCookies(lang, 9999);
}

function deleteLANG(lang) {
  setCookie(cookieName, lang, -1, false, 
    getRootDomain(window.location));
  syncAllLangCookies(lang, -1);
}

// Mendix app using 'language-country' locale string, for example: 'fr-ca'(all lowercase)
const REGEX_FRENCH = /(^fr$|^fr[-_])/i;
function getPageLanguage() {
  const htmlLang = document.querySelector('html').getAttribute('lang');
  return REGEX_FRENCH.test(htmlLang) ? 'fr' : 'en';
}

function getLANG() {
  return getCookie(cookieName);
}

export { setLANG, deleteLANG, getPageLanguage, getLANG };