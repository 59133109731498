export default { 
  en: {
    close: 'Close',
    betaUserRegistrationClose:
      'The new home page is temporarily unavailable. Please try again later.',
    betaUserOptOut:
      'You are using the new home page. <a href="/dash-tableau/en">Return to the old version</a>',
    betaUserOptIn:
      'We’ve made things better. <a href="/dash2-tableau2/en/ui/home">Start using the new home page today.</a>',
    betaUserError:
      'The new home page is temporarily unavailable. Please try again later.',
    MHbetaUserOptOut:
      'You are using the new business home page. <a href="/dash-tableau/en">Return to the old version</a>',
  },
  fr: {
    close: 'Fermer',
    betaUserRegistrationClose:
      'La nouvelle page d’accueil est temporairement inaccessible. Veuillez réessayer plus tard.',
    betaUserOptOut:
      'Vous utilisez la nouvelle page d’accueil. <a href="/dash-tableau/fr">Retournez à l’ancienne version.</a>',
    betaUserOptIn:
      'Nous avons amélioré les choses.<a href="/dash2-tableau2/fr/iu/maison">Commencez à utiliser la nouvelle page d’accueil dès aujourd’hui.</a>',
    betaUserError:
      'La nouvelle page d’accueil est temporairement inaccessible. Veuillez réessayer plus tard.',
    MHbetaUserOptOut:
      'Vous utilisez la nouvelle page d’accueil pour les entreprises. <a href="/dash-tableau/fr">Retournez à l’ancienne version.</a>',
  },
};