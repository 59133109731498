const ssoLocaleEn = {
  true: 'true',
  login: 'login',
  registration: 'registration',
  signIn: 'signin',
  forgotUsername: 'forgot-username',
  forgotPassword: 'forgot-password',
  authlvl: 'authlvl',
  businessUrl: 'businessUrl',
  personalUrl: 'personalUrl',
  forceVouchFor: 'forceVouchFor',
  language: 'language',
  business: 'business',
  personal: 'personal',
  existing: 'existing',
  sourceApp: 'sourceApp',
  sourceUrl: 'sourceUrl',
  stepupId: 'stepupId',
  targetUrl: 'targetUrl',
  EPOST: 'EPOST',
  ESTO: 'ESTO',
  RSO: 'RSO',
  SHOP: 'SHOP',
  AUTOMATIC_TRACKING: 'AUTOMATIC-TRACKING',
  ShipOnline: 'ShipOnline',
  SMB: 'SMB',
  commercial_link: 'commercial_link',
  consumer: 'consumer',
  smb_link: 'smb_link',
  smb_mode: 'smb_mode',
  SMS: 'TRSMS',
};

export default ssoLocaleEn;