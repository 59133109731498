function Snapship() {
  return {
    languageToggleStrategy
  };

  /**
   * Create the new URL for the target language. Snapship using URL parameter 'locale=${lang}_ca' as language
   * identifier. But the initial URL may not include this parameter.
   *
   * @param url - The URL of the page
   * @param curLang - The current language
   * @param targetLang - The target language switching to
   */
  function languageToggleStrategy(url, curLang, targetLang) {
    const pattern = new RegExp(`(locale=)${curLang}_ca`, 'g');
    const newUrl = url.replace(pattern, `locale=${targetLang}_ca`);

    // snapship-ui Angular app special handling
    if (document.querySelector('cpc-header').length > 0) {
      if (document.querySelector('cpc-header').getAttribute('data-app-id') === 'snapship-ui') {
        if (newUrl.search('/en/') > -1) {
          return newUrl.replace('/en/', '/fr/');
        } 
        return newUrl.replace('/fr/', '/en/');
      } 
    }

    // found and replaced with new locale
    if (newUrl !== url) return newUrl;

    // no locale parameter, append if url end with '?'
    if (url.endsWith('?')) return `${url}locale=${targetLang}_ca`;

    // no locale parameter, append if url has '?'
    if (url.indexOf('?') > 0) return `${url}&locale=${targetLang}_ca`;

    // no locale parameter, append if url has NO '?'
    return `${url}?locale=${targetLang}_ca`;
  }
}

export default Snapship;