/* eslint-disable */

module.exports = {
  signInLabel: "Sign in",
  searchLabel: "Search",
  closeLabel: "Close",
  utilityAriaRoleLabel: "Utility",
  logoAltText: "Canada Post/Postes Canada",
  activePage: "personal/sitemap",
  assetBaseUrl: "/cpc/",
  "nodes": [
    {
      "label": "Business",
      "visibleInSiteMap": "true",
      "visibleInBreadCrumbs": "true",
      "preserveQs": "false",
      "description": "Learn about mailing services for businesses of all sizes.",
      "link": "/cpc/en/business.page?",
      "linkCMSPage": "business",
      "nodes": [
        {
          "label": "Shipping",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "true",
          "queryString": "Shipping overview",
          "description": "Learn about services and rates for Canadian and international shipping.",
          "link": "/cpc/en/business/shipping.page?",
          "linkCMSPage": "business/shipping",
          "nodes": [
            {
              "label": "Ship in Canada",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Learn about domestic shipping services to suit your business needs.",
              "link": "/cpc/en/business/shipping/canada.page?",
              "linkCMSPage": "business/shipping/canada",
              "nodes": [
                {
                  "label": "Find a rate and ship",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "false",
                  "preserveQs": "false",
                  "description": "Learn about costs for small business and large volume shipping.",
                  "link": "/cpc/en/business/shipping/find-rates-ship.page?",
                  "linkCMSPage": "business/shipping/find-rates-ship",
                  "nodes": [
                    {
                      "label": "Snap Ship",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/business/shipping/find-rates-ship/snap-ship.page?",
                      "linkCMSPage": "business/shipping/find-rates-ship/snap-ship",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Shipping Manager",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/business/shipping/find-rates-ship/shipping-manager.page?",
                      "linkCMSPage": "business/shipping/find-rates-ship/shipping-manager",
                      "preserveQs": "false"
                    },
                    {
                      "label": "EST 2.0",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/business/shipping/find-rates-ship/est-2.page?",
                      "linkCMSPage": "business/shipping/find-rates-ship/est-2",
                      "preserveQs": "false"
                    }
                  ]
                },
                {
                  "label": "Compare shipping services",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "preserveQs": "false",
                  "description": "Learn about domestic shipping speeds and features.",
                  "link": "/cpc/en/business/shipping/canada/compare.page?",
                  "linkCMSPage": "business/shipping/canada/compare",
                  "nodes": [
                    {
                      "label": "Regular Parcel",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/business/shipping/canada/compare/regular.page?",
                      "linkCMSPage": "business/shipping/canada/compare/regular",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Expedited Parcel",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/business/shipping/canada/compare/expedited.page?",
                      "linkCMSPage": "business/shipping/canada/compare/expedited",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Xpresspost",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/business/shipping/canada/compare/xpresspost.page?",
                      "linkCMSPage": "business/shipping/canada/compare/xpresspost",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Priority",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/business/shipping/canada/compare/priority.page?",
                      "linkCMSPage": "business/shipping/canada/compare/priority",
                      "preserveQs": "false"
                    }
                  ]
                },
                {
                  "label": "View restrictions",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "preserveQs": "false",
                  "description": "Learn about non-mailable and controlled item mail restrictions by country.",
                  "link": "/cpc/en/business/shipping/restrictions.page?",
                  "linkCMSPage": "business/shipping/restrictions",
                  "nodes": [
                    {
                      "label": "Cannabis",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/business/shipping/restrictions/cannabis.page?",
                      "linkCMSPage": "business/shipping/restrictions/cannabis",
                      "preserveQs": "false"
                    }
                  ]
                },
                {
                  "label": "Choose a shipping tool",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Compare our 3 shipping tools to find the tool for your business.",
                  "link": "/cpc/en/business/shipping/compare-shipping-tools.page?",
                  "linkCMSPage": "business/shipping/compare-shipping-tools",
                  "preserveQs": "false"
                },
                {
                  "label": " Third-party shipping software",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/cpc/en/business/shipping/third-party-shipping-software.page?",
                  "linkCMSPage": "business/shipping/third-party-shipping-software",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Ship internationally",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Learn about international shipping services for your business needs.",
              "link": "/cpc/en/business/shipping/international.page?",
              "linkCMSPage": "business/shipping/international",
              "nodes": [
                {
                  "label": "Find a rate and ship",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "preserveQs": "false",
                  "description": "Learn about costs for small business and large volume shipping.",
                  "link": "/cpc/en/business/shipping/find-rates-ship.page?",
                  "linkCMSPage": "business/shipping/find-rates-ship",
                  "nodes": [
                    {
                      "label": "Snap Ship",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/business/shipping/find-rates-ship/snap-ship.page?",
                      "linkCMSPage": "business/shipping/find-rates-ship/snap-ship",
                      "preserveQs": "false"
                    },
                    {
                      "label": "EST 2.0",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/business/shipping/find-rates-ship/est-2.page?",
                      "linkCMSPage": "business/shipping/find-rates-ship/est-2",
                      "preserveQs": "false"
                    }
                  ]
                },
                {
                  "label": "Compare shipping services",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "preserveQs": "false",
                  "description": "Learn about U.S. and international shipping speeds and features.",
                  "link": "/cpc/en/business/shipping/international/compare.page?",
                  "linkCMSPage": "business/shipping/international/compare",
                  "nodes": [
                    {
                      "label": "Small Packet – USA",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/business/shipping/international/compare/small-packet-usa.page?",
                      "linkCMSPage": "business/shipping/international/compare/small-packet-usa",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Small Packet International – Air or Surface",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/business/shipping/international/compare/small-packet-international.page?",
                      "linkCMSPage": "business/shipping/international/compare/small-packet-international",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Tracked Packet – USA",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/business/shipping/international/compare/tracked-packet-usa.page?",
                      "linkCMSPage": "business/shipping/international/compare/tracked-packet-usa",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Tracked Packet – International",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/business/shipping/international/compare/tracked-packet-international.page?",
                      "linkCMSPage": "business/shipping/international/compare/tracked-packet-international",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Expedited Parcel – USA",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/business/shipping/international/compare/expedited-parcel-usa.page?",
                      "linkCMSPage": "business/shipping/international/compare/expedited-parcel-usa",
                      "preserveQs": "false"
                    },
                    {
                      "label": "International Parcel – Air or Surface",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/business/shipping/international/compare/international-parcel.page?",
                      "linkCMSPage": "business/shipping/international/compare/international-parcel",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Xpresspost – USA",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/business/shipping/international/compare/xpresspost-usa.page?",
                      "linkCMSPage": "business/shipping/international/compare/xpresspost-usa",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Xpresspost – International",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/business/shipping/international/compare/xpresspost-international.page?",
                      "linkCMSPage": "business/shipping/international/compare/xpresspost-international",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Priority Worldwide",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/business/shipping/international/compare/priority-worldwide.page?",
                      "linkCMSPage": "business/shipping/international/compare/priority-worldwide",
                      "preserveQs": "false"
                    }
                  ]
                },
                {
                  "label": "View restrictions",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Learn about non-mailable and controlled restrictions by country",
                  "link": "/cpc/en/business/shipping/restrictions.page?",
                  "linkCMSPage": "business/shipping/restrictions",
                  "preserveQs": "false"
                },
                {
                  "label": "Estimate duties and taxes",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Estimate duties and taxes",
                  "link": "/information/app/wtz/business/landedCost",
                  "preserveQs": "false"
                },
                {
                  "label": "Find customs codes",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/information/app/wtz/business/findHsCode",
                  "preserveQs": "false"
                },
                {
                  "label": "Complete customs form",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/information/app/cdc",
                  "preserveQs": "false"
                },
                {
                  "label": "Choose a shipping tool",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Compare our 3 shipping tools to find the tool for your business.",
                  "link": "/cpc/en/business/shipping/compare-shipping-tools.page?",
                  "linkCMSPage": "business/shipping/compare-shipping-tools",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Track and find",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Quick links to online tools.",
              "link": "/cpc/en/business/shipping/track-find.page?",
              "linkCMSPage": "business/shipping/track-find",
              "nodes": [
                {
                  "label": "Track a package",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/track-reperage/en",
                  "preserveQs": "false"
                },
                {
                  "label": "Find a postal code",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/cpc/en/tools/find-a-postal-code.page",
                  "preserveQs": "false"
                },
                {
                  "label": "Find an address",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/cpc/en/tools/find-a-postal-code.page",
                  "preserveQs": "false"
                },
                {
                  "label": "Find a post office",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/information/app/fpo/personal/findpostoffice",
                  "preserveQs": "false"
                },
                {
                  "label": "Find a drop-off location",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/information/app/fdl/business/findDepositLocation",
                  "preserveQs": "false"
                },
                {
                  "label": "Find a delivery standard",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/cpc/en/tools/delivery-standards.page?",
                  "linkCMSPage": "tools/delivery-standards",
                  "preserveQs": "false"
                },
                {
                  "label": "Package Redirection",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Redirect a shipment while in transit.",
                  "link": "/cpc/en/business/shipping/package-redirection.page?",
                  "linkCMSPage": "business/shipping/package-redirection",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Access our quick tools",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "false",
              "preserveQs": "false",
              "description": "Quick links to track your parcel, find an address or postal code.",
              "link": "/cpc/en/business/shipping/track-find.page?",
              "linkCMSPage": "business/shipping/track-find",
              "nodes": [
                {
                  "label": "Snap Ship",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Online shipping tool best for small businesses.",
                  "link": "/cpc/en/business/shipping/find-rates-ship/snap-ship.page?",
                  "linkCMSPage": "business/shipping/find-rates-ship/snap-ship",
                  "preserveQs": "false"
                },
                {
                  "label": "Shipping Manager",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Online shipping tool for large volume shippers with a parcels contract.",
                  "link": "/cpc/en/business/shipping/find-rates-ship/shipping-manager.page?",
                  "linkCMSPage": "business/shipping/find-rates-ship/shipping-manager",
                  "preserveQs": "false"
                },
                {
                  "label": "Request a pickup",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Have us pick up packages from your business for shipping.",
                  "link": "/cpc/en/business/shipping/request-pickup.page?",
                  "linkCMSPage": "business/shipping/request-pickup",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Simplify returns",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Learn about how to create a returns strategy for your customers.",
              "link": "/cpc/en/business/shipping/returns.page?",
              "linkCMSPage": "business/shipping/returns",
              "nodes": [
                {
                  "label": "Customer return policy",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Create and manage return policies online, for free.",
                  "link": "/cpc/en/business/shipping/returns/customer-return-policy.page?",
                  "linkCMSPage": "business/shipping/returns/customer-return-policy",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Get shipping resources and articles",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Business Matters blog.",
              "link": "/blogs/business/category/shipping/",
              "target": "_blank",
              "preserveQs": "false"
            }
          ]
        },
        {
          "label": "Marketing",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "queryString": "Marketing overview",
          "description": "Learn about direct mail campaigns and renting address data.",
          "link": "/cpc/en/business/marketing.page?",
          "linkCMSPage": "business/marketing",
          "nodes": [
            {
              "label": "Launch a campaign",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Compare our direct mail services to match your campaign goals.",
              "link": "/cpc/en/business/marketing/campaign.page?",
              "linkCMSPage": "business/marketing/campaign",
              "nodes": [
                {
                  "label": "Reach every mailbox",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "preserveQs": "false",
                  "description": "Create a direct mail campaign online or with the help of a partner.",
                  "link": "/cpc/en/business/marketing/campaign/reach-every-mailbox.page?",
                  "linkCMSPage": "business/marketing/campaign/reach-every-mailbox",
                  "nodes": [
                    {
                      "label": "Precision Targeter",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "preserveQs": "false",
                      "description": "Learn how to target the right audience for your campaign.",
                      "link": "/cpc/en/business/marketing/campaign/reach-every-mailbox/precision-targeter.page?",
                      "linkCMSPage": "business/marketing/campaign/reach-every-mailbox/precision-targeter",
                      "nodes": [
                        {
                          "label": "Get to the tool",
                          "visibleInSiteMap": "true",
                          "visibleInBreadCrumbs": "true",
                          "link": "/cpc/en/business/marketing/campaign/reach-every-mailbox/precision-targeter/get-to-the-tool.page?",
                          "linkCMSPage": "business/marketing/campaign/reach-every-mailbox/precision-targeter/get-to-the-tool",
                          "preserveQs": "false"
                        },
                        {
                          "label": "Create a mailing plan",
                          "visibleInSiteMap": "true",
                          "visibleInBreadCrumbs": "true",
                          "link": "/cpc/en/business/marketing/campaign/reach-every-mailbox/precision-targeter/create-mailing-plan.page?",
                          "linkCMSPage": "business/marketing/campaign/reach-every-mailbox/precision-targeter/create-mailing-plan",
                          "preserveQs": "false"
                        },
                        {
                          "label": "Review your mailing plan",
                          "visibleInSiteMap": "true",
                          "visibleInBreadCrumbs": "true",
                          "link": "/cpc/en/business/marketing/campaign/reach-every-mailbox/precision-targeter/review-your-mailing-plan.page?",
                          "linkCMSPage": "business/marketing/campaign/reach-every-mailbox/precision-targeter/review-your-mailing-plan",
                          "preserveQs": "false"
                        },
                        {
                          "label": "Map buttons",
                          "visibleInSiteMap": "true",
                          "visibleInBreadCrumbs": "true",
                          "link": "/cpc/en/business/marketing/campaign/reach-every-mailbox/precision-targeter/map-buttons.page?",
                          "linkCMSPage": "business/marketing/campaign/reach-every-mailbox/precision-targeter/map-buttons",
                          "preserveQs": "false"
                        },
                        {
                          "label": "Data view buttons",
                          "visibleInSiteMap": "true",
                          "visibleInBreadCrumbs": "true",
                          "link": "/cpc/en/business/marketing/campaign/reach-every-mailbox/precision-targeter/data-view-buttons.page?",
                          "linkCMSPage": "business/marketing/campaign/reach-every-mailbox/precision-targeter/data-view-buttons",
                          "preserveQs": "false"
                        },
                        {
                          "label": "Menu buttons",
                          "visibleInSiteMap": "true",
                          "visibleInBreadCrumbs": "true",
                          "link": "/cpc/en/business/marketing/campaign/reach-every-mailbox/precision-targeter/menu-buttons.page?",
                          "linkCMSPage": "business/marketing/campaign/reach-every-mailbox/precision-targeter/menu-buttons",
                          "preserveQs": "false"
                        }
                      ]
                    },
                    {
                      "label": "Snap Admail",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/sam/",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Find a partner",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/tools/marketing/find-a-partner.page?",
                      "linkCMSPage": "tools/marketing/find-a-partner",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Precision Targeter",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "description": "Precision Targeter",
                      "link": "/cpc/en/business/marketing/campaign/reach-every-mailbox/precision-targeter.page?",
                      "linkCMSPage": "business/marketing/campaign/reach-every-mailbox/precision-targeter",
                      "preserveQs": "false"
                    }
                  ]
                },
                {
                  "label": "Discover similar customers",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Effectively target potential customers using postal code data.",
                  "link": "/cpc/en/business/marketing/campaign/discover-similar-customers.page?",
                  "linkCMSPage": "business/marketing/campaign/discover-similar-customers",
                  "preserveQs": "false"
                },
                {
                  "label": "Send Personalized Mail",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Launch a campaign with personalized, addressed direct mail.",
                  "link": "/cpc/en/business/marketing/campaign/send-personalized-mail.page?",
                  "linkCMSPage": "business/marketing/campaign/send-personalized-mail",
                  "preserveQs": "false"
                },
                {
                  "label": "Why direct mail marketing?",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Our research shows how direct mail supports marketing campaigns.",
                  "link": "/cpc/en/business/marketing/campaign/why-direct-mail-marketing.page?",
                  "linkCMSPage": "business/marketing/campaign/why-direct-mail-marketing",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Audience insights and solutions",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Access targeted customer lists to boost your sales.",
              "link": "/cpc/en/business/marketing/audience.page?",
              "linkCMSPage": "business/marketing/audience",
              "nodes": [
                {
                  "label": "Rent our prospect lists",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "preserveQs": "false",
                  "description": "Use the most current address data to target and segment customers.",
                  "link": "/cpc/en/business/marketing/audience/rent-list.page?",
                  "linkCMSPage": "business/marketing/audience/rent-list",
                  "nodes": [
                    {
                      "label": "NCOA Mover Data",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/business/marketing/audience/clean-list.page?",
                      "linkCMSPage": "business/marketing/audience/clean-list",
                      "preserveQs": "false"
                    }
                  ]
                },
                {
                  "label": "Clean your customer lists",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "preserveQs": "false",
                  "description": "Learn about services that increase the accuracy of customer data.  ",
                  "link": "/cpc/en/business/marketing/audience/clean-list.page?",
                  "linkCMSPage": "business/marketing/audience/clean-list",
                  "nodes": [
                    {
                      "label": "NCOA mover data service",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/business/marketing/audience/clean-your-customer-lists/ncoa-mover-data-service.page?",
                      "linkCMSPage": "business/marketing/audience/clean-your-customer-lists/ncoa-mover-data-service",
                      "preserveQs": "false"
                    }
                  ]
                },
                {
                  "label": "Get audience insights",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Analyze campaign data to optimize future campaigns.",
                  "link": "/cpc/en/business/marketing/audience/insights.page?",
                  "linkCMSPage": "business/marketing/audience/insights",
                  "preserveQs": "false"
                },
                {
                  "label": "License our data",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Use our data products to create your campaign mailing lists.",
                  "link": "/cpc/en/business/marketing/audience/license-data.page?",
                  "linkCMSPage": "business/marketing/audience/license-data",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Get marketing resources and articles",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Business Matters blog.",
              "link": "/blogs/business/category/marketing/",
              "target": "_blank",
              "preserveQs": "false"
            }
          ]
        },
        {
          "label": "E-commerce",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "queryString": " E-commerce overview",
          "description": "Learn about services to support your online business for customers.",
          "link": "/cpc/en/business/ecommerce.page?",
          "linkCMSPage": "business/ecommerce",
          "nodes": [
            {
              "label": "Start selling online",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Learn about setting up your online store with our partners.",
              "link": "/cpc/en/business/ecommerce/start-selling-online.page?",
              "linkCMSPage": "business/ecommerce/start-selling-online",
              "preserveQs": "false"
            },
            {
              "label": "Enhance your e-commerce operations",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Learn about services to enable online purchase, delivery and returns.",
              "link": "/cpc/en/business/ecommerce/enhance.page?",
              "linkCMSPage": "business/ecommerce/enhance",
              "nodes": [
                {
                  "label": "Verify customer addresses",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Integrate AddressComplete™ to improve your online checkout experience.",
                  "link": "/cpc/en/business/ecommerce/enhance/verify-addresses.page?",
                  "linkCMSPage": "business/ecommerce/enhance/verify-addresses",
                  "preserveQs": "false"
                },
                {
                  "label": "Display rates and delivery dates",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Integrate shipping costs and speeds directly in your online checkout.",
                  "link": "/cpc/en/business/ecommerce/enhance/display-rates-delivery-dates.page?",
                  "linkCMSPage": "business/ecommerce/enhance/display-rates-delivery-dates",
                  "preserveQs": "false"
                },
                {
                  "label": "Request a pickup",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Have us pick up packages from your business for shipping.",
                  "link": "/cpc/en/business/shipping/request-pickup.page?",
                  "linkCMSPage": "business/shipping/request-pickup",
                  "preserveQs": "false"
                },
                {
                  "label": "Provide parcel tracking",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Add tracking to your website and let customers track their purchase.",
                  "link": "/cpc/en/business/ecommerce/enhance/parcel-tracking.page?",
                  "linkCMSPage": "business/ecommerce/enhance/parcel-tracking",
                  "preserveQs": "false"
                },
                {
                  "label": "Ship from a store",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Ship online purchases to customers from the closest retail store.",
                  "link": "/cpc/en/business/ecommerce/enhance/ship-from-store.page?",
                  "linkCMSPage": "business/ecommerce/enhance/ship-from-store",
                  "preserveQs": "false"
                },
                {
                  "label": "Deliver to a post office",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Offer post office pickup of purchases to your customers.",
                  "link": "/cpc/en/business/ecommerce/enhance/deliver-to-post-office.page?",
                  "linkCMSPage": "business/ecommerce/enhance/deliver-to-post-office",
                  "preserveQs": "false"
                },
                {
                  "label": "Simplify returns",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Create the best returns experience for your customers.",
                  "link": "/cpc/en/business/shipping/returns.page?",
                  "linkCMSPage": "business/shipping/returns",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Integrate with our APIs",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Use free APIs to integrate our services directly with your website.",
              "link": "/cpc/en/business/ecommerce/integrate-apis.page?",
              "linkCMSPage": "business/ecommerce/integrate-apis",
              "preserveQs": "false"
            },
            {
              "label": "Get e-commerce resources and articles",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Business Matters blog.",
              "link": "/blogs/business/category/ecommerce/",
              "target": "_blank",
              "preserveQs": "false"
            }
          ]
        },
        {
          "label": "Small business",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "queryString": "Small business overview",
          "description": "Learn about shipping tools and discounts tailored for small business.",
          "link": "/cpc/en/business/small-business.page?",
          "linkCMSPage": "business/small-business",
          "nodes": [
            {
              "label": "Shipping discounts",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Learn about savings program discount levels.",
              "link": "/cpc/en/business/small-business/shipping-discounts.page?",
              "linkCMSPage": "business/small-business/shipping-discounts",
              "preserveQs": "false"
            },
            {
              "label": "Start selling online",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Learn about setting up your online store with our partners. ",
              "link": "/cpc/en/business/ecommerce/start-selling-online.page?",
              "linkCMSPage": "business/ecommerce/start-selling-online",
              "preserveQs": "false"
            },
            {
              "label": "Exclusive discounts",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/business/small-business/third-party-discounts.page?",
              "linkCMSPage": "business/small-business/third-party-discounts",
              "preserveQs": "false"
            },
            {
              "label": "Direct mail discounts",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Learn about small business savings on direct mail marketing campaigns.",
              "link": "/cpc/en/business/small-business/direct-mail-savings.page?",
              "linkCMSPage": "business/small-business/direct-mail-savings",
              "preserveQs": "false"
            },
            {
              "label": "Snap Ship",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Create shipping labels online and access discounted rates.",
              "link": "/cpc/en/business/shipping/find-rates-ship/snap-ship.page?",
              "linkCMSPage": "business/shipping/find-rates-ship/snap-ship",
              "preserveQs": "false"
            }
          ]
        },
        {
          "label": "Postal services",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "queryString": "Postal services overview",
          "description": "Learn about mailing services to support your business operations.",
          "link": "/cpc/en/business/postal-services.page?",
          "linkCMSPage": "business/postal-services",
          "nodes": [
            {
              "label": "Mailing",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Learn about services to manage your business mailings.",
              "link": "/cpc/en/business/postal-services/mailing.page?",
              "linkCMSPage": "business/postal-services/mailing",
              "nodes": [
                {
                  "label": "Get business letter discounts",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Learn about savings on large-volume business correspondence mail. ",
                  "link": "/cpc/en/business/postal-services/mailing/letter-discounts.page?",
                  "linkCMSPage": "business/postal-services/mailing/letter-discounts",
                  "preserveQs": "false"
                },
                {
                  "label": "Send publications",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Access lower postage rates on magazines, newspapers and newsletters.",
                  "link": "/cpc/en/business/postal-services/mailing/send-publications.page?",
                  "linkCMSPage": "business/postal-services/mailing/send-publications",
                  "preserveQs": "false"
                },
                {
                  "label": "Prepaid reply mail",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "preserveQs": "false",
                  "description": "Include postage-paid return mail as part of direct mail campaigns.",
                  "link": "/cpc/en/business/postal-services/mailing/prepaid-reply-mail.page?",
                  "linkCMSPage": "business/postal-services/mailing/prepaid-reply-mail",
                  "nodes": [
                    {
                      "label": "Design and track reply mail",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/business/postal-services/mailing/prepaid-reply-mail/design-track.page?",
                      "linkCMSPage": "business/postal-services/mailing/prepaid-reply-mail/design-track",
                      "preserveQs": "false"
                    }
                  ]
                },
                {
                  "label": "Forward your mail",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Forward mail to a new or temporary address.",
                  "link": "/cpc/en/personal/receiving/manage-mail/mail-forwarding.page?",
                  "linkCMSPage": "personal/receiving/manage-mail/mail-forwarding",
                  "preserveQs": "false"
                },
                {
                  "label": "Hold your mail",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Temporarily stop mail delivery to your address.",
                  "link": "/cpc/en/personal/receiving/manage-mail/hold-mail.page?",
                  "linkCMSPage": "personal/receiving/manage-mail/hold-mail",
                  "preserveQs": "false"
                },
                {
                  "label": "Register your mail",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Pay a flat rate for Registered Mail™ and get a signature upon arrival.",
                  "link": "/cpc/en/business/postal-services/mailing/register.page?",
                  "linkCMSPage": "business/postal-services/mailing/register",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Money services and prepaid cards",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Learn about worldwide money transfers and buying secure, prepaid cards.",
              "link": "/cpc/en/business/postal-services/money-prepaid-cards.page?",
              "linkCMSPage": "business/postal-services/money-prepaid-cards",
              "nodes": [
                {
                  "label": "Money orders",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Send certified cashable documents securely through the mail.",
                  "link": "/cpc/en/business/postal-services/money-prepaid-cards/money-orders.page?",
                  "linkCMSPage": "business/postal-services/money-prepaid-cards/money-orders",
                  "preserveQs": "false"
                },
                {
                  "label": "Prepaid credit cards",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Buy prepaid cards at the post office for shopping and travel.",
                  "link": "/cpc/en/business/postal-services/money-prepaid-cards/credit-cards.page?",
                  "linkCMSPage": "business/postal-services/money-prepaid-cards/credit-cards",
                  "preserveQs": "false"
                },
                {
                  "label": "Gift cards and prepaid products",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Buy gift cards and phone vouchers at the post office. ",
                  "link": "/cpc/en/business/postal-services/money-prepaid-cards/mobile-top-ups-prepaid-products.page?",
                  "linkCMSPage": "business/postal-services/money-prepaid-cards/mobile-top-ups-prepaid-products",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Find a post office",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/information/app/fpo/personal/findpostoffice",
              "preserveQs": "false"
            },
            {
              "label": "Rent a post office box",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Get your business mail delivered to a secure Postal Box.",
              "link": "/cpc/en/business/postal-services/rent-postal-box.page?",
              "linkCMSPage": "business/postal-services/rent-postal-box",
              "preserveQs": "false"
            },
            {
              "label": "Digital mail and document sharing",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Send pay statements and tax forms or large files to employees securely.",
              "link": "/cpc/en/business/postal-services/digital-mail.page?",
              "linkCMSPage": "business/postal-services/digital-mail",
              "nodes": [
                {
                  "label": "Share confidential files digitally (Connect)",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Securely send messages and documents outside your corporate firewall.",
                  "link": "/cpc/en/business/postal-services/digital-mail/epost-connect.page?",
                  "linkCMSPage": "business/postal-services/digital-mail/epost-connect",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Verify customer identity",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Setup Digital Proof of Identity to protect against fraud and theft.",
              "link": "/cpc/en/business/postal-services/digital-proof-identity.page?",
              "linkCMSPage": "business/postal-services/digital-proof-identity",
              "preserveQs": "false"
            },
            {
              "label": "Purchase stamps and meters",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Choose your postage and save on frequent or large business mailing.",
              "link": "/cpc/en/business/postal-services/stamps-meters.page?",
              "linkCMSPage": "business/postal-services/stamps-meters",
              "preserveQs": "false"
            },
            {
              "label": "Shop",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Shop for stamps, shipping supplies and collectibles.",
              "link": "/store-boutique/en/home",
              "preserveQs": "false"
            },
            {
              "label": "Billing and Invoices",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Learn how to build customized invoices for your business.",
              "link": "/cpc/en/business/postal-services/commercial-invoices.page?",
              "linkCMSPage": "business/postal-services/commercial-invoices",
              "preserveQs": "false"
            }
          ]
        },
        {
          "label": "Articles and resources",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "queryString": "Business Matters home",
          "description": "Access articles with ideas and tips to support your business operations.",
          "link": "/blogs/business/",
          "target": "_blank",
          "nodes": [
            {
              "label": "All posts in shipping",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "link": "/blogs/business/category/shipping/",
              "target": "_blank",
              "nodes": [
                {
                  "label": "Shipping articles",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/blogs/business/category/shipping/shipping-insights",
                  "target": "_blank",
                  "preserveQs": "false"
                },
                {
                  "label": "Shipping resources",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/blogs/business/category/shipping/shipping-resources/",
                  "target": "_blank",
                  "preserveQs": "false"
                },
                {
                  "label": "Shipping events",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/cpc/en/business/marketing/events/featured.page?",
                  "linkCMSPage": "business/marketing/events/featured",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "All posts in marketing",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "link": "/blogs/business/category/marketing/",
              "target": "_blank",
              "nodes": [
                {
                  "label": "Marketing articles",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/blogs/business/category/marketing/marketing-insights",
                  "target": "_blank",
                  "preserveQs": "false"
                },
                {
                  "label": "Marketing resources",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/blogs/business/category/marketing/marketing-resources",
                  "target": "_blank",
                  "preserveQs": "false"
                },
                {
                  "label": "Marketing events",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/cpc/en/business/marketing/events/marketing.page?",
                  "linkCMSPage": "business/marketing/events/marketing",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "All posts in e-commerce",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "link": "/blogs/business/category/ecommerce/",
              "target": "_blank",
              "nodes": [
                {
                  "label": "E-commerce articles",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/blogs/business/category/ecommerce/ecommerce-insights",
                  "target": "_blank",
                  "preserveQs": "false"
                },
                {
                  "label": "E-commerce resources",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/blogs/business/category/ecommerce/ecommerce-resources",
                  "target": "_blank",
                  "preserveQs": "false"
                },
                {
                  "label": "E-commerce events",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/cpc/en/business/marketing/events/ecommerce.page?",
                  "linkCMSPage": "business/marketing/events/ecommerce",
                  "preserveQs": "false"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "label": "Utility",
      "visibleInSiteMap": "true",
      "visibleInBreadCrumbs": "false",
      "preserveQs": "false",
      "link": "/cpc/en/home.page?",
      "linkCMSPage": "",
      "nodes": [
        {
          "label": "Support",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "description": "Get answers to common questions and other details on our services.",
          "link": "/cpc/en/support.page?",
          "linkCMSPage": "support",
          "preserveQs": "false"
        }
      ]
    },
    {
      "label": "PersonalBusiness",
      "visibleInSiteMap": "true",
      "visibleInBreadCrumbs": "false",
      "preserveQs": "false",
      "link": "/cpc/en/home.page?",
      "linkCMSPage": "",
      "nodes": [
        {
          "label": "Personal",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "description": "personal",
          "link": "/cpc/en/personal.page?",
          "linkCMSPage": "personal",
          "preserveQs": "false"
        },
        {
          "label": "Business",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "description": "business",
          "link": "/cpc/en/business.page?",
          "linkCMSPage": "business",
          "preserveQs": "false"
        },
        {
          "label": "Our company",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "description": "Our company",
          "link": "/cpc/en/our-company.page?",
          "linkCMSPage": "our-company",
          "preserveQs": "false"
        },
        {
          "label": "Shop",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "description": "store",
          "link": "/store-boutique/en/home",
          "preserveQs": "false"
        },
        {
          "label": "Tools",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "description": "tools",
          "link": "/cpc/en/tools.page?",
          "linkCMSPage": "tools",
          "preserveQs": "false"
        }
      ]
    },
    {
      "label": "Footer",
      "visibleInSiteMap": "true",
      "visibleInBreadCrumbs": "true",
      "preserveQs": "false",
      "link": "/cpc/en/home.page?",
      "linkCMSPage": "",
      "nodes": [
        {
          "label": "Support",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "description": "Support",
          "link": "/cpc/en/home.page?",
          "linkCMSPage": "",
          "nodes": [
            {
              "label": "Need help?",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/support.page?",
              "linkCMSPage": "support",
              "preserveQs": "false"
            },
            {
              "label": "Contact us",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/support.page#panel2-1",
              "preserveQs": "false"
            }
          ]
        },
        {
          "label": "Follow Us",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "description": "Follow Us",
          "link": "/cpc/en/home.page?",
          "linkCMSPage": "",
          "nodes": [
            {
              "label": "Facebook",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/home.page?",
              "linkCMSPage": "",
              "target": "_blank"
            },
            {
              "label": "Twitter",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "http://twitter.com",
              "target": "_blank",
              "preserveQs": "false"
            },
            {
              "label": "Instagram",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/home.page?",
              "linkCMSPage": ""
            },
            {
              "label": "LinkedIn",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/home.page?",
              "linkCMSPage": ""
            }
          ]
        },
        {
          "label": "Corporate",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "description": "Corporate",
          "link": "/cpc/en/home.page?",
          "linkCMSPage": "",
          "nodes": [
            {
              "label": "About us",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/our-company/about-us.page?",
              "linkCMSPage": "our-company/about-us",
              "preserveQs": "false"
            },
            {
              "label": "Media centre",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/our-company/news-and-media/media-centre.page?",
              "linkCMSPage": "our-company/news-and-media/media-centre",
              "preserveQs": "false"
            },
            {
              "label": "Careers",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/our-company/jobs.page?",
              "linkCMSPage": "our-company/jobs",
              "preserveQs": "false"
            },
            {
              "label": "I'm an employee",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "https://infopost.ca/",
              "preserveQs": "false"
            },
            {
              "label": "Talent Zone",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "https://mysite.canadapost.ca/saml2/idp/sso?saml2sp=https%3A%2F%2Fwww.successfactors.com%2FS000016952T1&RelayState=%2Fsf%2Fhome",
              "preserveQs": "false"
            },
            {
              "label": "Negotiations Updates",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/our-company/news-and-media/corporate-news/negotiations-list.page?",
              "linkCMSPage": "our-company/news-and-media/corporate-news/negotiations-list",
              "preserveQs": "false"
            }
          ]
        },
        {
          "label": "Blogs",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "description": "Blogs",
          "link": "/cpc/en/home.page?",
          "linkCMSPage": "",
          "nodes": [
            {
              "label": "Business Matters",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/blogs/business",
              "preserveQs": "false"
            },
            {
              "label": "Canada Post Magazine",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/blogs/personal",
              "preserveQs": "false"
            }
          ]
        },
        {
          "label": "Terms",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "link": "/cpc/en/home.page?",
          "linkCMSPage": "",
          "nodes": [
            {
              "label": "Accessibility",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/our-company/about-us/corporate-responsibility/accessibility.page?",
              "linkCMSPage": "our-company/about-us/corporate-responsibility/accessibility",
              "preserveQs": "false"
            },
            {
              "label": "Legal",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/support/kb/general-inquiries/general-information/legal-terms-of-use-and-conditions",
              "preserveQs": "false"
            },
            {
              "label": "Privacy",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/our-company/about-us/transparency-and-trust/privacy-centre.page?",
              "preserveQs": "false"
            },
            {
              "label": "Cookie Policy",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/our-company/about-us/transparency-and-trust/privacy-centre/cookie-policy.page?",
              "preserveQs": "false",
            },
            {
              "label": "Research",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/our-company/research-panel.page",
              "preserveQs": "false"
            }
          ]
        }
      ]
    },
    {
      "label": "Personal",
      "visibleInSiteMap": "true",
      "visibleInBreadCrumbs": "true",
      "preserveQs": "false",
      "description": "Learn about mailing services for individuals.",
      "link": "/cpc/en/personal.page?",
      "linkCMSPage": "personal",
      "nodes": [
        {
          "label": "Receiving",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "queryString": "Receiving overview",
          "description": "Learn about the different ways to receive your mail and packages.",
          "link": "/cpc/en/personal/receiving.page?",
          "linkCMSPage": "personal/receiving",
          "nodes": [
            {
              "label": "Manage your mail",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Learn about residential community mailboxes and moving mail services.",
              "link": "/cpc/en/personal/receiving/manage-mail.page?",
              "linkCMSPage": "personal/receiving/manage-mail",
              "nodes": [
                {
                  "label": "Forward your mail",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "preserveQs": "false",
                  "description": "Forward mail to a new or temporary address.",
                  "link": "/cpc/en/personal/receiving/manage-mail/mail-forwarding.page?",
                  "linkCMSPage": "personal/receiving/manage-mail/mail-forwarding",
                  "nodes": [
                    {
                      "label": "Customized Mail Forwarding for commercial customers",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/personal/receiving/manage-mail/mail-forwarding/custom-commercial.page?",
                      "linkCMSPage": "personal/receiving/manage-mail/mail-forwarding/custom-commercial",
                      "preserveQs": "false"
                    }
                  ]
                },
                {
                  "label": "Hold your mail",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Temporarily stop mail delivery to your address.",
                  "link": "/cpc/en/personal/receiving/manage-mail/hold-mail.page?",
                  "linkCMSPage": "personal/receiving/manage-mail/hold-mail",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Alternative delivery options",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Learn about receiving mail at the post office and condo parcel lockers.",
              "link": "/cpc/en/personal/receiving/alternative-delivery.page?",
              "linkCMSPage": "personal/receiving/alternative-delivery",
              "nodes": [
                {
                  "label": "Deliver purchases to post office (FlexDelivery)",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Have packages sent to the post office for pickup. ",
                  "link": "/cpc/en/personal/receiving/alternative-delivery/flexdelivery.page?",
                  "linkCMSPage": "personal/receiving/alternative-delivery/flexdelivery",
                  "preserveQs": "false"
                },
                {
                  "label": "Rent a post office box",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Rent a secure PO box to receive mail and packages.",
                  "link": "/cpc/en/personal/receiving/alternative-delivery/post-office-box.page?",
                  "linkCMSPage": "personal/receiving/alternative-delivery/post-office-box",
                  "preserveQs": "false"
                },
                {
                  "label": "Parcel lockers",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Pick up packages from the locker in your condo or apartment building.",
                  "link": "/cpc/en/personal/receiving/alternative-delivery/parcel-lockers.page?",
                  "linkCMSPage": "personal/receiving/alternative-delivery/parcel-lockers",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Moving to a new home",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Learn about mail forwarding and accessing your new community mailbox.",
              "link": "/cpc/en/personal/receiving/moving-house.page?",
              "linkCMSPage": "personal/receiving/moving-house",
              "preserveQs": "false"
            },
            {
              "label": "Track a package",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Learn about tracking, delivery notice cards and reference numbers.",
              "link": "/track-reperage/en",
              "nodes": [
                {
                  "label": "Automatic tracking",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/cpc/en/personal/receiving/automatic-tracking.page?",
                  "linkCMSPage": "personal/receiving/automatic-tracking",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Find a post office",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/information/app/fpo/personal/findpostoffice",
              "preserveQs": "false"
            },
            {
              "label": "Our mobile app",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Learn about our free app and get package updates on the go. ",
              "link": "/cpc/en/personal/receiving/mobile-app.page?",
              "linkCMSPage": "personal/receiving/mobile-app",
              "preserveQs": "false"
            }
          ]
        },
        {
          "label": "Sending",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "queryString": "Sending overview",
          "description": "View postage rates and shipping services for mail and packages.",
          "link": "/cpc/en/personal/sending.page?",
          "linkCMSPage": "personal/sending",
          "nodes": [
            {
              "label": "Letters and mail",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Learn about postage prices and mail sizing.",
              "link": "/cpc/en/personal/sending/letters-mail.page?",
              "linkCMSPage": "personal/sending/letters-mail",
              "nodes": [
                {
                  "label": "Postage rates",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Mailing prices for domestic and international letters and cards.",
                  "link": "/cpc/en/personal/sending/letters-mail/postage-rates.page?",
                  "linkCMSPage": "personal/sending/letters-mail/postage-rates",
                  "preserveQs": "false"
                },
                {
                  "label": "Letter weight and size",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Measurements for standard and oversized or non-standard mail.",
                  "link": "/cpc/en/personal/sending/letters-mail/letter-size.page?",
                  "linkCMSPage": "personal/sending/letters-mail/letter-size",
                  "preserveQs": "false"
                },
                {
                  "label": "Register your mail",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Buy Registered Mail™ to get proof your letter was received.",
                  "link": "/cpc/en/personal/sending/letters-mail/registered-mail.page?",
                  "linkCMSPage": "personal/sending/letters-mail/registered-mail",
                  "preserveQs": "false"
                },
                {
                  "label": "Create custom stamps",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Design personal stamps for domestic and international mailing.",
                  "link": "/cpc/en/personal/sending/letters-mail/custom-stamps.page?",
                  "linkCMSPage": "personal/sending/letters-mail/custom-stamps",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Parcels",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Learn about different shipping services for packages.",
              "link": "/cpc/en/personal/sending/parcels.page?",
              "linkCMSPage": "personal/sending/parcels",
              "nodes": [
                {
                  "label": "Ship online",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Create, pay for and print a shipping label online.",
                  "link": "/cpc/en/personal/sending/parcels/ship-online.page?",
                  "linkCMSPage": "personal/sending/parcels/ship-online",
                  "preserveQs": "false"
                },
                {
                  "label": "Return your purchase",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Access and print a return shipping label online.",
                  "link": "/cpc/en/personal/sending/parcels/return-labels.page?",
                  "linkCMSPage": "personal/sending/parcels/return-labels",
                  "preserveQs": "false"
                },
                {
                  "label": "View restrictions",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "preserveQs": "false",
                  "description": "Learn about non-mailable and controlled item restrictions by country.",
                  "link": "/cpc/en/personal/sending/parcels/restrictions.page?",
                  "linkCMSPage": "personal/sending/parcels/restrictions",
                  "nodes": [
                    {
                      "label": "Cannabis",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/personal/sending/parcels/restrictions/cannabis.page?",
                      "linkCMSPage": "personal/sending/parcels/restrictions/cannabis",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Firearms",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/personal/sending/parcels/restrictions/firearms.page?",
                      "linkCMSPage": "personal/sending/parcels/restrictions/firearms",
                      "preserveQs": "false"
                    }
                  ]
                },
                {
                  "label": "Compare shipping services in Canada",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "preserveQs": "false",
                  "description": "Learn about domestic shipping speeds and features.",
                  "link": "/cpc/en/personal/sending/parcels/compare-services-canada.page?",
                  "linkCMSPage": "personal/sending/parcels/compare-services-canada",
                  "nodes": [
                    {
                      "label": "Regular Parcel",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/personal/sending/parcels/compare-services-canada/regular.page?",
                      "linkCMSPage": "personal/sending/parcels/compare-services-canada/regular",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Xpresspost",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/personal/sending/parcels/compare-services-canada/xpresspost.page?",
                      "linkCMSPage": "personal/sending/parcels/compare-services-canada/xpresspost",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Priority",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/personal/sending/parcels/compare-services-canada/priority.page?",
                      "linkCMSPage": "personal/sending/parcels/compare-services-canada/priority",
                      "preserveQs": "false"
                    }
                  ]
                },
                {
                  "label": "Compare international shipping services",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "preserveQs": "false",
                  "description": "Learn about international shipping speeds and features.",
                  "link": "/cpc/en/personal/sending/parcels/compare-services-international.page?",
                  "linkCMSPage": "personal/sending/parcels/compare-services-international",
                  "nodes": [
                    {
                      "label": "Small Packet USA",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/personal/sending/parcels/compare-services-international/small-packet-usa.page?",
                      "linkCMSPage": "personal/sending/parcels/compare-services-international/small-packet-usa",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Small Packet International – Air or Surface",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/personal/sending/parcels/compare-services-international/small-packet-international.page?",
                      "linkCMSPage": "personal/sending/parcels/compare-services-international/small-packet-international",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Xpresspost – International",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/personal/sending/parcels/compare-services-international/xpresspost-international.page?",
                      "linkCMSPage": "personal/sending/parcels/compare-services-international/xpresspost-international",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Xpresspost – USA",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/personal/sending/parcels/compare-services-international/xpresspost-usa.page?",
                      "linkCMSPage": "personal/sending/parcels/compare-services-international/xpresspost-usa",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Tracked Packet – International",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/personal/sending/parcels/compare-services-international/tracked-packet-international.page?",
                      "linkCMSPage": "personal/sending/parcels/compare-services-international/tracked-packet-international",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Tracked Packet – USA",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/personal/sending/parcels/compare-services-international/tracked-packet-usa.page?",
                      "linkCMSPage": "personal/sending/parcels/compare-services-international/tracked-packet-usa",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Expedited Parcel – USA",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/personal/sending/parcels/compare-services-international/expedited-usa.page?",
                      "linkCMSPage": "personal/sending/parcels/compare-services-international/expedited-usa",
                      "preserveQs": "false"
                    },
                    {
                      "label": "International Parcel – Air or Surface",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/personal/sending/parcels/compare-services-international/international-parcel.page?",
                      "linkCMSPage": "personal/sending/parcels/compare-services-international/international-parcel",
                      "preserveQs": "false"
                    }
                  ]
                },
                {
                  "label": "Estimate duties and taxes",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/information/app/wtz/business/landedCost",
                  "preserveQs": "false"
                },
                {
                  "label": "Complete customs form",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/information/app/cdc",
                  "preserveQs": "false"
                },
                {
                  "label": "Flat rate boxes",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Purchase the box and included postage at the post office, ship anytime.",
                  "link": "/cpc/en/personal/sending/parcels/flat-rate-box.page?",
                  "linkCMSPage": "personal/sending/parcels/flat-rate-box",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Access our quick tools",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Learn about your favourite shipping tools.",
              "link": "/cpc/en/personal/sending/quick-tools.page?",
              "linkCMSPage": "personal/sending/quick-tools",
              "nodes": [
                {
                  "label": "Find a rate",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/cpc/en/tools/find-a-rate.page",
                  "preserveQs": "false"
                },
                {
                  "label": "Find a delivery standard",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/cpc/en/tools/delivery-standards.page?",
                  "linkCMSPage": "tools/delivery-standards",
                  "preserveQs": "false"
                },
                {
                  "label": "Track a package",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/track-reperage/en",
                  "preserveQs": "false"
                },
                {
                  "label": "Find a post office",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/information/app/fpo/personal/findpostoffice",
                  "preserveQs": "false"
                },
                {
                  "label": "Find a postal code",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/cpc/en/tools/find-a-postal-code.page",
                  "preserveQs": "false"
                }
              ]
            }
          ]
        },
        {
          "label": "Money services",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "queryString": "Money services overview",
          "description": "Learn about money services available at the post office.",
          "link": "/cpc/en/personal/money-services.page?",
          "linkCMSPage": "personal/money-services",
          "nodes": [
            {
              "label": "Send money",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Learn about affordable money transfers and cashable money orders.",
              "link": "/cpc/en/personal/money-government-services/send-money.page?",
              "linkCMSPage": "personal/money-government-services/send-money",
              "nodes": [
                {
                  "label": "Money orders",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Send secure, cashable money orders from the post office.",
                  "link": "/cpc/en/personal/money-government-services/send-money/money-orders.page?",
                  "linkCMSPage": "personal/money-government-services/send-money/money-orders",
                  "preserveQs": "false"
                },
                {
                  "label": "International money transfer (MoneyGram)",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Send affordable, international money transfers all over the world.",
                  "link": "/cpc/en/personal/money-government-services/send-money/international-money-transfers.page?",
                  "linkCMSPage": "personal/money-government-services/send-money/international-money-transfers",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Manage money",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Learn about prepaid cards and ordering foreign currency.",
              "link": "/cpc/en/personal/money-government-services/manage-money.page?",
              "linkCMSPage": "personal/money-government-services/manage-money",
              "nodes": [
                {
                  "label": "Prepaid reloadable cards",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "preserveQs": "false",
                  "description": "Buy prepaid cards at the post office for shopping and travel.",
                  "link": "/cpc/en/personal/money-government-services/manage-money/prepaid-cards.page?",
                  "linkCMSPage": "personal/money-government-services/manage-money/prepaid-cards",
                  "nodes": [
                    {
                      "label": "Mastercard",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "preserveQs": "false",
                      "link": "/cpc/en/personal/money-government-services/manage-money/prepaid-cards/mastercard.page?",
                      "linkCMSPage": "personal/money-government-services/manage-money/prepaid-cards/mastercard",
                      "nodes": [
                        {
                          "label": "Get to know your card",
                          "visibleInSiteMap": "true",
                          "visibleInBreadCrumbs": "true",
                          "link": "/cpc/en/personal/money-government-services/manage-money/prepaid-cards/mastercard/overview.page?",
                          "linkCMSPage": "personal/money-government-services/manage-money/prepaid-cards/mastercard/overview",
                          "preserveQs": "false"
                        },
                        {
                          "label": "How to get started",
                          "visibleInSiteMap": "true",
                          "visibleInBreadCrumbs": "true",
                          "link": "/cpc/en/personal/money-government-services/manage-money/prepaid-cards/mastercard/how-to-get-started.page?",
                          "linkCMSPage": "personal/money-government-services/manage-money/prepaid-cards/mastercard/how-to-get-started",
                          "preserveQs": "false"
                        },
                        {
                          "label": "How it works",
                          "visibleInSiteMap": "true",
                          "visibleInBreadCrumbs": "true",
                          "link": "/cpc/en/personal/money-government-services/manage-money/prepaid-cards/mastercard/how-it-works.page?",
                          "linkCMSPage": "personal/money-government-services/manage-money/prepaid-cards/mastercard/how-it-works",
                          "preserveQs": "false"
                        },
                        {
                          "label": "Contact us",
                          "visibleInSiteMap": "true",
                          "visibleInBreadCrumbs": "true",
                          "link": "/cpc/en/personal/money-government-services/manage-money/prepaid-cards/mastercard/contact-us.page?",
                          "linkCMSPage": "personal/money-government-services/manage-money/prepaid-cards/mastercard/contact-us",
                          "preserveQs": "false"
                        },
                        {
                          "label": "FAQ",
                          "visibleInSiteMap": "true",
                          "visibleInBreadCrumbs": "true",
                          "link": "/cpc/en/personal/money-government-services/manage-money/prepaid-cards/mastercard/faq.page?",
                          "linkCMSPage": "personal/money-government-services/manage-money/prepaid-cards/mastercard/faq",
                          "preserveQs": "false"
                        }
                      ]
                    }
                  ]
                },
                {
                  "label": "Other prepaid services",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Buy calling cards and mobile recharge cards at the post office.",
                  "link": "/cpc/en/personal/money-government-services/manage-money/prepaid-services-mobile-recharge.page?",
                  "linkCMSPage": "personal/money-government-services/manage-money/prepaid-services-mobile-recharge",
                  "preserveQs": "false"
                },
                {
                  "label": "Foreign cash delivery",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Order currency online for delivery to your home or the post office.",
                  "link": "/cpc/en/personal/money-government-services/manage-money/foreign-currency-delivery.page?",
                  "linkCMSPage": "personal/money-government-services/manage-money/foreign-currency-delivery",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Personal loans",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Get migratory bird hunting permits at the post office.",
              "link": "/cpc/en/personal/mymoney/loan.page?",
              "linkCMSPage": "personal/mymoney/loan",
              "preserveQs": "false"
            },
            {
              "label": "Gift cards",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Purchase gift cards at the post office.",
              "link": "/cpc/en/personal/money-government-services/gift-cards.page?",
              "linkCMSPage": "personal/money-government-services/gift-cards",
              "preserveQs": "false"
            }
          ]
        },
        {
          "label": "Collectible stamps and coins",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "queryString": "Collectible stamps and coins overview",
          "description": "Learn about collectible stamps and access pictorial cancels.",
          "link": "/cpc/en/personal/collectibles.page?",
          "linkCMSPage": "personal/collectibles",
          "nodes": [
            {
              "label": "Canadian stamp stories",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Tips and ideas on collecting stamps and coins.",
              "link": "/cpc/en/personal/collectibles/stamp-stories.page?",
              "linkCMSPage": "personal/collectibles/stamp-stories",
              "nodes": [
                {
                  "label": "Details magazine collections catalogue",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/cpc/en/personal/collectibles/stamp-stories/details-magazine-collections-catalogue.page?",
                  "linkCMSPage": "personal/collectibles/stamp-stories/details-magazine-collections-catalogue",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Suggest a stamp",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Send us your stamp theme ideas.",
              "link": "/cpc/en/personal/collectibles/suggest-stamp.page?",
              "linkCMSPage": "personal/collectibles/suggest-stamp",
              "preserveQs": "false"
            },
            {
              "label": "Pictorial cancels",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "View available postal cancels and which post office offers them.",
              "link": "/cpc/en/personal/collectibles/pictorial-cancels.page?",
              "linkCMSPage": "personal/collectibles/pictorial-cancels",
              "preserveQs": "false"
            }
          ]
        }
      ]
    },
    {
      "label": "Mercury",
      "visibleInSiteMap": "true",
      "visibleInBreadCrumbs": "true",
      "preserveQs": "false",
      "description": "mercury",
      "link": "/cpc/en/home.page?",
      "linkCMSPage": "",
      "nodes": [
        {
          "label": "Overview",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "description": "overview",
          "link": "/cpc/en/home.page?",
          "linkCMSPage": "",
          "nodes": [
            {
              "label": "Accessibility",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "accessibility",
              "link": "/cpc/en/mercury/overview/accessibility.page?",
              "linkCMSPage": "mercury/overview/accessibility",
              "preserveQs": "false"
            },
            {
              "label": "Grids",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/mercury/overview/grids.page?",
              "linkCMSPage": "mercury/overview/grids",
              "preserveQs": "false"
            },
            {
              "label": "Optimizing for search",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/mercury/overview/optimizing-for-search.page?",
              "linkCMSPage": "mercury/overview/optimizing-for-search",
              "preserveQs": "false"
            },
            {
              "label": "Page Structure",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/mercury/overview/page-structure.page?",
              "linkCMSPage": "mercury/overview/page-structure",
              "preserveQs": "false"
            },
            {
              "label": "Templates: Campaign page",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/mercury/overview/template-campaign-page.page?",
              "linkCMSPage": "mercury/overview/template-campaign-page",
              "preserveQs": "false"
            },
            {
              "label": "Templates: Product page",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/mercury/overview/template-product-page.page?",
              "linkCMSPage": "mercury/overview/template-product-page",
              "preserveQs": "false"
            },
            {
              "label": "Units and measurements",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/mercury/overview/units-measurements.page?",
              "linkCMSPage": "mercury/overview/units-measurements",
              "preserveQs": "false"
            }
          ]
        },
        {
          "label": "Style",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "description": "style",
          "link": "/cpc/en/home.page?",
          "linkCMSPage": "",
          "nodes": [
            {
              "label": "Colour",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/mercury/style/colour.page?",
              "linkCMSPage": "mercury/style/colour",
              "preserveQs": "false"
            },
            {
              "label": "Grammar and mechanics",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "grammar-mechanics",
              "link": "/cpc/en/mercury/style/grammar-mechanics.page?",
              "linkCMSPage": "mercury/style/grammar-mechanics",
              "preserveQs": "false"
            },
            {
              "label": "Iconography",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/mercury/style/iconography.page?",
              "linkCMSPage": "mercury/style/iconography",
              "preserveQs": "false"
            },
            {
              "label": "Illustration",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/mercury/style/illustration.page?",
              "linkCMSPage": "mercury/style/illustration",
              "preserveQs": "false"
            },
            {
              "label": "Logo",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/mercury/style/logo.page?",
              "linkCMSPage": "mercury/style/logo",
              "preserveQs": "false"
            },
            {
              "label": "Photography",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/mercury/style/photography.page?",
              "linkCMSPage": "mercury/style/photography",
              "preserveQs": "false"
            },
            {
              "label": "Typography",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/mercury/style/typography.page?",
              "linkCMSPage": "mercury/style/typography",
              "preserveQs": "false"
            },
            {
              "label": "Voice and tone",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "voice-tone",
              "link": "/cpc/en/mercury/style/voice-tone.page?",
              "linkCMSPage": "mercury/style/voice-tone",
              "preserveQs": "false"
            }
          ]
        },
        {
          "label": "Components",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "description": "components",
          "link": "/cpc/en/home.page?",
          "linkCMSPage": "",
          "nodes": [
            {
              "label": "Accordions",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Accordions",
              "link": "/cpc/en/mercury/components/accordion.page?",
              "linkCMSPage": "mercury/components/accordion",
              "preserveQs": "false"
            },
            {
              "label": "Breadcrumbs",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/mercury/components/breadcrumbs.page?",
              "linkCMSPage": "mercury/components/breadcrumbs",
              "preserveQs": "false"
            },
            {
              "label": "Buttons",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/mercury/components/buttons.page?",
              "linkCMSPage": "mercury/components/buttons",
              "preserveQs": "false"
            },
            {
              "label": "Checkboxes",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/mercury/components/checkbox.page?",
              "linkCMSPage": "mercury/components/checkbox",
              "preserveQs": "false"
            },
            {
              "label": "Date pickers",
              "visibleInSiteMap": "false",
              "visibleInBreadCrumbs": "false",
              "link": "/cpc/en/mercury/components/date-picker.page?",
              "linkCMSPage": "mercury/components/date-picker",
              "preserveQs": "false"
            },
            {
              "label": "Dropdown menus",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/mercury/components/dropdown.page?",
              "linkCMSPage": "mercury/components/dropdown",
              "preserveQs": "false"
            },
            {
              "label": "Forms",
              "visibleInSiteMap": "false",
              "visibleInBreadCrumbs": "false",
              "link": "/cpc/en/mercury/components/form.page?",
              "linkCMSPage": "mercury/components/form",
              "preserveQs": "false"
            },
            {
              "label": "Input fields",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/mercury/components/input-field.page?",
              "linkCMSPage": "mercury/components/input-field",
              "preserveQs": "false"
            },
            {
              "label": "Links",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "links",
              "link": "/cpc/en/mercury/components/links.page?",
              "linkCMSPage": "mercury/components/links",
              "preserveQs": "false"
            },
            {
              "label": "Lists",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/mercury/components/lists.page?",
              "linkCMSPage": "mercury/components/lists",
              "preserveQs": "false"
            },
            {
              "label": "Modal",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/mercury/components/modal-window.page?",
              "linkCMSPage": "mercury/components/modal-window",
              "preserveQs": "false"
            },
            {
              "label": "Navigation",
              "visibleInSiteMap": "false",
              "visibleInBreadCrumbs": "false",
              "link": "/cpc/en/mercury/components/navigation.page?",
              "linkCMSPage": "mercury/components/navigation",
              "preserveQs": "false"
            },
            {
              "label": "Notifications",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/mercury/components/notification.page?",
              "linkCMSPage": "mercury/components/notification",
              "preserveQs": "false"
            },
            {
              "label": "Pagination",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Pagination",
              "link": "/cpc/en/mercury/components/pagination.page?",
              "linkCMSPage": "mercury/components/pagination",
              "preserveQs": "false"
            },
            {
              "label": "Progress bars",
              "visibleInSiteMap": "false",
              "visibleInBreadCrumbs": "false",
              "link": "/cpc/en/mercury/components/progress-bar.page?",
              "linkCMSPage": "mercury/components/progress-bar",
              "preserveQs": "false"
            },
            {
              "label": "Radio buttons",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/mercury/components/radio-button.page?",
              "linkCMSPage": "mercury/components/radio-button",
              "preserveQs": "false"
            },
            {
              "label": "Search",
              "visibleInSiteMap": "false",
              "visibleInBreadCrumbs": "false",
              "link": "/cpc/en/mercury/components/search.page?",
              "linkCMSPage": "mercury/components/search",
              "preserveQs": "false"
            },
            {
              "label": "Tabs",
              "visibleInSiteMap": "false",
              "visibleInBreadCrumbs": "false",
              "link": "/cpc/en/mercury/components/tabs.page?",
              "linkCMSPage": "mercury/components/tabs",
              "preserveQs": "false"
            },
            {
              "label": "Toggle",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Toggle",
              "link": "/cpc/en/mercury/components/toggle.page?",
              "linkCMSPage": "mercury/components/toggle",
              "preserveQs": "false"
            },
            {
              "label": "Tables",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Tables",
              "link": "/cpc/en/mercury/components/table.page?",
              "linkCMSPage": "mercury/components/table",
              "preserveQs": "false"
            },
            {
              "label": "Tool cards",
              "visibleInSiteMap": "false",
              "visibleInBreadCrumbs": "false",
              "link": "/cpc/en/mercury/components/tool-card.page?",
              "linkCMSPage": "mercury/components/tool-card",
              "preserveQs": "false"
            }
          ]
        },
        {
          "label": "Resources",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "description": "resources",
          "link": "/cpc/en/mercury/resources.page?",
          "linkCMSPage": "mercury/resources",
          "preserveQs": "false"
        }
      ]
    },
    {
      "label": "Our Company",
      "visibleInSiteMap": "true",
      "visibleInBreadCrumbs": "true",
      "preserveQs": "false",
      "description": "Learn about Canada Post and shipping service alerts.",
      "link": "/cpc/en/our-company.page?",
      "linkCMSPage": "our-company",
      "nodes": [
        {
          "label": "About us",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "queryString": "About us overview",
          "description": "Learn about our management team and corporate initiatives.",
          "link": "/cpc/en/our-company/about-us.page?",
          "linkCMSPage": "our-company/about-us",
          "nodes": [
            {
              "label": "Our leadership",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Learn about our leadership behaviours and teams.",
              "link": "/cpc/en/our-company/about-us/our-leadership.page?",
              "linkCMSPage": "our-company/about-us/our-leadership",
              "nodes": [
                {
                  "label": "Senior management team",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Learn about the members of our senior management team.",
                  "link": "/cpc/en/our-company/about-us/our-leadership/senior-management-team.page?",
                  "linkCMSPage": "our-company/about-us/our-leadership/senior-management-team",
                  "preserveQs": "false"
                },
                {
                  "label": "Corporate governance",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "preserveQs": "false",
                  "description": "Learn about the Board of Directors, our principles and policies.",
                  "link": "/cpc/en/our-company/about-us/our-leadership/corporate-governance.page?",
                  "linkCMSPage": "our-company/about-us/our-leadership/corporate-governance",
                  "nodes": [
                    {
                      "label": "Role of the Board",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/our-company/about-us/our-leadership/corporate-governance/role-of-the-board.page?",
                      "linkCMSPage": "our-company/about-us/our-leadership/corporate-governance/role-of-the-board",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Directors' biographies",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/our-company/about-us/our-leadership/corporate-governance/directors-biographies.page?",
                      "linkCMSPage": "our-company/about-us/our-leadership/corporate-governance/directors-biographies",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Directors' committees",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/our-company/about-us/our-leadership/corporate-governance/directors-committees.page?",
                      "linkCMSPage": "our-company/about-us/our-leadership/corporate-governance/directors-committees",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Board diversity",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/our-company/about-us/our-leadership/corporate-governance/diversity-on-our-board.page?",
                      "linkCMSPage": "our-company/about-us/our-leadership/corporate-governance/diversity-on-our-board",
                      "preserveQs": "false"
                    }
                  ]
                },
                {
                  "label": "Travel and hospitality policy",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "preserveQs": "false",
                  "description": "Learn about the Board and senior management members spending policy.",
                  "link": "/cpc/en/our-company/about-us/our-leadership/travel-and-hospitality-policy.page?",
                  "linkCMSPage": "our-company/about-us/our-leadership/travel-and-hospitality-policy",
                  "nodes": [
                    {
                      "label": "Travel and hospitality expenses",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/our-company/about-us/our-leadership/travel-and-hospitality-policy/travel-and-hospitality-expenses.page?",
                      "linkCMSPage": "our-company/about-us/our-leadership/travel-and-hospitality-policy/travel-and-hospitality-expenses",
                      "preserveQs": "false"
                    }
                  ]
                }
              ]
            },
            {
              "label": "Corporate sustainability",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Learn about how we support communities, employees and the environment.",
              "link": "/cpc/en/our-company/about-us/corporate-sustainability.page?",
              "linkCMSPage": "our-company/about-us/corporate-sustainability",
              "nodes": [
                {
                  "label": "Environmental responsibility",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Learn about our sustainability and conservation efforts.",
                  "link": "/cpc/en/our-company/about-us/corporate-sustainability/environment-responsibility.page?",
                  "linkCMSPage": "our-company/about-us/corporate-sustainability/environment-responsibility",
                  "preserveQs": "false"
                },
                {
                  "label": "Accessibility",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "preserveQs": "false",
                  "description": "Learn about how we’re improving the accessibility of our services.",
                  "link": "/cpc/en/our-company/about-us/corporate-responsibility/accessibility.page?",
                  "linkCMSPage": "our-company/about-us/corporate-responsibility/accessibility",
                  "nodes": [
                    {
                      "label": "Digital accessibility",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/our-company/about-us/corporate-responsibility/accessibility/digital-accessibility.page?",
                      "linkCMSPage": "our-company/about-us/corporate-responsibility/accessibility/digital-accessibility",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Delivery accommodation program",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/our-company/about-us/corporate-responsibility/accessibility/delivery-accommodation-program.page?",
                      "linkCMSPage": "our-company/about-us/corporate-responsibility/accessibility/delivery-accommodation-program",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Accessibility advisory panel",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/our-company/about-us/corporate-responsibility/accessibility/advisory-panel.page?",
                      "linkCMSPage": "our-company/about-us/corporate-responsibility/accessibility/advisory-panel",
                      "preserveQs": "false"
                    }
                  ]
                },
                {
                  "label": "Archived corporate reports",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Access past corporate responsibility reports.",
                  "link": "/cpc/en/our-company/about-us/corporate-responsibility/archived-corporate-reports.page?",
                  "linkCMSPage": "our-company/about-us/corporate-responsibility/archived-corporate-reports",
                  "preserveQs": "false"
                },
                {
                  "label": "Indigenous and Northern reconciliation",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Learn about how we’re improving our work with Indigenous communities.",
                  "link": "/cpc/en/our-company/about-us/corporate-sustainability/indigenous-reconciliation.page?",
                  "linkCMSPage": "our-company/about-us/corporate-sustainability/indigenous-reconciliation",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Transparency and trust",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Learn about how we protect your information and keep you informed. ",
              "link": "/cpc/en/our-company/about-us/transparency-and-trust.page?",
              "linkCMSPage": "our-company/about-us/transparency-and-trust",
              "nodes": [
                {
                  "label": "Privacy centre",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Learn about our privacy practices and access the Privacy Policy.",
                  "link": "/cpc/en/our-company/about-us/transparency-and-trust/privacy-centre.page?",
                  "linkCMSPage": "our-company/about-us/transparency-and-trust/privacy-centre",
                  "preserveQs": "false"
                },
                {
                  "label": "Access to information",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/cpc/en/our-company/about-us/transparency-and-trust/access-to-information.page?",
                  "linkCMSPage": "our-company/about-us/transparency-and-trust/access-to-information",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Legislation and regulations",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Access the Canada Post Corporation Act.",
              "link": "/cpc/en/our-company/about-us/legislation-and-regulations.page?",
              "linkCMSPage": "our-company/about-us/legislation-and-regulations",
              "preserveQs": "false"
            },
            {
              "label": "Financial reports",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "View our annual reports and quarterly financial reports.",
              "link": "/cpc/en/our-company/about-us/financial-reports.page?",
              "linkCMSPage": "our-company/about-us/financial-reports",
              "nodes": [
                {
                  "label": "Quarterly financial reports",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Access current and archived quarterly financial reports.",
                  "link": "/cpc/en/our-company/about-us/financial-reports/quarterly-financial-reports.page?",
                  "linkCMSPage": "our-company/about-us/financial-reports/quarterly-financial-reports",
                  "preserveQs": "false"
                },
                {
                  "label": "2021 Annual report",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Access the most recent Annual report.",
                  "link": "/cpc/en/our-company/about-us/financial-reports/2022-annual-report/a-stronger-canada.page?",
                  "linkCMSPage": "our-company/about-us/financial-reports/2022-annual-report/a-stronger-canada",
                  "preserveQs": "false"
                }
              ]
            }
          ]
        },
        {
          "label": "Giving back to our communities",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "queryString": "Giving back to our communities overview",
          "description": "Learn about grants, awards and access children’s activities.",
          "link": "/cpc/en/our-company/giving-back-to-our-communities.page?",
          "linkCMSPage": "our-company/giving-back-to-our-communities",
          "nodes": [
            {
              "label": "Canada Post Community Foundation",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Learn about Foundation grants for schools, charities and organizations.",
              "link": "/cpc/en/our-company/giving-back-to-our-communities/canada-post-community-foundation.page?",
              "linkCMSPage": "our-company/giving-back-to-our-communities/canada-post-community-foundation",
              "nodes": [
                {
                  "label": "Community Foundation application",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Learn how to apply for a Community Foundation grant.",
                  "link": "/cpc/en/our-company/giving-back-to-our-communities/canada-post-community-foundation/community-foundation-application.page?",
                  "linkCMSPage": "our-company/giving-back-to-our-communities/canada-post-community-foundation/community-foundation-application",
                  "preserveQs": "false"
                },
                {
                  "label": "Community Foundation trustees",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Learn about the trustees and advisors that award Foundation grants.",
                  "link": "/cpc/en/our-company/giving-back-to-our-communities/canada-post-community-foundation/community-foundation-trustees.page?",
                  "linkCMSPage": "our-company/giving-back-to-our-communities/canada-post-community-foundation/community-foundation-trustees",
                  "preserveQs": "false"
                },
                {
                  "label": "Community Foundation grant recipients",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Browse past community projects that have received grants.",
                  "link": "/cpc/en/our-company/giving-back-to-our-communities/canada-post-community-foundation/community-foundation-grant-recipients.page?",
                  "linkCMSPage": "our-company/giving-back-to-our-communities/canada-post-community-foundation/community-foundation-grant-recipients",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Canada Post Awards for Indigenous Students ",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Learn about education grants for Indigenous Peoples.",
              "link": "/cpc/en/our-company/giving-back-to-our-communities/canada-post-awards-for-indigenous-students.page?",
              "linkCMSPage": "our-company/giving-back-to-our-communities/canada-post-awards-for-indigenous-students",
              "nodes": [
                {
                  "label": "Education award recipients",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Browse a list of past Indigenous Student award winners.",
                  "link": "/cpc/en/our-company/giving-back-to-our-communities/canada-post-awards-for-indigenous-students/education-award-recipients.page?",
                  "linkCMSPage": "our-company/giving-back-to-our-communities/canada-post-awards-for-indigenous-students/education-award-recipients",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Write a letter to Santa",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Send a letter to the North Pole and get tips for parents and teachers.",
              "link": "/cpc/en/our-company/giving-back-to-our-communities/write-a-letter-to-santa.page?",
              "linkCMSPage": "our-company/giving-back-to-our-communities/write-a-letter-to-santa",
              "nodes": [
                {
                  "label": "Santa letter tips for parents",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Get letter templates and tips for writing to the North Pole.",
                  "link": "/cpc/en/our-company/giving-back-to-our-communities/write-a-letter-to-santa/santa-program-for-parents.page?",
                  "linkCMSPage": "our-company/giving-back-to-our-communities/write-a-letter-to-santa/santa-program-for-parents",
                  "preserveQs": "false"
                },
                {
                  "label": "Santa letter tips for teachers",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Get information on sending a class letter to Santa.",
                  "link": "/cpc/en/our-company/giving-back-to-our-communities/write-a-letter-to-santa/santa-program-for-teachers.page?",
                  "linkCMSPage": "our-company/giving-back-to-our-communities/write-a-letter-to-santa/santa-program-for-teachers",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Kids postal service activities",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Download printable mail templates and activity sheets.",
              "link": "/cpc/en/our-company/giving-back-to-our-communities/kids-postal-service-activities.page?",
              "linkCMSPage": "our-company/giving-back-to-our-communities/kids-postal-service-activities",
              "preserveQs": "false"
            }
          ]
        },
        {
          "label": "Jobs",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "queryString": "Jobs overview",
          "description": "View available job opportunities.",
          "link": "/cpc/en/our-company/jobs.page?",
          "linkCMSPage": "our-company/jobs",
          "nodes": [
            {
              "label": "Apply for current opportunities",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Browse available jobs at Canada Post.",
              "link": "https://jobs.canadapost.ca/go/Canada-Post-All-Current-Opportunities/2319117/",
              "target": "_blank",
              "preserveQs": "false"
            }
          ]
        },
        {
          "label": "Business opportunities",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "queryString": "Business opportunities overview",
          "description": "Learn about bids for contract work and retail partnerships.",
          "link": "/cpc/en/our-company/business-opportunities.page?",
          "linkCMSPage": "our-company/business-opportunities",
          "nodes": [
            {
              "label": "Contract work for your business",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Partner with us and bid on contracts for your business.",
              "link": "/cpc/en/our-company/business-opportunities/contracts-for-your-business.page?",
              "linkCMSPage": "our-company/business-opportunities/contracts-for-your-business",
              "nodes": [
                {
                  "label": "Goods and services contracts",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Browse and bid on goods and services contracts.",
                  "link": "/cpc/en/our-company/business-opportunities/contracts-for-your-business/goods-and-services-contracts.page?",
                  "linkCMSPage": "our-company/business-opportunities/contracts-for-your-business/goods-and-services-contracts",
                  "preserveQs": "false"
                },
                {
                  "label": "Transportation contracts",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Bid on air and ground transportation contracts.",
                  "link": "/cpc/en/our-company/business-opportunities/contracts-for-your-business/transportation-contracts.page?",
                  "linkCMSPage": "our-company/business-opportunities/contracts-for-your-business/transportation-contracts",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Become an authorized retail partner",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Offer post office products and services from your business.",
              "link": "/cpc/en/our-company/business-opportunities/become-an-authorized-retailer.page?",
              "linkCMSPage": "our-company/business-opportunities/become-an-authorized-retailer",
              "preserveQs": "false"
            }
          ]
        },
        {
          "label": "News and media",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "queryString": "News and media overview",
          "description": "Access mailing service updates and images for media.",
          "link": "/cpc/en/our-company/news-and-media.page?",
          "linkCMSPage": "our-company/news-and-media",
          "nodes": [
            {
              "label": "Service alerts",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Updated details on mail delivery interruptions.",
              "link": "/cpc/en/our-company/news-and-media/service-alerts.page?",
              "linkCMSPage": "our-company/news-and-media/service-alerts",
              "nodes": [
                {
                  "label": "Service alerts archive ",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Browse past service alerts.",
                  "link": "/cpc/en/our-company/news-and-media/service-alerts/service-alerts-archive.page?",
                  "linkCMSPage": "our-company/news-and-media/service-alerts/service-alerts-archive",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Corporate news",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Access news releases and company updates.",
              "link": "/cpc/en/our-company/news-and-media/corporate-news.page?",
              "linkCMSPage": "our-company/news-and-media/corporate-news",
              "nodes": [
                {
                  "label": "News releases",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Browse our most recent and past news releases.",
                  "link": "/cpc/en/our-company/news-and-media/corporate-news/news-release-list.page?",
                  "linkCMSPage": "our-company/news-and-media/corporate-news/news-release-list",
                  "preserveQs": "false"
                },
                {
                  "label": "Closures and service interruptions",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Learn about weather events and holiday hours impacting delivery.",
                  "link": "/cpc/en/our-company/news-and-media/corporate-news/closures-and-service-interruptions-list.page?",
                  "linkCMSPage": "our-company/news-and-media/corporate-news/closures-and-service-interruptions-list",
                  "preserveQs": "false"
                },
                {
                  "label": "Negotiations updates",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Get information on negotiations with our unions.",
                  "link": "/cpc/en/our-company/news-and-media/corporate-news/negotiations-list.page?",
                  "linkCMSPage": "our-company/news-and-media/corporate-news/negotiations-list",
                  "preserveQs": "false"
                },
                {
                  "label": "COVID-19 updates",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "preserveQs": "false",
                  "description": "Learn about impacts to sending amid COVID-19.",
                  "link": "/cpc/en/our-company/news-and-media/corporate-news/coronavirus-disease-covid-19.page?",
                  "linkCMSPage": "our-company/news-and-media/corporate-news/coronavirus-disease-covid-19",
                  "nodes": [
                    {
                      "label": "COVID-19 frequently asked questions ",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/cpc/en/our-company/news-and-media/corporate-news/coronavirus-disease-covid-19/coronavirus-disease-covid-19-faq.page?",
                      "linkCMSPage": "our-company/news-and-media/corporate-news/coronavirus-disease-covid-19/coronavirus-disease-covid-19-faq",
                      "preserveQs": "false"
                    }
                  ]
                }
              ]
            },
            {
              "label": "Media centre",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Access official company images, logos and videos.",
              "link": "/cpc/en/our-company/news-and-media/media-centre.page?",
              "linkCMSPage": "our-company/news-and-media/media-centre",
              "nodes": [
                {
                  "label": "Photo gallery",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Browse and download official company images.",
                  "link": "/cpc/en/our-company/news-and-media/media-centre/photo-gallery.page?",
                  "linkCMSPage": "our-company/news-and-media/media-centre/photo-gallery",
                  "preserveQs": "false"
                },
                {
                  "label": "B-roll footage",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "View and download B-roll footage of our facilities and products.",
                  "link": "/cpc/en/our-company/news-and-media/media-centre/b-roll-footage.page?",
                  "linkCMSPage": "our-company/news-and-media/media-centre/b-roll-footage",
                  "preserveQs": "false"
                },
                {
                  "label": "Canada Post logos",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Access our company logo and guidelines on how it should be applied.",
                  "link": "/cpc/en/our-company/news-and-media/media-centre/canada-post-logos.page?",
                  "linkCMSPage": "our-company/news-and-media/media-centre/canada-post-logos",
                  "preserveQs": "false"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "label": "Campaign",
      "visibleInSiteMap": "true",
      "visibleInBreadCrumbs": "false",
      "preserveQs": "false",
      "link": "/cpc/en/home.page?",
      "linkCMSPage": "",
      "nodes": [
        {
          "label": "Ecommerce-hub",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "false",
          "preserveQs": "false",
          "link": "/cpc/en/business/marketing/campaign/ecommerce-hub.page?",
          "linkCMSPage": "business/marketing/campaign/ecommerce-hub",
          "nodes": [
            {
              "label": "Being a leader",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/business/marketing/campaign/ecommerce-hub/being-a-leader.page?",
              "linkCMSPage": "business/marketing/campaign/ecommerce-hub/being-a-leader",
              "preserveQs": "false"
            },
            {
              "label": "Scaling up",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/business/marketing/campaign/ecommerce-hub/scaling-up.page?",
              "linkCMSPage": "business/marketing/campaign/ecommerce-hub/scaling-up",
              "preserveQs": "false"
            },
            {
              "label": "Inspiring stories",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/business/marketing/campaign/ecommerce-hub/inspiring-stories.page?",
              "linkCMSPage": "business/marketing/campaign/ecommerce-hub/inspiring-stories",
              "preserveQs": "false"
            },
            {
              "label": "Best practices",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/business/marketing/campaign/ecommerce-hub/best-practices.page?",
              "linkCMSPage": "business/marketing/campaign/ecommerce-hub/best-practices",
              "preserveQs": "false"
            },
            {
              "label": "Insider",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/business/marketing/campaign/ecommerce-hub/ecommerce-insider.page?",
              "linkCMSPage": "business/marketing/campaign/ecommerce-hub/ecommerce-insider",
              "preserveQs": "false"
            }
          ]
        },
        {
          "label": "Direct-mail-smm",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "false",
          "preserveQs": "false",
          "link": "/cpc/en/home.page?",
          "linkCMSPage": "",
          "nodes": [
            {
              "label": "Get Noticed",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "#",
              "preserveQs": "false"
            },
            {
              "label": "Get results",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "#",
              "preserveQs": "true"
            },
            {
              "label": "Get Inspired",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "#",
              "preserveQs": "true"
            },
            {
              "label": "Get help",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "#",
              "preserveQs": "true"
            }
          ]
        },
        {
          "label": "Tales-of-triumph",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "false",
          "preserveQs": "false",
          "link": "/cpc/en/home.page?",
          "linkCMSPage": "",
          "nodes": [
            {
              "label": "Details",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "link": "/cpc/en/home.page?",
              "linkCMSPage": "",
              "nodes": [
                {
                  "label": "Categories and prizes",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/cpc/en/business/marketing/campaign/tales-of-triumph/details/categories-and-prizes.page",
                  "preserveQs": "false"
                },
                {
                  "label": "Application process and key dates",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/cpc/en/business/marketing/campaign/tales-of-triumph/details/application-process-key-dates.page",
                  "preserveQs": "false"
                },
                {
                  "label": "Judges",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/cpc/en/business/marketing/campaign/tales-of-triumph/details/meet-the-judges.page",
                  "preserveQs": "false"
                },
                {
                  "label": "Finalists",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/cpc/en/business/marketing/campaign/tales-of-triumph/details/meet-the-finalists.page",
                  "preserveQs": "false"
                },
                {
                  "label": "2021 winners",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/cpc/en/business/marketing/campaign/tales-of-triumph/details/2021-winners.page",
                  "preserveQs": "false"
                },
                {
                  "label": "2020 winners",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/cpc/en/business/marketing/campaign/tales-of-triumph/details/2020-winners.page",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "FAQs",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/business/marketing/campaign/tales-of-triumph/faq.page",
              "preserveQs": "false"
            },
            {
              "label": "Terms and conditions",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/business/marketing/campaign/tales-of-triumph/terms-and-conditions.page",
              "preserveQs": "false"
            }
          ]
        },
        {
          "label": "Ecommerce-infographic",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "link": "/cpc/en/home.page?",
          "linkCMSPage": "",
          "nodes": [
            {
              "label": "On this page",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "link": "/cpc/en/home.page?",
              "linkCMSPage": "",
              "nodes": [
                {
                  "label": " New normal",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "#newnormal",
                  "preserveQs": "false"
                },
                {
                  "label": "Master",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "#master",
                  "preserveQs": "false"
                },
                {
                  "label": "Build",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "#build",
                  "preserveQs": "false"
                },
                {
                  "label": "Discover",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "#discover",
                  "preserveQs": "false"
                },
                {
                  "label": "Thrive",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "#thrive",
                  "preserveQs": "false"
                },
                {
                  "label": " Explore",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "#explore",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Downloads",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "link": "/cpc/en/home.page?",
              "linkCMSPage": "",
              "nodes": [
                {
                  "label": "Get Market Insights infographic",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/cpc/doc/en/campaigns/2021/ecommerce-infographic/marketing-insights-infographic.pdf?icid=cta_int_bc_1695_tlael",
                  "target": "_blank",
                  "preserveQs": "false"
                },
                {
                  "label": "Shipping Report",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/info/mc/app/personal/miniforms/shippingguide.jsf",
                  "target": "_blank",
                  "preserveQs": "false"
                },
                {
                  "label": "Curbside Pickup Guide",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/info/mc/app/personal/miniforms/curbsidepickup.jsf",
                  "target": "_blank",
                  "preserveQs": "false"
                },
                {
                  "label": "Shopper Journey Report",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/info/mc/app/personal/miniforms/ecommerce-report.jsf",
                  "target": "_blank",
                  "preserveQs": "false"
                }
              ]
            }
          ]
        },
        {
          "label": "Small-business-welcome",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "link": "/cpc/en/home.page?",
          "linkCMSPage": "",
          "nodes": [
            {
              "label": "Get Shipping",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/business/marketing/campaign/small-business-welcome/small-business-shipping.page",
              "preserveQs": "false"
            },
            {
              "label": "Market your business",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/business/marketing/campaign/small-business-welcome/market-your-business.page",
              "preserveQs": "false"
            },
            {
              "label": "Sell Online",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/business/marketing/campaign/small-business-welcome/sell-online.page",
              "preserveQs": "false"
            }
          ]
        },
        {
          "label": "Incite-magazine",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "link": "/cpc/en/business/marketing/campaign/incite-magazine.page",
          "nodes": [
            {
              "label": "Case studies",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/business/marketing/campaign/incite/success-stories.page?icid=cta_int_rs_403",
              "preserveQs": "false"
            },
            {
              "label": "Video stories",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/business/marketing/campaign/incite/video-stories.page?icid=cta_int_rs_405",
              "preserveQs": "false"
            },
            {
              "label": "Get the facts",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/cpc/en/business/marketing/campaign/incite/get-the-facts.page?icid=cta_int_rs_407",
              "preserveQs": "false"
            }
          ]
        },
        {
          "label": "Campaign-library",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "false",
          "preserveQs": "false",
          "link": "/cpc/en/business/marketing/campaign/demo/library.page?",
          "linkCMSPage": "business/marketing/campaign/demo/library",
          "nodes": [
            {
              "label": "Hero",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "false",
              "link": "/cpc/en/business/marketing/campaign/demo/library/hero.page?",
              "linkCMSPage": "business/marketing/campaign/demo/library/hero",
              "preserveQs": "false"
            },
            {
              "label": "Common",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "false",
              "link": "/cpc/en/business/marketing/campaign/demo/library/common.page?",
              "linkCMSPage": "business/marketing/campaign/demo/library/common",
              "preserveQs": "false"
            },
            {
              "label": "Helpers",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "false",
              "link": "/cpc/en/business/marketing/campaign/demo/library/helpers.page?",
              "linkCMSPage": "business/marketing/campaign/demo/library/helpers",
              "preserveQs": "false"
            },
            {
              "label": "Animations",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "false",
              "link": "/cpc/en/business/marketing/campaign/demo/library/animations.page?",
              "linkCMSPage": "business/marketing/campaign/demo/library/animations",
              "preserveQs": "false"
            },
            {
              "label": "Other",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "false",
              "link": "/cpc/en/business/marketing/campaign/demo/library/other.page?",
              "linkCMSPage": "business/marketing/campaign/demo/library/other",
              "preserveQs": "false"
            }
          ]
        }
      ]
    },
    {
      "nodes": [
        {
          "label": "Mailing and shipping",
          "description": "Order packaging, envelopes, stamps, boxes and wraps.",
          "overViewContent": {
            "label": "Mailing and shipping overview",
            "link": "/store-boutique/en/1/c/mailing-and-shipping"
          },
          "nodes": [
            {
              "label": "Postage stamps",
              "link": "/store-boutique/en/5/c/postage-stamps",
              "nodes": []
            },
            {
              "label": "Flat rate (prepaid) products",
              "description": "Discover prepaid envelopes and flat rate boxes, priced by region.",
              "link": "/store-boutique/en/8/c/flat-rate-prepaid-products",
              "nodes": [
                {
                  "label": "Flat rate (prepaid) products and shipping regions",
                  "link": "/store-boutique/en/38/c/flat-rate-prepaid-products-and-shipping-regions"
                }
              ]
            },
            {
              "label": "Shipping supplies",
              "link": "/store-boutique/en/7/c/shipping-supplies",
              "nodes": []
            }
          ]
        },
        {
          "label": "Stamp collecting",
          "description": "See the latest stamp collections and quality accessories.",
          "overViewContent": {
            "label": "Stamp collecting overview",
            "link": "/store-boutique/en/2/c/stamp-collecting"
          },
          "nodes": [
            {
              "label": "Stamps and collectibles",
              "link": "/store-boutique/en/10/c/stamps-and-collectibles",
              "nodes": []
            },
            {
              "label": "Stamp collecting accessories",
              "link": "/store-boutique/en/13/c/stamp-collecting-accessories",
              "nodes": []
            },
            {
              "label": "Postcards",
              "link": "/store-boutique/en/14/c/postcards",
              "nodes": []
            }
          ]
        },
        {
          "label": "Coin collecting",
          "description": "View featured collectible coins released by the Canadian Mint.",
          "overViewContent": {
            "label": "Coin collecting overview",
            "link": "/store-boutique/en/3/c/coin-collecting"
          },
          "nodes": [
            {
              "label": "New arrivals",
              "link": "/store-boutique/en/15/c/new-arrivals",
              "nodes": []
            },
            {
              "label": "Coins and coin sets",
              "link": "/store-boutique/en/16/c/coins-and-collectables",
              "nodes": []
            },
            {
              "label": "Coin albums and accessories",
              "link": "/store-boutique/en/17/c/coin-collecting-accessories",
              "nodes": []
            }
          ]
        },
        {
          "label": "Quick Order",
          "link": "/quick-order"
        },
        {
          "label": "Favourites",
          "link": "/favourites"
        }
      ],
      "label": "Shop",
      "description": "Shop for stamps, shipping supplies and collectibles.",
      "link": "/store-boutique/en/home"
    }
  ]
};