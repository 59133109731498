/* eslint-disable */
module.exports = {
  signInLabel: "Sign in",
  searchLabel: "Search",
  closeLabel: "Close",
  utilityAriaRoleLabel: "Utility",
  logoAltText: "Canada Post/Postes Canada",
  activePage: "personal/sitemap",
  assetBaseUrl: "/scp/",
  "nodes": [
    {
      "label": "Entreprise",
      "visibleInSiteMap": "true",
      "visibleInBreadCrumbs": "true",
      "preserveQs": "false",
      "description": "Découvrir les services postaux offerts aux entreprises.",
      "link": "/scp/fr/entreprise.page?",
      "linkCMSPage": "entreprise",
      "nodes": [
        {
          "label": "Expédition",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "queryString": "Aper%c3%a7u de l%e2%80%99exp%c3%a9dition",
          "description": "Voir nos services et tarifs d’expédition au Canada et à l’étranger.",
          "link": "/scp/fr/entreprise/expedition.page?",
          "linkCMSPage": "entreprise/expedition",
          "nodes": [
            {
              "label": "Expédition au Canada",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Découvrir nos services d’expédition au Canada pour entreprises.",
              "link": "/scp/fr/entreprise/expedition/canada.page?",
              "linkCMSPage": "entreprise/expedition/canada",
              "nodes": [
                {
                  "label": "Trouver des tarifs et expédier",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "preserveQs": "false",
                  "description": "Connaître les tarifs d’expédition pour les PME et les gros volumes.",
                  "link": "/scp/fr/entreprise/expedition/trouver-tarifs-expedier.page?",
                  "linkCMSPage": "entreprise/expedition/trouver-tarifs-expedier",
                  "nodes": [
                    {
                      "label": "Colis clic",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/entreprise/expedition/trouver-tarifs-expedier/colis-clic.page?",
                      "linkCMSPage": "entreprise/expedition/trouver-tarifs-expedier/colis-clic",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Gestion des envois",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/entreprise/expedition/trouver-tarifs-expedier/gestion-des-envois.page?",
                      "linkCMSPage": "entreprise/expedition/trouver-tarifs-expedier/gestion-des-envois",
                      "preserveQs": "false"
                    },
                    {
                      "label": "OEE 2.0",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/entreprise/expedition/trouver-tarifs-expedier/est-2.page?",
                      "linkCMSPage": "entreprise/expedition/trouver-tarifs-expedier/est-2",
                      "preserveQs": "false"
                    }
                  ]
                },
                {
                  "label": "Comparer les services d’expédition",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "preserveQs": "false",
                  "description": "Voir les délais et caractéristiques d’expédition du régime intérieur.",
                  "link": "/scp/fr/entreprise/expedition/canada/comparer.page?",
                  "linkCMSPage": "entreprise/expedition/canada/comparer",
                  "nodes": [
                    {
                      "label": "Colis standard",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/entreprise/expedition/canada/comparer/regulier.page?",
                      "linkCMSPage": "entreprise/expedition/canada/comparer/regulier",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Colis accélérés",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/entreprise/expedition/canada/comparer/colis-accelere.page?",
                      "linkCMSPage": "entreprise/expedition/canada/comparer/colis-accelere",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Xpresspost",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/entreprise/expedition/canada/comparer/xpresspost.page?",
                      "linkCMSPage": "entreprise/expedition/canada/comparer/xpresspost",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Priorité",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/entreprise/expedition/canada/comparer/priorite.page?",
                      "linkCMSPage": "entreprise/expedition/canada/comparer/priorite",
                      "preserveQs": "false"
                    }
                  ]
                },
                {
                  "label": "Consulter les restrictions",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "preserveQs": "false",
                  "description": "Restrictions liées aux articles inadmissibles et contrôlés par pays.",
                  "link": "/scp/fr/entreprise/expedition/restrictions.page?",
                  "linkCMSPage": "entreprise/expedition/restrictions",
                  "nodes": [
                    {
                      "label": "Cannabis",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/entreprise/expedition/restrictions/cannabis.page?",
                      "linkCMSPage": "entreprise/expedition/restrictions/cannabis",
                      "preserveQs": "false"
                    }
                  ]
                },
                {
                  "label": "Choisissez un outil d’expédition",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Comparer nos 3 outils d’expédition et trouver celui qui vous convient.",
                  "link": "/scp/fr/entreprise/expedition/comparer-outils-expedition.page?",
                  "linkCMSPage": "entreprise/expedition/comparer-outils-expedition",
                  "preserveQs": "false"
                },
                {
                  "label": "Solutions d’expédition de tiers",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Tout savoir sur les logiciels approuvés pour vos besoins d’expédition.",
                  "link": "/scp/fr/entreprise/expedition/fournisseurs-tiers.page?",
                  "linkCMSPage": "entreprise/expedition/fournisseurs-tiers",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Expédition à l’étranger",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Découvrir nos services d’expédition à l’étranger pour entreprises.",
              "link": "/scp/fr/entreprise/expedition/international.page?",
              "linkCMSPage": "entreprise/expedition/international",
              "nodes": [
                {
                  "label": "Trouver des tarifs et expédier",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "preserveQs": "false",
                  "description": "Connaître les tarifs d’expédition pour les PME et les gros volumes.",
                  "link": "/scp/fr/entreprise/expedition/trouver-tarifs-expedier.page?",
                  "linkCMSPage": "entreprise/expedition/trouver-tarifs-expedier",
                  "nodes": [
                    {
                      "label": "Colis clic",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/entreprise/expedition/trouver-tarifs-expedier/colis-clic.page?",
                      "linkCMSPage": "entreprise/expedition/trouver-tarifs-expedier/colis-clic",
                      "preserveQs": "false"
                    },
                    {
                      "label": "OEE 2.0",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/entreprise/expedition/trouver-tarifs-expedier/est-2.page?",
                      "linkCMSPage": "entreprise/expedition/trouver-tarifs-expedier/est-2",
                      "preserveQs": "false"
                    }
                  ]
                },
                {
                  "label": "Comparer les services d’expédition",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "preserveQs": "false",
                  "description": "Délais et caractéristiques d’expédition aux É.-U. et à l’étranger.",
                  "link": "/scp/fr/entreprise/expedition/international/comparer.page?",
                  "linkCMSPage": "entreprise/expedition/international/comparer",
                  "nodes": [
                    {
                      "label": "Petit paquet par avion – É.-U.",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/entreprise/expedition/international/comparer/petit-paquet-eu.page?",
                      "linkCMSPage": "entreprise/expedition/international/comparer/petit-paquet-eu",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Petit paquet par avion ou de surface – International",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/entreprise/expedition/international/comparer/petit-paquet-international.page?",
                      "linkCMSPage": "entreprise/expedition/international/comparer/petit-paquet-international",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Paquet repérable – É.-U.",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/entreprise/expedition/international/comparer/paquet-reperable-eu.page?",
                      "linkCMSPage": "entreprise/expedition/international/comparer/paquet-reperable-eu",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Paquet repérable – International",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/entreprise/expedition/international/comparer/paquet-reperable-international.page?",
                      "linkCMSPage": "entreprise/expedition/international/comparer/paquet-reperable-international",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Colis accélérés – É.-U.",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/entreprise/expedition/international/comparer/colis-acceleres-eu.page?",
                      "linkCMSPage": "entreprise/expedition/international/comparer/colis-acceleres-eu",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Colis-avion ou Colis de surface – International",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/entreprise/expedition/international/comparer/colis-international.page?",
                      "linkCMSPage": "entreprise/expedition/international/comparer/colis-international",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Xpresspost – É.-U.",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/entreprise/expedition/international/comparer/xpresspost-eu.page?",
                      "linkCMSPage": "entreprise/expedition/international/comparer/xpresspost-eu",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Xpresspost – International",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/entreprise/expedition/international/comparer/xpresspost-international.page?",
                      "linkCMSPage": "entreprise/expedition/international/comparer/xpresspost-international",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Priorité Mondial",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/entreprise/expedition/international/comparer/priorite-mondial.page?",
                      "linkCMSPage": "entreprise/expedition/international/comparer/priorite-mondial",
                      "preserveQs": "false"
                    }
                  ]
                },
                {
                  "label": "Consulter les restrictions",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Consulter les restrictions",
                  "link": "/scp/fr/entreprise/expedition/restrictions.page?",
                  "linkCMSPage": "entreprise/expedition/restrictions",
                  "preserveQs": "false"
                },
                {
                  "label": "Évaluer les frais de douane et les taxes",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/information/app/wtz/entreprise/coutlivre",
                  "preserveQs": "false"
                },
                {
                  "label": "Trouver un code douanier",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/information/app/wtz/entreprise/trouvercodesh",
                  "preserveQs": "false"
                },
                {
                  "label": "Remplir un formulaire douanier",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/information/app/cdc",
                  "preserveQs": "false"
                },
                {
                  "label": "Choisissez un outil d’expédition",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Comparer nos 3 outils d’expédition et trouver celui qui vous convient.",
                  "link": "/scp/fr/entreprise/expedition/comparer-outils-expedition.page?",
                  "linkCMSPage": "entreprise/expedition/comparer-outils-expedition",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Repérer et trouver",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Accéder à des liens rapides vers nos outils en ligne.",
              "link": "/scp/fr/entreprise/expedition/reperer-trouver.page?",
              "linkCMSPage": "entreprise/expedition/reperer-trouver",
              "nodes": [
                {
                  "label": "Repérer un colis",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/trackweb/fr",
                  "preserveQs": "false"
                },
                {
                  "label": "Trouver un code postal",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/scp/fr/outils/trouver-un-code-postal.page",
                  "preserveQs": "false"
                },
                {
                  "label": "Trouver une adresse",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/scp/fr/outils/trouver-un-code-postal.page",
                  "preserveQs": "false"
                },
                {
                  "label": "Trouver un bureau de poste",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/information/app/trouverbdp/personnel/trouverunbureaudeposte",
                  "preserveQs": "false"
                },
                {
                  "label": "Trouver un lieu de dépôt",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/information/app/fdl/entreprise/trouverunlieudedepot",
                  "preserveQs": "false"
                },
                {
                  "label": "Normes de livraison",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/business/tools/ds/default.aspx",
                  "preserveQs": "false"
                },
                {
                  "label": "Rediriger un colis",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Faire rediriger un envoi qui est en transit.",
                  "link": "/scp/fr/entreprise/expedition/rediriger-colis.page?",
                  "linkCMSPage": "entreprise/expedition/rediriger-colis",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Accédez nos outils rapides",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Liens rapides pour repérer votre colis ou pour trouver une adresse ou un code postal.",
              "link": "/scp/fr/entreprise/expedition/reperer-trouver.page?",
              "linkCMSPage": "entreprise/expedition/reperer-trouver",
              "nodes": [
                {
                  "label": "Colis clic",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Voir les outils d’expédition en ligne pour les petites entreprises.",
                  "link": "/scp/fr/entreprise/expedition/trouver-tarifs-expedier/colis-clic.page?",
                  "linkCMSPage": "entreprise/expedition/trouver-tarifs-expedier/colis-clic",
                  "preserveQs": "false"
                },
                {
                  "label": "Gestion des envois",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Outil pour gros expéditeurs titulaires d’une convention de colis.",
                  "link": "/scp/fr/entreprise/expedition/trouver-tarifs-expedier/gestion-des-envois.page?",
                  "linkCMSPage": "entreprise/expedition/trouver-tarifs-expedier/gestion-des-envois",
                  "preserveQs": "false"
                },
                {
                  "label": "Demander un ramassage de colis",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Demander un ramassage de colis dans les locaux de votre entreprise.",
                  "link": "/scp/fr/entreprise/expedition/demander-ramassage.page?",
                  "linkCMSPage": "entreprise/expedition/demander-ramassage",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Simplifier les retours",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Voir comment élaborer une stratégie de retour d’articles gagnante.",
              "link": "/scp/fr/entreprise/expedition/retours.page?",
              "linkCMSPage": "entreprise/expedition/retours",
              "nodes": [
                {
                  "label": "Politique de retour",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Créer et gérer des politiques de retour d’article gratuitement.",
                  "link": "/scp/fr/entreprise/expedition/retours/politique-retour-client.page?",
                  "linkCMSPage": "entreprise/expedition/retours/politique-retour-client",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Obtenir des ressources et des articles sur l’expédition",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Blogue En affaires.",
              "link": "/blogs/business/fr/category/expedition/",
              "target": "_blank",
              "preserveQs": "false"
            }
          ]
        },
        {
          "label": "Marketing",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "queryString": "Aper%c3%a7u du marketing",
          "description": "Tout savoir sur le publipostage et la location de listes d’adresses.",
          "link": "/scp/fr/entreprise/marketing.page?",
          "linkCMSPage": "entreprise/marketing",
          "nodes": [
            {
              "label": "Lancer une campagne",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Comparer nos services de publipostage pour atteindre vos objectifs.",
              "link": "/scp/fr/entreprise/marketing/campagne.page?",
              "linkCMSPage": "entreprise/marketing/campagne",
              "nodes": [
                {
                  "label": "Atteindre toutes les boîtes aux lettres",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "preserveQs": "false",
                  "description": "Créer une campagne de publipostage en ligne ou avec un partenaire.",
                  "link": "/scp/fr/entreprise/marketing/campagne/atteindre-toutes-les-boites-aux-lettres.page?",
                  "linkCMSPage": "entreprise/marketing/campagne/atteindre-toutes-les-boites-aux-lettres",
                  "nodes": [
                    {
                      "label": "Cibleur précis",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "preserveQs": "false",
                      "description": "Apprenez comment cibler le bon public pour votre campagne.",
                      "link": "/scp/fr/entreprise/marketing/campagne/atteindre-toutes-les-boites-aux-lettres/cibleur-precis.page?",
                      "linkCMSPage": "entreprise/marketing/campagne/atteindre-toutes-les-boites-aux-lettres/cibleur-precis",
                      "nodes": [
                        {
                          "label": "Accéder à l'outil",
                          "visibleInSiteMap": "true",
                          "visibleInBreadCrumbs": "true",
                          "link": "/scp/fr/entreprise/marketing/campagne/atteindre-toutes-les-boites-aux-lettres/cibleur-precis/comment-acceder-a-loutil.page?",
                          "linkCMSPage": "entreprise/marketing/campagne/atteindre-toutes-les-boites-aux-lettres/cibleur-precis/comment-acceder-a-loutil",
                          "preserveQs": "false"
                        },
                        {
                          "label": "Créer un plan de diffusion",
                          "visibleInSiteMap": "true",
                          "visibleInBreadCrumbs": "true",
                          "link": "/scp/fr/entreprise/marketing/campagne/atteindre-toutes-les-boites-aux-lettres/cibleur-precis/comment-creer-un-plan-denvoi.page?",
                          "linkCMSPage": "entreprise/marketing/campagne/atteindre-toutes-les-boites-aux-lettres/cibleur-precis/comment-creer-un-plan-denvoi",
                          "preserveQs": "false"
                        },
                        {
                          "label": "Examiner un plan d'expédition",
                          "visibleInSiteMap": "true",
                          "visibleInBreadCrumbs": "true",
                          "link": "/scp/fr/entreprise/marketing/campagne/atteindre-toutes-les-boites-aux-lettres/cibleur-precis/comment-passer-en-revue-un-plan-denvoi.page?",
                          "linkCMSPage": "entreprise/marketing/campagne/atteindre-toutes-les-boites-aux-lettres/cibleur-precis/comment-passer-en-revue-un-plan-denvoi",
                          "preserveQs": "false"
                        },
                        {
                          "label": "Boutons du format Carte",
                          "visibleInSiteMap": "true",
                          "visibleInBreadCrumbs": "true",
                          "link": "/scp/fr/entreprise/marketing/campagne/atteindre-toutes-les-boites-aux-lettres/cibleur-precis/boutons-du-format-carte.page?",
                          "linkCMSPage": "entreprise/marketing/campagne/atteindre-toutes-les-boites-aux-lettres/cibleur-precis/boutons-du-format-carte",
                          "preserveQs": "false"
                        },
                        {
                          "label": "Boutons du format Données",
                          "visibleInSiteMap": "true",
                          "visibleInBreadCrumbs": "true",
                          "link": "/scp/fr/entreprise/marketing/campagne/atteindre-toutes-les-boites-aux-lettres/cibleur-precis/boutons-du-format-donnees.page?",
                          "linkCMSPage": "entreprise/marketing/campagne/atteindre-toutes-les-boites-aux-lettres/cibleur-precis/boutons-du-format-donnees",
                          "preserveQs": "false"
                        },
                        {
                          "label": "Boutons du menu",
                          "visibleInSiteMap": "true",
                          "visibleInBreadCrumbs": "true",
                          "link": "/scp/fr/entreprise/marketing/campagne/atteindre-toutes-les-boites-aux-lettres/cibleur-precis/boutons-du-menu.page?",
                          "linkCMSPage": "entreprise/marketing/campagne/atteindre-toutes-les-boites-aux-lettres/cibleur-precis/boutons-du-menu",
                          "preserveQs": "false"
                        }
                      ]
                    },
                    {
                      "label": "Médiaposte clic",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/mpc/",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Trouvez un partenaire",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/outils/marketing/trouver-un-partenaire.page?",
                      "linkCMSPage": "outils/marketing/trouver-un-partenaire",
                      "preserveQs": "false"
                    }
                  ]
                },
                {
                  "label": "Trouver des clients similaires",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Cibler des clients prometteurs grâce à des données de codes postaux.",
                  "link": "/scp/fr/entreprise/marketing/campagne/trouver-clients-similaires.page?",
                  "linkCMSPage": "entreprise/marketing/campagne/trouver-clients-similaires",
                  "preserveQs": "false"
                },
                {
                  "label": "Envoyer du Courrier personnalisé",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Lancer une campagne de publipostage personnalisée avec adresse.",
                  "link": "/scp/fr/entreprise/marketing/campagne/envoi-courrier-personnalise.page?",
                  "linkCMSPage": "entreprise/marketing/campagne/envoi-courrier-personnalise",
                  "preserveQs": "false"
                },
                {
                  "label": "Pourquoi choisir le publipostage?",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Notre recherche explique comment le publipostage appuie les campagnes.",
                  "link": "/scp/fr/entreprise/marketing/campagne/pourquoi-publipostage.page?",
                  "linkCMSPage": "entreprise/marketing/campagne/pourquoi-publipostage",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Données sur le public cible et solutions",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Accéder à des listes de clients ciblés pour augmenter vos ventes.",
              "link": "/scp/fr/entreprise/marketing/public-cible.page?",
              "linkCMSPage": "entreprise/marketing/public-cible",
              "nodes": [
                {
                  "label": "Louer nos listes de clients potentiels",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "preserveQs": "false",
                  "description": "Utiliser des données d’adressage pour cibler et segmenter des clients.",
                  "link": "/scp/fr/entreprise/marketing/public-cible/louer-liste.page?",
                  "linkCMSPage": "entreprise/marketing/public-cible/louer-liste",
                  "nodes": [
                    {
                      "label": "Service de données sur les déménagements du PNCA",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/entreprise/marketing/public-cible/epurer-liste.page?",
                      "linkCMSPage": "entreprise/marketing/public-cible/epurer-liste",
                      "preserveQs": "false"
                    }
                  ]
                },
                {
                  "label": "Épurer vos listes d’adresses",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "preserveQs": "false",
                  "description": "Connaître les services qui haussent l’exactitude des données clients.  ",
                  "link": "/scp/fr/entreprise/marketing/public-cible/epurer-liste.page?",
                  "linkCMSPage": "entreprise/marketing/public-cible/epurer-liste",
                  "nodes": [
                    {
                      "label": "Service de données sur les déménagements du PNCA",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/entreprise/marketing/public-cible/epurer-vos-listes-dadresses/service-de-donnees-sur-les-demenagements-du-pnca.page?",
                      "linkCMSPage": "entreprise/marketing/public-cible/epurer-vos-listes-dadresses/service-de-donnees-sur-les-demenagements-du-pnca",
                      "preserveQs": "false"
                    }
                  ]
                },
                {
                  "label": "Obtenir des données sur le public cible",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Analyser les données d’une campagne aux fins d’optimisation.",
                  "link": "/scp/fr/entreprise/marketing/public-cible/obtenir-donnees.page?",
                  "linkCMSPage": "entreprise/marketing/public-cible/obtenir-donnees",
                  "preserveQs": "false"
                },
                {
                  "label": "Licence d’utilisation de nos données",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Utiliser nos produits de données pour créer des listes d’envoi.",
                  "link": "/scp/fr/entreprise/marketing/public-cible/licence-donnees.page?",
                  "linkCMSPage": "entreprise/marketing/public-cible/licence-donnees",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Obtenir des ressources et des articles sur le marketing",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Blogue En affaires.",
              "link": "/blogs/business/fr/category/marketing/",
              "target": "_blank",
              "preserveQs": "false"
            }
          ]
        },
        {
          "label": "Cybercommerce",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "queryString": "Aper%c3%a7u du cybercommerce",
          "description": "Découvrir les services pouvant faciliter vos transactions en ligne.",
          "link": "/scp/fr/entreprise/cybercommerce.page?",
          "linkCMSPage": "entreprise/cybercommerce",
          "nodes": [
            {
              "label": "Commencer à vendre en ligne",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Voir comment créer votre boutique en ligne avec nos partenaires. ",
              "link": "/scp/fr/entreprise/cybercommerce/commencer-vendre-en-ligne.page?",
              "linkCMSPage": "entreprise/cybercommerce/commencer-vendre-en-ligne",
              "preserveQs": "false"
            },
            {
              "label": "Améliorer vos activités de cybercommerce",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Découvrir nos solutions de vente en ligne, de livraison et de retour.",
              "link": "/scp/fr/entreprise/cybercommerce/ameliorer.page?",
              "linkCMSPage": "entreprise/cybercommerce/ameliorer",
              "nodes": [
                {
                  "label": "Vérifier les adresses des clients",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Installer AdresseComplète<SUP>MC</SUP> pour faciliter le passage à la caisse.",
                  "link": "/scp/fr/entreprise/cybercommerce/ameliorer/verifier-adresses.page?",
                  "linkCMSPage": "entreprise/cybercommerce/ameliorer/verifier-adresses",
                  "preserveQs": "false"
                },
                {
                  "label": "Afficher les tarifs d’expédition et délais de livraison",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Indiquer les frais et délais d’expédition au passage à la caisse.",
                  "link": "/scp/fr/entreprise/cybercommerce/ameliorer/tarifs-et-delais-de-livraison.page?",
                  "linkCMSPage": "entreprise/cybercommerce/ameliorer/tarifs-et-delais-de-livraison",
                  "preserveQs": "false"
                },
                {
                  "label": "Demander un ramassage de colis",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Demander un ramassage de colis dans les locaux de votre entreprise.",
                  "link": "/scp/fr/entreprise/expedition/demander-ramassage.page?",
                  "linkCMSPage": "entreprise/expedition/demander-ramassage",
                  "preserveQs": "false"
                },
                {
                  "label": "Fournir le repérage des colis",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Intégrer le repérage à votre site pour permettre le suivi des colis.  ",
                  "link": "/scp/fr/entreprise/cybercommerce/ameliorer/reperage-de-colis.page?",
                  "linkCMSPage": "entreprise/cybercommerce/ameliorer/reperage-de-colis",
                  "preserveQs": "false"
                },
                {
                  "label": "Expédier à partir d’un magasin",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Expédier les achats en ligne directement depuis vos magasins. ",
                  "link": "/scp/fr/entreprise/cybercommerce/ameliorer/expedier-a-partir-magasin.page?",
                  "linkCMSPage": "entreprise/cybercommerce/ameliorer/expedier-a-partir-magasin",
                  "preserveQs": "false"
                },
                {
                  "label": "Livrer à un bureau de poste",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Offrir aux clients le ramassage de leurs commandes au bureau de poste.",
                  "link": "/scp/fr/entreprise/cybercommerce/ameliorer/livrer-au-bureau-de-poste.page?",
                  "linkCMSPage": "entreprise/cybercommerce/ameliorer/livrer-au-bureau-de-poste",
                  "preserveQs": "false"
                },
                {
                  "label": "Simplifier les retours",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Offrir la meilleure expérience de retour d’article à votre clientèle.",
                  "link": "/scp/fr/entreprise/expedition/retours.page?",
                  "linkCMSPage": "entreprise/expedition/retours",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Intégrer nos API",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Utiliser nos API pour intégrer nos services sur votre site Web.",
              "link": "/scp/fr/entreprise/cybercommerce/integrer-api.page?",
              "linkCMSPage": "entreprise/cybercommerce/integrer-api",
              "preserveQs": "false"
            },
            {
              "label": "Obtenir des ressources et des articles sur le cybercommerce",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Blogue En affaires.",
              "link": "/blogs/business/fr/category/cybercommerce/",
              "target": "_blank",
              "preserveQs": "false"
            }
          ]
        },
        {
          "label": "Petite entreprise",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "queryString": "Aper%c3%a7u pour petites entreprises",
          "description": "Découvrir nos outils d’expédition et rabais pour petites entreprises.",
          "link": "/scp/fr/entreprise/petite-entreprise.page?",
          "linkCMSPage": "entreprise/petite-entreprise",
          "nodes": [
            {
              "label": "Réductions sur les frais d’expédition",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Découvrir les niveaux de réduction de notre programme d’économies.",
              "link": "/scp/fr/entreprise/petite-entreprise/expedition-reductions.page?",
              "linkCMSPage": "entreprise/petite-entreprise/expedition-reductions",
              "preserveQs": "false"
            },
            {
              "label": "Réductions sur le publipostage",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Voir comment créer votre boutique en ligne avec nos partenaires. ",
              "link": "/scp/fr/entreprise/petite-entreprise/publipostage-reductions.page?",
              "linkCMSPage": "entreprise/petite-entreprise/publipostage-reductions",
              "preserveQs": "false"
            },
            {
              "label": "Commencer à vendre en ligne",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/entreprise/cybercommerce/commencer-vendre-en-ligne.page?",
              "linkCMSPage": "entreprise/cybercommerce/commencer-vendre-en-ligne",
              "preserveQs": "false"
            },
            {
              "label": "Réductions exclusives",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Voir les rabais liés au publipostage offerts aux petites entreprises.",
              "link": "/scp/fr/entreprise/petite-entreprise/reductions-partenaires.page?",
              "linkCMSPage": "entreprise/petite-entreprise/reductions-partenaires",
              "preserveQs": "false"
            },
            {
              "label": "Colis clic",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Créer des étiquettes d’expédition en ligne et voir nos tarifs réduits.   ",
              "link": "/scp/fr/entreprise/expedition/trouver-tarifs-expedier/colis-clic.page?",
              "linkCMSPage": "entreprise/expedition/trouver-tarifs-expedier/colis-clic",
              "preserveQs": "false"
            }
          ]
        },
        {
          "label": "Services postaux",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "queryString": "Aper%c3%a7u des services postaux",
          "description": "Découvrir les services postaux pouvant appuyer vos opérations.",
          "link": "/scp/fr/entreprise/services-postaux.page?",
          "linkCMSPage": "entreprise/services-postaux",
          "nodes": [
            {
              "label": "Envoi postal",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Tout savoir sur nos services de gestion des envois commerciaux.",
              "link": "/scp/fr/entreprise/services-postaux/expedition.page?",
              "linkCMSPage": "entreprise/services-postaux/expedition",
              "nodes": [
                {
                  "label": "Réductions sur le courrier d’affaires",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Économiser sur l’envoi de gros volumes de correspondance d’affaires. ",
                  "link": "/scp/fr/entreprise/services-postaux/envoipostal/reduction-courrier.page?",
                  "linkCMSPage": "entreprise/services-postaux/envoipostal/reduction-courrier",
                  "preserveQs": "false"
                },
                {
                  "label": "Envoyer des publications",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Profiter de tarifs d’envoi réduits (magazines, journaux et bulletins).",
                  "link": "/scp/fr/entreprise/services-postaux/envoipostal/envoyer-publications.page?",
                  "linkCMSPage": "entreprise/services-postaux/envoipostal/envoyer-publications",
                  "preserveQs": "false"
                },
                {
                  "label": "Correspondance-réponse d’affaires prépayée",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "preserveQs": "false",
                  "description": "Inclure un article port payé dans une campagne de publipostage.  ",
                  "link": "/scp/fr/entreprise/services-postaux/envoipostal/correspondance-reponse-daffaires-prepayee.page?",
                  "linkCMSPage": "entreprise/services-postaux/envoipostal/correspondance-reponse-daffaires-prepayee",
                  "nodes": [
                    {
                      "label": "Création et suivi d’envois Correspondance-réponse",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/entreprise/services-postaux/envoipostal/correspondance-reponse-daffaires-prepayee/creation-suivi.page?",
                      "linkCMSPage": "entreprise/services-postaux/envoipostal/correspondance-reponse-daffaires-prepayee/creation-suivi",
                      "preserveQs": "false"
                    }
                  ]
                },
                {
                  "label": "Réacheminement du courrier",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Faire réacheminer du courrier à une autre adresse.",
                  "link": "/scp/fr/personnel/recevoir/gerer-votre-courrier/reacheminement-du-courrier.page?",
                  "linkCMSPage": "personnel/recevoir/gerer-votre-courrier/reacheminement-du-courrier",
                  "preserveQs": "false"
                },
                {
                  "label": "Retenue du courrier",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Suspendre temporairement la livraison du courrier à votre adresse.",
                  "link": "/scp/fr/personnel/recevoir/gerer-votre-courrier/retenue-courrier.page?",
                  "linkCMSPage": "personnel/recevoir/gerer-votre-courrier/retenue-courrier",
                  "preserveQs": "false"
                },
                {
                  "label": "Courrier recommandé",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Payer un tarif fixe Courrier recommandéMC et obtenir une signature.",
                  "link": "/scp/fr/entreprise/services-postaux/envoipostal/courrier-recommande.page?",
                  "linkCMSPage": "entreprise/services-postaux/envoipostal/courrier-recommande",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Services financiers et cartes prépayées",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "En savoir plus sur les transferts de fonds et les cartes prépayées.",
              "link": "/scp/fr/entreprise/services-postaux/services-financiers-cartes-prepayees.page?",
              "linkCMSPage": "entreprise/services-postaux/services-financiers-cartes-prepayees",
              "nodes": [
                {
                  "label": "Mandats-poste",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Poster des documents certifiés et encaissables en toute sécurité.",
                  "link": "/scp/fr/entreprise/services-postaux/services-financiers-cartes-prepayees/mandat-poste.page?",
                  "linkCMSPage": "entreprise/services-postaux/services-financiers-cartes-prepayees/mandat-poste",
                  "preserveQs": "false"
                },
                {
                  "label": "Cartes de crédit prépayées",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Acheter des cartes prépayées au bureau de poste.",
                  "link": "/scp/fr/entreprise/services-postaux/services-financiers-cartes-prepayees/cartes-credit.page?",
                  "linkCMSPage": "entreprise/services-postaux/services-financiers-cartes-prepayees/cartes-credit",
                  "preserveQs": "false"
                },
                {
                  "label": "Cartes-cadeaux et produits prépayés",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Acheter des cartes-cadeaux et des cartes d’appel au bureau de poste. ",
                  "link": "/scp/fr/entreprise/services-postaux/services-financiers-cartes-prepayees/carte-cadeaux-produits-prepayes.page?",
                  "linkCMSPage": "entreprise/services-postaux/services-financiers-cartes-prepayees/carte-cadeaux-produits-prepayes",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Trouver un bureau de poste",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/information/app/trouverbdp/personnel/trouverunbureaudeposte",
              "preserveQs": "false"
            },
            {
              "label": "Louer une case postale",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Recevoir son courrier d’entreprise dans une case postale sécurisée.",
              "link": "/scp/fr/entreprise/services-postaux/louer-case-postale.page?",
              "linkCMSPage": "entreprise/services-postaux/louer-case-postale",
              "preserveQs": "false"
            },
            {
              "label": "Courrier numérique et partage de documents",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Envoyer des bulletins de paie et des formulaires d’impôt.",
              "link": "/scp/fr/entreprise/services-postaux/courrier-numerique.page?",
              "linkCMSPage": "entreprise/services-postaux/courrier-numerique",
              "nodes": [
                {
                  "label": "Partager des fichiers numériques confidentiels (Connexion)",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Envoyer des documents en toute sécurité à l’extérieur d’un pare-feu.",
                  "link": "/scp/fr/entreprise/services-postaux/courrier-numerique/connexion-postel.page?",
                  "linkCMSPage": "entreprise/services-postaux/courrier-numerique/connexion-postel",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Vérifier l’identité du client",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Prévenir les fraudes et les vols avec une preuve d’identité numérique.",
              "link": "/scp/fr/entreprise/services-postaux/preuve-identite-numerique.page?",
              "linkCMSPage": "entreprise/services-postaux/preuve-identite-numerique",
              "preserveQs": "false"
            },
            {
              "label": "Acheter des timbres et machines à affranchir",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Choisir un affranchissement et économiser sur les envois d’entreprise.",
              "link": "/scp/fr/entreprise/services-postaux/machines-affranchir.page?",
              "linkCMSPage": "entreprise/services-postaux/machines-affranchir",
              "preserveQs": "false"
            },
            {
              "label": "Magasiner",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Voir nos timbres, fournitures d’expédition et articles de collection.",
              "link": "/store-boutique/fr/accueil",
              "preserveQs": "false"
            },
            {
              "label": "Facturation",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Créer des factures personnalisées pour votre entreprise.",
              "link": "/scp/fr/entreprise/services-postaux/factures-commerciales.page?",
              "linkCMSPage": "entreprise/services-postaux/factures-commerciales",
              "preserveQs": "false"
            }
          ]
        },
        {
          "label": "Articles et ressources",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "queryString": "En affaires %e2%80%93 Page d%e2%80%99accueil",
          "description": "Lire des articles utiles qui vous aideront à optimiser vos opérations.",
          "link": "/blogs/business/fr/",
          "target": "_blank",
          "nodes": [
            {
              "label": "Tous les billets sur l’expédition",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "link": "/blogues/entreprise/categorie/expedition/",
              "target": "_blank",
              "nodes": [
                {
                  "label": "Expédition – Articles",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/blogs/business/fr/category/expedition/expedition-recommandations/",
                  "target": "_blank",
                  "preserveQs": "false"
                },
                {
                  "label": "Expédition – Ressources",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/blogs/business/fr/category/expedition/expedition-ressources/",
                  "target": "_blank",
                  "preserveQs": "false"
                },
                {
                  "label": "Expédition – Activités",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/scp/fr/entreprise/marketing/activites/en-vedettes.page?",
                  "linkCMSPage": "entreprise/marketing/activites/en-vedettes",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Tous les billets sur le marketing",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "link": "/blogs/business/fr/category/marketing/",
              "target": "_blank",
              "nodes": [
                {
                  "label": "Marketing – Articles",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/blogues/entreprise/categorie/marketing/marketing-recommandations/",
                  "target": "_blank",
                  "preserveQs": "false"
                },
                {
                  "label": "Marketing – Ressources",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/blogues/entreprise/categorie/marketing/marketing-ressources/",
                  "target": "_blank",
                  "preserveQs": "false"
                },
                {
                  "label": "Marketing – Activités",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/scp/fr/entreprise/marketing/activites/marketing.page?",
                  "linkCMSPage": "entreprise/marketing/activites/marketing",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Tous les billets sur le cybercommerce",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "link": "/blogs/business/fr/category/cybercommerce/",
              "target": "_blank",
              "nodes": [
                {
                  "label": "Cybercommerce - Articles",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/blogs/business/fr/category/cybercommerce/cybercommerce-recommandations/",
                  "target": "_blank",
                  "preserveQs": "false"
                },
                {
                  "label": "Cybercommerce – Ressources",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/blogs/business/fr/category/cybercommerce/cybercommerce-ressources/",
                  "target": "_blank",
                  "preserveQs": "false"
                },
                {
                  "label": "Cybercommerce – Activités",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/scp/fr/entreprise/marketing/activites/liste-des-activites-sur-le-cybercommerce.page?",
                  "linkCMSPage": "entreprise/marketing/activites/liste-des-activites-sur-le-cybercommerce",
                  "preserveQs": "false"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "label": "Utility",
      "visibleInSiteMap": "true",
      "visibleInBreadCrumbs": "false",
      "preserveQs": "false",
      "link": "/scp/fr/accueil.page?",
      "linkCMSPage": "",
      "nodes": [
        {
          "label": "Soutien",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "link": "/scp/fr/soutien.page?",
          "linkCMSPage": "soutien",
          "preserveQs": "false"
        }
      ]
    },
    {
      "label": "PersonalBusiness",
      "visibleInSiteMap": "true",
      "visibleInBreadCrumbs": "false",
      "preserveQs": "false",
      "link": "/scp/fr/accueil.page?",
      "linkCMSPage": "",
      "nodes": [
        {
          "label": "Personnel",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "description": "Découvrir les services postaux offerts aux particuliers.",
          "link": "/scp/fr/personnel.page?",
          "linkCMSPage": "personnel",
          "preserveQs": "false"
        },
        {
          "label": "Entreprise",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "description": "Découvrir les services postaux offerts aux entreprises.",
          "link": "/scp/fr/entreprise.page?",
          "linkCMSPage": "entreprise",
          "preserveQs": "false"
        },
        {
          "label": "Notre entreprise",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "description": "En savoir plus sur Postes Canada et ses alertes de service.",
          "link": "/scp/fr/notre-entreprise.page?",
          "linkCMSPage": "notre-entreprise",
          "preserveQs": "false"
        },
        {
          "label": "Boutique",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "description": "Voir nos timbres, fournitures d’expédition et articles de collection.",
          "link": "/store-boutique/fr/accueil",
          "preserveQs": "false"
        },
        {
          "label": "Outils",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "description": "Voir plus d’outils",
          "link": "/scp/fr/outils.page?",
          "linkCMSPage": "outils",
          "preserveQs": "false"
        }
      ]
    },
    {
      "label": "Footer",
      "visibleInSiteMap": "true",
      "visibleInBreadCrumbs": "true",
      "preserveQs": "false",
      "link": "/scp/fr/accueil.page?",
      "linkCMSPage": "",
      "nodes": [
        {
          "label": "Support",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "description": "Soutiens",
          "link": "/scp/fr/accueil.page?",
          "linkCMSPage": "",
          "nodes": [
            {
              "label": "Besoin d'aide?",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/soutien.page?",
              "linkCMSPage": "soutien",
              "preserveQs": "false"
            },
            {
              "label": "Contactez-nous",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/soutien.page#panneau2-1",
              "preserveQs": "false"
            }
          ]
        },
        {
          "label": "Connect with us",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "description": "Suivez-nous",
          "link": "/scp/fr/accueil.page?",
          "linkCMSPage": "",
          "nodes": [
            {
              "label": "Facebook",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/accueil.page?",
              "linkCMSPage": ""
            },
            {
              "label": "Twitter",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/accueil.page?",
              "linkCMSPage": ""
            },
            {
              "label": "Instagram",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/accueil.page?",
              "linkCMSPage": ""
            },
            {
              "label": "LinkedIn",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/accueil.page?",
              "linkCMSPage": ""
            }
          ]
        },
        {
          "label": "Corporate",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "description": "Postes Canada",
          "link": "/scp/fr/accueil.page?",
          "linkCMSPage": "",
          "nodes": [
            {
              "label": "&Agrave; notre sujet",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/notre-entreprise/a-notre-sujet.page?",
              "linkCMSPage": "notre-entreprise/a-notre-sujet",
              "preserveQs": "false"
            },
            {
              "label": "M&eacute;dia",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/notre-entreprise/nouvelles-et-medias/centre-media.page?",
              "linkCMSPage": "notre-entreprise/nouvelles-et-medias/centre-media",
              "preserveQs": "false"
            },
            {
              "label": "Carri&egrave;res",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/notre-entreprise/emplois.page?",
              "linkCMSPage": "notre-entreprise/emplois",
              "preserveQs": "false"
            },
            {
              "label": "Je travaille &agrave; Postes Canada",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "https://infopost.ca/fr/",
              "preserveQs": "false"
            },
            {
              "label": "Zone de talent",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "https://mysite.canadapost.ca/saml2/idp/sso?saml2sp=https%3A%2F%2Fwww.successfactors.com%2FS000016952T1&RelayState=%2Fsf%2Fhome",
              "preserveQs": "false"
            },
            {
              "label": "Mises à jour sur les négociations",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/notre-entreprise/nouvelles-et-medias/nouvelles-de-la-societe/liste-negotiations.page?",
              "linkCMSPage": "notre-entreprise/nouvelles-et-medias/nouvelles-de-la-societe/liste-negotiations",
              "preserveQs": "false"
            }
          ]
        },
        {
          "label": "Blogs",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "description": "Blogues",
          "link": "/scp/fr/accueil.page?",
          "linkCMSPage": "",
          "nodes": [
            {
              "label": "En affaires",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/blogues/entreprise/",
              "preserveQs": "false"
            },
            {
              "label": "Magazine de Postes Canada",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/blogues/personnel/",
              "preserveQs": "false"
            }
          ]
        },
        {
          "label": "Terms",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "link": "/scp/fr/accueil.page?",
          "linkCMSPage": "",
          "nodes": [
            {
              "label": "Accessibilit&eacute;",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/notre-entreprise/a-notre-sujet/responsabilite-dentreprise/accessibilite.page",
              "linkCMSPage": "notre-entreprise/a-notre-sujet/developpement-durable/accessibilite",
              "preserveQs": "false"
            },
            {
              "label": "Avis juridiques",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/soutien/bc/demandes-generales/renseignements-generaux/juridiques-conditions-dutilisation",
              "preserveQs": "false"
            },
            {
              "label": "Confidentialit&eacute;",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/notre-entreprise/a-notre-sujet/transparence-et-confiance/centre-de-protection-de-la-vie-privee.page",
              "preserveQs": "false"
            },
            {
              "label": "Politique sur les t&eacute;moins",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/notre-entreprise/a-notre-sujet/transparence-et-confiance/centre-de-protection-de-la-vie-privee/politique-sur-les-temoins.page",
              "preserveQs": "false",
              
            },
            {
              "label": "Recherche",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/notre-entreprise/panel-recherche.page",
              "preserveQs": "false"
            }
          ]
        }
      ]
    },
    {
      "label": "Personnel",
      "visibleInSiteMap": "true",
      "visibleInBreadCrumbs": "true",
      "preserveQs": "false",
      "description": "Découvrir les services postaux offerts aux particuliers.",
      "link": "/scp/fr/personnel.page?",
      "linkCMSPage": "personnel",
      "nodes": [
        {
          "label": "Recevoir du courrier",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "queryString": "Recevoir : aper%c3%a7u",
          "description": "Découvrir toutes les façons de recevoir du courrier et des colis.",
          "link": "/scp/fr/personnel/recevoir.page?",
          "linkCMSPage": "personnel/recevoir",
          "nodes": [
            {
              "label": "G&eacute;rer votre courrier",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Tout savoir sur nos boîtes communautaires et la gestion du courrier.",
              "link": "/scp/fr/personnel/recevoir/gerer-votre-courrier.page?",
              "linkCMSPage": "personnel/recevoir/gerer-votre-courrier",
              "nodes": [
                {
                  "label": "Faire r&eacute;acheminer votre courrier",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "preserveQs": "false",
                  "description": "Faire réacheminer du courrier à une autre adresse.",
                  "link": "/scp/fr/personnel/recevoir/gerer-votre-courrier/reacheminement-du-courrier.page?",
                  "linkCMSPage": "personnel/recevoir/gerer-votre-courrier/reacheminement-du-courrier",
                  "nodes": [
                    {
                      "label": "Service de réacheminement du courrier sur mesure pour entreprise",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/personnel/recevoir/gerer-votre-courrier/reacheminement-du-courrier/sur-mesure-commercial.page?",
                      "linkCMSPage": "personnel/recevoir/gerer-votre-courrier/reacheminement-du-courrier/sur-mesure-commercial",
                      "preserveQs": "false"
                    }
                  ]
                },
                {
                  "label": "Faire retenir votre courrier",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Suspendre temporairement la livraison du courrier à votre adresse.",
                  "link": "/scp/fr/personnel/recevoir/gerer-votre-courrier/retenue-courrier.page?",
                  "linkCMSPage": "personnel/recevoir/gerer-votre-courrier/retenue-courrier",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Autres options de livraison",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Recevoir des envois à un bureau de poste et dans une armoire à colis.",
              "link": "/scp/fr/personnel/recevoir/autres-options-livraison.page?",
              "linkCMSPage": "personnel/recevoir/autres-options-livraison",
              "nodes": [
                {
                  "label": "Faire livrer vos achats au bureau de poste (FlexiLivraison)",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Faire livrer des colis au bureau de poste aux fins de ramassage. ",
                  "link": "/scp/fr/personnel/recevoir/autres-options-livraison/flexilivraison.page?",
                  "linkCMSPage": "personnel/recevoir/autres-options-livraison/flexilivraison",
                  "preserveQs": "false"
                },
                {
                  "label": "Louer une case postale",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Louer une case postale pour recevoir du courrier et des colis.",
                  "link": "/scp/fr/personnel/recevoir/autres-options-livraison/casier-postal.page?",
                  "linkCMSPage": "personnel/recevoir/autres-options-livraison/casier-postal",
                  "preserveQs": "false"
                },
                {
                  "label": "Armoires à colis",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Ramasser des colis dans le casier de votre immeuble d’habitation.",
                  "link": "/scp/fr/personnel/recevoir/autres-options-livraison/armoires-colis.page?",
                  "linkCMSPage": "personnel/recevoir/autres-options-livraison/armoires-colis",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Faire un changement d'adresse",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Faire réacheminer du courrier et accéder à votre boîte communautaire.",
              "link": "/scp/fr/personnel/recevoir/demenagement.page?",
              "linkCMSPage": "personnel/recevoir/demenagement",
              "preserveQs": "false"
            },
            {
              "label": "Rep&eacute;rer un colis",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Lire sur le repérage, les Avis de livraison et les numéros de référence.",
              "link": "/track-reperage/fr",
              "nodes": [
                {
                  "label": "Suivi automatisé",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/scp/fr/personnel/recevoir/suivi-automatise.page?",
                  "linkCMSPage": "personnel/recevoir/suivi-automatise",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Trouver un bureau de poste",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/information/app/trouverbdp/personnel/trouverunbureaudeposte",
              "preserveQs": "false"
            },
            {
              "label": "Notre application mobile",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Découvrir notre appli gratuite et recevoir des avis sur vos colis. ",
              "link": "/scp/fr/personnel/recevoir/app-mobile.page?",
              "linkCMSPage": "personnel/recevoir/app-mobile",
              "preserveQs": "false"
            }
          ]
        },
        {
          "label": "Exp&eacute;dier",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "queryString": "Exp%c3%a9dier : aper%c3%a7u",
          "description": "Voir les tarifs et services d’expédition du courrier et des colis.",
          "link": "/scp/fr/personnel/expedier.page?",
          "linkCMSPage": "personnel/expedier",
          "nodes": [
            {
              "label": "Lettres et courrier",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Tout savoir sur les tarifs d’envoi et les dimensions du courrier.",
              "link": "/scp/fr/personnel/expedier/lettres-courrier.page?",
              "linkCMSPage": "personnel/expedier/lettres-courrier",
              "nodes": [
                {
                  "label": "Tarifs d'affranchissement ",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Voir les tarifs d’envoi de lettres et de cartes ici et à l’étranger.",
                  "link": "/scp/fr/personnel/expedier/lettres-courrier/tarifs-affranchissement.page?",
                  "linkCMSPage": "personnel/expedier/lettres-courrier/tarifs-affranchissement",
                  "preserveQs": "false"
                },
                {
                  "label": "Poids et dimensions d&apos;une lettre",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Dimensions du courrier standard et surdimensionné ou non standard.",
                  "link": "/scp/fr/personnel/expedier/lettres-courrier/format-lettre.page?",
                  "linkCMSPage": "personnel/expedier/lettres-courrier/format-lettre",
                  "preserveQs": "false"
                },
                {
                  "label": "Courrier recommand&eacute;",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Acheter le service Courrier recommandéMC pour un envoi.",
                  "link": "/scp/fr/personnel/expedier/lettres-courrier/courrier-recommande.page?",
                  "linkCMSPage": "personnel/expedier/lettres-courrier/courrier-recommande",
                  "preserveQs": "false"
                },
                {
                  "label": "Timbres personnalis&eacute;s",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Créer des timbres sur mesure pour des envois au pays et à l’étranger.",
                  "link": "/scp/fr/personnel/expedier/lettres-courrier/timbres-personnalises.page?",
                  "linkCMSPage": "personnel/expedier/lettres-courrier/timbres-personnalises",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Colis",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Découvrir nos services d’expédition de colis.",
              "link": "/scp/fr/personnel/expedier/colis.page?",
              "linkCMSPage": "personnel/expedier/colis",
              "nodes": [
                {
                  "label": "Exp&eacute;dier en ligne",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Créer, payer et imprimer une étiquette d’expédition en ligne.",
                  "link": "/scp/fr/personnel/expedier/colis/expedier-en-ligne.page?",
                  "linkCMSPage": "personnel/expedier/colis/expedier-en-ligne",
                  "preserveQs": "false"
                },
                {
                  "label": "Retourner un colis",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Accéder à une étiquette de retour et l’imprimer en ligne.",
                  "link": "/scp/fr/personnel/expedier/colis/etiquette-retour.page?",
                  "linkCMSPage": "personnel/expedier/colis/etiquette-retour",
                  "preserveQs": "false"
                },
                {
                  "label": "Consulter les restrictions",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "preserveQs": "false",
                  "description": "Restrictions liées aux articles inadmissibles et contrôlés par pays.",
                  "link": "/scp/fr/personnel/expedier/colis/restrictions.page?",
                  "linkCMSPage": "personnel/expedier/colis/restrictions",
                  "nodes": [
                    {
                      "label": "Cannabis",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/personnel/expedier/colis/restrictions/cannabis.page?",
                      "linkCMSPage": "personnel/expedier/colis/restrictions/cannabis",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Armes à feu",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/personnel/expedier/colis/restrictions/armes-a-feu.page?",
                      "linkCMSPage": "personnel/expedier/colis/restrictions/armes-a-feu",
                      "preserveQs": "false"
                    }
                  ]
                },
                {
                  "label": "Comparer les services d'exp&eacute;dition au Canada",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "preserveQs": "false",
                  "description": "Voir les délais et caractéristiques d’expédition du régime intérieur.",
                  "link": "/scp/fr/personnel/expedier/colis/comparer-services-canada.page?",
                  "linkCMSPage": "personnel/expedier/colis/comparer-services-canada",
                  "nodes": [
                    {
                      "label": "Colis standard",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/personnel/expedier/colis/comparer-services-canada/regulier.page?",
                      "linkCMSPage": "personnel/expedier/colis/comparer-services-canada/regulier",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Xpresspost",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/personnel/expedier/colis/comparer-services-canada/xpresspost.page?",
                      "linkCMSPage": "personnel/expedier/colis/comparer-services-canada/xpresspost",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Priorit&eacute;",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/personnel/expedier/colis/comparer-services-canada/priorite.page?",
                      "linkCMSPage": "personnel/expedier/colis/comparer-services-canada/priorite",
                      "preserveQs": "false"
                    }
                  ]
                },
                {
                  "label": "Comparer les services d'exp&eacute;dition du r&eacute;gime international",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "preserveQs": "false",
                  "description": "Voir les délais et caractéristiques d’expédition à l’international.",
                  "link": "/scp/fr/personnel/expedier/colis/comparer-services-international.page?",
                  "linkCMSPage": "personnel/expedier/colis/comparer-services-international",
                  "nodes": [
                    {
                      "label": "Petit paquet – &Eacute;.-U.",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/personnel/expedier/colis/comparer-services-international/petit-paquet-eu.page?",
                      "linkCMSPage": "personnel/expedier/colis/comparer-services-international/petit-paquet-eu",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Petit paquet par avion ou de surface – International",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/personnel/expedier/colis/comparer-services-international/petit-paquet-international.page?",
                      "linkCMSPage": "personnel/expedier/colis/comparer-services-international/petit-paquet-international",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Xpresspost – International",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/personnel/expedier/colis/comparer-services-international/xpresspost-international.page?",
                      "linkCMSPage": "personnel/expedier/colis/comparer-services-international/xpresspost-international",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Xpresspost – &Eacute;.-U.",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/personnel/expedier/colis/comparer-services-international/xpresspost-eu.page?",
                      "linkCMSPage": "personnel/expedier/colis/comparer-services-international/xpresspost-eu",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Paquet rep&eacute;rable – International",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/personnel/expedier/colis/comparer-services-international/paquet-reperable-international.page?",
                      "linkCMSPage": "personnel/expedier/colis/comparer-services-international/paquet-reperable-international",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Paquet rep&eacute;rable – &Eacute;.-U.",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/personnel/expedier/colis/comparer-services-international/paquet-reperable-eu.page?",
                      "linkCMSPage": "personnel/expedier/colis/comparer-services-international/paquet-reperable-eu",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Colis acc&eacute;l&eacute;r&eacute;s – &Eacute;.-U.",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/personnel/expedier/colis/comparer-services-international/accelere-eu.page?",
                      "linkCMSPage": "personnel/expedier/colis/comparer-services-international/accelere-eu",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Colis-avion ou Colis de surface – International",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/personnel/expedier/colis/comparer-services-international/colis-international.page?",
                      "linkCMSPage": "personnel/expedier/colis/comparer-services-international/colis-international",
                      "preserveQs": "false"
                    }
                  ]
                },
                {
                  "label": "Frais de douanes et taxes",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/information/app/wtz/entreprise/coutlivre",
                  "preserveQs": "false"
                },
                {
                  "label": "Remplir le formulaire douanier",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/information/app/cdc",
                  "preserveQs": "false"
                },
                {
                  "label": "Boîte à tarif fixe",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Acheter une boîte à tarif fixe préaffranchie au bureau de poste.",
                  "link": "/scp/fr/personnel/expedier/colis/boite-tarif-fixe.page?",
                  "linkCMSPage": "personnel/expedier/colis/boite-tarif-fixe",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Acc&eacute;dez nos outils rapides",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Tout savoir sur vos outils d’expédition préférés.",
              "link": "/scp/fr/personnel/expedier/outils-pratiques.page?",
              "linkCMSPage": "personnel/expedier/outils-pratiques",
              "nodes": [
                {
                  "label": "Trouver un tarif",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/scp/fr/outils/trouver-un-tarif.page",
                  "preserveQs": "false"
                },
                {
                  "label": "Normes de livraison",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/business/tools/ds/default.aspx?LOCALE=fr",
                  "preserveQs": "false"
                },
                {
                  "label": "Rep&eacute;rer un colis",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/track-reperage/fr",
                  "preserveQs": "false"
                },
                {
                  "label": "Trouver un bureau de poste",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/information/app/trouverbdp/personnel/trouverunbureaudeposte",
                  "preserveQs": "false"
                },
                {
                  "label": "Trouver un code postal",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/scp/fr/outils/trouver-un-code-postal.page",
                  "preserveQs": "false"
                }
              ]
            }
          ]
        },
        {
          "label": "Services financiers",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "queryString": "Services Financiers: aper%c3%a7u",
          "description": "Découvrez les services financiers offerts dans nos bureaux de poste.",
          "link": "/scp/fr/personnel/services-financiers.page?",
          "linkCMSPage": "personnel/services-financiers",
          "nodes": [
            {
              "label": "Envoyer de l'argent",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "En savoir plus sur les transferts de fonds et les mandats-poste.",
              "link": "/scp/fr/personnel/services-monetaires-et-gouvernementaux/envoyer-argent.page?",
              "linkCMSPage": "personnel/services-monetaires-et-gouvernementaux/envoyer-argent",
              "nodes": [
                {
                  "label": "Mandats-poste",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Envoyer des mandats-poste à partir d’un bureau de poste.",
                  "link": "/scp/fr/personnel/services-monetaires-et-gouvernementaux/envoyer-argent/mandat-poste.page?",
                  "linkCMSPage": "personnel/services-monetaires-et-gouvernementaux/envoyer-argent/mandat-poste",
                  "preserveQs": "false"
                },
                {
                  "label": "Transfert international de fonds (MoneyGram)",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Envoyer des fonds à prix abordable partout dans le monde.",
                  "link": "/scp/fr/personnel/services-monetaires-et-gouvernementaux/envoyer-argent/transfert-international-de-fonds.page?",
                  "linkCMSPage": "personnel/services-monetaires-et-gouvernementaux/envoyer-argent/transfert-international-de-fonds",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "G&eacute;rer un envoi mon&eacute;taire",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Découvrir nos cartes prépayées et notre service de commande de devises.",
              "link": "/scp/fr/personnel/services-monetaires-et-gouvernementaux/gerer-envoi-monetaire.page?",
              "linkCMSPage": "personnel/services-monetaires-et-gouvernementaux/gerer-envoi-monetaire",
              "nodes": [
                {
                  "label": "Cartes prépayées rechargeables",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "preserveQs": "false",
                  "description": "Acheter des cartes prépayées au bureau de poste.",
                  "link": "/scp/fr/personnel/services-monetaires-et-gouvernementaux/gerer-envoi-monetaire/cartes-prepayees.page?",
                  "linkCMSPage": "personnel/services-monetaires-et-gouvernementaux/gerer-envoi-monetaire/cartes-prepayees",
                  "nodes": [
                    {
                      "label": "Mastercard",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "preserveQs": "false",
                      "link": "/scp/fr/personnel/services-monetaires-et-gouvernementaux/gerer-envoi-monetaire/cartes-prepayees/mastercard.page?",
                      "linkCMSPage": "personnel/services-monetaires-et-gouvernementaux/gerer-envoi-monetaire/cartes-prepayees/mastercard",
                      "nodes": [
                        {
                          "label": "Découvrez Cash Passport",
                          "visibleInSiteMap": "true",
                          "visibleInBreadCrumbs": "true",
                          "link": "/scp/fr/personnel/services-monetaires-et-gouvernementaux/gerer-envoi-monetaire/cartes-prepayees/mastercard/apercu.page?",
                          "linkCMSPage": "personnel/services-monetaires-et-gouvernementaux/gerer-envoi-monetaire/cartes-prepayees/mastercard/apercu",
                          "preserveQs": "false"
                        },
                        {
                          "label": "Commencez à utiliser votre carte",
                          "visibleInSiteMap": "true",
                          "visibleInBreadCrumbs": "true",
                          "link": "/scp/fr/personnel/services-monetaires-et-gouvernementaux/gerer-envoi-monetaire/cartes-prepayees/mastercard/commencez-a-utiliser.page?",
                          "linkCMSPage": "personnel/services-monetaires-et-gouvernementaux/gerer-envoi-monetaire/cartes-prepayees/mastercard/commencez-a-utiliser",
                          "preserveQs": "false"
                        },
                        {
                          "label": "Comment utiliser votre carte",
                          "visibleInSiteMap": "true",
                          "visibleInBreadCrumbs": "true",
                          "link": "/scp/fr/personnel/services-monetaires-et-gouvernementaux/gerer-envoi-monetaire/cartes-prepayees/mastercard/comment-utiliser.page?",
                          "linkCMSPage": "personnel/services-monetaires-et-gouvernementaux/gerer-envoi-monetaire/cartes-prepayees/mastercard/comment-utiliser",
                          "preserveQs": "false"
                        },
                        {
                          "label": "Contactez-nous",
                          "visibleInSiteMap": "true",
                          "visibleInBreadCrumbs": "true",
                          "link": "/scp/fr/personnel/services-monetaires-et-gouvernementaux/gerer-envoi-monetaire/cartes-prepayees/mastercard/contactez-nous.page?",
                          "linkCMSPage": "personnel/services-monetaires-et-gouvernementaux/gerer-envoi-monetaire/cartes-prepayees/mastercard/contactez-nous",
                          "preserveQs": "false"
                        },
                        {
                          "label": "FAQ",
                          "visibleInSiteMap": "true",
                          "visibleInBreadCrumbs": "true",
                          "link": "/scp/fr/personnel/services-monetaires-et-gouvernementaux/gerer-envoi-monetaire/cartes-prepayees/mastercard/faq.page?",
                          "linkCMSPage": "personnel/services-monetaires-et-gouvernementaux/gerer-envoi-monetaire/cartes-prepayees/mastercard/faq",
                          "preserveQs": "false"
                        }
                      ]
                    }
                  ]
                },
                {
                  "label": "Autres services pr&eacute;pay&eacute;s",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Acheter des cartes d’appel et pour appareil mobile au bureau de poste.",
                  "link": "/scp/fr/personnel/services-monetaires-et-gouvernementaux/gerer-envoi-monetaire/autres-services-prepayes.page?",
                  "linkCMSPage": "personnel/services-monetaires-et-gouvernementaux/gerer-envoi-monetaire/autres-services-prepayes",
                  "preserveQs": "false"
                },
                {
                  "label": "Livraison d’espèces en devises",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Commander des devises en ligne et les faire livrer.",
                  "link": "/scp/fr/personnel/services-monetaires-et-gouvernementaux/gerer-envoi-monetaire/livraison-especes-devises.page?",
                  "linkCMSPage": "personnel/services-monetaires-et-gouvernementaux/gerer-envoi-monetaire/livraison-especes-devises",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Prêts personnels",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Obtenir un permis de chasse aux oiseaux migrateurs au bureau de poste.",
              "link": "/scp/fr/personnel/monargent/pret.page?",
              "linkCMSPage": "personnel/monargent/pret",
              "preserveQs": "false"
            },
            {
              "label": "Cartes-cadeaux",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Acheter des cartes-cadeaux à un bureau de poste.",
              "link": "/scp/fr/personnel/services-monetaires-et-gouvernementaux/carte-cadeau.page?",
              "linkCMSPage": "personnel/services-monetaires-et-gouvernementaux/carte-cadeau",
              "preserveQs": "false"
            }
          ]
        },
        {
          "label": "Timbres et collection",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "queryString": "Timbres et collection : aper%c3%a7u",
          "description": "Découvrir nos timbres de collection et nos cachets illustrés.",
          "link": "/scp/fr/personnel/articles-de-collection.page?",
          "linkCMSPage": "personnel/articles-de-collection",
          "nodes": [
            {
              "label": "R&eacute;cits sur les timbres canadiens",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Obtenir des conseils pour collectionner des timbres et des pièces.",
              "link": "/scp/fr/personnel/articles-de-collection/recits-timbre.page?",
              "linkCMSPage": "personnel/articles-de-collection/recits-timbre",
              "nodes": [
                {
                  "label": "En detail catalogue collections",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/scp/fr/personnel/articles-de-collection/recits-timbre/en-detail-catalogue-collections.page?",
                  "linkCMSPage": "personnel/articles-de-collection/recits-timbre/en-detail-catalogue-collections",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Proposer un timbre",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Envoyer des suggestions de thèmes de timbres.",
              "link": "/scp/fr/personnel/articles-de-collection/suggestion-timbre.page?",
              "linkCMSPage": "personnel/articles-de-collection/suggestion-timbre",
              "preserveQs": "false"
            },
            {
              "label": "Cachets d'oblit&eacute;ration illustr&eacute;s",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Voir les cachets d’oblitération offerts et où ils sont disponibles.",
              "link": "/scp/fr/personnel/articles-de-collection/cachets-dobliteration.page?",
              "linkCMSPage": "personnel/articles-de-collection/cachets-dobliteration",
              "preserveQs": "false"
            }
          ]
        }
      ]
    },
    {
      "label": "Mercure",
      "visibleInSiteMap": "true",
      "visibleInBreadCrumbs": "true",
      "preserveQs": "false",
      "description": "mercury",
      "link": "/scp/fr/accueil.page?",
      "linkCMSPage": "",
      "nodes": [
        {
          "label": "Aperçu",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "description": "apercu",
          "link": "/scp/fr/accueil.page?",
          "linkCMSPage": "",
          "nodes": [
            {
              "label": "Accessibilité",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "accessibility",
              "link": "/scp/fr/mercure/apercu/accessibilite.page?",
              "linkCMSPage": "mercure/apercu/accessibilite",
              "preserveQs": "false"
            },
            {
              "label": "Grilles",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/mercure/apercu/grilles.page?",
              "linkCMSPage": "mercure/apercu/grilles",
              "preserveQs": "false"
            },
            {
              "label": "Modèles : Page de campagne",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/mercure/apercu/modeles-page-de-campagne.page?",
              "linkCMSPage": "mercure/apercu/modeles-page-de-campagne",
              "preserveQs": "false"
            },
            {
              "label": "Modèles : Page de produit",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/mercure/apercu/modeles-page-de-produit.page?",
              "linkCMSPage": "mercure/apercu/modeles-page-de-produit",
              "preserveQs": "false"
            },
            {
              "label": "Organisation du contenu",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/mercure/apercu/organisation-du-contenu.page?",
              "linkCMSPage": "mercure/apercu/organisation-du-contenu",
              "preserveQs": "false"
            },
            {
              "label": "Optimisation pour la recherche",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/mercure/apercu/optimisation-pour-la-recherche.page?",
              "linkCMSPage": "mercure/apercu/optimisation-pour-la-recherche",
              "preserveQs": "false"
            },
            {
              "label": "Unités et mesures",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/mercure/apercu/unites-et-mesures.page?",
              "linkCMSPage": "mercure/apercu/unites-et-mesures",
              "preserveQs": "false"
            }
          ]
        },
        {
          "label": "Style",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "description": "style",
          "link": "/scp/fr/accueil.page?",
          "linkCMSPage": "",
          "nodes": [
            {
              "label": "Couleur",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/mercure/style/couleur.page?",
              "linkCMSPage": "mercure/style/couleur",
              "preserveQs": "false"
            },
            {
              "label": "Grammaire et structure",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/mercure/style/grammaire-et-structure.page?",
              "linkCMSPage": "mercure/style/grammaire-et-structure",
              "preserveQs": "false"
            },
            {
              "label": "Iconographie",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/mercure/style/iconographie.page?",
              "linkCMSPage": "mercure/style/iconographie",
              "preserveQs": "false"
            },
            {
              "label": "Illustration",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/mercure/style/illustration.page?",
              "linkCMSPage": "mercure/style/illustration",
              "preserveQs": "false"
            },
            {
              "label": "Logo",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/mercure/style/logo.page?",
              "linkCMSPage": "mercure/style/logo",
              "preserveQs": "false"
            },
            {
              "label": "Photographie",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/mercure/style/photographie.page?",
              "linkCMSPage": "mercure/style/photographie",
              "preserveQs": "false"
            },
            {
              "label": "Typographie",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/mercure/style/typographie.page?",
              "linkCMSPage": "mercure/style/typographie",
              "preserveQs": "false"
            },
            {
              "label": "Voix et ton",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/mercure/style/voix-et-ton.page?",
              "linkCMSPage": "mercure/style/voix-et-ton",
              "preserveQs": "false"
            }
          ]
        },
        {
          "label": "Composants",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "description": "Composants",
          "link": "/scp/fr/accueil.page?",
          "linkCMSPage": "",
          "nodes": [
            {
              "label": "Avis",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/mercure/composants/avis.page?",
              "linkCMSPage": "mercure/composants/avis",
              "preserveQs": "false"
            },
            {
              "label": "Accordéons",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Accordéons",
              "link": "/scp/fr/mercure/composants/accordeons.page?",
              "linkCMSPage": "mercure/composants/accordeons",
              "preserveQs": "false"
            },
            {
              "label": "Barres de progression",
              "visibleInSiteMap": "false",
              "visibleInBreadCrumbs": "false",
              "link": "/scp/fr/mercure/composants/barres-de-progression.page?",
              "linkCMSPage": "mercure/composants/barres-de-progression",
              "preserveQs": "false"
            },
            {
              "label": "Boutons",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/mercure/composants/boutons.page?",
              "linkCMSPage": "mercure/composants/boutons",
              "preserveQs": "false"
            },
            {
              "label": "Bouton à bascule",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Bouton a bascule",
              "link": "/scp/fr/mercure/composants/bouton-bascule.page?",
              "linkCMSPage": "mercure/composants/bouton-bascule",
              "preserveQs": "false"
            },
            {
              "label": "Boutons radio",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/mercure/composants/boutons-radio.page?",
              "linkCMSPage": "mercure/composants/boutons-radio",
              "preserveQs": "false"
            },
            {
              "label": "Cartes-outils",
              "visibleInSiteMap": "false",
              "visibleInBreadCrumbs": "false",
              "link": "/scp/fr/mercure/composants/cartes-outils.page?",
              "linkCMSPage": "mercure/composants/cartes-outils",
              "preserveQs": "false"
            },
            {
              "label": "Cases à cocher",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/mercure/composants/cases-a-cocher.page?",
              "linkCMSPage": "mercure/composants/cases-a-cocher",
              "preserveQs": "false"
            },
            {
              "label": "Champs de saisie",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/mercure/composants/champs-de-saisie.page?",
              "linkCMSPage": "mercure/composants/champs-de-saisie",
              "preserveQs": "false"
            },
            {
              "label": "Modales",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "modales",
              "link": "/scp/fr/mercure/composants/fenetres-modales.page?",
              "linkCMSPage": "mercure/composants/fenetres-modales",
              "preserveQs": "false"
            },
            {
              "label": "Pistes de navigation",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/mercure/composants/pistes-de-navigation.page?",
              "linkCMSPage": "mercure/composants/pistes-de-navigation",
              "preserveQs": "false"
            },
            {
              "label": "Formulaires",
              "visibleInSiteMap": "false",
              "visibleInBreadCrumbs": "false",
              "link": "/scp/fr/mercure/composants/formulaires.page?",
              "linkCMSPage": "mercure/composants/formulaires",
              "preserveQs": "false"
            },
            {
              "label": "Infobulles",
              "visibleInSiteMap": "false",
              "visibleInBreadCrumbs": "false",
              "link": "/scp/fr/mercure/composants/infobulles.page?",
              "linkCMSPage": "mercure/composants/infobulles",
              "preserveQs": "false"
            },
            {
              "label": "Liens",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Liens",
              "link": "/scp/fr/mercure/composants/liens.page?",
              "linkCMSPage": "mercure/composants/liens",
              "preserveQs": "false"
            },
            {
              "label": "Listes",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/mercure/composants/listes.page?",
              "linkCMSPage": "mercure/composants/listes",
              "preserveQs": "false"
            },
            {
              "label": "Menus déroulants",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/mercure/composants/menus-deroulants.page?",
              "linkCMSPage": "mercure/composants/menus-deroulants",
              "preserveQs": "false"
            },
            {
              "label": "Navigation",
              "visibleInSiteMap": "false",
              "visibleInBreadCrumbs": "false",
              "link": "/scp/fr/mercure/composants/navigation.page?",
              "linkCMSPage": "mercure/composants/navigation",
              "preserveQs": "false"
            },
            {
              "label": "Onglets",
              "visibleInSiteMap": "false",
              "visibleInBreadCrumbs": "false",
              "link": "/scp/fr/mercure/composants/onglets.page?",
              "linkCMSPage": "mercure/composants/onglets",
              "preserveQs": "false"
            },
            {
              "label": "Pagination",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Pagination",
              "link": "/scp/fr/mercure/composants/pagination.page?",
              "linkCMSPage": "mercure/composants/pagination",
              "preserveQs": "false"
            },
            {
              "label": "Sélecteurs de date",
              "visibleInSiteMap": "false",
              "visibleInBreadCrumbs": "false",
              "link": "/scp/fr/mercure/composants/selecteurs-de-date.page?",
              "linkCMSPage": "mercure/composants/selecteurs-de-date",
              "preserveQs": "false"
            },
            {
              "label": "Tableaux",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Tableaux",
              "link": "/scp/fr/mercure/composants/tableaux.page?",
              "linkCMSPage": "mercure/composants/tableaux",
              "preserveQs": "false"
            }
          ]
        },
        {
          "label": "Ressources",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "description": "resources",
          "link": "/scp/fr/mercure/ressources.page?",
          "linkCMSPage": "mercure/ressources",
          "preserveQs": "false"
        }
      ]
    },
    {
      "label": "Notre entreprise",
      "visibleInSiteMap": "true",
      "visibleInBreadCrumbs": "true",
      "preserveQs": "false",
      "description": "En savoir plus sur Postes Canada et ses alertes de service.",
      "link": "/scp/fr/notre-entreprise.page?",
      "linkCMSPage": "notre-entreprise",
      "nodes": [
        {
          "label": "À notre sujet",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "queryString": "%c3%80 notre sujet : aper%c3%a7u",
          "description": "Découvrir notre équipe de direction et les initiatives de la Société.",
          "link": "/scp/fr/notre-entreprise/a-notre-sujet.page?",
          "linkCMSPage": "notre-entreprise/a-notre-sujet",
          "nodes": [
            {
              "label": "Notre direction",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Découvrir nos équipes et nos pratiques de leadership. ",
              "link": "/scp/fr/notre-entreprise/a-notre-sujet/notre-direction.page?",
              "linkCMSPage": "notre-entreprise/a-notre-sujet/notre-direction",
              "nodes": [
                {
                  "label": "Équipe des cadres supérieurs",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "En apprendre davantage sur nos cadres supérieurs.",
                  "link": "/scp/fr/notre-entreprise/a-notre-sujet/notre-direction/equipe-cadres-superieurs.page?",
                  "linkCMSPage": "notre-entreprise/a-notre-sujet/notre-direction/equipe-cadres-superieurs",
                  "preserveQs": "false"
                },
                {
                  "label": "Gouvernance d’entreprise",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "preserveQs": "false",
                  "description": "Découvrir le Conseil d’administration, et nos principes et politiques.",
                  "link": "/scp/fr/notre-entreprise/a-notre-sujet/notre-direction/gouvernance-dentreprise.page?",
                  "linkCMSPage": "notre-entreprise/a-notre-sujet/notre-direction/gouvernance-dentreprise",
                  "nodes": [
                    {
                      "label": "Rôles au sein du Conseil d’administration",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/notre-entreprise/a-notre-sujet/notre-direction/gouvernance-dentreprise/role-du-conseil.page?",
                      "linkCMSPage": "notre-entreprise/a-notre-sujet/notre-direction/gouvernance-dentreprise/role-du-conseil",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Biographies des administrateurs",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/notre-entreprise/a-notre-sujet/notre-direction/gouvernance-dentreprise/directeurs-biographies.page?",
                      "linkCMSPage": "notre-entreprise/a-notre-sujet/notre-direction/gouvernance-dentreprise/directeurs-biographies",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Comités du Conseil d’administration",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/notre-entreprise/a-notre-sujet/notre-direction/gouvernance-dentreprise/directeurs-comites.page?",
                      "linkCMSPage": "notre-entreprise/a-notre-sujet/notre-direction/gouvernance-dentreprise/directeurs-comites",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Diversité du Conseil d’administration",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/notre-entreprise/a-notre-sujet/notre-direction/gouvernance-dentreprise/diversite-direction.page?",
                      "linkCMSPage": "notre-entreprise/a-notre-sujet/notre-direction/gouvernance-dentreprise/diversite-direction",
                      "preserveQs": "false"
                    }
                  ]
                },
                {
                  "label": "Politique sur les frais de déplacement et d’accueil",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "preserveQs": "false",
                  "description": "Explorer la politique de dépenses du Conseil et des cadres supérieurs.",
                  "link": "/scp/fr/notre-entreprise/a-notre-sujet/notre-direction/politique-frais-deplacement-et-accueil.page?",
                  "linkCMSPage": "notre-entreprise/a-notre-sujet/notre-direction/politique-frais-deplacement-et-accueil",
                  "nodes": [
                    {
                      "label": "Frais de déplacement et d’accueil",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/notre-entreprise/a-notre-sujet/notre-direction/politique-frais-de-deplacement-et-daccueil/frais-de-deplacement-et-daccueil.page?",
                      "linkCMSPage": "notre-entreprise/a-notre-sujet/notre-direction/politique-frais-de-deplacement-et-daccueil/frais-de-deplacement-et-daccueil",
                      "preserveQs": "false"
                    }
                  ]
                }
              ]
            },
            {
              "label": "Developpement durable",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Comment nous aidons les collectivités, notre personnel et la planète.",
              "link": "/scp/fr/notre-entreprise/a-notre-sujet/developpement-durable.page?",
              "linkCMSPage": "notre-entreprise/a-notre-sujet/developpement-durable",
              "nodes": [
                {
                  "label": "Responsabilité environnementale",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Lire sur nos efforts de développement durable et de conservation.",
                  "link": "/scp/fr/notre-entreprise/a-notre-sujet/developpement-durable/responsabilite-environnementale.page?",
                  "linkCMSPage": "notre-entreprise/a-notre-sujet/developpement-durable/responsabilite-environnementale",
                  "preserveQs": "false"
                },
                {
                  "label": "Accessibilité",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "preserveQs": "false",
                  "description": "Découvrir comment nous améliorons l’accessibilité de nos services.",
                  "link": "/scp/fr/notre-entreprise/a-notre-sujet/responsabilite-dentreprise/accessibilite.page",
                  "linkCMSPage": "notre-entreprise/a-notre-sujet/developpement-durable/accessibilite",
                  "nodes": [
                    {
                      "label": "Accessibilité en ligne",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/notre-entreprise/a-notre-sujet/responsabilite-dentreprise/accessibilite/accessibilite-numerique.page?",
                      "linkCMSPage": "notre-entreprise/a-notre-sujet/responsabilite-dentreprise/accessibilite/accessibilite-numerique",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Programme de mesures d’adaptation pour la livraison",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/notre-entreprise/a-notre-sujet/responsabilite-dentreprise/accessibilite/programme-adaptation-livraison.page?",
                      "linkCMSPage": "notre-entreprise/a-notre-sujet/responsabilite-dentreprise/accessibilite/programme-adaptation-livraison",
                      "preserveQs": "false"
                    },
                    {
                      "label": "Comité consultatif sur l’accessibilité",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/notre-entreprise/a-notre-sujet/responsabilite-dentreprise/accessibilite/comite-consultatif.page?",
                      "linkCMSPage": "notre-entreprise/a-notre-sujet/responsabilite-dentreprise/accessibilite/comite-consultatif",
                      "preserveQs": "false"
                    }
                  ]
                },
                {
                  "label": "Anciens rapports d'entreprise",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Accéder aux Rapports sur le développement durable antérieurs.",
                  "link": "/scp/fr/notre-entreprise/a-notre-sujet/responsabilite-dentreprise/rapports-entreprise-archives.page?",
                  "linkCMSPage": "notre-entreprise/a-notre-sujet/responsabilite-dentreprise/rapports-entreprise-archives",
                  "preserveQs": "false"
                },
                {
                  "label": "Réconciliation avec les Autochtones et le Nord",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Comment nous veillons à collaborer avec les communautés autochtones.",
                  "link": "/scp/fr/notre-entreprise/a-notre-sujet/developpement-durable/reconciliation-avec-les-autochtones.page?",
                  "linkCMSPage": "notre-entreprise/a-notre-sujet/developpement-durable/reconciliation-avec-les-autochtones",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Transparence et confiance",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Savoir comment nous protégeons vos renseignements et vous informons. ",
              "link": "/scp/fr/notre-entreprise/a-notre-sujet/transparence-et-confiance.page?",
              "linkCMSPage": "notre-entreprise/a-notre-sujet/transparence-et-confiance",
              "nodes": [
                {
                  "label": "Centre de protection de la vie privée",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Explorer notre Politique de confidentialité et nos pratiques connexes.",
                  "link": "/scp/fr/notre-entreprise/a-notre-sujet/transparence-et-confiance/centre-de-protection-de-la-vie-privee.page?",
                  "linkCMSPage": "notre-entreprise/a-notre-sujet/transparence-et-confiance/centre-de-protection-de-la-vie-privee",
                  "preserveQs": "false"
                },
                {
                  "label": "Accès à l’information",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/scp/fr/notre-entreprise/a-notre-sujet/transparence-et-confiance/acces-a-linformation.page?",
                  "linkCMSPage": "notre-entreprise/a-notre-sujet/transparence-et-confiance/acces-a-linformation",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Réglementation du gouvernement fédéral",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Lire la Loi sur la Société canadienne des postes.",
              "link": "/scp/fr/notre-entreprise/a-notre-sujet/reglementation.page?",
              "linkCMSPage": "notre-entreprise/a-notre-sujet/reglementation",
              "preserveQs": "false"
            },
            {
              "label": "Rapports financiers",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Consulter nos rapports annuels et rapports financiers trimestriels.",
              "link": "/scp/fr/notre-entreprise/a-notre-sujet/rapports-financiers.page?",
              "linkCMSPage": "notre-entreprise/a-notre-sujet/rapports-financiers",
              "nodes": [
                {
                  "label": "Rapports financiers trimestriels ",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Voir nos rapports financiers trimestriels d’hier et d’aujourd’hui.",
                  "link": "/scp/fr/notre-entreprise/a-notre-sujet/rapports-financiers/rapports-financiers-trimestriels.page?",
                  "linkCMSPage": "notre-entreprise/a-notre-sujet/rapports-financiers/rapports-financiers-trimestriels",
                  "preserveQs": "false"
                },
                {
                  "label": "Rapport annuel 2021",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Consulter le rapport annuel le plus récent.",
                  "link": "/scp/fr/notre-entreprise/a-notre-sujet/rapports-financiers/rapport-annuel-2022/un-canada-plus-fort.page?",
                  "linkCMSPage": "notre-entreprise/a-notre-sujet/rapports-financiers/rapport-annuel-2022/un-canada-plus-fort",
                  "preserveQs": "false"
                }
              ]
            }
          ]
        },
        {
          "label": "Investir dans nos collectivités ",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "queryString": "Investir dans nos collectivit%c3%a9s : aper%c3%a7u",
          "description": "En savoir plus sur nos subventions, prix et activités pour enfants.",
          "link": "/scp/fr/notre-entreprise/investir-dans-nos-collectivites.page?",
          "linkCMSPage": "notre-entreprise/investir-dans-nos-collectivites",
          "nodes": [
            {
              "label": "Fondation communautaire de Postes Canada",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "En savoir plus sur les subventions de notre Fondation communautaire.",
              "link": "/scp/fr/notre-entreprise/investir-dans-nos-collectivites/fondation-communautaire-de-postes-canada.page?",
              "linkCMSPage": "notre-entreprise/investir-dans-nos-collectivites/fondation-communautaire-de-postes-canada",
              "nodes": [
                {
                  "label": "Demande de subvention de la Fondation communautaire",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Comment faire une demande de subvention de la Fondation communautaire.",
                  "link": "/scp/fr/notre-entreprise/investir-dans-nos-collectivites/fondation-communautaire-de-postes-canada/demande-de-subvention-de-la-fondation-communautaire.page?",
                  "linkCMSPage": "notre-entreprise/investir-dans-nos-collectivites/fondation-communautaire-de-postes-canada/demande-de-subvention-de-la-fondation-communautaire",
                  "preserveQs": "false"
                },
                {
                  "label": "Administrateurs de la Fondation communautaire",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "En savoir plus sur les administrateurs de la Fondation communautaire.",
                  "link": "/scp/fr/notre-entreprise/investir-dans-nos-collectivites/fondation-communautaire-de-postes-canada/administrateurs-fondation-communautaire.page?",
                  "linkCMSPage": "notre-entreprise/investir-dans-nos-collectivites/fondation-communautaire-de-postes-canada/administrateurs-fondation-communautaire",
                  "preserveQs": "false"
                },
                {
                  "label": "Bénéficiaires de la Fondation communautaire",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Découvrir les projets communautaires subventionnés.",
                  "link": "/scp/fr/notre-entreprise/investir-dans-nos-collectivites/fondation-communautaire-de-postes-canada/beneficiaires-fondation-communautaire.page?",
                  "linkCMSPage": "notre-entreprise/investir-dans-nos-collectivites/fondation-communautaire-de-postes-canada/beneficiaires-fondation-communautaire",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": " Prix de Postes Canada pour les Autochtones aux études",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "En savoir plus sur les prix d’études à l’intention des Autochtones. ",
              "link": "/scp/fr/notre-entreprise/investir-dans-nos-collectivites/prix-postes-canada-autochtones-aux-etudes.page?",
              "linkCMSPage": "notre-entreprise/investir-dans-nos-collectivites/prix-postes-canada-autochtones-aux-etudes",
              "nodes": [
                {
                  "label": "Gagnants des prix d’études",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Voir les anciens lauréats des prix d’études pour les Autochtones.",
                  "link": "/scp/fr/notre-entreprise/investir-dans-nos-collectivites/prix-postes-canada-autochtones-aux-etudes/laureat-prix-etudes.page?",
                  "linkCMSPage": "notre-entreprise/investir-dans-nos-collectivites/prix-postes-canada-autochtones-aux-etudes/laureat-prix-etudes",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Écrire une lettre au père Noël",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Envoyer une lettre au pôle Nord et obtenir des conseils à ce sujet.",
              "link": "/scp/fr/notre-entreprise/investir-dans-nos-collectivites/ecris-une-lettre-au-perenoel.page?",
              "linkCMSPage": "notre-entreprise/investir-dans-nos-collectivites/ecris-une-lettre-au-perenoel",
              "nodes": [
                {
                  "label": "Conseils à l’intention des parents pour les lettres au père Noël",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Obtenir des modèles de lettre et des idées pour écrire au père Noël.",
                  "link": "/scp/fr/notre-entreprise/investir-dans-nos-collectivites/ecris-une-lettre-au-perenoel/programme-perenoel-parents.page?",
                  "linkCMSPage": "notre-entreprise/investir-dans-nos-collectivites/ecris-une-lettre-au-perenoel/programme-perenoel-parents",
                  "preserveQs": "false"
                },
                {
                  "label": "Conseils à l’intention des enseignants pour les lettres au père Noël",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Envoyer une lettre de toute la classe au père Noël.",
                  "link": "/scp/fr/notre-entreprise/investir-dans-nos-collectivites/ecris-une-lettre-au-perenoel/programme-perenoel-professeurs.page?",
                  "linkCMSPage": "notre-entreprise/investir-dans-nos-collectivites/ecris-une-lettre-au-perenoel/programme-perenoel-professeurs",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Activités du service postal pour les jeunes",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Télécharger nos modèles de lettres et fiches d’activités à imprimer.",
              "link": "/scp/fr/notre-entreprise/investir-dans-nos-collectivites/activites-service-postal-jeunes.page?",
              "linkCMSPage": "notre-entreprise/investir-dans-nos-collectivites/activites-service-postal-jeunes",
              "preserveQs": "false"
            }
          ]
        },
        {
          "label": "Emplois",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "queryString": "Emplois : aper%c3%a7u",
          "description": "Voir les possibilités d’emploi actuelles.",
          "link": "/scp/fr/notre-entreprise/emplois.page?",
          "linkCMSPage": "notre-entreprise/emplois",
          "nodes": [
            {
              "label": "Postuler pour les possibilités d’emploi actuelles",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Voir les postes vacants à Postes Canada.",
              "link": "https://jobs.canadapost.ca/go/Postes-Canada-Toutes-les-opportunit%C3%A9s-d%E2%80%99emploi/2320517/",
              "target": "_blank",
              "preserveQs": "false"
            }
          ]
        },
        {
          "label": "Occasions d’affaires ",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "queryString": "Occasions d%e2%80%99affaires : aper%c3%a7u",
          "description": "En savoir plus sur les propositions de contrats et de partenariats.",
          "link": "/scp/fr/notre-entreprise/occasion-affaires.page?",
          "linkCMSPage": "notre-entreprise/occasion-affaires",
          "nodes": [
            {
              "label": "Contrats pour votre entreprise",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Collaborer avec nous et obtenir des contrats pour votre entreprise.",
              "link": "/scp/fr/notre-entreprise/occasion-affaires/contrats-pour-votre-entreprise.page?",
              "linkCMSPage": "notre-entreprise/occasion-affaires/contrats-pour-votre-entreprise",
              "nodes": [
                {
                  "label": "Contrats de biens et services",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Parcourir les contrats de biens et services, et soumissionner.",
                  "link": "/scp/fr/notre-entreprise/occasion-affaires/contrats-pour-votre-entreprise/contrats-biens-et-services.page?",
                  "linkCMSPage": "notre-entreprise/occasion-affaires/contrats-pour-votre-entreprise/contrats-biens-et-services",
                  "preserveQs": "false"
                },
                {
                  "label": "Contrats de transport",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Soumissionner à des contrats de transport aérien et terrestre.",
                  "link": "/scp/fr/notre-entreprise/occasion-affaires/contrats-pour-votre-entreprise/contrats-transport.page?",
                  "linkCMSPage": "notre-entreprise/occasion-affaires/contrats-pour-votre-entreprise/contrats-transport",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Partenaires de vente au détail autorisés",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "description": "Offrir des produits et services postaux dans votre entreprise.",
              "link": "/scp/fr/notre-entreprise/occasion-affaires/devenir-partenaire-autorise.page?",
              "linkCMSPage": "notre-entreprise/occasion-affaires/devenir-partenaire-autorise",
              "preserveQs": "false"
            }
          ]
        },
        {
          "label": "Nouvelles et médias",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "queryString": "Nouvelles et medias : aper%c3%a7u",
          "description": "Voir les mises à jour du service postal et les images pour les médias.",
          "link": "/scp/fr/notre-entreprise/nouvelles-et-medias.page?",
          "linkCMSPage": "notre-entreprise/nouvelles-et-medias",
          "nodes": [
            {
              "label": "Alertes sur le service de livraison",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Voir les mises à jour sur les interruptions de livraison du courrier.",
              "link": "/scp/fr/notre-entreprise/nouvelles-et-medias/alertes-service-livraison.page?",
              "linkCMSPage": "notre-entreprise/nouvelles-et-medias/alertes-service-livraison",
              "nodes": [
                {
                  "label": "Archive des alertes sur le service de livraison",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Consulter les alertes de service antérieures.",
                  "link": "/scp/fr/notre-entreprise/nouvelles-et-medias/alertes-service-livraison/archive-alertes-service-livraison.page?",
                  "linkCMSPage": "notre-entreprise/nouvelles-et-medias/alertes-service-livraison/archive-alertes-service-livraison",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "Nouvelles de la Société",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Voir les communiqués et mises à jour de Postes Canada.",
              "link": "/scp/fr/notre-entreprise/nouvelles-et-medias/nouvelles-de-la-societe.page?",
              "linkCMSPage": "notre-entreprise/nouvelles-et-medias/nouvelles-de-la-societe",
              "nodes": [
                {
                  "label": "Communiqués",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Consulter nos communiqués récents et antérieurs.",
                  "link": "/scp/fr/notre-entreprise/nouvelles-et-medias/nouvelles-de-la-societe/liste-communiques.page?",
                  "linkCMSPage": "notre-entreprise/nouvelles-et-medias/nouvelles-de-la-societe/liste-communiques",
                  "preserveQs": "false"
                },
                {
                  "label": "Fermetures et interruptions",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Lire sur les conditions météo et jours fériés touchant la livraison.",
                  "link": "/scp/fr/notre-entreprise/nouvelles-et-medias/nouvelles-de-la-societe/fermetures-interruptions-liste.page?",
                  "linkCMSPage": "notre-entreprise/nouvelles-et-medias/nouvelles-de-la-societe/fermetures-interruptions-liste",
                  "preserveQs": "false"
                },
                {
                  "label": "Mise à jour sur les négociations",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Se renseigner sur les négociations avec nos syndicats.",
                  "link": "/scp/fr/notre-entreprise/nouvelles-et-medias/nouvelles-de-la-societe/liste-negotiations.page?",
                  "linkCMSPage": "notre-entreprise/nouvelles-et-medias/nouvelles-de-la-societe/liste-negotiations",
                  "preserveQs": "false"
                },
                {
                  "label": "Mises à jour sur la COVID-19",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "preserveQs": "false",
                  "description": "Connaître les répercussions de la COVID-19 sur les envois postaux.",
                  "link": "/scp/fr/notre-entreprise/nouvelles-et-medias/nouvelles-de-la-societe/maladie-coronavirus-covid-19.page?",
                  "linkCMSPage": "notre-entreprise/nouvelles-et-medias/nouvelles-de-la-societe/maladie-coronavirus-covid-19",
                  "nodes": [
                    {
                      "label": "Foire aux questions sur la COVID-19 ",
                      "visibleInSiteMap": "true",
                      "visibleInBreadCrumbs": "true",
                      "link": "/scp/fr/notre-entreprise/nouvelles-et-medias/nouvelles-de-la-societe/maladie-a-coronavirus-19/maladie-a-coronavirus-19-faq.page?",
                      "linkCMSPage": "notre-entreprise/nouvelles-et-medias/nouvelles-de-la-societe/maladie-a-coronavirus-19/maladie-a-coronavirus-19-faq",
                      "preserveQs": "false"
                    }
                  ]
                }
              ]
            },
            {
              "label": "Centre des médias",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "description": "Accéder aux images, logos et vidéos officiels de Postes Canada.",
              "link": "/scp/fr/notre-entreprise/nouvelles-et-medias/centre-media.page?",
              "linkCMSPage": "notre-entreprise/nouvelles-et-medias/centre-media",
              "nodes": [
                {
                  "label": "Galerie de photos",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Parcourir les images officielles de l’entreprise et en télécharger.",
                  "link": "/scp/fr/notre-entreprise/nouvelles-et-medias/centre-media/galerie-photos.page?",
                  "linkCMSPage": "notre-entreprise/nouvelles-et-medias/centre-media/galerie-photos",
                  "preserveQs": "false"
                },
                {
                  "label": "Rouleaux B",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Voir et télécharger les rouleaux B sur nos installations et produits.",
                  "link": "/scp/fr/notre-entreprise/nouvelles-et-medias/centre-media/rouleaux-b.page?",
                  "linkCMSPage": "notre-entreprise/nouvelles-et-medias/centre-media/rouleaux-b",
                  "preserveQs": "false"
                },
                {
                  "label": "Logos de Postes Canada",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "description": "Accéder au logo de l’entreprise et aux lignes directrices connexes.",
                  "link": "/scp/fr/notre-entreprise/nouvelles-et-medias/centre-media/logos-postes-canada.page?",
                  "linkCMSPage": "notre-entreprise/nouvelles-et-medias/centre-media/logos-postes-canada",
                  "preserveQs": "false"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "label": "Campaign",
      "visibleInSiteMap": "true",
      "visibleInBreadCrumbs": "false",
      "preserveQs": "false",
      "link": "/scp/fr/accueil.page?",
      "linkCMSPage": "",
      "nodes": [
        {
          "label": "asducommerceenligne",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "link": "/scp/fr/accueil.page?",
          "linkCMSPage": "",
          "nodes": [
            {
              "label": "Mener",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/entreprise/marketing/campagne/asducommerceenligne/menerlacourse.page?",
              "linkCMSPage": "entreprise/marketing/campagne/asducommerceenligne/menerlacourse",
              "preserveQs": "false"
            },
            {
              "label": "Croître",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/entreprise/marketing/campagne/asducommerceenligne/allerplusloin.page?",
              "linkCMSPage": "entreprise/marketing/campagne/asducommerceenligne/allerplusloin",
              "preserveQs": "false"
            },
            {
              "label": "S’inspirer",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/entreprise/marketing/campagne/asducommerceenligne/succesinspirants.page?",
              "linkCMSPage": "entreprise/marketing/campagne/asducommerceenligne/succesinspirants",
              "preserveQs": "false"
            },
            {
              "label": "Approfondir",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/entreprise/marketing/campagne/asducommerceenligne/meilleurespratiques.page?",
              "linkCMSPage": "entreprise/marketing/campagne/asducommerceenligne/meilleurespratiques",
              "preserveQs": "false"
            },
            {
              "label": "Briller",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/entreprise/marketing/campagne/asducommerceenligne/briller.page?",
              "linkCMSPage": "entreprise/marketing/campagne/asducommerceenligne/briller",
              "preserveQs": "false"
            }
          ]
        },
        {
          "label": "Belles-histoires",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "link": "/scp/fr/accueil.page?",
          "linkCMSPage": "",
          "nodes": [
            {
              "label": "Précisions",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "link": "/scp/fr/accueil.page?",
              "linkCMSPage": "",
              "nodes": [
                {
                  "label": "Catégories et prix",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/scp/fr/entreprise/marketing/campagne/belles-histoires/precisions/categories-et-prix.page",
                  "preserveQs": "false"
                },
                {
                  "label": "Modalités de participation et calendrier",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/scp/fr/entreprise/marketing/campagne/belles-histoires/precisions/participation-et-calendrier.page",
                  "preserveQs": "false"
                },
                {
                  "label": "Jury",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/scp/fr/entreprise/marketing/campagne/belles-histoires/precisions/rencontrer-les-membres-du-jury.page",
                  "preserveQs": "false"
                },
                {
                  "label": "Finalistes",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/scp/fr/entreprise/marketing/campagne/belles-histoires/precisions/voici-les-finalistes.page",
                  "preserveQs": "false"
                },
                {
                  "label": "Entreprises gagnantes de 2021",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/scp/fr/entreprise/marketing/campagne/belles-histoires/precisions/gagnants-de-2021.page",
                  "preserveQs": "false"
                },
                {
                  "label": "Entreprises gagnantes de 2020",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/scp/fr/entreprise/marketing/campagne/belles-histoires/precisions/gagnants-de-2020.page",
                  "preserveQs": "false"
                }
              ]
            },
            {
              "label": "FAQ",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/entreprise/marketing/campagne/belles-histoires/faq.page",
              "preserveQs": "false"
            },
            {
              "label": "Règlement",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/entreprise/marketing/campagne/belles-histoires/modalites.page",
              "preserveQs": "false"
            }
          ]
        },
        {
          "label": "Petite-entreprise-acceuil",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "link": "/scp/fr/accueil.page?",
          "linkCMSPage": "",
          "nodes": [
            {
              "label": "Expédiez",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/entreprise/marketing/campagne/petite-entreprise-acceuil/expedition-pour-petites-entreprises.page",
              "preserveQs": "false"
            },
            {
              "label": "Faites connaître votre entreprise",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/entreprise/marketing/campagne/petite-entreprise-acceuil/faites-connaitre-votre-entreprise.page",
              "preserveQs": "false"
            },
            {
              "label": "Vente en ligne",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/entreprise/marketing/campagne/petite-entreprise-acceuil/vente-en-ligne.page",
              "preserveQs": "false"
            }
          ]
        },
        {
          "label": "Resume-cybercommerce",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "link": "/scp/fr/accueil.page?",
          "linkCMSPage": "",
          "nodes": [
            {
              "label": "Télécharger",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "preserveQs": "false",
              "link": "/scp/fr/accueil.page?",
              "linkCMSPage": "",
              "nodes": [
                {
                  "label": "Perspectives du marché",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/scp/doc/fr/campagne/2021/resume-cybercommerc/perspectives-du-marche.pdf?icid=cta_int_bc_1697_tlael",
                  "target": "_blank",
                  "preserveQs": "false"
                },
                {
                  "label": "Les attentes de la clientèle en matière de livraison",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/info/cg/app/personnel/miniformulaires/guidefraisdelivraison.jsf",
                  "target": "_blank",
                  "preserveQs": "false"
                },
                {
                  "label": "La cueillette en bordure de magasin",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/info/cg/app/personnel/miniformulaires/cueilletteborduredemagasin.jsf",
                  "target": "_blank",
                  "preserveQs": "false"
                },
                {
                  "label": "Le parcours d’achat moderne",
                  "visibleInSiteMap": "true",
                  "visibleInBreadCrumbs": "true",
                  "link": "/info/cg/app/personnel/miniformulaires/guide-cybercommerce.jsf",
                  "target": "_blank",
                  "preserveQs": "false"
                }
              ]
            }
          ]
        },
        {
          "label": "Magazine-inspiractions",
          "visibleInSiteMap": "true",
          "visibleInBreadCrumbs": "true",
          "preserveQs": "false",
          "link": "/scp/fr/entreprise/marketing/campagne/magazine-inspiractions.page",
          "nodes": [
            {
              "label": "Études de cas",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/entreprise/marketing/campagne/inspiractions/etudes-de-cas.page?icid=cta_int_rs_404",
              "preserveQs": "false"
            },
            {
              "label": "Vidéos",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/entreprise/marketing/campagne/inspiractions/videos.page?icid=cta_int_rs_406",
              "preserveQs": "false"
            },
            {
              "label": "Le publipostage en chiffres",
              "visibleInSiteMap": "true",
              "visibleInBreadCrumbs": "true",
              "link": "/scp/fr/entreprise/marketing/campagne/inspiractions/publipostage-en-chiffres.page?icid=cta_int_rs_408",
              "preserveQs": "false"
            }
          ]
        }
      ]
    }
  ]
};
