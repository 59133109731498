/* eslint no-console: 0 */
/**
 * Components have their own -module.js file, where you import
 * everything that they require - a bit cleaner to include Components
 * in the main app.js.
 */
import defineWidget from './footer.component';

export default function (lang) {
  return defineWidget(lang);
}