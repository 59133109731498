/* eslint no-console: 0 */
/**
 * Components have their own -module.js file, where you import
 * everything that they require - a bit cleaner to include Components
 * in the main app.js.
 */
import defineWidget from './header.component';
import initializeComponent from './mobile/mobile-nav.component';

initializeComponent();


export default function (lang) {
  return defineWidget(lang);
}