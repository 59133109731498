// dependencies
//
import sitemapEn from './sitemap-en';
import sitemapFr from './sitemap-fr';
import locale from './locale';
import '../../../utils/polyfills/object.entries';

const $ = window.jQuery;

const locales = {
  en: {
    label_support: 'Support'
  },
  fr: {
    label_support: 'Soutien'
  }
};

/**
 * CPC sitemap service, retrieves the sitemap from backend.
 */
function Sitemap() {
  // make a little cache just in case somewhere may call this multiple times.
  const sitemaps = {
    // TODO:
    //
    // We temporarily bundle the sitemap(En/Fr) as there is a cross domain call
    // issue in applications which are not under 'canadapost.ca'. For long term,
    // we should have a better way to expose the sitemap.
    //
    // Currently, the limitation is when sitemap changed, we have to redeploy
    // cwc.js
    //
    en: sitemapEn,
    fr: sitemapFr
  };

  let utilityNode;
  let supportNode;
  const nodeMap = {};

  return {
    getPersonalNodeMap,
    getBusinessNodeMap,
    getSupportNode,
    getUtilityNode,
    fetch: doFetch
  };

  function doFetch(lang, callback) {
    if (sitemaps[lang]) {
      callback(sitemaps[lang]);
      return;
    }

    //
    // The below code has not been used due to there is cross domain issue.
    // We currently using the bundled sitemap-en/fr.js. TODO: Fix this
    //
    // temporary hard code the URL
    $.get(`/cpc/${lang}/personal/sitemap.page`, (data) => {
      sitemaps[lang] = lsHtmlJsonHandler(data);
      callback(sitemaps[lang]);
    });
  }

  /**
   * It looking for '<div id="sitemap-json" style="display:none">THE_JSON_IS_HERE</div>'
   * in the response HTML and strip out the JSON string.
   */
  function lsHtmlJsonHandler(html) {
    const search = '<div id\\=\\"sitemap\\-json\\" style\\=\\"display\\:none\\">([\\S\\s.]*?)<\\/div>';
    const result = new RegExp(search, 'gi').exec(html);
    if (result === null) {
      console.error('ERR_SITEMAP_INVALID_JSON_RESPONSE');
      return undefined;
    }

    return JSON.parse(result[1]);
  }

  /**
   * Searching for support node
   * @param lang - langugae
   * @param callback - The callback will be called with the found support node
   */
  function getSupportNode(lang, callback) {
    if (supportNode !== undefined) {
      callback(supportNode);
      return;
    }

    getUtilityNode(lang, (_utilityNode) => {
      if (_utilityNode === undefined) throw new Error('ERR_SITEMAP_UTILITY_NODE_UNDEFINED');

      supportNode = _utilityNode.nodes.find(node => node.label === locales[lang].label_support);
      if (supportNode === undefined) console.warn('ERR_SITEMAP_SUPPORT_NODE_UNDEFINED');
      callback(supportNode);
    });
  }

  /**
   * Searching for utility node
   *
   * @param lang
   * @param callback - The callback will be called with the found utility node
   */
  function getUtilityNode(lang, callback) {
    if (utilityNode !== undefined) {
      callback(utilityNode);
      return;
    }

    doFetch(lang, (sitemap) => {
      if (sitemap === undefined) throw new Error('ERR_SITEMAP_UNDEFINED');

      utilityNode = sitemap.nodes.find(node => node.label === 'Utility');
      if (utilityNode === undefined) console.warn('ERR_SITEMAP_UTILITY_NODE_UNDEFINED');
      callback(utilityNode);
    });
  }

  function getPersonalNodeMap(lang, callback) {
    getNodeMap(lang === 'en' ? 'Personal' : 'Personnel', lang, callback);
  }

  function getBusinessNodeMap(lang, callback) {
    getNodeMap(lang === 'en' ? 'Business' : 'Entreprise', lang, callback);
  }

  function getNodeMap(nodeName, lang, callback) {
    if (nodeMap[nodeName] !== undefined) {
      callback(nodeMap[nodeName]);
      return;
    }

    doFetch(lang, (sitemap) => {
      if (sitemap === undefined) throw new Error('ERR_SITEMAP_UNDEFINED');

      const theNode = sitemap.nodes.find(node => node.label === nodeName);
      if (theNode === undefined) {
        console.warn(`'WARN_SITEMAP_${nodeName.toUpperCase()}_NODE_UNDEFINED`);
        callback(undefined);
        return;
      }

      nodeMap[nodeName] = createNodeMap(nodeName.toLowerCase(), theNode);
      callback(nodeMap[nodeName]);
    });
  }

  function createNodeMap(nodeId, theNode) {
    const labelEntries = Object.entries(locale.getString(nodeId));
    const mp = {};
    theNode.nodes.forEach((node) => {
      // it's little pain here
      for (let i = 0; i < labelEntries.length; i += 1) {
        if (labelEntries[i][1] === htmlDecode(node.label)) {
          mp[labelEntries[i][0]] = node;
          break;
        }
      }
    });

    return mp;
  }

  function htmlDecode(input) {
    return domParser.parseFromString(input, 'text/html').documentElement.textContent;
  }
}
const domParser = new DOMParser();

// The singleton
let singleton;

/**
 * Get the global wide singleton sitemap instance
 *
 * @returns {*}
 */
Sitemap.getInstance = function getInstance() {
  if (singleton === undefined) singleton = new Sitemap();
  return singleton;
};

/**
 * A convenient method for fetching sitemap
 *
 * @param lang - language
 * @param callback - The callback will be called with fetched sitemap
 */
Sitemap.get = function get(lang, callback) {
  return Sitemap.getInstance().fetch(lang, callback);
};

/**
 * A convenient method for finding the support node in sitemap
 *
 * @param lang - language
 * @param callback - The callback will be called with the found support node
 */
Sitemap.supportNode = function supportNode(lang, callback) {
  Sitemap.getInstance().getSupportNode(lang, callback);
};

/**
 * A convenient method for finding the utility node in sitemap
 *
 * @param lang - language
 * @param callback - The callback will be called with the found utility node
 */
Sitemap.utilityNode = function utilityNode(lang, callback) {
  Sitemap.getInstance().getUtilityNode(lang, callback);
};

/**
 * A convenient method for finding the personal node in sitemap
 *
 * @param lang - language
 * @param callback - The callback will be called with the found personal node
 */
Sitemap.personalNodeMap = function utilityNode(lang, callback) {
  Sitemap.getInstance().getPersonalNodeMap(lang, callback);
};

/**
 * A convenient method for finding the business node in sitemap
 *
 * @param lang - language
 * @param callback - The callback will be called with the found business node
 */
Sitemap.businessNodeMap = function utilityNode(lang, callback) {
  Sitemap.getInstance().getBusinessNodeMap(lang, callback);
};

export default Sitemap;