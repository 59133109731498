/* eslint-disable no-undef */
import CpcOption from './option';
import PasswordToggles from './password';
import CpcSelects from './select';

//
// The place to define, initialize all control related APIs. The way to consume those API is through
// the global cpc object. For example: to call checkbox's focus handler function, use the below:
//
//       cpc.lib.controlOptionFocusHandler();
//
class CpcControls {
  static api = {
    // ----------------------------------------------------------------------------------
    // The focus handler for radio and checkbox. Can be directly called as:
    //
    //   cpc.lib.controlOptionFocusHandler();
    //
    // Or can be attached as an event listener be executed when the event triggered:
    //
    //   elem.addEventListener('DOMContentLoaded', cpc.lib.controlOptionFocusHandler);
    //
    controlOptionFocusHandler: CpcOption.focusHandler,

    // ---------------------------------------------------------------
    // Create password toggles and return an Array of toggles
    //
    //    const toggles = cpc.lib.initPasswordToggles();
    //
    // Which returns a map of password toggles keyed with the value of 'data-pwd-toggle-for'.
    // The map can be iterated through to get each toggle. You can call toggle's toggle()
    // method to programmatically display/hide password. For example:
    //
    //  toggles['password_1'].toggle();
    //
    initPasswordToggles: PasswordToggles.init,

    // ---------------------------------------------------------------
    // Create CpcSelect object on the page. Return a map
    // of the initialized CpcSelect object, keyed with the native
    // select element's id, or an auto generated id if the native
    // select element missing the id attribute.
    //
    //     const cpcSelects = cpc.lib.initSeletecs();
    //
    initSelects: CpcSelects.init
  };

  // defined which will be initialized when CpcControls.init() called
  static initializables = [
    'controlOptionFocusHandler',
    'initPasswordToggles',
    'initSelects'
  ];

  static cpc;

  static init() {
    if (!CpcControls.cpc) {
      window.cpc = window.cpc || {};
      CpcControls.cpc = window.cpc;
      CpcControls.cpc.lib = CpcControls.api;
    }

    for (let i = 0; i < CpcControls.initializables.length; i += 1) {
      const api = CpcControls.initializables[i];
      CpcControls.cpc.lib[api]();
    }
  }
}

document.addEventListener('DOMContentLoaded', CpcControls.init);
