/*
 * For IA-4312: Quick n dirty script that adds Ship Online tool card to legacy tools page.
 * TODO: remove this file along with the import statement in cpc-cwc.js once the legacy tools page
 * is retired and replaced with the new tool page
 */

function createShipOnlineLegacyTool(lang) {
  // IA-4669 add an extra condition to if state to fix a bug.
  if (window.location.href.indexOf('tools') !== -1 && document.querySelectorAll('.toolmenu').length > 0) {
    const toolmenus = document.querySelectorAll('.toolmenu');
    const panels = toolmenus[1].querySelectorAll('.panel');
    const snapShip = panels[0];
    const snapShipClone = snapShip.cloneNode(true);
    snapShipClone.setAttribute('href', 
      `${window.location.href.substring(0, window.location.href.indexOf('canadapost.ca')).replace('www.', '')}est.canadapost-postescanada.ca/esto/app/snapship?locale=${lang}_CA`);
    snapShipClone.setAttribute('data-cpc-modal-secure-cta', 'shipping');
    snapShipClone.classList.add('cpc-modal-secure-default');
    snapShipClone.addEventListener('click', () => {
      const checkExist = setInterval(() => {
        const tingleModal = document.querySelector('.tingle-modal');
        if (tingleModal) {
          tingleModal.classList.add('cpc-component');
          tingleModal.classList.add('tingle-modal--overflow');
          // fun with iOS Safari
          document.body.style.overflow = 'hidden'; 
          document.body.style.height = '100%';
          document.body.style.width = '100%';
          document.body.style.position = 'fixed';
          tingleModal.querySelector('.tingle-modal-box__close-icon').addEventListener('click', () => {
            document.body.removeAttribute('style');
            tingleModal.parentNode.removeChild(tingleModal);
          });
          // end of fun
          if (lang === 'fr') {
            document.getElementById('cpc-dialog-description-id').innerHTML = 'Ouvrez une session dans votre compte Postes Canada ou postel<sup>MC</sup> pour cr&eacute;er une &eacute;tiquette d’exp&eacute;dition &agrave; la maison. Utilisez le service Exp&eacute;dier en ligne pour envoyer un colis n&apos;importe o&ugrave; au Canada.';
            document.querySelector('.signup-text').innerHTML = 'Ouvrez un compte en ligne pour utiliser l&apos;outil Exp&eacute;dier en ligne, sauvegarder vos articles rep&eacute;rables, recevoir vos factures et vos relev&eacute;s par l&apos;entremise de postel et acc&eacute;der &agrave; votre tableau de bord personnalis&eacute;. C&apos;est simple et rapide!';
          } else {
            document.getElementById('cpc-dialog-description-id').innerHTML = 'Sign in to your Canada Post or epost<sup>TM</sup> account and you&apos;re ready to create a shipping label at home. Use Ship Online to send a parcel anywhere in Canada.';
            document.querySelector('.signup-text').innerHTML = 'Create an online account to use Ship Online, save your tracked items, get bills and statements through epost and access your personalized Dashboard. Fast and easy in a few simple steps!';
          }
          const signInModal = document.querySelector('.sign-in-modal-content');
          signInModal.querySelector('.cpc-sign-in-footnotes').style.display = 'none';
          const modalForm = signInModal.querySelector('form');
          modalForm.setAttribute('action', cleanupURL(modalForm.getAttribute('action')));
          const signUpBtn = signInModal.querySelector('.sso_link.button');
          signUpBtn.setAttribute('href', cleanupURL(signUpBtn.getAttribute('href')));
          clearInterval(checkExist);
        }
      }, 100);
    });
    if (lang === 'fr') {
      snapShip.querySelector('p').innerHTML = 'Outil d&#x2019;exp&#xE9;dition pour les petites entreprises. Imprimez des &#xE9;tiquettes et obtenez les tarifs en cinq minutes.';      
      snapShipClone.querySelector('h5').innerHTML = 'Exp&eacute;dier en ligne';
      snapShipClone.querySelector('p').innerHTML = 'NOUVEL outil. Cr&#xE9;ez une &#xE9;tiquette d&#x2019;exp&#xE9;dition &#xE0; la maison pour envoyer un colis au Canada.';
      panels[1].querySelector('p').innerHTML = 'Imprimez des &#xE9;tiquettes et pr&#xE9;parez &#xE0; la maison vos colis &#xE0; exp&#xE9;dier au Canada.';
      panels[2].querySelector('p').innerHTML = 'Les clients commerciaux peuvent pr&#xE9;parer en ligne des envois simples &#xE0; exp&#xE9;dier &#xE0; l&#x2019;&#xE9;tranger.';
      panels[3].querySelector('h5').innerHTML = 'Logiciel OEE 2.0';
      panels[3].querySelector('p').innerHTML = 'T&#xE9;l&#xE9;chargez le logiciel d&#x2019;exp&#xE9;dition sur votre bureau. Pr&#xE9;parez plusieurs envois commerciaux vers des destinations internationales.';
      panels[4].querySelector('p').innerHTML = 'Effectuez un envoi simple de mani&#xE8;re rapide et facile au Canada. Pour les clients commerciaux.';
    } else {
      snapShip.querySelector('p').innerHTML = 'Ideal tool for small business shipping. Print labels and get rates in only 5 minutes.';      
      snapShipClone.querySelector('h5').innerHTML = 'Ship Online';
      snapShipClone.querySelector('p').innerHTML = 'NEW consumer shipping tool. Create a shipping label at home to send a package anywhere in Canada.';
      panels[1].querySelector('p').innerHTML = 'Print labels and prepare a shipment at home for shipping in Canada.';
      panels[2].querySelector('p').innerHTML = 'Business customers can prepare single online shipments anywhere in the world.';
      panels[3].querySelector('p').innerHTML = 'Download shipping software to your desktop. Prepare multiple business orders to worldwide destinations.';
      panels[4].querySelector('p').innerHTML = 'Quick and simple single shipments within Canada. For business customers.';
    }  
    
    const snapShipCloneContainer = document.createElement('div');
    snapShipCloneContainer.classList.add('columns');
    snapShipCloneContainer.appendChild(snapShipClone);
    snapShip.parentNode.parentNode.insertBefore(snapShipCloneContainer, 
      snapShip.parentNode.nextSibling);
  }
}

function cleanupURL(url) {
  const returnUrl = url.replace('stepupId=smb_mode1,commercial_link,smb_link', 'stepupId=consumer')
    .replace('manifestId=bizSecurity', 'manifestId=consrSecurity')
    .replace('sourceApp=SMB', 'sourceApp=ShipOnline');
  return returnUrl;
}

export default createShipOnlineLegacyTool;