/* eslint-disable no-undef */
/**
 * When using keyboard letting radio or checkbox get focus, we require to show the focus ring.
 * We don't want to show the focus ring if the focus triggered by mouse click. Currently there
 * is not way to implement it using pure CSS. This is what this class for.
 */
class CpcOption {
  static SELECTOR_CPC_CONTROL_OPTION = '.cpc-control-option';
  static CLASS_LABEL_ONCLICK = 'cpc-control-option--clicked';
  static CLASS_INPUT_ONFOCUS_BY_KEYBOARD = 'cpc-control-option--focused-by-keyboard';

  // keep track the instance so we can un-register event handler if we want to
  // static inst = undefined;

  // Convenient method called by outside, simply called as:
  //
  //     CpcOption.focusHandler();
  //
  // will make it start to function. It can also be attached as event listener as:
  //
  //     elem.attachEventListener('DOMContentLoaded', CpcOption.focusHandler);
  //
  static focusHandler() {
    new CpcOption().init();
  }

  constructor() {
    this.optionElms = document.querySelectorAll(CpcOption.SELECTOR_CPC_CONTROL_OPTION);
  }

  init() {
    this.registerEventHandler();
  }

  static onLabelClick(e) {
    // Add this flag when click, so when the input getting focus, it can check this
    // flag to determine the focus is triggered by mouse click, not other sources.
    CpcOption.setClickedFlag(e.target, 'add');
  }

  static onInputFocus(e) {
    const inputElm = e.target;
    const labelElm = e.target.nextElementSibling;

    if (CpcOption.isClicked(labelElm)) {
      // reset the click flag, so we can check next cycle.
      CpcOption.setClickedFlag(labelElm, 'remove');

      // disable focus ring regardless
      CpcOption.setFocusRing(inputElm, 'remove');
    } else {
      CpcOption.setFocusRing(inputElm, 'add');
    }
  }

  static setClickedFlag(labelElm, op) {
    labelElm.classList[op](CpcOption.CLASS_LABEL_ONCLICK);
  }

  static isClicked(labelElm) {
    return labelElm.classList.contains(CpcOption.CLASS_LABEL_ONCLICK);
  }

  static setFocusRing(inputElm, op) {
    inputElm.classList[op](CpcOption.CLASS_INPUT_ONFOCUS_BY_KEYBOARD);
  }

  registerEventHandler() {
    const optionElms = this.optionElms;
    for (let i = 0; i < optionElms.length; i += 1) {
      const optionElm = optionElms[i];
      const labelElm = optionElm.querySelector('label');
      if (labelElm === null) {
        console.error('ERROR_CPC_FORM_INPUT_NO_LABEL');
      } else {
        labelElm.addEventListener('click', CpcOption.onLabelClick);

        const inputElm = optionElm.querySelector('input');
        if (inputElm === null) {
          console.error('ERROR_CPC_FORM_LABEL_NO_INPUT');
        } else {
          inputElm.addEventListener('focus', CpcOption.onInputFocus);
        }
      }
    }
  }
}

export default CpcOption;
