/* eslint no-console: 0 */
/* eslint no-new: 0 */
/* eslint max-len: 0 */
/* eslint no-nested-ternary: 0 */


import DOMPurify from 'dompurify';
import Masonry from 'masonry-layout';
import { initializeComponent as initializeToolbar } from '../Toolbar-CG/toolbar.module';
import { initializeComponent as initializeSignInDesktop } from '../Sign-In/sign-in.module';
import { getHeaderHtml, getCampaignHeaderHTML } from './header-builder';
import globals from '../../../../utils/globals';
import { getCookie } from '../../../../utils/cookies';
import { setOrUpdateHashParameter } from '../../../../utils/url-utils';
import {
  getMHbetaUserResponse,
  betaUserRedirect,
} from './user-beta';


/**
 * header.component is implemented as a jQuery plugin.
 * Foundation components are all jQuery based. However where possible
 * implementing our components with plain JS is prefer129red since it is
 * easier to decouple from jQuery in the future
 *
 * This component consumes a LiveSite sitemap. The LiveSite sitemap
 * must be exposed via JSON as a part of the end-user payload. In
 * addition, some localization data is expected to be included in the
 * JSON payload which is not SiteMap specific, but is header specific.
 *
 * For example, the "Close" alt text for the close icon, the "Search" button
 * text, aria-role labels, etc.
 *
 * CPC.globalNavigation.
 *
 *
 * @example
 *
 * $('selector').cpc_nav()
 */
const $ = window.jQuery;
const defaultOptions = {};
const focusableElements = 'a, [tabindex]';

const constants = {
  hoverClass: 'hover',
  ariaExpanded: 'aria-expanded',
  expanded: 'expanded',
};

ensureNamespace();

function ensureNamespace() {
  if (!$.cpc) {
    $.cpc = {};
  }
}

function defineWidget(lang) {
  $.cpc.lang = lang;

  $.cpc.Nav = function navConstructor(el, options) {
    // To avoid scope issues, use 'base' instead of 'this'
    // to reference this class from internal events and functions.
    const base = this;

    // Access to jQuery and DOM versions of element
    base.$el = $(el);
    base.el = el;

    // Add a reverse reference to the DOM object
    base.$el.data('cpc.nav', base);

    base.init = () => {
      const mergedOptions = getOptions();
      base.options = mergedOptions;
      swapInNav();
      setOverlayElement();
      initializeEventListeners();
      reLayoutMegaMenu();
    };

    function getOptions() {
      const mergeOptions = options || {};
      if (!hasProperty(mergeOptions, 'suppressProductNav')) {
        mergeOptions.suppressProductNav = compareAttributeValue(
          el,
          'data-suppress-product-nav',
          'true'
        );
      }
      if (!hasProperty(mergeOptions, 'suppressToolbar')) {
        mergeOptions.suppressToolbar = compareAttributeValue(
          el,
          'data-suppress-toolbar',
          'true'
        );
      }

      return $.extend({}, $.cpc.Nav.defaultOptions, mergeOptions);
    }

    function hasProperty(obj, propName) {
      return obj && Object.prototype.hasOwnProperty.call(obj, propName);
    }
    function compareAttributeValue(ele, property, value) {
      return (el.getAttribute(property) || '').toLowerCase() === value;
    }

    function setOverlayElement() {
      const overlay = document.createElement('div');
      overlay.className = 'mega-nav-overlay';
      const refNode = document.querySelector('#sign-in-modal');
      if (refNode) refNode.parentNode.insertBefore(overlay, refNode);
    }

    function swapInNav() {
      // TO DO: remove attr data-suppress-product-nav after KIBO fixes this
      // note: kibo team needs to add .show-for-large-up for their mz-category-nav-menu and fix dropdown styles

      if (window.window.cpShopSiteNavigation) {
        base.$el.attr('data-suppress-product-nav', 'true');
        base.$el.attr('data-current-page', 'store');
        $('cpc-header').attr('data-suppress-product-nav', 'true');

        // hiding as it is using kibo server to render main-business-nav desktop version
        $(() => {
          $('div.main-business-nav')
            .addClass('hide')
            .removeClass('show-for-large-up');
          $('.mz-category-nav-menu').addClass('hide-for-medium-down');
        });
      }
      const siteMapRoot = getSitMapRoot(lang);

      function getSitMapRoot(lan) {
        let nodeRoot = (el.getAttribute('data-sitemap') || 'business').trim().toLowerCase();

        switch (nodeRoot) {
          case 'business':
            nodeRoot = lan === 'en' ? 'business' : 'entreprise';
            break;

          case 'personal':
            nodeRoot = lan === 'en' ? 'personal' : 'personnel';
            break;

          case 'our company':
            nodeRoot = lan === 'en' ? 'our company' : 'notre entreprise';
            break;

          case 'campaign':
            nodeRoot = 'campaign';
            break;

          default:
            nodeRoot = lan === 'en' ? 'business' : 'entreprise';
            break;
        }
        return nodeRoot;
      }

      if (siteMapRoot === 'campaign') {
        getCampaignHeaderHTML(base, siteMapRoot);
        return;
      }
      const globalNavHtml = getHeaderHtml(lang, base, siteMapRoot);


      base.$nav = $(globalNavHtml);
      base.$el.append(base.$nav);
      if (!base.options.suppressToolbar) {
        initializeToolbar();
      }
      initializeSignInDesktop();
    }

    // TO DO: extract this event listener so it not part of the header component

    function addShoppingCartObserver() {
      const shoppingCartQtyElem = document.querySelector('.cart-qty-indicator');
      const config = { childList: true, subtree: true, characterData: true };
      const QtyObserver = new MutationObserver(callback);

      let cartQtyValue = getCookie('estorecartcount');

      function updateShoppingCartUrl(anchor) {
        const currentHref = anchor.href.split('#')[0];
        const newHref = setOrUpdateHashParameter(
          currentHref,
          'qty',
          cartQtyValue
        );
        anchor.setAttribute('href', newHref);
      }

      if (!shoppingCartQtyElem) {
        QtyObserver.disconnect();
        return;
      } else if (cartQtyValue !== '0') {
        const headerPostesCanadaLinks = document.querySelectorAll(
          '.cpc-nav a[href*="postescanada.ca"]'
        );
        const footerPostesCanadaLinks = document.querySelectorAll(
          '.cpc-footer-container a[href*="postescanada.ca"]'
        );
        headerPostesCanadaLinks.forEach(anchor =>
          updateShoppingCartUrl(anchor)
        );
        footerPostesCanadaLinks.forEach(anchor =>
          updateShoppingCartUrl(anchor)
        );
      }

      QtyObserver.observe(shoppingCartQtyElem, config);
      function callback(mutationsList) {
        const headerPostesCanadaLinks = document.querySelectorAll(
          '.cpc-nav a[href*="postescanada.ca"]'
        );
        const footerPostesCanadaLinks = document.querySelectorAll(
          '.cpc-footer-container a[href*="postescanada.ca"]'
        );
        cartQtyValue = mutationsList[0].addedNodes[0].nodeValue;
        headerPostesCanadaLinks.forEach(anchor =>
          updateShoppingCartUrl(anchor)
        );
        footerPostesCanadaLinks.forEach(anchor =>
          updateShoppingCartUrl(anchor)
        );
      }
    }

    function addLogoutCleanup() {
      const logoutLink = document.querySelector('.sso-signout');
      if (!logoutLink) return;
      logoutLink.addEventListener('click', (evt) => {
        evt.preventDefault();
        window.sessionStorage.clear();
        document.cookie = 'XSRF-TOKEN=; expires=Thu, 01 Jan 1970 00:00:00 UTC;';
        document.cookie = 'acc=; Domain=.canadapost-postescanada.ca; Path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;';
        document.cookie = 'acc=; Path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;';
        window.location = evt.target.href;
      });
    }

    function addCLDropdownHandling() {
      const campaignLibrary = document.querySelector('.cl-header');
      const campaignLibraryNav = document.querySelector('.cl-header-nav');
      const fDropdownLinks = document.querySelectorAll('button.cl-top-section_nav-item.has-dropdown');
      const closeDropdownOnFocusItems = document.querySelectorAll('.campaign-library a, .footer__container a, #main-content button, .cl-top-section_title a, #main-content *, #tot-content *');


      if (!campaignLibrary) { return; }

      if (!fDropdownLinks) { return; }

      campaignLibraryNav.setAttribute('role', 'application');

      window.addEventListener('load', () => {
        $('#linksHover a').attr('tabindex', -1);

        fDropdownLinks.forEach((fDropdownLink) => {
          const dropdownId = fDropdownLink.getAttribute('data-dropdown');
          const dropdownListElm = document.getElementById(dropdownId);
          fDropdownLink.setAttribute('role', 'button');
          fDropdownLink.setAttribute('aria-expanded', 'false');
          dropdownListElm.setAttribute('aria-hidden', 'true');
          unwrapLinks(dropdownListElm.querySelector('li')); // unwrap main li

          // for all the links within the list element, default to -1 so no tab traps exist
          dropdownListElm.querySelectorAll('a').forEach((link) => {
            link.setAttribute('tabindex', -1);
            wrapLinks(link); // rewrap individually
          });

          fDropdownLink.addEventListener('click', (evt) => {
            handleKeypress(evt);
          });

          dropdownListElm.setAttribute('role', 'link');
        });

        // keyup event if Escape pressed anywhere on the document to close the open menu
        document.addEventListener('keyup', (evt) => {
          if (evt.keyCode === 27) { closeDropdown(); }
        });

        // also close the menu if any other items are focused
        closeDropdownOnFocusItems.forEach((element) => {
          element.addEventListener('focus', () => { closeDropdown(); });
        });

        document.addEventListener('click', (evt) => {
          if (!evt.target.classList.contains('has-dropdown')) {
            closeDropdown();
          }
        });

        function handleKeypress(event) {
          if (event.keyCode === 32 || event.keyCode === 13 || event.type === 'click') {
            // get the element ID from trigger and use it to select the actual dropdown
            const hiddenId = event.target.getAttribute('data-dropdown') ? event.target.getAttribute('data-dropdown') : '';
            const hidden = (hiddenId) ? document.getElementById(hiddenId).getAttribute('aria-hidden') : '';
            const dropdowns = document.querySelectorAll('[data-dropdown]');


            if (hidden === 'false') {
              // console.log('hidden was false', event);
              dropdowns.forEach((dropdown) => {
                const ddId = dropdown.getAttribute('data-dropdown');
                const ddLinks = document.querySelectorAll(`#${ddId} a`);
                ddLinks.forEach((ddLink) => {
                  ddLink.setAttribute('tabindex', -1);
                });
              });
              document.querySelectorAll(`#${hiddenId} a`).forEach((link) => {
                link.setAttribute('tabindex', -1);
              });

              document.getElementById(hiddenId).ariaHidden = true;
            } else if (hidden === 'true') {
              // close any open dropdowns first
              closeDropdown();
              // console.log('hidden was true', event);
              document.querySelectorAll(`#${hiddenId} a`).forEach((link) => {
                link.setAttribute('tabindex', 0);
              });
              document.getElementById(hiddenId).ariaHidden = false;
            } else {
              document.querySelectorAll('.f-open-dropdown a').forEach((link) => {
                link.setAttribute('tabindex', -1);
              });
            }
          }
        }

        function debounce(func) {
          let timer;
          return function (evt) {
            if (timer) clearTimeout(timer);
            timer = setTimeout(func, 250, evt);
          };
        }

        // on resize
        window.addEventListener('resize', debounce(() => {
          // console.log('resize dropdown close (or attempt)');
          closeDropdown();
        }));

        // function to close the dropdown and make the links inside unfocusable 
        function closeDropdown() {
          const dropdowns = document.querySelectorAll('[data-dropdown]');
          // close dropdown

          // prevent focus of dd items
          dropdowns.forEach((dropdown) => {
            const id = dropdown.getAttribute('data-dropdown');
            const subLinks = document.querySelectorAll(`#${id} a`);
            if (dropdown.getAttribute('aria-expanded') === 'true') {
              dropdown.setAttribute('aria-expanded', 'false');
            }
            subLinks.forEach((sublink) => {
              sublink.setAttribute('tabindex', '-1');
            });
          });
          $(document).foundation('dropdown', 'closeall');
        }

        function unwrapLinks(element) {
          const parent = element.parentNode;
          while (element.firstChild) parent.insertBefore(element.firstChild, element);
          parent.removeChild(element);
        }

        function wrapLinks(element) {
          const wrappingElement = document.createElement('li');
          element.replaceWith(wrappingElement);
          wrappingElement.appendChild(element);
        }
      });
    }

    function initializeEventListeners() {
      addUserNavEventListeners();
      addMainBusinessNavEventListeners();
      addUtilityNavEventListeners();
      addShoppingCartObserver();
      addLogoutCleanup();
      addCLDropdownHandling();
    }

    function addUtilityNavEventListeners() {
      const utilityNavElem = document.querySelector('.utility-business-nav');
      if (!utilityNavElem) return;
      const utilityChildNodes = utilityNavElem.querySelectorAll(
        '.top-bar-section li a'
      );

      // deepcode ignore reDOS: <please specify a reason of ignoring this>
      const cpcL2PathArr = DOMPurify.sanitize(window.location.href).split('/');
      const cpcL2PathRegex = (/\?|=|&/.test(cpcL2PathArr[5]) ? undefined : new RegExp(`^${cpcL2PathArr[5]}`));

      if (cpcL2PathArr.length <= 5 || cpcL2PathRegex === undefined) return; // if cpc or scp homepage or path contains a query param

      if (utilityChildNodes) {
        [...utilityChildNodes].map((elem) => {
          const currentL2Path = elem.href.split('/')[5];
          const res = currentL2Path && currentL2Path.match(cpcL2PathRegex) && elem.id !== 'searchBtn' ?
            elem.className = 'active' :
            elem.classList.remove('active');
          return res;
        });
      }
    }

    function addMainBusinessNavEventListeners() {
      const mainBusinessNav = document.querySelector('.main-business-nav');
      const cpcL3Path = DOMPurify.sanitize(window.location.href).split('/')[6];
      if (!mainBusinessNav) return;

      const body = document.querySelector('body');
      const moneyGovregex = /money-government-services|services-monetaires-et-gouvernementaux/;

      mainBusinessNav.addEventListener(
        globals.events.click,
        mainBusinessNavClick,
      );

      // Money services l3 highlight fix until pages have been structured properly
      if (cpcL3Path && cpcL3Path.match(moneyGovregex)) {
        const moneyServicesMenu = lang === 'en' ? 'Money services' : 'Services financiers';
        const moneySelector = document.querySelector(`[data-title="${moneyServicesMenu}"]`);
        if (!moneySelector) return;
        moneySelector.classList.add('active');
      }

      // Accessibility - aria-expanded attribute for screenreaders on focus/blur
      // TODO - de-jQuery-ify
      $(mainBusinessNav)
        .find('.top-bar .dropdown')
        .find(focusableElements)
        .focus((evt) => {
          // Give Foundation thread slice to do what it needs to do before we
          // inspect / revise DOM
          setTimeout(() => {
            handleTopBarFocusBlur($(evt.currentTarget), true);
          }, 0);
        })
        .blur((evt) => {
          // Give Foundation thread slice to do what it needs to do before we
          // inspect / revise DOM
          setTimeout(() => {
            handleTopBarFocusBlur($(evt.currentTarget), false);
          }, 0);
        });

      function handleTopBarFocusBlur($target, expanded) {
        const parent = $target.parents('.has-dropdown');
        const isMegaNavOpen = parent.hasClass(constants.hoverClass);
        parent.children('a:first-child').attr(constants.ariaExpanded, expanded);

        body.classList[isMegaNavOpen ? 'add' : 'remove']('mega-nav-open');

        setMenuLevelTabIndex(
          parent[0].querySelectorAll('.mega-nav a'),
          isMegaNavOpen ? '0' : '-1'
        );
      }

      // Safari is not triggering blur on a, so I just capture it at a higher level...which Chrome doesn't
      // capture at
      $('.main-business-nav .top-bar .has-dropdown>a').on(
        globals.events.blur,
        () => {
          setupDropDownTabHandling();
        }
      );

      // Accessibility - aria-expanded attribute for screenreaders on click
      $('.main-business-nav nav.top-bar').on('click', (evt) => {
        const parent = $(evt.target).parents('.has-dropdown')[0];
        const isMegaNavOpen = parent.classList.contains(constants.hoverClass);
        const megaNavElem = document.querySelectorAll('.main-business-nav .top-bar .has-dropdown>.mega-nav');
        if (isMegaNavOpen && evt.target.tagName === 'A') {
          const parentMegaNavItem = Array.prototype.slice
            .call(base.el.querySelectorAll('.mega-nav'))
            .find(megaNavItem => megaNavItem.contains(evt.target));

          if (
            parentMegaNavItem &&
            !evt.target.classList.contains('close-mega-nav')
          ) {
            // Prevent drop down from visually closing before
            // directing user to their destination
            evt.preventDefault();
            evt.stopPropagation();

            if (evt.target.target === '_blank') {
              window.open(evt.target.href, '_blank');
            } else {
              window.location.href = evt.target.href;
            }

            return;
          }
        }

        parent.firstElementChild.setAttribute(
          constants.ariaExpanded,
          !isMegaNavOpen
        );

        body.classList[isMegaNavOpen ? 'remove' : 'add']('mega-nav-open');
        setMenuLevelTabIndex(
          parent.querySelectorAll('.mega-nav a'),
          isMegaNavOpen ? '-1' : '0'
        );

        if (evt.target.classList.contains('close-mega-nav')) {
          setMenuLevelTabIndex(
            megaNavElem, '-1'
          );

          megaNavElem.forEach((ele) => {
            ele.setAttribute('aria-hidden', 'true');
          });
        }
      });

      setupDropDownTabHandling();

      function setupDropDownTabHandling() {
        setMenuLevelTabIndex(
          document.querySelectorAll(
            '.main-business-nav .top-bar .has-dropdown>.mega-nav a'
          ),
          '-1'
        );
      }

      // Detect when the mega nav has been closed to set tabindex to -1 for
      // all mega-nav links 
      // including tab index and aria attributes for mega-navs
      const mainNavElem = document.getElementById('mainNav');
      const megaNavElem = document.querySelectorAll('.main-business-nav .top-bar .has-dropdown>.mega-nav');
      setMenuLevelTabIndex(
        megaNavElem, '-1'
      );

      megaNavElem.forEach((ele) => {
        ele.setAttribute('aria-hidden', 'true');
      });


      ['click', 'keypress', 'focus'].forEach((evt) => {
        mainNavElem.addEventListener(evt, setMegaNavState);
      });


      function setMegaNavState(evt) {
        // setTimeout so that foundation has time to do what it needs to do
        // with its handlers since we query the DOM looking for stuff
        // that we expect Foundation to add on show/hide of mega nav items


        if (evt.target.classList.contains('close-mega-nav')) {
          megaNavElem.forEach((ele) => {
            ele.removeAttribute('tabindex');
          });
          megaNavElem.forEach((ele) => {
            ele.setAttribute('aria-hidden', 'false');
          });
        }
        setTimeout(() => {
          const isMegaNavClick = document
            .querySelector('.main-business-nav nav.top-bar')
            .contains(evt.target);

          const isMegaNavOpen =
            document.querySelectorAll(
              '.main-business-nav nav.top-bar >ul>li.hover'
            ).length > 0;

          if (!isMegaNavClick && !isMegaNavOpen) {
            setMenuLevelTabIndex(
              document.querySelectorAll('.main-business-nav .top-bar .has-dropdown>.mega-nav a'),
              '-1'
            );
            body.classList.remove('mega-nav-open');
          }

          if (!isMegaNavOpen) {
            setMenuLevelTabIndex(
              megaNavElem, '-1'
            );

            megaNavElem.forEach((ele) => {
              ele.setAttribute('aria-hidden', 'true');
            });
          } else {
            megaNavElem.forEach((ele) => {
              ele.removeAttribute('tabindex');
            });
            megaNavElem.forEach((ele) => {
              ele.setAttribute('aria-hidden', 'false');
            });
          }
        }, 0);
      }
    }

    function addUserNavEventListeners() {
      const usernameEle = document.querySelector('.sso-username');
      const userNavWrapper = document.querySelector('.user-nav-wrapper');
      const megaNavOverlay = document.querySelector('.mega-nav-overlay');
      const dashBoardLink = document.querySelector('.sso-user-nav > li');

      if (megaNavOverlay) {
        megaNavOverlay.addEventListener(globals.events.click, () => {
          body.classList.remove('mega-nav-open');
        });
      }

      const userNavList = userNavWrapper
        ? userNavWrapper.querySelector('.sso-user-nav')
        : null;
      const userNavSeparator = userNavWrapper
        ? userNavWrapper.previousElementSibling
        : null;
      const body = document.querySelector('body');

      if (usernameEle) {
        usernameEle.addEventListener(globals.events.click, userNavClick);

        userNavList.addEventListener(globals.events.focusOut, (evt) => {
          if (!userNavList.contains(evt.relatedTarget)) {
            userNavClose();
          }
        });
      }

      if (dashBoardLink) {
        dashBoardLink.addEventListener(globals.events.click, dashBoardClick);
        dashBoardLink.addEventListener(globals.events.click, dashBoardKeyPress);
      }

      $('.main-business-nav .top-bar .has-dropdown>a').on(
        globals.events.blur,
        (evt) => {
          if (userNavWrapper) {
            const isClickInUserNavDropdown = userNavWrapper.contains(
              evt.target
            );
            if (!isClickInUserNavDropdown) {
              userNavClose();
            }
          }
        }
      );

      document.body.addEventListener(globals.events.click, (evt) => {
        // setTimeout so that foundation has time to do what it needs to do
        // with its handlers since we query the DOM looking for stuff
        // that we expect Foundation to add on show/hide of mega nav items
        setTimeout(() => {
          if (userNavWrapper) {
            const isClickInUserNavDropdown = userNavWrapper.contains(
              evt.target
            );
            if (!isClickInUserNavDropdown) {
              userNavClose();
            }
          }
        }, 0);
      });

      function userNavClick(evt) {
        userNavWrapper.classList.toggle(constants.expanded);
        usernameEle.setAttribute(
          constants.ariaExpanded,
          usernameEle.getAttribute(constants.ariaExpanded) === 'true'
            ? 'false'
            : 'true'
        );
        if (userNavSeparator.classList.contains('top-bar-separator')) {
          userNavSeparator.classList.toggle('expanded-shadow');
        }
        evt.preventDefault();
      }

      function userNavClose() {
        if (!userNavWrapper.classList.contains(constants.expanded)) return;
        userNavWrapper.classList.remove(constants.expanded);
        usernameEle.setAttribute(constants.ariaExpanded, 'false');
        if (userNavSeparator.classList.contains('top-bar-separator')) {
          userNavSeparator.classList.remove('expanded-shadow');
        }
        body.classList.remove('mega-nav-open');
      }
    }

    function dashBoardClick(evt) {
      evt.preventDefault();
      const urlPathName = window.location.pathname;
      const MHbetaUserResponse = getMHbetaUserResponse();
      const targetUrl = evt.target.getAttribute('href');
  

      if (targetUrl.includes(urlPathName)) {
        window.location.reload();
      }

      if (MHbetaUserResponse) {
        betaUserRedirect(MHbetaUserResponse);
      }
      window.location.assign(targetUrl);
    }

    function dashBoardKeyPress(evt) {
      evt.preventDefault();
      if (evt.keyCode === 13) {
        dashBoardClick();
      }
    }

    function setMenuLevelTabIndex(menuEle, tabIndex) {
      menuEle.forEach((ele) => {
        ele.setAttribute('tabindex', tabIndex);
      });
    }

    // Prevent mega nav from closing when the body of the mega nav is clicked - wasn't sure if
    // foundation somehow supports this via their configuration or API
    function mainBusinessNavClick(evt) {
      const parentMegaNavItem = Array.prototype.slice
        .call(base.el.querySelectorAll('.mega-nav'))
        .find(megaNavItem => megaNavItem.contains(evt.target));

      // Prevent click in mega nav from closing mega nav
      // if (!isMegaNavClick) return;
      if (
        !parentMegaNavItem &&
        !evt.target.classList.contains('close-mega-nav')
      ) {
        evt.preventDefault();
        return;
      }

      const isTopbarFixed = document.body.classList.contains('f-topbar-fixed');
      const isClickOnFixedMenuOverlay =
        isTopbarFixed &&
        evt.target &&
        evt.target.classList.contains('dropdown') &&
        evt.target.classList.contains('mega-nav');

      if (
        evt.target.tagName !== 'A' &&
        parentMegaNavItem &&
        parentMegaNavItem.contains(evt.target) &&
        (!isTopbarFixed || (isTopbarFixed && !isClickOnFixedMenuOverlay))
      ) {
        document.querySelector('body').classList.add('mega-nav-open');
        evt.preventDefault();
        evt.stopPropagation();
        return;
      }

      // Focus ring - avoid the focus ring when closing the meganav if closing by mouse
      // click
      if (
        evt.target.classList.contains('close-mega-nav') &&
        !!parentMegaNavItem.previousElementSibling
      ) {
        if (isActivatedByKeyboard(evt)) {
          parentMegaNavItem.previousElementSibling.focus();
        } else {
          document.activeElement.blur();
        }
        setMenuLevelTabIndex(parentMegaNavItem.querySelectorAll('a'), '-1');
        evt.preventDefault();
        evt.stopPropagation();
      }
    }

    //
    // Here using masonry layout to evenly distribute menu items
    //
    function reLayoutMegaMenu() {
      document.querySelectorAll('.mega-nav--grid').forEach((elm) => {
        new Masonry(elm, {
          columnWidth: '.mega-nav--grid-sizer',
          itemSelector: '.mega-nav--grid-item',
          percentPosition: true,
          horizontalOrder: true,
          transitionDuration: 0,
        });
      });
    }

    base.init();
  };
  // mozInputSource is FF specific, 6=KB, 1=MouseClick
  function isActivatedByKeyboard(evt) {
    return (
      (!evt.detail &&
        (!evt.pointerType || (evt.pointerType && !evt.pointerType))) ||
      evt.mozInputSource === 6
    );
  }

  // Default options for component
  $.cpc.Nav.defaultOptions = defaultOptions;

  // Add the plugin with an underscore for namespacing
  $.fn.cpc_nav = function cpcnav(options) {
    const returnNav = this.each(function eachEle() {
      new $.cpc.Nav(this, options);
    });
    return returnNav;
  };
}

export default defineWidget;
