function setLocaleEn(params = {}) {
  return {
    aboutUs: 'About Us',
    aboutus: 'Our company',
    aboutusTab: 'Our company',
    ariaLabelMobile: 'Mobile main navigation',
    backLabel: 'Back',
    backMainMenu: 'Back to main navigation',
    business: 'Business',
    businessOverview: 'Learn about business services',
    businessTab: 'Business',
    cartLabel: 'Cart',
    cartURL: '/store-boutique/en/cart',
    closeLabel: 'Close',
    createAccount: 'Register online',
    dashboard: 'Dashboard',
    eshopOverview: 'Shop for stamps, shipping supplies and collectibles',
    eshopTab: 'Store',
    findPostalCode: 'Find a postal code',
    findPostalCodeURL: '/cpc/en/tools/find-a-postal-code.page',
    findRate: 'Find a rate',
    findRateURL: '/cpc/en/tools/find-a-rate.page',
    forgot: 'Forgot',
    forgotPasswordLabel: 'Password',
    forgotPasswordPasswordLabel: 'Password',
    forgotPasswordUsernameLabel: 'Username',
    home: '/cpc/en/our-company/home.page',
    lang_toggle: 'Fran&ccedil;ais',
    linkAboutUs: '/cpc/en/our-company/about-us.page',
    linkMySupport: '/information/app/ccm/support',
    linkBusinessSettings: '/bam-gce/en/profile',
    linkInvoices: '/fam-gcf/en/billing-and-payments',
    linkSignIn: '/lfe-cap/en/login',
    linkSupport: '/cpc/en/support.page',
    logoAltText: 'Canada Post',
    mainMenu: 'Back to main menu',
    mainNavMobile: 'Mobile main navigation',
    mainNavigationAriaLabel: 'Main navigation',
    menu: 'Menu',
    moreToolsURL: '/cpc/en/tools.page',
    myAccount: 'My Account',
    myAccountTab: 'My Account',
    myprofile: 'My Profile',
    businessSettings: 'Business Settings',
    invoices: 'Billing and Payments',
    mysupport: 'My Support',
    opensInNewTab: 'Opens in new tab',
    or: 'or',
    orders: 'Orders',
    overview: ' overview',
    ourcompanyOverview: 'Learn about Canada Post and shipping service alerts.',
    password: 'Password',
    personal: 'Personal',
    personalOverview: 'Learn about mailing services for individuals.',
    personalTab: 'Personal',
    personalURl: '/cpc/en/personal.page',
    productionNavigationAriaLabel: 'Product navigation',
    rememberUsername: 'Remember my username on this device',
    searchAriaLabel: 'Search products, related articles and support topics',
    searchErrorText: 'Please enter a topic. Examples: send packages, change my address',
    searchErrorTextMobile: 'Please enter a topic.',
    searchLabel: 'Search',
    searchText: 'Search our website',
    seeMoreTools: 'See more tools',
    shop: 'Shop',
    shopURL: '/shop-magasin/shop.jsf',
    signInButton: 'Sign in',
    signInHeader: 'Access your account',
    signInRegister: 'Sign in or Register',
    signOut: 'Sign out',
    signUp: 'Register now',
    signUpText: 'Create an online account in a few easy steps!',
    support: 'Support',
    supportUrl: '/cpc/en/support.page',
    tools: 'Tools',
    track: 'Track',
    username: 'Username',
    welcome: 'Hello',
    nodes: [{
      labelId: 'QuickLinks',
      label: 'Popular searches',
      nodes: [{
        label: 'Look up a postal code',
        description: 'Look up a postal code',
        link: `${params.cpDomain}/cpc/en/tools/find-a-postal-code.page`,
        icon: 'look-up-postal-code'
      },
      {
        label: 'Stamp prices',
        description: 'Stamp prices',
        link: `${params.cpDomain}/cpc/en/personal/sending/letters-mail/postage-rates.page`,
        icon: 'stamp-prices'
      },
      {
        label: 'Mail Forwarding',
        description: 'Mail Forwarding',
        link: `${params.cpDomain}/cpc/en/personal/receiving/manage-mail/mail-forwarding.page`,
        icon: 'mail-forwarding'
      },
      {
        label: 'Track',
        description: 'Track',
        link: `${params.cpDomain}/track-reperage/en`,
        icon: 'track'
      },
      {
        label: 'All postal guides',
        description: 'All postal guides',
        link: `${params.cpDomain}/cpc/en/support/postal-services-information.page`,
        icon: 'all-postal-guides'
      },
      {
        label: 'Support',
        description: 'Get answers to common questions and other details on our services.',
        link: `${params.cpDomain}/cpc/en/support.page`,
        icon: 'support'
      }
      ]
    }],
    gdpr_cookie_banner_title: 'Cookies on the Canada Post website',
    gdpr_cookie_banner_policy: `We've placed cookies on your device to improve your browsing experience. They're safe and don't contain sensitive information. If you want, you can <a href="${params.cpDomain}/cpc/en/support/kb/general-inquiries/general-information/delete-cookies-using-your-browser-canada-post">change your cookies</a> through your browser settings. If you continue without changing your settings, we'll assume you're ok to receive all cookies on the Canada Post website. <a href="${params.cpDomain}/cpc/en/our-company/about-us/privacy-policy/privacy-policy-introduction.page">For more information view our privacy policy.</a>`,
    gdpr_cookie_banner_btn_text: 'I understand',
    gdpr_cookie_banner_btn_alt: 'Click button called I understand to delete the cookies message',
    gdpr_cookie_banner_readmore: 'Read more',
    sitemapTypeBusiness: 'business',
    sitemapTypePersonal: 'personal',
    sitemapTypeTools: 'tools',
    sitemapTypeAboutUs: 'about us',
    
  };
}

export default setLocaleEn;