const searchTemplate = items => `
<div id="searchPopup" aria-live="assertive" role="dialog" aria-hidden="true">

  <div id="searchModalInputRow">
    <div id="searchModalInputContainer">
      <label for="searchModalInput" class="visually-hidden">${items.searchAriaLabel}</label>
      <input id="searchModalInput" type="text" placeholder="${items.searchText}" tabindex="0">
      <button id="searchModalBtn" class="searchModalBtn" aria-label="${items.searchAriaLabel}" tabindex="0">${items.searchLabel}</button> 
    </div>
    <div id="searchModalClose" aria-label="${items.closeLabel}" tabindex="0"> </div>
  </div>
  <div id="searchModalLabelError" aria-live="assertive" role="alert" > ${items.searchErrorText}</div>
  <div id="searchResultsRow">
    <div id="searchModalResults"></div>
    <h2>${items.nodes[0].label}</h2>
    <div class="row">
      <ul id="searchModalQuickLinks" class="searchModalQuickLinks small-12 medium-6 columns"></ul>
      <ul id="searchModalQuickLinks2" class="searchModalQuickLinks small-12 medium-6 columns"></ul>
    <div>
  </div>
</div>`;


const searchTemplateList = items => `
  <li class="search-modal-quick-link">
    <a href="${items.link}" class="search-modal-link" tabindex="0">
      <span class="search-icon ${items.icon}"></span>
      <span class="search-modal-label">${items.label}</span>
    </a>
  </li>
`;


export default {
  searchTemplate,
  searchTemplateList
};