import locale from './locale';

const ATTR_DATA_PWD_TOGGLE_FOR = 'data-pwd-toggle-for';
const SELECTOR_PWD_TOGGLE = `[${ATTR_DATA_PWD_TOGGLE_FOR}]`;

/**
 * Password toggle for show/hide the password. The HTML snippet should have the
 * below minimum structure(does not necessarily be an anchor tag ):
 *
 *   <a data-pwd-toggle-for="password_1">Show password</a>
 *   <input id="password_1" type="password" />
 *
 * The value of 'data-pwd-toggle-for' is the id of the password input element.
 *
 * As an API, it can be called as
 *
 *   const toggles = cpc.lib.initPasswordToggles()
 *
 * Which returns a map of password toggles keyed with the value of 'data-pwd-toggle-for'.
 * The map can be iterated through to get each toggle. You can call toggle's toggle()
 * method to programmatically display/hide password. For example:
 *
 *   toggles['password_1'].toggle();
 *
 */
function PasswordToggles() {
  const toggles = {};
  initialize();

  return {
    toggles
  };

  function initialize() {
    const toggleElms = document.querySelectorAll(SELECTOR_PWD_TOGGLE);
    for (let i = 0; i < toggleElms.length; i += 1) {
      const toggleElm = toggleElms[i];
      const toggleFor = toggleElm.getAttribute(ATTR_DATA_PWD_TOGGLE_FOR).trim();
      if (toggleFor === '') return;

      const pwdInput = document.querySelector(`#${toggleFor}`);
      if (pwdInput === null) {
        console.error(`ERR_PWD_TOGGLE_NO_PASSWORD_INPUT: ${toggleFor}`);
        return;
      }

      const newToggle = new PasswordToggle(toggleElm, pwdInput, toggleFor);
      toggles[newToggle.id] = newToggle;
    }
  }

  function toggle(toggleElm, passwordElm) {
    if (passwordElm.type === 'password') {
      passwordElm.type = 'text';
      toggleElm.innerHTML = locale.getString('hidePassword');
    } else {
      passwordElm.type = 'password';
      toggleElm.innerHTML = locale.getString('showPassword');
    }
  }

  function PasswordToggle(_toggleElm, _passwordElm, _id) {
    const toggleElm = _toggleElm;
    const passwordElm = _passwordElm;
    const id = _id || toggleElm.getAttribute(ATTR_DATA_PWD_TOGGLE_FOR).trim();
    toggleElm.addEventListener('click', onClick);
    toggleElm.addEventListener('keyup', onKeyup);

    return {
      id,
      toggleElement: toggleElm,
      passwordElement: passwordElm,
      toggle: doToggle
    };

    function onClick() {
      doToggle();
    }

    function onKeyup(e) {
      if (e.key === 'Enter') doToggle();
    }
    function doToggle() {
      toggle(toggleElm, passwordElm);
    }
  }
}

/**
 * Convenient method to search password toggle elements and initialization. It
 * returns a map of password toggles keyed with the value of 'data-pwd-toggle-for'.
 */
PasswordToggles.init = function init() {
  return new PasswordToggles().toggles;
};

export default PasswordToggles;
