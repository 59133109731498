import '@babel/polyfill';
import axios from 'axios';
import {
  setCookie,
  getCookie,
  getRootDomain,
  deleteCookie,
} from '../../../../utils/cookies';
import { getLANG } from '../../../../utils/language.data';

function initializeBetaUser() {
  const currentPath = window.location.pathname;
  if (
    isMerchantHomeAppHomePage(currentPath)) {
    getUserBetaData();
  }
}

function handleMHbetaUserResponse(betaUserResponse) {
  const currentBetaUser = getCookie('MHbetaUser');

  if (currentBetaUser !== betaUserResponse) {
    setCookie(
      'MHbetaUser',
      betaUserResponse,
      9999,
      false,
      getRootDomain(window.location)
    );
  }
}

function betaUserRedirect(betaUserResponse) {
  const currentPath = window.location.pathname;
  const lang = getLANG();
  const merchantHomePath = getMerchantHomePath(lang);
  const isMHbetaUser = isMHbetaUserResponse(betaUserResponse);
  // const isMHUserNotEligible = betaUserResponse === 'NOT_ELIGIBLE';

  // if (isMHbetaUser && isMHUserNotEligible) {
  //   deleteCookie('preoutClose');
  // }

  if (isMHbetaUser && !currentPath.includes(merchantHomePath)) {
    window.location.assign(merchantHomePath);
  }
}

function isMerchantHomeAppHomePage(urlPathName) {
  const merchantHomePathNames = [
    '/businesshome-boutiquedaffaires/en',
    '/businesshome-boutiquedaffaires/fr',
  ];

  return merchantHomePathNames.some(merchantHomePath =>
    urlPathName.includes(merchantHomePath)
  );
}

function getMerchantHomePath(lang) {
  return lang === 'e'
    ? '/businesshome-boutiquedaffaires/en'
    : '/businesshome-boutiquedaffaires/fr';
}

function isMHbetaUserResponse(betaUserResponse) {
  return String(betaUserResponse) === 'OPTIN';
}

function getMHbetaUserResponse() {
  return getCookie('MHbetaUser');
}


const getUserBetaData = async () => {
  try {
    const betaUserMH = await axios.get('/businesshome-boutiquedaffaires/beta/customer/checkforbeta');

    const urlPathName = window.location.pathname;
    if (betaUserMH) {
      handleMHbetaUserResponse(betaUserMH.data.status);
    }

    if (isMerchantHomeAppHomePage(urlPathName)) {
      deleteCookie('preoutClose');
      betaUserRedirect(betaUserMH.data.status);
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }

  return null;
};

export { initializeBetaUser, getMHbetaUserResponse, betaUserRedirect };
