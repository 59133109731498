/* eslint no-console: 0 */
/* eslint no-new: 0 */

/**
 * Global Back To Top JS for usability and accessibility.
 * 
 * @example
 * 
 * Back to top button HTML is inserted into the DOM. 
 * Events will be wired up to the back to top button.
 */

document.addEventListener('DOMContentLoaded', initializeComponent);
// let hideBtnTimeout = null;

function initializeComponent() {
  const cpcHeader = document.querySelector('cpc-header');
  let mainContentAreaID = 'main-content';
  if (cpcHeader && cpcHeader.getAttribute('data-skip-id')) {
    mainContentAreaID = cpcHeader.getAttribute('data-skip-id');
  }

  if (!document.querySelector(`#${mainContentAreaID}`)) return;

  const ariaLabel = document.querySelector('html').getAttribute('lang') === 'fr' ? 'Retour au sommet' : 'Back to top'; 
  const backToTopButton = document.createElement('div');
  const bodyEle = document.querySelector('body');
  let lastScrollPosition = 0;
  // iphone 5 - IA-1865
  let forceBottom = false;
  
  backToTopButton.setAttribute('aria-label', ariaLabel);
  backToTopButton.classList.add('back-to-top-button');
  backToTopButton.tabIndex = 0;
  backToTopButton.addEventListener('click', scrollToTop);
  backToTopButton.addEventListener('keypress', (e) => {
    const key = e.which || e.keyCode;
    if (key === 13) {
      scrollToTop(e);
    }
  });
  
  // desktop browsers raise scroll events at the window level, not at the body level
  // Using Foundation's debounce underscore wrapper, since it's in their JS API
  window.addEventListener('scroll', window.Foundation.utils.debounce(() => { 
    // iphone 5 - IA-1865
    if (screen.availWidth === 320 && screen.availHeight === 568) {
      forceBottom = true;
    } else {
      const currentScrollPosition = document.body.scrollTop || document.documentElement.scrollTop;
      let topPoint = 20;
      if (window.matchMedia('(orientation: landscape)').matches) {
        topPoint = 420;
      }      
      // clearTimeout(hideBtnTimeout);
      if ((document.body.scrollTop > topPoint || document.documentElement.scrollTop > topPoint) &&
        currentScrollPosition < lastScrollPosition) {      
        backToTopButton.classList.add('show');
        // backToTopButton.classList.remove('fade-out');
      } else if (backToTopButton.classList.contains('show')) {
        // backToTopButton.classList.add('fade-out');     
        backToTopButton.classList.remove('show');
      }
      lastScrollPosition = currentScrollPosition;
    }
  }, 10, true));

  // iPhone and Android scroll events are raised at the body level, not at the window level
  // Using Foundation's debounce underscore wrapper, since it's in their JS API
  // TODO: remove this event handler if no longer required - suspect that it's only used
  // for iPhone 5 as window scroll event isn't triggered on that device.
  bodyEle.addEventListener('scroll', window.Foundation.utils.debounce(() => {
    const mainContentRect = document.querySelector(`#${mainContentAreaID}`).getBoundingClientRect();
    const currentScrollPosition = mainContentRect.top;
    const mobileTb = document.querySelector('.cpc-tb--mobile');

    if ((mainContentRect.bottom - screen.availHeight - 1) < 0 && mobileTb) {
      mobileTb.classList.add('force-bottom');
      backToTopButton.classList.add('force-bottom');
    } else if (mobileTb && !forceBottom) {
      mobileTb.classList.remove('force-bottom');
      backToTopButton.classList.remove('force-bottom');
    }

    let topPoint = 0;
    if (window.matchMedia('(orientation: landscape)').matches) {
      topPoint = -400;
    }    
    // clearTimeout(hideBtnTimeout);
    if ((currentScrollPosition < topPoint) &&
      currentScrollPosition > lastScrollPosition) {      
      backToTopButton.classList.add('show');
      // backToTopButton.classList.remove('fade-out');
    } else if (backToTopButton.classList.contains('show')) {    
      // backToTopButton.classList.add('fade-out');
      backToTopButton.classList.remove('show');
    }
    lastScrollPosition = currentScrollPosition;
  }, 10, true));

  bodyEle.appendChild(backToTopButton);

  function scrollToTop(e) {
    const curElement = e.target || e.srcElement;
    let scrollEle = document.body || document.documentElement;
    // IE11
    if (scrollEle.scrollTop === 0) {
      scrollEle = document.documentElement;
    }
    scrollTo(scrollEle, 1000);
    curElement.blur();
  }

  function scrollTo(element, scrollDuration) {
    const ele = element;
    const scrollHeight = ele.scrollTop;
    const scrollStep = (Math.PI / (scrollDuration / 15));
    const cosParameter = scrollHeight / 2;
    let scrollCount = 0;
    let scrollMargin = 0;
    const scrollInterval = setInterval(() => {
      if (ele.scrollTop > 5) {
        scrollCount += 1;
        const cosDeduct = cosParameter * Math.cos(scrollCount * scrollStep);
        scrollMargin = cosParameter - cosDeduct;
        ele.scrollTop = scrollHeight - scrollMargin;
      } else if (document.querySelector(`#${mainContentAreaID}`).getBoundingClientRect().top < 0) {
        // Iphone 5
        if (!document.querySelector('a[name=pageTop]')) {
          const topAnchor = document.createElement('a');
          topAnchor.setAttribute('name', 'pageTop');         
          bodyEle.insertBefore(topAnchor, bodyEle.firstChild);
        }
        window.location.hash = 'pageTop';
        window.location.href = location.hash;
        clearInterval(scrollInterval);
      } else { 
        // iphone 6
        ele.scrollTop = 0;
        clearInterval(scrollInterval);
      }
    }, 10);    
  } 
}