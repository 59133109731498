/**
 * Skip to nav for our CWC
 * main CPC is using server side inline skip to nav logic
 */

import locale from './locale';

const initialize = () => {
  const skipNav = document.querySelector('.skip-nav');
  if (!skipNav) return;

  skipNav.innerHTML = locale.skipNavLabel;
  skipNav.addEventListener('keydown', skipToH1);
  skipNav.addEventListener('click', skipToH1);
};

const skipToH1 = (event) => {
  if (event.type === 'keydown') {
    const kbEvent = event;
    if (kbEvent.code !== 'Enter' && kbEvent.key !== 'Enter') return;
  }
  event.preventDefault();
  const mainHeadings = document.body.querySelectorAll('h1');
  if (mainHeadings.length !== 0) {
    mainHeadings.forEach((heading) => {
      const headingHidden = heading.style.display === 'none';
      if (!headingHidden) {
        heading.setAttribute('tabindex', '-1');
        heading.focus();
      }
    });
  }
};

export default initialize;
