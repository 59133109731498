import globals from './globals';

/**
 * Some stuff to register resize handlers following practices from 
 * MDN https://developer.mozilla.org/en-US/docs/Web/Events/resize#Examples
 */

export default class ResizeHandler {
  constructor() {
    this.callbacks = [];
    this.running = false;
  }

  /**
   * Adds a resize callback to invoke on Window resize event
   * 
   * @param {function} callback 
   */
  add(callback) {
    if (!this.callbacks.length) {
      window.addEventListener(globals.events.resize, (evt) => { 
        if (!this.running) {
          this.running = true;

          if (window.requestAnimationFrame) {
            window.requestAnimationFrame(runCallbacks.bind(this, evt));
          } else {
            setTimeout(runCallbacks.bind(this, evt), 66);
          }
        }
      });
    }
    addCallback.call(this, callback);
  }
}

// run the actual callbacks
function runCallbacks(evt) {
  this.callbacks.forEach((callback) => {
    callback(evt);
  });

  this.running = false;
}

// adds callback to loop
function addCallback(callback) {
  if (callback) {
    this.callbacks.push(callback);
  }
}