// this stuff is for inlining the icons...Ideally a webpack loader is setup that allows 
// us to point to the actual SVG on the filesystem instead of a JS file that returns the SVG source
function getLocale() {
  return {
    ariaLandmarkLabel: "Liens rapides et outils d'exp&eacute;dition",
    feedback: 'R&eacute;troaction',
    closeLabel: 'Fermer',
    closeIcon: 'cpc-tb--icon-expand-toggle',
    links: [ 
      {
        label: 'Rep&eacute;rer',
        url: '/track-reperage/fr',
        icon: 'icon-track',
        hiddenMobile: false
      },
      {
        label: 'Trouver un code postal',
        url: '/scp/fr/outils/trouver-un-code-postal.page',
        icon: 'icon-postal-code',
        hiddenMobile: false
      },
      {
        label: 'Trouver un tarif',
        url: '/scp/fr/outils/trouver-un-tarif.page',
        icon: 'icon-find-rate',
        hiddenMobile: true
      },
      {
        label: 'Trouver un bureau de poste',
        url: '/scp/fr/outils/trouver-un-bureau-de-poste.page',
        icon: 'icon-find-po',
        hiddenMobile: false
      },
      {
        label: 'Soutien',
        url: '/scp/fr/soutien.page',
        icon: 'icon-support chat-link',
        hiddenMobile: false
      },
      /* {
        label: 'R&eacute;troaction',
        // eslint-disable-next-line no-script-url
        template: `
        <li id="feedback-tab-mobile-2" class="show-for-small-only">
        <a href="#" data-cpc-modal-id="#qualtricsFeedbackModal">
          <div class="cpc-tb--icon cpc-tb--icon-feedback"></div><span class="tool-description">R&eacute;troaction</span>
        </a>
        </li>        
        `
      },
      }, */
      {
        label: 'Autres outils',
        url: '/scp/fr/outils.page',
        icon: 'icon-more-tools',
        hiddenMobile: false
      }
    ],
    homepageLinks: [ 
      {
        label: 'Rep&eacute;rer',
        url: '/track-reperage/fr',
        icon: 'icon-track',
        hiddenMobile: false
      },
      {
        label: 'Trouver un code postal',
        url: '/scp/fr/outils/trouver-un-code-postal.page',
        icon: 'icon-postal-code',
        hiddenMobile: false
      },
      {
        label: 'Trouver un tarif',
        url: '/scp/fr/outils/trouver-un-tarif.page',
        icon: 'icon-find-rate',
        hiddenMobile: false
      },
      {
        label: 'Trouver un bureau de poste',
        url: '/scp/fr/outils/trouver-un-bureau-de-poste.page',
        icon: 'icon-find-po',
        hiddenMobile: false
      },
      {
        label: 'R&eacute;acheminement du courrier',
        url: '/tools/eCOA/Web/SignIn.aspx?LOCALE=fr',
        icon: 'icon-mail-forwarding',
        hiddenMobile: false
      },
      {
        label: 'postel',
        url: '/cpc/en/personal/receiving/manage-mail/epost.page',
        icon: 'icon-epost',
        hiddenMobile: false
      }
    ]    
  };
}

export default getLocale;