/**
 * Simple API to get page specific information
 *
 * @example
 * 
 */

const PATH_HOME_PAGE = [
  '/cpc/en',
  '/scp/fr',
  '/cpc/en/home.page',
  '/scp/fr/accueil.page',
  '/cpc/en/',
  '/scp/fr/',
  '/cpc/en/main.page', // The currently test homepage
  '/cpc/en/home.html' // The test homepage in local env
];

const PATH_BUSINESS_REGEXP = [
  /^\/cpc\/en\/business.page$/,
  /^\/cpc\/fr\/business.page$/,
  /^\/cpc\/en\/business\//,
  /^\/cpc\/fr\/business\//
];

const PATH_PERSONAL_REGEXP = [
  /^\/cpc\/en\/personal.page$/,
  /^\/cpc\/fr\/personal.page$/,
  /^\/cpc\/en\/personal\//,
  /^\/cpc\/fr\/personal\//
];

const PATH_ABOUTUS_REGEXP = [
  /^\/cpc\/en\/our-company.page$/,
  /^\/cpc\/fr\/our-company.page$/,
  /^\/cpc\/en\/our-company\//,
  /^\/cpc\/fr\/our-company\//
];

const PATH_TOOLS_REGEXP = [
  /^\/cpc\/en\/tools.page$/,
  /^\/cpc\/fr\/tools.page$/
];

export default class PageUtils {
  static isCanadaPostHomepage() {
    return PageUtils.isHomepage(window.location.pathname.toLowerCase());
  }

  /**
   * Check whether the given path is the home page
   * 
   * @param {string} url - The URL path
   * @return Return true if the given path is home page, otherwise false
   */
  static isHomepage(path) {
    return PATH_HOME_PAGE.indexOf(path) !== -1;
  }

  static isBusinessPage(path) {
    // exclude '/cpc/en/personal' and '/cpc/en/our-company/'
    if (/\/business\/?$/.test(path)) return false;

    for (let i = 0; i < PATH_BUSINESS_REGEXP.length; i += 1) {
      if (PATH_BUSINESS_REGEXP[i].test(path)) return true;
    }

    return false;
  }

  static isPersonalPage(path) {
    // exclude '/cpc/en/business' and '/cpc/en/our-company/'
    if (/\/personal\/?$/.test(path)) return false;

    for (let i = 0; i < PATH_PERSONAL_REGEXP.length; i += 1) {
      if (PATH_PERSONAL_REGEXP[i].test(path)) return true;
    }

    return false;
  }

  static isAboutusPage(path) {
    // exclude '/cpc/en/personal' and '/cpc/en/business/'
    if (/\/our-company\/?$/.test(path)) return false;

    for (let i = 0; i < PATH_ABOUTUS_REGEXP.length; i += 1) {
      if (PATH_ABOUTUS_REGEXP[i].test(path)) return true;
    }

    return false;
  }

  static isToolsPage(path) {
    // exclude '/cpc/en/business' and '/cpc/en/personal/' and '/cpc/en/our-company/'
    if (/\/tools\/?$/.test(path)) return false;

    for (let i = 0; i < PATH_TOOLS_REGEXP.length; i += 1) {
      if (PATH_TOOLS_REGEXP[i].test(path)) return true;
    }

    return false;
  }
}
