import signinForm from './sign-in.form.html';

export default items => `
<div class="cpc-modal-template-modal-body">
  <div class="row sign-in-modal-content flex-row">
    <div class="large-6 cpc-modal__fluid-gutter-m columns left-area">
      ${signinForm(items)}
    </div>
  
    <div class="large-6 columns cpc-modal__fluid-gutter-m columns content">${items.rightAreaCopy}</div>
  </div>
</div>
`;
