const icon = `
<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 48.2 (47327) - http://www.bohemiancoding.com/sketch -->
    <title>Cart</title>
    <desc>Created with Sketch.</desc>
    <defs>
        <polygon id="path-1" points="2.44889282 2.38866856 0.145457665 2.38866856 0.145457665 0.079197633 2.44889282 0.079197633"></polygon>
        <polygon id="path-3" points="2.48429747 2.38866856 0.181395519 2.38866856 0.181395519 0.079197633 2.48429747 0.079197633"></polygon>
    </defs>
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Cart">
            <g transform="translate(2.000000, 3.000000)">
                <g id="Group-3" transform="translate(8.000000, 16.000000)">
                    <mask id="mask-2" fill="white">
                        <use xlink:href="#path-1"></use>
                    </mask>
                    <g id="Clip-2"></g>
                    <path d="M2.44889282,1.23425158 C2.44889282,1.87202609 1.93275272,2.38877472 1.29690864,2.38877472 C0.660797961,2.38877472 0.145457665,1.87202609 0.145457665,1.23425158 C0.145457665,0.596477079 0.660797961,0.079197633 1.29690864,0.079197633 C1.93275272,0.079197633 2.44889282,0.596477079 2.44889282,1.23425158" id="Fill-1" fill="#333333" mask="url(#mask-2)"></path>
                </g>
                <g id="Group-6" transform="translate(15.000000, 16.000000)">
                    <mask id="mask-4" fill="white">
                        <use xlink:href="#path-3"></use>
                    </mask>
                    <g id="Clip-5"></g>
                    <path d="M2.48429747,1.23425158 C2.48429747,1.87202609 1.96869058,2.38877472 1.3328465,2.38877472 C0.697002417,2.38877472 0.181395519,1.87202609 0.181395519,1.23425158 C0.181395519,0.596477079 0.697002417,0.079197633 1.3328465,0.079197633 C1.96869058,0.079197633 2.48429747,0.596477079 2.48429747,1.23425158" id="Fill-4" fill="#333333" mask="url(#mask-4)"></path>
                </g>
                <polyline id="Stroke-7" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" points="0 0 3.19638771 0 4.00146292 2.21068946 8.29307176 14 17 14"></polyline>
                <polyline id="Stroke-9" stroke="#333333" stroke-linejoin="round" points="4 2 20 2 17.2917728 9 6.70822716 9"></polyline>
            </g>
        </g>
    </g>
</svg>
          `;

export default icon;