/* eslint no-console: 0 */
/* eslint no-new: 0 */
/* eslint no-unused-expressions: 0 */

/**
 * Support to initiate modals on call to actions when users are not authenticated.
 * If users are not authenticated, they will see a sign in modal and upon successful sign
 * in they are redirected to the cta destination.
 *
 * For users that are already authenticated, they are simply brought to the CTA destination.
 *
 * @usage
 *
 * <a href="/$EST_SUBDOMAIN$est.canadapost-postescanada.ca/some-eventual-tool-url?locale=$SHIPPING_LOCALE$"
 *  data-cpc-sso-modal="shipping">Ship now</a>
 *
 * The data-cpc-sso-modal is the id of the modal to display. It also sets the mode for
 * environment, etc.
 *
 * Supported URL tokens:
 *  - $EST_SUBDOMAIN$ - for est.canadapost-postescanada.ca URLs, this token will generate dev/stg environment
 *    specific est sub-domain URLs
 *  - $SHIPPING_LOCALE$ - generates 'en_CA' for English locale pages, 'fr_CA' for Frech locale pages
 */
import { load } from 'recaptcha-v3';
import ModalConstructor from './modal.component';
import authModule from '../../../../utils/auth.module';
import { getCookie, setCookie } from '../../../../utils/cookies';
import {
  initializeComponent as initializeSignInDesktop,
  getHtmlTemplate,
  fillTemplate as fillHtmlTemplate
} from '../Sign-In/sign-in.module';
import { getPageLanguage } from '../../../../utils/language.data';
import { createLoginLink, createRegisterLink } from '../../../../utils/sso/sso-link-utilities';
import SSOLocale from '../../../../utils/sso/sso-link.locale';

const captchaKey = authModule.getCAPTCHKey();
const $ = window.jQuery;
const lang = getPageLanguage();

document.addEventListener('DOMContentLoaded', initializeSSOHandlers);
// Custom event to init modal from angular  app // track-reperage
document.addEventListener('reInitModal', initializeSSOHandlers);

const localeMap = {
  en: 'en_CA',
  fr: 'fr_CA'
};

const modalAnimationTransitionTiming = 250;
const modalSecureAttribute = 'data-cpc-modal-secure-cta';
const modalSecureClassPrefix = 'cpc-modal-secure';

//
// additional CTAs that need to launch a modal have an entry here for their
// destination URL
//
const ctaURLs = {
  shipping: {
    url: 'https://$EST_SUBDOMAIN$est-oee.canadapost-postescanada.ca/esto-oee/app/shippingtools-outilsexpedition?locale=$SHIPPING_LOCALE$'
  },
  default: {
    scenarioCopy: 'default'
  },
  personal: {
    useDefaultModal: 'default',
    url: `${authModule.getSSODomain()}/pfe-pap/${lang}/${SSOLocale.registration}/${SSOLocale.personal}`,
    scenarioCopy: 'default'
  },
  biz: {
    useDefaultModal: 'biz',
    business: true,
    scenarioCopy: 'biz'
  },
  biz_smb_mode1: {
    useDefaultModal: 'biz',
    business: true,
    scenarioCopy: 'biz'
  },
  biz_smb_mode2: {
    useDefaultModal: 'biz',
    business: true,
    scenarioCopy: 'biz'
  },
  pup: {
    useDefaultModal: 'biz',
    business: true,
    scenarioCopy: 'biz'
  },
  connect: {
    useDefaultModal: 'biz',
    business: true,
    scenarioCopy: 'biz'
  },
  smb_mode1: {
    useDefaultModal: 'biz',
    business: true,
    scenarioCopy: 'biz'
  },
  smb_mode2: {
    useDefaultModal: 'biz',
    business: true,
    scenarioCopy: 'biz'
  },
  commercial: {
    useDefaultModal: 'commercial',
    business: true,
    scenarioCopy: 'commercial'
  },
  rso: {
    useDefaultModal: 'biz',
    business: true,
    scenarioCopy: 'biz'
  },
  tools: {
    useDefaultModal: 'tools',
    business: true,
    scenarioCopy: 'tools'
  },
  ship_online: {
    useDefaultModal: 'ship_online',
    business: true,
    scenarioCopy: 'default'
  },
  shipping_manager: {
    useDefaultModal: 'shipping_manager',
    business: true,
    scenarioCopy: 'biz'
  },
  automatic_tracking: {
    useDefaultModal: 'default',
    business: true,
    scenarioCopy: 'default'
  },
  sms: {
    useDefaultModal: 'default',
    business: true,
    scenarioCopy: 'default'
  },
  'default-with-consumer': {
    scenarioCopy: 'default'
  }
};

function isUx() {
  const hostname = window.location.hostname;
  return /ux/.test(hostname);
}

function initializeSSOHandlers() {
  const isAuthenticated = authModule.getIsAuthenticated();
  const allSSOInitiators = Array.prototype.slice.call(
    document.querySelectorAll(
      `[${modalSecureAttribute}], [class*=" ${modalSecureClassPrefix}"], [class^="${modalSecureClassPrefix}"]`
    )
  );
  const modals = [];

  allSSOInitiators.forEach((initiator) => {
    const modalId = getModalId(initiator);
    const matchingCTAentry = ctaURLs[modalId];
    let updatedLoginURL = '';
    let updatedRegisterURL = '';

    if (!matchingCTAentry) {
      console.error(`Unknown modal CTA link id. Id provided was ${modalId}`);
      return;
    }

    let eleUrl = initiator.tagName === 'A' ? initiator.getAttribute('href') : null;
    if (eleUrl === '') {
      // The page author may not set the href
      eleUrl = eleUrl === '' ? null : eleUrl;
    }
    const url = (unescape(eleUrl) || ctaURLs[modalId].url)
      .replace(/\$EST_SUBDOMAIN\$/gi, authModule.getESTSubdomain())
      .replace(/\$EPOST_DOMAIN\$/gi, authModule.getEpostDomain())
      .replace(/\$CSRF_TOKEN\$/gi, authModule.getCSRFToken())
      .replace(/\$SHIPPING_LOCALE\$/g, localeMap[lang]);
    let modal = null;

    if (isAuthenticated) {
      initiator.setAttribute('href', url);
    } else if (url.indexOf('/inbox/') !== -1) {
      updatedLoginURL = createLoginLink({ type: 'epost' }, url);
      updatedRegisterURL = createRegisterLink({ type: 'epost' }, url);
    } else if (url.indexOf('/flexdelivery-flexilivraison/') !== -1) {
      updatedLoginURL = createLoginLink({ type: 'flex' }, url);
      updatedRegisterURL = createRegisterLink({ type: 'flex' }, url);
    } else if (url.indexOf('/connect-connexion/') !== -1) { 
      let targetURL = url;
      const curURL = window.location.href.split('?')[1];
      if (curURL && curURL.split('parameter=')[1]) {
        targetURL = `${url.split('?')[0]}?${decodeURIComponent(window.location.href.split('?')[1]).split('parameter=')[1]}`;
      }
      initiator.setAttribute('href', (updatedLoginURL = createLoginLink({ type: 'connect' }, targetURL)));
      updatedRegisterURL = createRegisterLink({ type: 'connect' }, targetURL);
    } else if (ctaURLs[modalId].business) {
      updatedLoginURL = createLoginLink({ type: modalId }, url);
      updatedRegisterURL = createRegisterLink({ type: modalId }, url);
    } else if (modalId === 'personal') {
      document.querySelector('.cpc-modal-secure-personal').href = ctaURLs[modalId].url;
    } else if (modalId === 'automatic_tracking') {
      updatedRegisterURL = createRegisterLink({ type: 'automatic_tracking' }, url);
    } else if (modalId === 'sms') {
      updatedRegisterURL = createRegisterLink({ type: 'sms' }, url);
    } else {
      initiator.setAttribute('href', createLoginLink({ type: 'default' }, url));
    }

    initiator.addEventListener('click', clickHandler.bind(this));

    function followHref(e) {
      window.location = e.target.href || e.target.parentNode.href;
    }

    function followModal() {
      let ssoModalId = getModalId(initiator);
      if (ctaURLs[ssoModalId].useDefaultModal) {
        ssoModalId = ctaURLs[ssoModalId].useDefaultModal;
      }
      const modalInDOM = document.getElementById(ssoModalId);
      if (modalInDOM) {
        if (modal !== null) {
          modal = modals.find(ele => ele.modal.modal.id === ssoModalId);
          modal.focusedElementBeforeModal = initiator;
        }

        // To force the scale transition so that it looks like the modal has opened again
        modalInDOM.removeAttribute('style');
        modalInDOM.classList.remove('tingle-modal--visible');
        modalInDOM.setAttribute('aria-hidden', false);
        modalInDOM.setAttribute('tabindex', '0');
        document.body.classList.add('tingle-enabled');

        // Defer execution of adding the tingle-modal--visible attribute to give
        // the opportunity to animate the scale in effect, and defer input focus
        // as it seems to be needed in order for focus
        setTimeout(() => {
          modalInDOM.classList.add('tingle-modal--visible');
          setTimeout(() => {
            modalInDOM.querySelector('input').focus();
          }, modalAnimationTransitionTiming);
        }, 0);
        return;
      }

      const modalClasses = ['sign-in-modal', 'shipping-modal', 'cta-sso-modal'];
      const modalOptions = {
        title: '',
        autoOpen: false,
        closeMethods: ['overlay', 'escape'],
        cssClass: modalClasses,
        id: ssoModalId
      };

      const desktopSignInHtml = fillHtmlTemplate(getModalTemplate(initiator), ssoModalId, url, {
        suppressBusinessUrl: true
      });

      modal = new ModalConstructor(initiator, modalOptions);
      modal.setContent(desktopSignInHtml);
      initializeSignInDesktop(ssoModalId);
      modal.open();
      modals.push(modal);
      usernameOnFocus(ssoModalId, updatedLoginURL, updatedRegisterURL);
    }
  
    function clickHandler(e) {
      if (isAuthenticated) return;

      e.preventDefault();
      e.stopPropagation();

      if (!getCookie('hc')) {
        load(captchaKey).then((recaptcha) => {
          recaptcha.execute('submit').then((token) => {
            $.ajax({
              url: '/cwc/components/rs/cwc/msform/auth',
              type: 'POST',
              data: `{ captchaResponse: ${token} }`,
              dataType: 'json',
              contentType: 'application/vnd.cpc.cwc-v1+json;',
              success: cb
            });
          }).catch(err => console.error(err));
        }).catch(err => console.error(err));
      } else if (Number(getCookie('hc')) > 0.6) {
        followModal();
      } else {
        followHref(e);
      }

      function cb(data) {
        isUx() ? setCookie('hc', 0.7) : setCookie('hc', parseFloat(data.score));
        if (Number(getCookie('hc')) > 0.6) {
          followModal();
        } else {
          // go to SSO
          followHref(e);
        }
      } 
    }
  });
}

function getModalId(initiator) {
  let modalId = initiator.getAttribute(modalSecureAttribute);
  if (!modalId) {
    modalId = [].slice
      .call(initiator.classList)
      .find(ele => ele.indexOf(modalSecureClassPrefix) === 0);
    if (modalId) modalId = modalId.substring(modalSecureClassPrefix.length + 1);
  }
  modalId = modalId.toLowerCase();
  return modalId;
}

function getModalTemplate(initiator) {
  const modalSecureClassSuffix = getModalId(initiator);
  return getHtmlTemplate(modalSecureClassSuffix);
}

function usernameOnFocus(ssoModalId, updatedLoginURL, updatedRegisterURL) {
  setTimeout(doFocus, modalAnimationTransitionTiming);

  function doFocus() {
    const modalInDOM2 = document.getElementById(ssoModalId);
    modalInDOM2.querySelector('input').focus();
    if (updatedLoginURL === '') return;
    modalInDOM2.querySelector('.cpidSignIn').setAttribute('action', updatedLoginURL);

    if (modalInDOM2.querySelector('.content .sso_link')) {
      modalInDOM2.querySelector('.content .sso_link').setAttribute('href', updatedRegisterURL);
    }
  }
}