/* eslint no-console: 0 */
/* eslint no-new: 0 */
/* eslint max-len: 0 */

import templates from './footer.component.html';
import getResourcesEN from './footer.locale.en';
import getResourcesFR from './footer.locale.fr';
import {
  getStore,
  storeKeys
} from '../../../../utils/data-store';
import DomainParser from '../../../../utils/domain-utils';
// import {
//   getContextPath
// } from '../../../../utils/url-utils';
/**
 * footer.component is implemented as a plain JS component
 *
 * This component consumes a LiveSite sitemap. The LiveSite sitemap
 * must be exposed via JSON as a part of the end-user payload. In
 * addition, some localization data is expected to be included in the
 * JSON payload which is not SiteMap specific, but is header specific.
 *
 * For example, the "Close" alt text for the close icon, the "Search" button
 * text, aria-role labels, etc.
 *
 * @example
 *
 * $('selector').cpc_nav()
 */
const logoUrls = {
  relUrl: 'assets/cpc/img/logos/',
  govCan: 'gov-canada-white-logo.svg',
};

const regexLib = {
  questionMarkAtEnd: /\?$/
};

function defineFooter(lang) {
  const footer = document.querySelector('#cpc-main-footer');
  const resources = lang === 'en' ? getResourcesEN() : getResourcesFR();
  const domainParser = new DomainParser(window.location.href);
  const assetSchemeAndRoot = `https://${domainParser.getCanadaPostDomain()}`;


  if (!footer) {
    // this particular page isn't using the footer. Avoid any further processing
    return;
  }

  const nav = (getStore(storeKeys.nav)) ? getStore(storeKeys.nav) : null;
  const footerNav = nav ? nav.nodes.find(ele => ele.label === 'Footer') : null;
  const supportUrls = footerNav && footerNav.nodes.find(
    ele => ele.label === 'Support');
  const corporateUrls = footerNav && footerNav.nodes.find(
    ele => ele.label === 'Corporate');
  const blogsUrls = footerNav && footerNav.nodes.find(
    ele => ele.label === 'Blogs');
  const documentManagementUrls = footerNav && footerNav.nodes.find(
    ele => ele.label === 'Document Management');
  const termsUrls = footerNav && footerNav.nodes.find(
    ele => ele.label === 'Terms');

  const footerHtml = templates.footer.main({
    footerAriaLabel: resources.ariaLandmarkLabel,
    supportUrls: supportUrls ? processLinkGroupSuppport(supportUrls) : '',
    socialMediaUrls: processSocialMediaIcons(),
    feedback: processFeedbackBlock(),
    connectWithUsHeading: resources.connectWithUs,
    supportHeading: resources.support,
    corporateHeading: resources.corporate,
    blogsHeading: resources.blogs,
    corporateUrls: corporateUrls ? processLinkGroup(corporateUrls) : '',
    blogsUrls: blogsUrls ? processLinkGroup(blogsUrls) : '',
    documentManagementUrls: documentManagementUrls ? processLinkGroup(documentManagementUrls) : '',
    termsUrls: termsUrls ? processLinkGroup(termsUrls, true) : '',
    classSuppressToolbar: getClassSuppressToolbar(),
    footerContentInfoLabel: resources.ariaContentInfoLandmarkLabel,
    copyright: resources.copyright,
    govCanLogo: assetSchemeAndRoot +
      nav.assetBaseUrl + logoUrls.relUrl +
      logoUrls.govCan,
    canada: resources.canada
  });

  footer.innerHTML = footerHtml;

  addQualtricsScript();
  addAccordionEasing();

  function addQualtricsScript() {
    let qualtricsPlaceholder = document.querySelector('.cpc-footer-container');
    if (!qualtricsPlaceholder) {
      qualtricsPlaceholder = document.querySelector('body');
    }
    const script = document.createElement('script');
    script.innerHTML = templates.footer.qualtrics();
    qualtricsPlaceholder.appendChild(script);
  }

  function addAccordionEasing() {
    const $ = window.jQuery;
    $('.cpc-footer-container .accordion').on('click', 'li', easeAccordion);

    function easeAccordion() {
      $('li.active').find('.content').slideUp();
      if (!$(this).hasClass('active')) {
        $(this).find('.content').slideToggle();
      }
    }
  }

  function processLinkGroup(container, hideHeader) {
    let links = '';

    container.nodes.forEach((node) => {
      links += templates.footer.links({
        linkUrl: getDomainUrl(node.link),
        linkLabel: node.label,
        linktarget: (node.linktarget === undefined) ? '' : node.linktarget
      });
    });

    return templates.footer.linkContainer({
      linksHeading: hideHeader ? '' : templates.footer.heading(container.description),
      socialMediaClass: '',
      linksUrl: links
    });
  }

  function getDomainUrl(url) {
    return domainParser.isUrlSameDomain(url) ?
      assetSchemeAndRoot + url.replace(regexLib.questionMarkAtEnd, '') :
      url;
  }

  function processSocialMediaIcons() {
    let links = '';

    resources.socialMediaURLs.forEach((node) => {
      links += templates.footer.socialMediaLinks({
        linkUrl: getDomainUrl(node.url),
        svg: node.iconSVG
      });
    });
    return templates.footer.linkContainer({
      linksHeading: templates.footer.heading(resources.connectWithUs),
      socialMediaClass: 'class="social-media-icons"',
      linksUrl: links
    });
  }

  function processLinkGroupSuppport(container, hideHeader) {
    let links = '';

    container.nodes.forEach((node) => {
      links += templates.footer.supportLinks({
        linkUrl: getDomainUrl(node.link),
        linkLabel: node.label,
        linktarget: (node.linktarget === undefined) ? '' : node.linktarget
      });
    });
    return templates.footer.linkContainer({
      linksHeading: hideHeader ? '' : templates.footer.heading(container.description),
      socialMediaClass: '',
      linksUrl: links
    });
  }


  function processFeedbackBlock() {
    let links = '';

    resources.feedback.forEach((node) => {
      links += templates.footer.feedbackLinks({
        text: node.name,
        linkUrl: getDomainUrl(node.url),
        iconClass: node.iconClass,
        title: resources.newWindow
      });
    });
    return templates.footer.linkContainer({
      linksHeading: '',
      socialMediaClass: 'class="feedback-group"',
      linksUrl: links
    });
  }

  function getClassSuppressToolbar() {
    const cwc = getStore(storeKeys.cwc);
    return (cwc && cwc.isSuppressToolbar()) ? 'cpc-tb--suppress-toolbar' : '';
  }
}


export default defineFooter;