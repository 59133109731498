const templates = {
  footer: {
    main: items => `<div class='cpc-footer'>
<div class="noindex">
<section class="footer-l1" role='navigation' aria-label="${items.footerAriaLabel}"> 
  <div class="row show-for-large-up">
    <div class="large-3 columns">
    ${items.socialMediaUrls}
    ${items.feedback}
    </div>
    <div class="large-3 columns">
    ${items.supportUrls}
    </div>
    <div class="large-3 columns">
    ${items.corporateUrls}
    </div>
    <div class="large-3 columns">
    ${items.blogsUrls}
    </div>
  </div>
  <div class="row show-for-medium-only">
    <div class="medium-6 columns">
    ${items.socialMediaUrls}
    ${items.feedback}
    ${items.supportUrls}
    </div>
    <div class="medium-6 columns">
    ${items.corporateUrls}

    ${items.blogsUrls}
    </div>
  </div>
  <div class="row show-for-small-only">
    <div class="small-12 columns">
      <ul class="accordion" data-accordion="data-accordion" role="tablist">
        <li>
          <h2>${items.connectWithUsHeading}</h2>
          ${items.socialMediaUrls}
          ${items.feedback}
          <hr>
        </li>
        <li class="accordion-navigation">
          <a href="#panelSupport" role="tab" class="accordion-title" id="panelSupport-heading" aria-controls="panelSupport"><h2>${items.supportHeading}</h2></a>
          <div id="panelSupport" class="content" role="tabpanel" aria-labelledby="panelSupport-heading">
            ${items.supportUrls}
          </div>
          <hr>
        </li>
        <li class="accordion-navigation">
          <a href="#panelCorporate" role="tab" class="accordion-title" id="panelCorporate-heading" aria-controls="panelCorporate"><h2>${items.corporateHeading}</h2></a>
          <div id="panelCorporate" class="content" role="tabpanel" aria-labelledby="panelCorporate-heading">
            ${items.corporateUrls}
          </div>
          <hr>
        </li>
        <li class="accordion-navigation">
          <a href="#panelBlogs" role="tab" class="accordion-title" id="panelBlogs-heading" aria-controls="panelBlogs"><h2>${items.blogsHeading}</h2></a>
          <div id="panelBlogs" class="content" role="tabpanel" aria-labelledby="panelBlogs-heading">
            ${items.blogsUrls}
          </div>
          <hr>
        </li>
      </ul>
    </div>
  </div>
</section>

<section class="footer-l2 ${items.classSuppressToolbar}" role='contentinfo' aria-label="${items.footerContentInfoLabel}">
  <div class="row large-up-footer show-for-large-up">
    <div class="large-12 columns text-center">
      <div class="left-items text-left">
      ${items.copyright}
      </div>
      <div class="center terms-links">
      ${items.termsUrls}
      </div>
      <div class="right-items">
      <a href="https://www.canada.ca"><img class="cl-white" src="${items.govCanLogo}" alt="${items.canada}"/></a>
      </div>
    </div>
  </div>
  <div class="row show-for-medium-only">
    <div class="large-12 columns text-center">
      
      <div class="terms-links">
      ${items.termsUrls}
      </div>
        <div class="cpc-corp-copyright">
        ${items.copyright}

          <div class="gov-can-logo">
          <a class="cl-white" href="https://www.canada.ca"><img src="${items.govCanLogo}" alt="${items.canada}"/></a>
          </div>
        </div>
    </div>
  </div>
  <div class="row show-for-small-only">
    <div class="large-12 columns text-center">
      
      <div class="center terms-links">
      ${items.termsUrls}
      </div>
      <div class="row">
        <div class="large-12 columns">
          <div class="cpc-corp-copyright">
          ${items.copyright}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="large-12 columns">
        <div class="center">
        <a class="cl-white" href="https://www.canada.ca"><img src="${items.govCanLogo}" alt="${items.canada}"/></a>
        </div>
        </div>
      </div>
    </div>
  </div>
</section>
</div>
</div>
<div id='ZN_0xleIR6sWSZaNY9'></div>
`,
    qualtrics: () => `
  (function(){var g=function(e,h,f,g){
  this.get=function(a){for(var a=a+"=",c=document.cookie.split(";"),b=0,e=c.length;b<e;b++){for(var d=c[b];" "==d.charAt(0);)d=d.substring(1,d.length);if(0==d.indexOf(a))return d.substring(a.length,d.length)}return null};
  this.set=function(a,c){var b="",b=new Date;b.setTime(b.getTime()+6048E5);b="; expires="+b.toGMTString();document.cookie=a+"="+c+b+"; path=/; "};
  this.check=function(){var a=this.get(f);if(a)a=a.split(":");else if(100!=e)"v"==h&&(e=Math.random()>=e/100?0:100),a=[h,e,0],this.set(f,a.join(":"));else return!0;var c=a[1];if(100==c)return!0;switch(a[0]){case "v":return!1;case "r":return c=a[2]%Math.floor(100/c),a[2]++,this.set(f,a.join(":")),!c}return!0};
  this.go=function(){if(this.check()){var a=document.createElement("script");a.type="text/plain";a.className="optanon-category-C0002";a.src=g+ "&t=" + (new Date()).getTime();document.body&&document.body.appendChild(a)}};
  this.start=function(){var a=this;window.addEventListener?window.addEventListener("load",function(){a.go()},!1):window.attachEvent&&window.attachEvent("onload",function(){a.go()})}};
  try{(new g(100,"r","QSI_S_ZN_0xleIR6sWSZaNY9","https://zn0xleir6swszany9-canadapostdigital.siteintercept.qualtrics.com/WRSiteInterceptEngine/?Q_ZID=ZN_0xleIR6sWSZaNY9&Q_LOC="+encodeURIComponent(window.location.href))).start()}catch(i){}})();
  `,
    linkContainer: items => `
    ${items.linksHeading}
    <ul ${items.socialMediaClass}>
      ${items.linksUrl}
    </ul>    
    `,
    heading: header => `<h2 class="show-for-medium-up">${header}</h2>`,
    links: items => `
    <li><a href='${items.linkUrl}' target='${items.linktarget}'>${items.linkLabel}</a></li>
    `, 
    supportLinks: items => `
    <li><a href='${items.linkUrl}' class='chat-link' target='${items.linktarget}'>${items.linkLabel}</a></li>
    `,
    socialMediaLinks: items => `
    <li><a href='${items.linkUrl}' target='_blank'>${items.svg}</a></li>
    `,
    feedbackLinks: items => `
    <li>
      <div class="cpc-tb--icon cpc-tb--icon-${items.iconClass} pk"></div>
      <a href="${items.linkUrl}" target="_blank" title="${items.title}">
        <span class="tool-description">${items.text}</span>
      </a>
    </li>
    `
  },
};

export default templates;