/* eslint-env jquery */

/**
 * Responsible for rendering the global alert banner if a message is
 * available, and related user interactions
 * 
 * Merchant home has no banner for the cwc.js
 */
import { getPageLanguage } from '../../../../utils/language.data';
import { getStore, storeKeys } from '../../../../utils/data-store';
import { setPreoutClose } from '../../../../utils/preoutClose.data';
import globals from '../../../../utils/globals';
import locale from './global-alert-banner.locale';
import MessageBannerData from '../../../../utils/message-banner.data';


function initializeGlobalBanner() {
  const globalBanner = new GlobalAlertBanner(
    document.getElementById('mainNav')
  );
  new MessageBannerData().initialize(globalBanner);
}

class GlobalAlertBanner {
  constructor(parentElement) {
    this.parentElement = parentElement;
  }

  initialize() {
    const bannerHtml = getBanner();

    if (bannerHtml) {
      if (this.parentElement) this.parentElement.appendChild(bannerHtml);

      this.alertBanner = document.querySelector(
        `.${constants.globalAlertBannerClass}:not(.cpc-global-alert-banner--beta-user)`
      );
      this.closeButton = this.alertBanner.querySelector(
        `.${constants.closeIconClass}`
      );
    }
    setEventHandlers.call(this);
  }
}

let isGlobalMessage;
const language = getPageLanguage();
const constants = {
  globalAlertBannerClass: 'cpc-global-alert-banner',
  closeIconClass: 'cpc-global-alert-banner__close-icon',
};

const template = items => `
<div class="cpc-global-alert-banner__inner">
<div class="cpc-global-alert-banner__message">
<div class="cpc-global-alert-banner__info" ></div>
  ${items.message}
</div>
</div>
<a href="#" class="${constants.closeIconClass}" ${globals.a11y.ariaLabel}="${items.closeAriaLabel}"></a>
`;


/**
 * Returns null if there is no global alert banner, otherwise
 * returns a populated document fragment
 */
function getBanner() {
  const message = getStore(storeKeys.banner);
  if (!message) return null;
  const fragment = document.createDocumentFragment();
  isGlobalMessage = true;
  const containerDiv = createBannerContainer(isGlobalMessage);
  containerDiv.innerHTML = template({
    message: message[language],
    closeAriaLabel: locale[language].close,
  });
  fragment.appendChild(containerDiv);
  return fragment;
}

function createBannerContainer(globalMsg) {
  const containerDiv = document.createElement('article');
  containerDiv.setAttribute(globals.a11y.ariaRole, 'banner');
  containerDiv.className = constants.globalAlertBannerClass;

  if (!globalMsg) {
    containerDiv.className = `${constants.globalAlertBannerClass} cpc-global-alert-banner--beta-user`;
  }
  return containerDiv;
}


function setEventHandlers() {
  if (this.alertBanner && this.closeButton) {
    this.closeButton.addEventListener('click', onCloseClick.bind(this));
  }
}


function onCloseClick(evt) {
  evt.preventDefault();
  setBannerHeight.call(this);
  // close class animates height of banner to 0

  setTimeout(() => {
    this.alertBanner.addEventListener('transitionend', () => {
      this.alertBanner.parentNode.removeChild(this.alertBanner);
    });
    this.alertBanner.classList.add('close');
  }, 0);
  setPreoutClose('1');
}

/**
 * In order to animate a slide up well, you need to set the actual height of the element as a CSS style property and then animate the height to 0
 */
function setBannerHeight() {
  this.alertBanner.style.height = '';
  const bannerHeight = getBannerHeight.call(this);
  this.alertBanner.style.height = `${bannerHeight}px`;
}

function getBannerHeight() {
  return this.alertBanner.getClientRects()[0].height;
}

export {
  initializeGlobalBanner,
  GlobalAlertBanner,
};