/* eslint no-console: 0 */
/* eslint no-new: 0 */

/**
 * Components have their own -module.js file, where you import
 * everything that they require - a bit cleaner to include Components
 * in the main app.js.
 */
import './modal.component';
import './modal.html-attribute-initiator';
import './modal.sso-attribute-initiator';
