export default items => `
  <form method="post" action="${items.ssoLink}" name="headerSISU" class="cpidSignIn sso_form">
    ${items.leftAreaCopy}
    <label for="${items.usernameId}" class="sign-in-input-label">${items.username}</label>
    <input id="${items.usernameId}" class="sign-in-modal-input" name="username" type="text">
    <div class="remember-me-container cpc-control-group">
      <div class="cpc-control-option">
        <input value="1" id="${items.rememberMeId}" class="sign-in-modal-input" name="rememberme" type="checkbox">
        <label for="${items.rememberMeId}">${items.rememberUsername}</label>
      </div>  
    </div>
    <label for="${items.passwordId}" class="sign-in-input-label">${items.password}</label>
    <input id="${items.passwordId}" class="sign-in-modal-input" name="password" type="password" autocomplete="nope">
    <input value="${items.signInButton}" class="button sign-in-up-buttons" type="submit" role="button">
    <p class="forgot-username-password">${items.forgot}
      <a tabindex="0" href="${items.ssoForgotUsernameLink}" class="sso_link" businessurl="https://www.canadapost-postescanada.ca/dash-tableau/${items.cpcLang}/" tabindex="-1">${items.fpUsername}?</a> ${items.or} <a tabindex="0" href="${items.ssoForgotPasswordLink}" class="sso_link" businessurl="https://www.canadapost-postescanada.ca/dash-tableau/${items.cpcLang}/" tabindex="-1">${items.fpPassword}?</a>
    </p>
    <input name="cpidSignIn_SUBMIT" value="1" type="hidden">
    <input name="cpidSignIn_widgetSignIn" value="1" type="hidden">
    ${items.footnoteDesktop}
  </form>
`;
