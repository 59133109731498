const ssoLocaleFr = {

  true: 'vrai',
  login: 'ouvertur-session',
  registration: 'inscription',
  signIn: 'ouverture-session',
  forgotUsername: 'nom-utilisateur-oublie',
  forgotPassword: 'mot-de-passe-oublie',
  authlvl: 'nivaut',
  businessUrl: 'urlEntreprise',
  personalUrl: 'urlPersonnel',
  forceVouchFor: 'connOblig',
  language: 'langue',
  business: 'entreprise',
  personal: 'personnel',
  existing: 'existant',
  sourceApp: 'appSource',
  sourceUrl: 'urlSource',
  stepupId: 'idCreationProfil',
  targetUrl: 'urlCible',
  AUTOMATIC_TRACKING: 'SUIVI-AUTOMATISE',
  EPOST: 'POSTEL',
  ESTO: 'OEE',
  RSO: 'SRL',
  SHOP: 'MAGASIN',
  ShipOnline: 'ExpEnligne',
  SMB: 'PME',
  commercial_link: 'lien_commercial',
  consumer: 'consommateur',
  smb_link: 'pme_lien',
  smb_mode: 'pme_mode',
  SMS: 'TRSMS',
};

export default ssoLocaleFr;