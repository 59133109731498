/**
 * Takes a relative URL path and get context root path
 * @param {string} url - relative URL
 * /scp/fr/accueil.page -> /scp
 */

function getContextPath(url) {
  return url.substring(0, url.indexOf('/', 2));
}


function getUrlHashValue(param) {
  const urlHref = window.location.href;
  let index = urlHref.indexOf(`#${param}`);

  if (index > -1) {
    index += param.length + 2; // will not include # and =
    return urlHref.substr(index);
  }
  return false;
}

function setOrUpdateHashParameter(url, hashParameterName, hashParameterValue) {
  const hashParam = `#${hashParameterName}`;
  const hashPosition = url.indexOf(hashParam);
  const currentHash = window.location.hash.substr(1);
  if (hashPosition > -1) {
    return url.replace(currentHash, `${hashParameterName}=${hashParameterValue}`);
  }
  return `${url}#${hashParameterName}=${hashParameterValue}`;
}

/**
 * removes specific hash param and using history pushState 
 * in order to have a clean uri and not resorting to reload page
 * @param {string} param 
 */
function removeHashParamater(param) {
  const urlHref = window.location.href;
  const hashParam = `#${param}`;

  const hashPosition = urlHref.indexOf(hashParam);
  if (hashPosition > -1) {
    const hashLength = window.location.hash.length;
    const parsedURI = urlHref.substring(hashPosition, hashLength);
    window.history.pushState(null, null, parsedURI);
  }
}

/**
 * Gets url search parameter value by including key value
 * @param {key} string
 */
function getUrlParameterValue(key) {
  // const name = key.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${key}=([^&#]*)`);
  const results = regex.exec(location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export {
  getContextPath,
  getUrlHashValue,
  getUrlParameterValue,
  setOrUpdateHashParameter,
  removeHashParamater
};