/* eslint no-console: 0 */
/**
 * Components have their own -module.js file, where you import
 * everything that they require - a bit cleaner to include Components
 * in the main app.js.
 */
// import './toolbar.locale.en';
// import './toolbar.locale.fr';
import { initializeComponent, getHtml, isToolbarDefined } from './toolbar.component';

export { initializeComponent, getHtml, isToolbarDefined };