// import Inbox from './inbox';
// import Profile from './profile';
import Snapship from './snapship';
import SkipNav from './skip-nav';

function CpcHeader() {
  const component = document.querySelector('cpc-header');
  component.classList.add('cpc-component');

  const suppressProductNav = component.getAttribute('data-suppress-product-nav') || 'true';
  const suppressToolbar = component.getAttribute('data-suppress-toolbar') || 'true';
  const currentPage = component.getAttribute('data-current-page');
  const appId = component.getAttribute('data-app-id');
  const skipNavPrefix = component.getAttribute('data-skip-prefix') || '';
  const skipNavId = SkipNav.setSkipNavId(component.getAttribute('data-skip-id') || 'main-content', component);
  const suppressHeaderSticky = component.getAttribute('data-suppress-header-sticky') === 'true';

  component.innerHTML = `
    <div class="cpc-skip-nav"><a href="${skipNavPrefix}#${skipNavId}" class="skip-nav" target="_self"><span class="cpc-skip-nav-label"></span></a></div>
    <div id="mainNav" class="cpc-nav" data-suppress-product-nav="${suppressProductNav}" data-suppress-toolbar="${suppressToolbar}"
      data-current-page="${currentPage}"></div>
  `;

  return {
    appId,
    currentPage,
    suppressHeaderSticky,
    suppressToolbar,
  };
}

function CpcFooter() {
  const component = document.querySelector('cpc-footer');

  if (component) {
    component.classList.add('cpc-component');
    component.innerHTML = '<div id="cpc-main-footer" class="cpc-footer-container"></div>';
  }

  // Some sites won't have a footer like precision targeter. If we 
  // return an object of all possible components, we should probably track
  // whether or not those components are in use on a site
  return { 
    isDefined: !!component
  };
}

const cpcApps = {
  snapship: Snapship
};

function CpcApp(appId, lang, currentPage) {
  const opts = {
    appId,
    language: lang,
    currentPage
  };

  const initializer = cpcApps[appId];
  return (initializer !== undefined) ? initializer(opts) : opts;
}

function Cwc(lang) {
  const cpcHeader = new CpcHeader();
  const cpcFooter = new CpcFooter();
  const cpcApp = new CpcApp(cpcHeader.appId, lang, cpcHeader.currentPage);

  return {
    language: lang,
    cpcHeader,
    cpcFooter,
    cpcApp,
    isSuppressHeaderSticky,
    isSuppressToolbar
  };

  /**
   * Check whether need to sticky the cpc header or not
   *
   * @returns {boolean|*}
   */
  function isSuppressHeaderSticky() {
    return cpcHeader.suppressHeaderSticky;
  }

  function isSuppressToolbar() {
    return cpcHeader.suppressToolbar === 'true';
  }
}

export default Cwc;