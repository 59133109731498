function setLocaleFr(params = {}) {
  return {
    aboutus: 'Notre entreprise',
    aboutUs: '&Agrave; notre sujet',
    aboutusTab: 'Notre entreprise',
    ariaLabelMobile: 'Mobile navigation principale',
    backLabel: 'Retour',
    backMainMenu: 'Retour à la navigation principale',
    business: 'Entreprise',
    businessOverview: 'D&eacute;couvrir les services aux entreprises',
    businessTab: 'Entreprise',
    cartLabel: 'Panier',
    cartURL: '/store-boutique/fr/panier',
    closeLabel: 'Fermer',
    createAccount: 'Ouvrir un compte',
    dashboard: 'Tableau de bord',
    eshopOverview: 'Accueil de la Boutique',
    eshopTab: 'Boutique',
    findPostalCode: 'Trouver un code postal',
    findPostalCodeURL: '/scp/fr/outils/trouver-un-code-postal.page',
    findRate: 'Trouver un tarif',
    findRateURL: '/scp/fr/outils/trouver-un-tarif.page',
    forgot: 'Oubli&eacute; votre',
    forgotPasswordLabel: 'mot de passe',
    forgotPasswordPasswordLabel: 'mot de passe',
    forgotPasswordUsernameLabel: "nom d'utilisateur",
    home: '/scp/fr/accueil.page',
    lang_toggle: 'English',
    linkAboutUs: '/scp/fr/notre-entreprise/a-notre-sujet.page',
    linkMySupport: '/information/app/msc/soutien',
    linkSignIn: '/lfe-cap/fr/ouvertur-session',
    linkSupport: '/scp/fr/soutien.page',
    linkBusinessSettings: '/bam-gce/fr/profil',
    linkInvoices: '/fam-gcf/fr/facturation-et-paiements',
    logoAltText: 'Postes Canada',
    mainNavigationAriaLabel: 'Navigation principale',
    mainNavMobile: 'Mobile navigation principale',
    mainMenu: 'Retour au menu principal',
    menu: 'Menu',
    moreToolsURL: '/scp/fr/outils.page',
    myAccount: 'Mon compte',
    myAccountTab: 'Mon compte',
    myprofile: 'Mon profil',
    businessSettings: "Paramètres d'entreprise",
    invoices: 'Facturation et paiements',
    mysupport: 'Mon soutien',
    supportUrl: '/scp/fr/soutien.page',
    opensInNewTab: 'Ouvrir dans un nouvel onglet',
    or: 'ou',
    orders: 'Commandes',
    overview: ': aperçu',
    ourcompanyOverview: 'Découvrir notre entreprise',
    password: 'Mot de passe',
    personal: 'Personnel',
    personalOverview: 'D&eacute;couvrir les services personnels',
    personalTab: 'Personnel',
    personalURl: '/scp/fr/personnel.page',
    productionNavigationAriaLabel: 'Navigation du produit',
    rememberUsername: 'M&eacute;moriser mes coordonn&eacute;es',
    searchAriaLabel: "Cherchez ici : produits, articles connexes et rubriques d'aide",
    searchErrorText: 'Veuillez saisir un sujet. Par exemple, envoyer un colis, acheminer mon courrier',
    searchErrorTextMobile: 'Veuillez saisir un sujet.',
    searchLabel: 'Rechercher',
    searchText: 'Recherchez notre site Web',
    seeMoreTools: "Voir plus d'outils",
    shop: 'Magasiner',
    signInButton: 'Ouvrir une session',
    signInHeader: 'Acc&eacute;dez &aacute; votre compte',
    signInRegister: "Ouvrir une session ou s'inscrire",
    signOut: 'Fermer la session',
    signUp: 'Ouvrir un compte',
    signUpText: 'Créez un compte en ligne en quelques étapes faciles!',
    support: 'Soutiens',
    tools: 'Outils',
    track: 'Rep&eacute;rage',
    username: "Nom d'utilisateur",
    welcome: 'Allo',
    nodes: [{
      labelId: 'QuickLinks',
      label: 'Recherches populaires',
      nodes: [{
        label: 'Trouver un code postal',
        description: 'Trouver un code postal',
        link: `${params.cpDomain}/scp/fr/outils/trouver-un-code-postal.page`,
        icon: 'look-up-postal-code'
      },
      {
        label: 'Prix des timbres',
        description: 'Prix des timbres',
        link: `${params.cpDomain}/scp/fr/personnel/expedier/lettres-courrier/tarifs-affranchissement.page`,
        icon: 'stamp-prices'
      },
      {
        label: 'Réacheminement du courrier',
        description: 'Réacheminement du courrier',
        link: `${params.cpDomain}/scp/fr/personnel/recevoir/gerer-votre-courrier/reacheminement-du-courrier.page`,
        icon: 'mail-forwarding'
      },
      {
        label: 'Repérage',
        description: 'Repérage',
        link: `${params.cpDomain}/track-reperage/fr`,
        icon: 'track'
      },
      {
        label: 'Guides des services postaux',
        description: 'Guides des services postaux',
        link: `${params.cpDomain}/scp/fr/soutien/information-sur-les-services-postaux.page`,
        icon: 'all-postal-guides'
      },
      {
        label: 'Soutien',
        description: 'Soutien',
        link: `${params.cpDomain}/scp/fr/soutien.page`,
        icon: 'support'
      }
      ]
    }],
    gdpr_cookie_banner_title: 'Site Web de Postes Canada et t\u00E9moins',
    gdpr_cookie_banner_policy: `Des t\u00E9moins sont enregistr\u00E9s sur votre appareil pour am\u00E9liorer votre exp\u00E9rience de navigation. Ils sont s\u00E9curitaires et ne contiennent pas de renseignements confidentiels. Au besoin, vous pouvez <a href="${params.cpDomain}/scp/fr/soutien/bc/demandes-generales/renseignements-generaux/supprimer-les-temoins-dans-votre-navigateur">modifier les t\u00E9moins</a> dans les param\u00E8tres de votre navigateur. Si vous continuez sans changer vos param\u00E8tres, nous supposerons que vous acceptez tous les t\u00E9moins du site Web de Postes Canada. <a href="${params.cpDomain}/scp/fr/notre-entreprise/a-notre-sujet/politique-protection-renseignements-personnels/introduction-politique-renseignements-personnels.page">Pour en savoir plus, consultez notre politique sur la protection des renseignements personnels.</a>`,
    gdpr_cookie_banner_btn_text: 'Je comprends',
    gdpr_cookie_banner_btn_alt: 'Cliquez sur le bouton \u00AB Je comprends \u00BB pour supprimer le message sur les t\u00E9moins.',
    gdpr_cookie_banner_readmore: 'Lire la suite'
  };
}

export default setLocaleFr;