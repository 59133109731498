/* OneTrust Cookies Consent Notice test script */
/* eslint-disable */

import authModule from './auth.module';
import { getLANG } from './language.data';
import { getCookie } from './cookies';

const oneTrustEnv = (window.location.host.slice(0, 3) === 'www' ? '81e27fc3-6180-4e36-93de-ce5db7c16b79' : '81e27fc3-6180-4e36-93de-ce5db7c16b79-test');
// const otAutoBlock = `https://cdn.cookielaw.org/consent/${oneTrustEnv}/OtAutoBlock.js`;
const otSDKStub = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';

const homePageUrlEn = '/cpc/en/home.page';
const homePageUrlFr = '/scp/fr/accueil.page';
const currentUrl = window.location.pathname;
let cookiePrefId;
let timerInterval;
let timerInterval2;

function createScript(path) {
  const s = document.getElementsByTagName('script')[0];
  const b = document.createElement('script');
  b.type = 'text/javascript';
  b.async = false;
  b.src = path;
  b.dataset.domainScript = oneTrustEnv;
  b.dataset.documentLanguage = true;
  s.parentNode.insertBefore(b, s);
}

function OptanonWrapper() {
  // Dispatch event for Adobe Launch
  window.dispatchEvent(new Event('oneTrustCategoriesChanged'));
}

OptanonWrapper();

function cookiePolicy() {
  if (getLANG() === '' && (currentUrl === homePageUrlEn || currentUrl === homePageUrlFr)) return;
  // createScript(otAutoBlock);
  createScript(otSDKStub);
}

document.addEventListener("DOMContentLoaded", () => {
  const isAuthenticated = authModule.getIsAuthenticated();
  
  // Cycle 1
  function startCycle() {
    timerInterval = setInterval(getClickReady, 500);
  }

  function stopCycle() {
    clearInterval(timerInterval);
    timerInterval = null;
  }

  function getClickReady() {
    if (document.body.contains(document.getElementById('onetrust-consent-sdk')) === true) {
      convertUserOnClick();
      stopCycle();
    }
  }

  if (isAuthenticated && getCookie('OptanonAlertBoxClosed') === '') {
    startCycle();
  }

  function convertUserOnClick() {
    // Get meta tag content
    // const policyUrlE = '/cpc/en/our-company/about-us/transparency-and-trust/privacy-centre/cookie-policy.page';
    // const policyUrlF = '/scp/fr/notre-entreprise/a-notre-sujet/transparence-et-confiance/centre-de-protection-de-la-vie-privee/politique-sur-les-temoins.page';
    try {
      cookiePrefId = document.querySelector('meta[name="cookie-pref-id"]').getAttribute('content');
      console.log(`The cookie pref id is: ${cookiePrefId}`);
    } catch (err) {
      console.log(`The cookie pref id is blank: ${cookiePrefId}`);
    }
    if (typeof cookiePrefId !== 'undefined' && document.body.contains(document.getElementById('onetrust-consent-sdk')) === true) {
      // Assign to buttons
        // document.getElementById('onetrust-pc-btn-handler').addEventListener('click', () => startCycle2());
        Array.from(document.getElementsByClassName('save-preference-btn-handler')).forEach(function (element) {
          element.addEventListener('click', () => convertanonymoususer(cookiePrefId));
        });
        if (document.getElementById('onetrust-reject-all-handler') !== null) {document.getElementById('onetrust-reject-all-handler').addEventListener('click', () => convertanonymoususer(cookiePrefId));}
        if (document.getElementById('onetrust-accept-btn-handler') !== null) {document.getElementById('onetrust-accept-btn-handler').addEventListener('click', () => convertanonymoususer(cookiePrefId));}
      // if (window.location.pathname === policyUrlE || window.location.pathname === policyUrlF) {
      //   document.getElementById('ot-sdk-btn').addEventListener('click', () => startCycle2());
      // }
    }
  };
});

// Update OneTrust Data
function convertanonymoususer(identifier) {
  const consentIntergration = OneTrust.GetDomainData().ConsentIntegrationData;
  OneTrust.setDataSubjectId(identifier, false);
  const consentPayload = consentIntergration.consentPayload;
  consentPayload.identifier = identifier;
  consentPayload.isAnonymous = false;
  const url = consentIntergration.consentApi;
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  const requestOptions = {
    method: 'POST',
    headers,
    body: JSON.stringify(consentPayload),
    redirect: 'follow',
  };
  fetch(url, requestOptions);
}

export default cookiePolicy;