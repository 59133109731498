/**
 * Main entry point for the shared header/footer distribution that we provide to other Canada Post
 * properties, such as tools, shop, support
 */
import './utils/polyfills/string.prototype.startsWith';
import './utils/polyfills/string.prototype.endsWith';
import './utils/polyfills/array.prototype.find';
import './utils/polyfills/nodelist.prototype.foreach';
import './utils/polyfills/object.assign';
import './utils/head-utils';
import BodyUtils from './utils/body-utils';
import '../../lib/tingle.js/tingle';
import globals from './utils/globals';
import FocusRingHandler from '../cpc/utils/focus-ring-handler';

import './components/global/combinations/cpc-controls/controls';
import './components/global/combinations/cpc-modal/modal.module';
import sitemap from './components/elements/sitemap/sitemap';
import initializeSkipNav from './components/global/combinations/cpc-skip-nav/skipnav.component';
import './components/global/combinations/Back-To-Top-CG/backtotop.module';
import initializeFeedbackModal from './components/global/combinations/feedback-modal/feedback-modal';
import header from './components/global/combinations/Header-CG/header.module';
import footer from './components/global/combinations/Footer-CG/footer.module';
import { initializeLangToggle } from './components/global/combinations/lang-toggle/lang.toggle';
import initializeSearch from './components/global/combinations/Header-CG/search';
import initializeModals from './components/global/combinations/cpc-modal/modal.html-attribute-initiator';
import { setLANG, getPageLanguage } from './utils/language.data';
import Cwc from './utils/cwc/cwc';
import { getStore, setStore, storeKeys } from './utils/data-store';
import { getStoredCookies, syncAllCPCCookies } from './utils/cookies.data';
import { initializeBetaUser } from './components/global/combinations/Header-CG/user-beta';
import cookiePolicy from './utils/cookie.policy';

// TODO: remove the following import once legacy cpc tools is retired. See: IA-4312
// TODO: also remove line 83: createShipOnlineLegacyTool(pageLang);
import createShipOnlineLegacyTool from './cpc-legacy-tools';
import getStoreSiteMapNodes from './components/elements/sitemap/shop.sitemap';

const $ = window.jQuery;

cookiePolicy();

// Going to use the <html lang="" attribute to determine language for resource loading. This
// should be statically located in each HTML page
const pageLang = getPageLanguage();
$.cpc.lang = pageLang;

// Sync all persisted stored cookies
const storedCookiesObj = getStoredCookies();
syncAllCPCCookies(storedCookiesObj);

// We need to set the LANG cookie on every page load because our pages
// might link out to other pages like tools, which determine what language to render
// based on the value of the LANG cookie
setLANG(pageLang === 'en' ? 'e' : 'f');
window.CPC = window.CPC || {};
window.CPC.init = () => {
  if (window.CPC && window.CPC.globalNavigation) {
    setStore(storeKeys.nav, window.CPC.globalNavigation);
  }

  if (getStore(storeKeys.nav)) {
    // for all biz/personal pages
    start();
    return;
  }


  // for all other applications
  sitemap.get(pageLang, (nav) => {
    nav.nodes.push(getStoreSiteMapNodes());
    setStore(storeKeys.nav, nav);
    start();
  });
};

$(() => {
  window.CPC.init();
});

function start() {
  window.CPC = window.CPC || {};
  window.CPC.cwc = new Cwc(pageLang);

  setStore(storeKeys.cwc, window.CPC.cwc);
  // Inject environment + CWC application into body - do this before any async requests so UI
  // paints quickly
  new BodyUtils().initialize();

  header(pageLang);
  initializeSkipNav();
  initializeFeedbackModal({ isCommonComponent: true });
  $('#mainNav').cpc_nav();
  $('#mainNav').cpc_MobileNav();
  footer(pageLang);
  initializeSearch(pageLang, { isCommonComponent: true });
  // TODO: remove the following call once legacy cpc tools is retired. See: IA-4312
  createShipOnlineLegacyTool(pageLang);
  initializeModals({ isCommonComponent: true });
  initializeLangToggle(pageLang);
  setupFocusRingActivation();
  initializeBetaUser();
  $(document).foundation();
}

function setupFocusRingActivation() {
  new FocusRingHandler(
    `.${globals.cwc.componentClassName} a, .${globals.cwc.componentClassName} button`
  ).initialize();
}

// IA-3562
// Remove this if strange behaviour occurs as a result of this
window.addEventListener('resize', resizeWait);
document.addEventListener('DOMContentLoaded', setupFoundationResize);
let resizeTimer;
let curWindowWidth;
const pageYOffset = 100;

function setupFoundationResize() {
  curWindowWidth = document.body.clientWidth;
  if (curWindowWidth > globals.tabletUpperBound) {
    curWindowWidth = globals.tabletUpperBound + 1;
  } else if (curWindowWidth > globals.mobileUpperBound) {
    curWindowWidth = globals.mobileUpperBound + 1;
  } else {
    curWindowWidth = globals.mobileUpperBound;
  }

  window.onscroll = () => {
    if (
      curWindowWidth > globals.tabletUpperBound &&
      window.pageYOffset > pageYOffset &&
      document.querySelector('[data-suppress-header-sticky=true]') === null
    ) {
      adjustFoundationElements();
    }
  };
}

function resizeWait() {
  clearTimeout(resizeTimer);
  resizeTimer = setTimeout(() => {
    let curWindowWidthAfterResize = document.body.clientWidth;
    if (curWindowWidthAfterResize > globals.tabletUpperBound) {
      curWindowWidthAfterResize = globals.tabletUpperBound + 1;
    } else if (curWindowWidthAfterResize > globals.mobileUpperBound) {
      curWindowWidthAfterResize = globals.mobileUpperBound + 1;
    } else {
      curWindowWidthAfterResize = globals.mobileUpperBound;
    }
    if (curWindowWidthAfterResize !== curWindowWidth) {
      $(document).foundation();
      if (
        curWindowWidthAfterResize > globals.tabletUpperBound &&
        window.pageYOffset > pageYOffset &&
        document.querySelector('[data-suppress-header-sticky=true]') === null
      ) {
        adjustFoundationElements();
      }
    } else if (
      curWindowWidthAfterResize > globals.tabletUpperBound &&
      window.pageYOffset > pageYOffset &&
      document.querySelector('[data-suppress-header-sticky=true]') === null
    ) {
      adjustFoundationElements();
    }
    curWindowWidth = curWindowWidthAfterResize;
    // IA-3607
    document.body.classList.remove('mega-nav-open');
  }, 500);
}

function adjustFoundationElements() {
  document.body.classList.add('f-topbar-fixed');
  const stickyHeader = document.querySelector('.utility-business-nav-sticky-container');
  if (stickyHeader) {
    stickyHeader.classList.add('fixed');
  }
}
// End of fix for IA-3562

// Exports from this entry are exposed as part of the Webpack UMD library export. This
// will allow the Webpack distribution's API to be used as a global variable, or via
// CommonJS i.e. as part of a JS source code build of another application, etc.
export { header, footer, initializeSearch };
