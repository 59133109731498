import { getPageLanguage } from '../../../../utils/language.data';
import localeEn from './locale.en';
import localeFr from './locale.fr';

function getLocale() {
  const lang = getPageLanguage();

  return lang === 'fr' ? localeFr : localeEn;
}

export default getLocale();
