const langSelectorHtml = `
<div class="cpc-lang-selector-overlay-content" role="dialog" aria-labelledby="welcomeMsgLangSelector">
  <label id="welcomeMsgLangSelector">Welcome to Canada Post. Bienvenue &agrave; Postes Canada</label>
  <div class="cpc-logo"><img src="/cpc/assets/cpc/img/logos/cpc-logo.svg" alt="Canada Post/Postes Canada"></div>
  <div class="welcome-title" id="engWelcome">Welcome to Canada Post</div>
  <div class="welcome-title" id="freWelcome">Bienvenue &agrave; Postes Canada</div>
  <div class="lang-buttons">
    <a href="#" id="engLangSelector" class="lang-button">English</a>
    <a href="#" id="freLangSelector" class="lang-button">Fran&ccedil;ais</a>
  </div>
  <div class="canada-logo"><a href="https://www.canada.ca" id="canada-gov-logo"><img src="/cpc/assets/cpc/img/logos/gov-canada-white-logo.svg" alt="Canada"></a></div>
</div>
`;

export default langSelectorHtml;