const toolbarTemplate = (ariaLandmarkLabel, toolbarLinks, feedback, closeLabel) => `
<div class="cpc-tb--outer noindex">
  <div class="cpc-tb--desktop-aria-container" role="navigation" aria-label="${ariaLandmarkLabel}" aria-expanded="false">
    <div class="cpc-tb cpc-tb--desktop">
      <div class="cpc-tb--container">
        <ul class="cpc-tb--top">
          ${toolbarLinks}
        </ul>
      </div>
    </div>
    <div class="cpc-tb--bottom-tools cpc-tb--desktop"> 
      <div class="cpc-tb--toggler">
        <a href="#">
          <div class="cpc-tb--icon cpc-tb--icon-expand-toggle pk"></div><span class="tool-description">${closeLabel}</span></a>
      </div>
    </div>
  </div>
  <div class="cpc-tb cpc-tb--mobile" role="navigation" aria-label="{ariaLandmarkLabel}">
    <div class="cpc-tb--container">
      <ul class="cpc-tb--top">
        ${toolbarLinks}
      </ul>
    </div>
  </div>
</div>  
`;

const toolbarLinks = (linkVisibility, linkUrl, linkIcon, linkLabel) => `
    <li ${linkVisibility}>
          <a href="${linkUrl}">
            <div class="cpc-tb--icon ${linkIcon}"></div><span class="tool-description">${linkLabel}</span></a>
    </li>
`;

export { toolbarTemplate, toolbarLinks };