/**
 * Simple API to set the Skip navigation accessibility feature
 * If target ID is not found, insert a target after the source element
 *
 * @example
 * 
 */

export default class SkipNav {
  static setSkipNavId(id, sourceEle) {
    const skipToContentId = id;
    const mainContentEle = document.getElementById(skipToContentId);
    if (typeof (mainContentEle) === 'undefined' || mainContentEle === null) {
      const newSkipTarget = document.createElement('span');
      newSkipTarget.id = skipToContentId;
      insertAfter(newSkipTarget, sourceEle);
    }

    return skipToContentId;
  }
}

function insertAfter(newElement, targetElement) {
  const parent = targetElement.parentNode;

  if (parent.lastChild === targetElement) {
    parent.appendChild(newElement);
  } else {
    parent.insertBefore(newElement, targetElement.nextSibling);
  }
}