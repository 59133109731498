import signinForm from './sign-in.form.html';

/**
 * The sign-in model without registration link
 */
export default items => `
<div class="cpc-modal-template-modal-body">
  <div class="row sign-in-modal-content flex-row">
    <div class="columns">
      ${signinForm(items)}
    </div>
  </div>
</div>
`;
