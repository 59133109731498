/* eslint no-console: 0 */
/* eslint no-new: 0 */
/* eslint no-param-reassign: 0 */

/**
 * CPC Modal component. First draft of the modal component wraps the tingle.js modal API. 
 * 
 * @usage
 * 
 * via HTML attributes: 
 *   <div id="some-modal-id" data-cpc-modal-options="footer:true||
 *           stickyFooter:true||autoOpen:false||closeLabel:'Close'||
 *           closeMethods:['overlay','button', 
 *           'escape']||cssClass:['custom-class-1','custom-class-2']">
 *      This is my modal!
 *   </div>
 *   <span data-cpc-modal-id="some-modal-id">Click me!</span>
 * 
 * via JS: 
 *   var modalConstructor = require('modal.component');
 * 
 * Directly on webpage:
    import Modal from './components/global/combinations/cpc-modal/modal.component';
    // Test modal
    const modal = new Modal(null, {
      title: 'Ship a parcel online',
      footer: true,
      stickyFooter: false,
      closeMethods: ['overlay', 'button', 'escape'],
      closeLabel: 'Close',
      cssClass: ['custom-class-1', 'custom-class-2'],
      onOpen: () => {
        console.log('modal open');
      },
      onClose: () => {
        console.log('modal closed');
      },
      beforeClose: () => {
        // here's goes some logic
        // e.g. save content before closing the modal
        console.log('before close');
        return true; // close the modal
        // return false; // nothing happens
      }
    });
    modal.setContent(`
    <button class="tingle-modal-box__close-icon" aria-label="Close" tabindex="0"></button>
    <div class="row">
    <div class="large-6 columns">
    <h2>Ship a parcel online</h2>
    <p>Why you should sign up:</p>
    <ul>
      <li>Save on shipping and sending direct mail to your customers</li>
      <li>Get access to easy-to-use online shipping and direct marketing tools</li>
      <li>Enjoy pickup and return privileges</li>
      <li>Pay online by credit card or your account</li>
    </ul>

    <p>
    <a href="#" class="button">Sign up</a>
    </p>
    
    <p>Already a Solutions for Small Business member? 
    <a href="#">Sign in</a> to ship your parcel online</p>

    </div>
    <div class="large-6 columns">
    <h3>Stuff on this side</h3>
    <p>Stuff Stuff Stuff Stuff Stuff Stuff Stuff Stuff Stuff Stuff Stuff Stuff Stuff Stuff </p>
    </div>
    </div>
    
    `);
    // add a button
    modal.addFooterBtn('Primary action', 'button cta-class', () => {
      // here goes some logic
      modal.close();
    });

    // add another button
    modal.addFooterBtn('Secondary action', 'button secondary cta-second-class', () => {
      // here goes some logic
      modal.close();
    });

    // open modal
    modal.open();
 */
import CpcOption from '../cpc-controls/option';
import locale from './locale';

const tingle = require('../../../../../../lib/tingle.js/tingle');

if (!window.cpc) {
  window.cpc = {};
}

const focusableElementsString = `
a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]
`;

class Modal { 
  constructor(initiator, options) {
    this.options = options;
    this.options.closeMethods = ['overlay', 'escape'];
    this.options.closeLabel = this.options.closeLabel || locale.modal_close_label;
    this.options.title = (this.options.title || '').trim();

    this.setupEvents();

    /* eslint new-cap: 0 */
    this.modal = new tingle.modal(this.options);

    // In case modal needs an id for further manipulation
    if (this.options.id) {
      this.modal.modal.setAttribute('id', this.options.id);
    }

    if (initiator && initiator.tagName === 'A' && initiator.getAttribute('href')) { 
      this.options.href = initiator.getAttribute('href');
    }

    // Focus tracking for accessibility - focus back to 
    // initiator on modal close
    this.focusedElementBeforeModal = initiator;
  }

  setupEvents() {
    // tingle.js doesn't support multiple event handlers
    this.chainedEvents = { 
      beforeClose: this.options.beforeClose,
      onClose: this.options.onClose,
      onOpen: this.options.onOpen
    };
    this.events = { };

    // Events
    this.beforeClose = (...args) => {
      if (this.chainedEvents && this.chainedEvents.beforeClose) {
        this.chainedEvents.beforeClose.apply(args);
      }
      return true;
    };

    this.onOpen = (...args) => { 
      if (this.chainedEvents && this.chainedEvents.onOpen) {
        this.chainedEvents.onOpen.apply(args);
      }

      // TODO - address the root cause - why are event handlers
      // not getting cleaned up when modal windows close
      if (!this.modal) return;

      this.modal.modal.setAttribute('aria-hidden', false);
      this.modal.modal.setAttribute('tabindex', '0');
      this.modal.modal.setAttribute('role', 'dialog');

      this.focusableEles = Array.prototype.slice.call(
        this.modal.modal.querySelectorAll(focusableElementsString));
      this.firstInput = this.focusableEles[this.focusableEles.findIndex(
        ele => isVisible(ele))];
      this.lastInput = this.focusableEles[this.focusableEles.reverse().findIndex(
        ele => isVisible(ele))];

      this.contentCloseIcon = this.modal.modalBoxContent.querySelector(
        '.tingle-modal-box__close-icon');
      this.modalCloseButton = this.modal.modalCloseBtn;

      this.createTabHandler();
      this.createCloseHandler();
        
      // Focus into modal on open for keyboard trap purposes
      this.modal.modal.focus();

      const inputFields = this.modal.modal.querySelectorAll('input');
      if (inputFields && inputFields[0]) {
        inputFields[0].focus();
      }

      function isVisible(ele) { 
        const ret = !!ele.offsetParent;
        return ret;
      }

      this.createDocumentFocusHandler();
      Modal.ensureControlFocusHandlers();

      setupWhenToCheckOverflow(this.modal, this.options);
    };

    // If there is tab inside modal, or the content in the modal window may
    // dynamically changed(such as asynchronized call), so modal size may
    // get changed as well. To correctly display/hide scroll bar, we check
    // overflow if any events set in the options.
    //
    // It should be able to handle CustomEvent as well, so if the standard set
    // events cannot cover the requirement, CustomEvent can be engaged.
    //
    // Thought about using 'overflow:auto' on tingle-modal, but it shows the
    // v-scrollbar first, then hide it in half second, did not figure out
    // why, but creating a confusion UI effect. If we can understand why it
    // happen, we may not need this method here.
    //
    function setupWhenToCheckOverflow(modal, options) {
      const events = options.checkOverflowEvents;
      if (!events) return;

      for (let i = 0; i < events.length; i += 1) {
        modal.modal.addEventListener(events[i], modal.checkOverflow.bind(modal));
      }
    }

    // To be able to remove document focus event listener when modal window is closed, 
    // I use the pattern described on MDN at https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#The_value_of_this_within_the_handler
    this.handleEvent = (event) => {
      if (event.type === 'focus') {
        this.documentFocusHandler(event);        
      }
    };

    this.onClose = (...args) => { 
      this.removeDocumentFocusHandler();
    
      if (this.options.preserveOnClose) {
        this.modal.modal.setAttribute('aria-hidden', true);
        if (this.focusedElementBeforeModal) {
          if (this.focusedElementBeforeModal.getAttribute('data-activated-via') === 'keyboard') {
            this.focusedElementBeforeModal.focus();
            this.focusedElementBeforeModal = null;
          }
        } else { 
          document.querySelector(focusableElementsString).focus();
        }
      } else {
        if (this.chainedEvents && this.chainedEvents.onClose) {
          this.chainedEvents.onClose.apply(args);
        }
        if (this.focusedElementBeforeModal && this.focusedElementBeforeModal.getAttribute('data-activated-via') === 'keyboard') {
          this.focusedElementBeforeModal.focus();
          this.focusedElementBeforeModal = null;
        } else {
          document.querySelector(focusableElementsString).focus();
        }

        this.lastInput.removeEventListener('keydown', this.events.tabHandlerRef);
        this.firstInput.removeEventListener('keydown', this.events.shiftTabHandlerRef);

        // TODO - event handlers aren't getting cleaned up when modals
        // are closed. Need to figure out why and refactor code
        if (!this.modal) return;
          
        this.contentCloseIcon.removeEventListener('click', 
          /* eslint no-underscore-dangle: 0 */
          this.modal._events.clickCloseBtn);
        if (this.modalCloseButton) {
          this.modalCloseButton.removeEventListener('click', 
            /* eslint no-underscore-dangle: 0 */
            this.modal._events.clickCloseBtn);
        }

        this.modal.modal.setAttribute('aria-hidden', true);

        this.modal.destroy(); 
        this.modal = null;        

        const trigger = document.getElementById('trigger');
        if (trigger && trigger.classList.contains('active')) {
          trigger.classList.remove('active');
        }
      }        
    }; 

    this.options.beforeClose = this.beforeClose;
    this.options.onClose = this.onClose;
    this.options.onOpen = this.onOpen;
  }

  // Click to close modal 
  createCloseHandler() {
    this.events.closeHandlerRef = this.closeHandler.bind(this);

    // TODO - add close event handler to close icon
    this.contentCloseIcon.addEventListener('click', 
      /* eslint no-underscore-dangle: 0 */
      this.modal._events.clickCloseBtn);

    this.contentCloseIcon.addEventListener('keypress', (e) => {
      const key = e.which || e.keyCode;
      if (key === 13) {
        e.preventDefault();
        this.modal.close();
      }
    });    

    this.modalCloseButton = this.modal.modal.querySelector('.tingle-modal__close');
    
    if (this.modalCloseButton) {
      this.modalCloseButton.addEventListener('click', 
        /* eslint no-underscore-dangle: 0 */
        this.modal.close.bind(this.modal));
    }
  }
  
  //
  // modal key trapping for accessibility
  //
  createTabHandler() {
    /* set focus on first input */
    // this.modal.modalBoxContent.focus(); // this.firstInput.focus();

    /* redirect last tab to first input */
    this.events.tabHandlerRef = this.tabKeyHandler.bind(this);
    /* redirect first shift+tab to last input */
    this.events.shiftTabHandlerRef = this.shiftTabKeyHandler.bind(this);

    this.lastInput.addEventListener('keydown', this.events.tabHandlerRef);
    this.firstInput.addEventListener('keydown', this.events.shiftTabHandlerRef);
  }

  //
  // Need to explicitly call the checkbox API to 
  // get focus rings working around checkboxes that are 
  // dynamically added into DOM
  static ensureControlFocusHandlers() {
    CpcOption.focusHandler();
  }
  //
  // If focus gets to an element under an active modal window, focus back into the modal window
  //
  createDocumentFocusHandler() {
    document.addEventListener('focus', this, true);
  }

  removeDocumentFocusHandler() {
    document.removeEventListener('focus', this, true);
  }

  closeHandler(e) {
    e.preventDefault();
    this.modal.close();
  }

  tabKeyHandler(e) {
    if ((e.which === 9 && !e.shiftKey)) {
      e.preventDefault();
      this.firstInput.focus();
    }
  }

  shiftTabKeyHandler(e) {
    if ((e.which === 9 && e.shiftKey)) {
      e.preventDefault();
      this.lastInput.focus();
    }
  }

  documentFocusHandler(e) {
    if (!e.target || !this.modal.modal.contains(e.target)) {
      if (this.firstInput) { 
        this.firstInput.focus();
      } else { 
        this.modal.modal.focus();
      }
    }
  }

  setContentFromDOM(domEle) {
    const fragment = document.createDocumentFragment();
    Array.prototype.slice.call(domEle.childNodes).forEach((ele) => {
      fragment.appendChild(ele);
    });
    if (this.options.title !== '') {
      this.modal.setContent(`
    <div class="row cpc-modal__title-row"><div class="large-6 columns cpc-modal__fluid-gutter-m"><h3>${this.options.title}</h3></div><a class="tingle-modal-box__close-icon" aria-label="${this.options.closeLabel}" tabindex="0"></a></div>
    `);
    } else {
      this.modal.setContent(`
    <div class="row cpc-modal__title-row"><div class="large-6 columns cpc-modal__fluid-gutter-m"></div><a class="tingle-modal-box__close-icon" aria-label="${this.options.closeLabel}" tabindex="0"></a></div>
    `);
    }
    this.modal.modalBoxContent.appendChild(fragment); 
    return this;
  }

  setMobileCloseBtnFromDOM(domEle) {
    const fragment = document.createDocumentFragment();
    
    Array.prototype.slice.call(domEle.childNodes).forEach((ele) => {
      fragment.appendChild(ele.cloneNode(true));
    });
    this.modal.modalCloseBtn.replaceWith(fragment);
    
    return this;
  }

  setFooterFromDOM(domEle) {
    const fragment = document.createDocumentFragment();
    
    Array.prototype.slice.call(domEle.childNodes).forEach((ele) => {
      fragment.appendChild(ele.cloneNode(true));
    });
    this.modal.modalBoxFooter.appendChild(fragment); // modalEle);
    return this;
  }

  setContent(content) { 
    if (this.options.title !== '') {
      content = `
    <div class="row cpc-modal__title-row"><div class="large-6 columns cpc-modal__fluid-gutter-m"><h3>${this.options.title}</h3></div><a class="tingle-modal-box__close-icon" aria-label="${this.options.closeLabel}" tabindex="0"></a></div>
    ${content}
    `;
    } else {
      content = `
    <div class="row cpc-modal__title-row"><div class="large-6 columns cpc-modal__fluid-gutter-m"></div><a class="tingle-modal-box__close-icon" aria-label="${this.options.closeLabel}" tabindex="0"></a></div>
    ${content}
    `;
    }
    this.modal.setContent(content);    
    return this;
  }

  /*
   * Be sure to call open() after fully setting the content of
   * the modal. Otherwise the modal doesn't properly add the tingle-modal--overflow
   * class if content is in an overflow state
   */
  open() { 
    this.modal.open();
    return this;
  }

  close() { 
    this.lastInput.removeEventListener('keydown', this.events.tabHandlerRef);
    this.firstInput.removeEventListener('keydown', this.events.shiftTabHandlerRef);    
    this.modal.close();
  }

  addFooterBtn(label, buttonClasses, callback) {
    this.modal.addFooterBtn(label, buttonClasses, callback);
    return this;
  }

  /*
  static showTestModal() {
    const modal = new Modal(null, {
      title: 'Ship a parcel online',
      footer: false,
      stickyFooter: false,
      closeMethods: ['overlay', 'button', 'escape'],
      closeLabel: 'Close',
      cssClass: ['custom-class-1', 'custom-class-2'],
      onOpen: () => {
        // console.log('modal open');
      },
      onClose: () => {
        // console.log('modal closed');
      },
      beforeClose: () => true // {
        // here's goes some logic
        // e.g. save content before closing the modal
        // console.log('before close');
        // return false; // nothing happens
      //   return true; // close the modal
      // }
    });
    modal.setContent(`
          <button class="tingle-modal-box__close-icon" aria-label="Close" tabindex="0"></button>
          <div class="row">
          <div class="large-6 cpc-modal__fluid-gutter-m columns">
          <!--<h2 id="modal-from-spec--heading">Ship a parcel online</h2>-->
          <p>Why you should sign up:</p>
          <ul>
            <li>Save on shipping and sending direct mail to your customers</li>
            <li>Get access to easy-to-use online shipping and direct marketing tools</li>
            <li>Enjoy pickup and return privileges</li>
            <li>Pay online by credit card or your account</li>
          </ul>

          <div class="show-for-medium-up">
          <p>
          <a href="#" class="button">Sign up</a>
          </p>
          <p>Already a Solutions for Small Business member? 
          <a href="#">Sign in</a> to ship your parcel online</p>
          </div>
          
          </div>
          <div class="large-6 columns show-for-large-up cpc-modal__fluid-gutter-m bkg-container">
          <div class="modal-bkg"></div>
          </div>
          </div>
    `);
    // add a button
    // modal.addFooterBtn('Primary action', 'button cta-class', () => {
      // here goes some logic
    //   modal.close();
    // });

    // add another button
    // modal.addFooterBtn('Secondary action', 'button secondary cta-second-class', () => {
      // here goes some logic
    //   modal.close();
    // });

    // open modal
    modal.open();
  }
  */
}

window.cpc.Modal = Modal;
export default Modal;

