/* eslint no-console: 0 */
/* eslint no-new: 0 */

/**
 * Renders sign-in modal HTML. Supports different sign-in 
 * content scenarios (currently 'default' and Snap Ship). Modals
 * generate sign in, sign up, forgot user / passwords URLs and support
 * different SSO link generation rules, based on the rules defined
 * in sso-link-utilities.jss
 * 
 */
import desktopHtml from './sign-in.desktop.component.html';
import signInToolsHtml from './sign-in.tools.html';
import signInCommercialHtml from './sign-in.commercial.html';
import defaultRightCopy from './right-area.default.html';
import shippingRightCopy from './right-area.shipping.html';
import SfSBRightCopy from './right-area.biz.html';
import getENLocale from './sign-in.locale.en';
import getFRLocale from './sign-in.locale.fr';
import mobilecheck from '../../../../utils/mobile-check';
import authModule from '../../../../utils/auth.module';
import { createLoginLink, createRegisterLink, createForgotUsernameLink, createForgotPasswordLink } from '../../../../utils/sso/sso-link-utilities';
import { getUsername, setUsername, clearUsername } from '../../../../utils/username.data';
import { getPageLanguage } from '../../../../utils/language.data';
import DomainParser from '../../../../utils/domain-utils';


const lang = getPageLanguage();
const cpDomain = new DomainParser(window.location.href).getCanadaPostDomainUrl('');

//
// Specific copy depending on sign in scenario                     
//
const scenarioCopy = { 
  default: { 
    ssoTargetUrl: () => `${window.location.href}`,
    businessUrl: items => `${items.cpDomain}/dash-tableau/${items.lang}/`,
    // businessSignInParams: `?${SSOLocale.signIn}=true`,
    right: { 
      initializeCopy: getDefaultRightCopy
    },
    left: { 
      copy: items => `<h3 id="cpc-dialog-header-id">${items.signIn}</h3>`,
      initializeCopy: getDefaultLeftCopy
    },
    eleIds: { 
      usernameId: 'usernameLarge',
      rememberMeId: 'remembermeLarge',
      passwordId: 'passwordLarge'
    }
  },
  'default-with-consumer': { 
    ssoTargetUrl: () => `${window.location.href}`,
    businessUrl: items => `${items.cpDomain}/dash-tableau/${items.lang}/`,
    // businessSignInParams: '?signin=true',
    right: { 
      initializeCopy: getDefaultRightCopy
    },
    left: { 
      copy: items => `<h3 id="cpc-dialog-header-id">${items.signIn}</h3>`,
      initializeCopy: getDefaultLeftCopy
    },
    eleIds: { 
      usernameId: 'usernameLarge',
      rememberMeId: 'remembermeLarge',
      passwordId: 'passwordLarge'
    }
  },
  ship_online: {
    ssoTargetUrl: items => `https%3A%2F%2F${items.subDomain}est-oee.canadapost-postescanada.ca/esto-oee/app/shippingtools-outilsexpedition?locale=${items.cpcLocale}`,
    businessUrl: '',
    right: { 
      initializeCopy: getShippingRightCopy
    },
    left: { 
      copy: items => `<h3 id="cpc-dialog-header-id">${items.signIn}</h3><p id="cpc-dialog-description-id">${items.overview}</p>`,
      initializeCopy: getShippingLeftCopy
    },
    footnote: { 
      copy: items => `<p class="cpc-sign-in-footnotes">${items.footnote}</p>`,
      initializeCopy: getFootnoteCopy
    },
    eleIds: { 
      usernameId: 'sign-in-user-name',
      rememberMeId: 'sign-in-remember-me',
      passwordId: 'sign-in-password'
    }
  },
  shipping_manager: {
    ssoTargetUrl: () => `${window.location.href}`,
    businessUrl: items => `${items.cpDomain}/dash-tableau/${items.lang}/`,
    // businessSignInParams: '?signin=true',
    right: { 
      initializeCopy: getShippingManagerRightCopy
    },
    left: { 
      copy: items => `<h3 id="cpc-dialog-header-id">${items.signIn}</h3><p id="cpc-dialog-description-id">${items.overview}</p>`,
      initializeCopy: getShippingManagerLeftCopy
    },
    footnote: { 
      copy: items => `<p class="cpc-sign-in-footnotes">${items.footnote}</p>`,
      initializeCopy: getFootnoteCopy
    },
    eleIds: { 
      usernameId: 'sign-in-user-name',
      rememberMeId: 'sign-in-remember-me',
      passwordId: 'sign-in-password'
    }
  },
  biz: { 
    ssoTargetUrl: () => `${window.location.href}`,
    businessUrl: items => `${items.cpDomain}/dash-tableau/${items.lang}/`,
    // businessSignInParams: '?signin=true',
    right: { 
      initializeCopy: getSfSBRightCopy
    },
    left: { 
      copy: items => `<h3 id="cpc-dialog-header-id">${items.signIn}</h3><p id="cpc-dialog-description-id">${items.overview}</p>`,
      initializeCopy: getSfSBLeftCopy
    },
    footnote: { 
      copy: items => `<p class="cpc-sign-in-footnotes">${items.footnote}</p>`,
      initializeCopy: getFootnoteCopy
    },
    eleIds: { 
      usernameId: 'usernameLarge',
      rememberMeId: 'remembermeLarge',
      passwordId: 'passwordLarge'
    }
  },
  commercial: {
    ssoTargetUrl: () => `${window.location.href}`,
    businessUrl: items => `${items.cpDomain}/dash-tableau/${items.lang}/`,
    // businessSignInParams: '?signin=true',
    right: {
      initializeCopy: getSfSBRightCopy
    },
    left: {
      copy: items => `<h3 id="cpc-dialog-header-id">${items.signIn}</h3><p id="cpc-dialog-description-id">${items.overview}</p>`,
      initializeCopy: getCommercialLeftCopy
    },
    footnote: {
      copy: items => `<p class="cpc-sign-in-footnotes">${items.footnote}</p>`,
      initializeCopy: getFootnoteCopy
    },
    eleIds: {
      usernameId: 'usernameLarge',
      rememberMeId: 'remembermeLarge',
      passwordId: 'passwordLarge'
    }
  },
  tools: {
    ssoTargetUrl: () => `${window.location.href}`,
    businessUrl: items => `${items.cpDomain}/dash-tableau/${items.lang}/`,
    // businessSignInParams: '?signin=true',
    right: {
      initializeCopy: getSfSBRightCopy
    },
    left: {
      copy: items => `<h3 id="cpc-dialog-header-id">${items.signIn}</h3>`,
      initializeCopy: getToolsLeftCopy
    },
    footnote: {
      copy: items => `<p class="cpc-sign-in-footnotes">${items.footnote}</p>`,
      initializeCopy: getFootnoteCopy
    },
    eleIds: {
      usernameId: 'usernameLarge',
      rememberMeId: 'remembermeLarge',
      passwordId: 'passwordLarge'
    }
  }
};

const largeDesktopLowerBound = 1025;

const templates = {
  automatic_tracking: desktopHtml,
  sms: desktopHtml,
  biz_smb_mode1: desktopHtml,
  biz_smb_mode2: desktopHtml,
  commercial: signInCommercialHtml,
  connect: desktopHtml,
  default: desktopHtml,
  personal: desktopHtml,
  pup: desktopHtml,
  rso: desktopHtml,
  ship_online: desktopHtml,
  shipping_manager: desktopHtml,
  smb_mode1: desktopHtml,
  smb_mode2: desktopHtml,
  tools: signInToolsHtml,
};

/**
 * See sign-in-module.js for a description of this module
 * 
 * Instantiated by header. See modal.html-attribute-initiator and 
 * modal.sso-attribute-initiator for usages
 */
function getHtmlTemplate(templateName) {
  return templateName ? templates[templateName] : templates.default;
}

function fillTemplate(template, mode = 'default', ssoTargetUrlOverride, businessUrlOverride) {
  // console.info(`mode: ${mode} ---`);
  // console.log(scenarioCopy[mode]);
  if (!scenarioCopy[mode]) { 
    console.error(`Invalid sign in scenario copy. Mode specified: ${mode}`);
    return null;
  }

  const locale = lang === 'fr' ? getFRLocale() : getENLocale();
  const modalCopy = getModalCopy(mode);
  const businessUrl = getBusinessUrl(modalCopy, businessUrlOverride);
  return template({
    leftAreaCopy: getSignInLeftCopy(modalCopy, locale),
    footnoteDesktop: getFootnoteCopy(
      modalCopy, scenarioCopy[mode].modalCopy ? scenarioCopy[mode].modalCopy : mode, locale),
    rightAreaCopy: getSignInRightCopy(modalCopy,
      { type: mode, businessUrl }, locale, ssoTargetUrlOverride),
    close: locale.closeLabel,
    signInButton: locale.signInButton,
    usernameId: modalCopy.eleIds.usernameId,
    username: locale.username,
    rememberMeId: modalCopy.eleIds.rememberMeId,
    rememberUsername: locale.rememberUsername,
    passwordId: modalCopy.eleIds.passwordId,
    password: locale.password,
    forgot: locale.forgot,
    fpUsername: locale.forgotPasswordUsernameLabel,
    fpPassword: locale.forgotPasswordPasswordLabel,
    or: locale.or,
    ssoLink: createSsoSignInLink(
      mode, modalCopy, ssoTargetUrlOverride, businessUrlOverride),
    ssoForgotUsernameLink: createSsoForgotUsernameLink(
      mode, modalCopy, ssoTargetUrlOverride, businessUrlOverride),
    ssoForgotPasswordLink: createSsoForgotPasswordLink(
      mode, modalCopy, ssoTargetUrlOverride, businessUrlOverride),
    cpcLang: lang,

    // The below 3 properties are not been used, we leave it here for in case
    // we need it back
    dontHaveAccount: locale.dontHaveAccount,
    registerNow: locale.registerNow,
    ssoRegisterNowLink: createRegisterLink(
      { type: mode, businessUrl }, ssoTargetUrlOverride || scenarioCopy.default.ssoTargetUrl())
  });
}

function getLocale() {
  return lang === 'fr' ? 'fr_CA' : 'en_CA';
}

function getUrl(url) {
  return url({
    subDomain: authModule.getESTSubdomain(),
    domainUrl: authModule.getDomain(),
    cpcLocale: getLocale(),
    cpDomain,
    lang
  });
}

let resizingTimeout = null;
let lastResizeWidth = null;
let resizeEvent = null;
let formSubmitEvt = null;

function initializeComponent() {
  if (!formSubmitEvt) {
    formSubmitEvt = document.addEventListener('submit', (evt) => {
      if (evt.target.tagName === 'FORM' 
        && document.querySelector('.sign-in-modal') !== null
        && document.querySelector('.sign-in-modal').contains(evt.target)) {
        setRememberMeCookie();
      }
    });
  }

  // ensure resize event triggers at end of event. Applicable to the default
  // sign in form only. For sign in modals initiated from CTAs, not applicable
  // since desktop / mobile use the same DOM structure. 
  // Also - avoid adding a new event listener if it's already been defined
  if (!resizeEvent) {
    resizeEvent = window.addEventListener('resize', resizeEnd);
  }
  setRememberUserName();
}

function resizeEnd() {
  clearTimeout(resizingTimeout);
  resizingTimeout = setTimeout(retainSigninModal, 200);
}

function retainSigninModal() {
  const signinModal = document.getElementById('signinFormDesktop');
  const trigger = document.getElementById('trigger');
  const bodyElement = document.querySelector('body');
  const myAccountTab = document.querySelector('.my-account-link');

  if (signinModal && bodyElement.classList.contains('tingle-enabled') && 
    document.body.clientWidth < largeDesktopLowerBound) {
    if (!lastResizeWidth || lastResizeWidth >= largeDesktopLowerBound) {
      copyFromLarge();
    }

    if (!trigger.classList.contains('active')) {
      if (mobilecheck()) {
        const e = new Event('touchstart');
        trigger.dispatchEvent(e);
      } else {
        trigger.click();
      }
      myAccountTab.click();
    }
    if (!lastResizeWidth || lastResizeWidth >= largeDesktopLowerBound) {
      copyFromMobile();
    }
  } else if (trigger && trigger.classList.contains('active') && 
    myAccountTab.classList.contains('active') &&
    document.body.clientWidth >= largeDesktopLowerBound) {
    if (!signinModal) {
      document.getElementById('signinModalLarge').click();
      trigger.classList.remove('active');
    }     
    if (!lastResizeWidth || lastResizeWidth < largeDesktopLowerBound) {
      copyFromMobile();
      copyFromLarge();
    }
  }
  lastResizeWidth = document.body.clientWidth;
}

// create or delete remember me cookie
function setRememberMeCookie() {
  // set cookie
  if (document.querySelector('input[name=rememberme]').checked) {
    setUsername(document.querySelector('input[name=username]').value);
  } else {
    clearUsername();
  }
}

// This function is only for the global sign in. Global sign in transitions from a modal window in 
// desktop to an inline block on mobile/tablet, and they are 2 different sets of DOM elements. 
// This is a function that keeps the global desktop sign in values in sync with the global 
// mobile sign in values.  
function copyFromMobile() {
  syncUserNamePassword(
    document.getElementById('username').value, 
    document.getElementById('password').value, 
    document.getElementById('rememberme').checked);
}

function syncUserNamePassword(userName, password, rememberMeChecked) {
  Array.prototype.slice.call(document.querySelectorAll('[name=username]')).forEach((input) => { 
    input.value = userName;
  });    
  Array.prototype.slice.call(document.querySelectorAll('[name=password]')).forEach((input) => { 
    input.value = password;
  });    
  Array.prototype.slice.call(document.querySelectorAll('[name=rememberme]')).forEach((input) => { 
    input.checked = rememberMeChecked;
  });    
}

// This function is only for the global sign in. Global sign in transitions from a modal window in 
// desktop to an inline block on mobile/tablet, and they are 2 different sets of DOM elements. 
// This is a function that keeps the global desktop sign in values in sync with the global 
// mobile sign in values.  
function copyFromLarge() {
  syncUserNamePassword(
    document.getElementById('usernameLarge').value, 
    document.getElementById('passwordLarge').value, 
    document.getElementById('remembermeLarge').checked);
}

// Iterate on all sign in forms since there may be more than 1 in the DOM. There may be
// the global sign in form, but there may also be a sign in form initiated from a CTA for example. 
function setRememberUserName() {
  const remembermeVal = getUsername();
  if (remembermeVal.length > 0) {
    Array.prototype.slice.call(document.querySelectorAll('[name=rememberme]')).forEach((input) => {
      input.checked = true;
    });    
    Array.prototype.slice.call(document.querySelectorAll('[name=username]')).forEach((input) => { 
      input.value = remembermeVal;
    });    
  }
}

function getSignInRightCopy(copyContainer, options, locale, ssoTargetUrlOverride) {
  return copyContainer.right.initializeCopy(locale, options, ssoTargetUrlOverride);
}

function getRightCopy(copy, locale) {
  return copy(locale);
}

function getDefaultRightCopy(locale, options, ssoTargetUrlOverride) { 
  return getRightCopy(defaultRightCopy, { 
    createAccount: locale.rightArea.default.createAccount,
    signUpText: locale.rightArea.default.signUpText,
    signUpPoint1: locale.rightArea.default.signUpPoint1,
    signUpPoint2: locale.rightArea.default.signUpPoint2,
    signUpPoint3: locale.rightArea.default.signUpPoint3,
    signUp: locale.signUp,
    ssoRegisterLink: createRegisterLink(
      options,
      ssoTargetUrlOverride || scenarioCopy.default.ssoTargetUrl())
  });
}

function getShippingRightCopy(locale, options, ssoTargetUrlOverride) { 
  return getRightCopy(shippingRightCopy, { 
    createAccount: locale.rightArea.ship_online.createAccount,
    signUpText: locale.rightArea.ship_online.signUpText,
    signUp: locale.signUp,
    ssoRegisterLink: createRegisterLink(
      options,
      ssoTargetUrlOverride || scenarioCopy.default.ssoTargetUrl())    
  });
}

function getSfSBRightCopy(locale, options, ssoTargetUrlOverride) { 
  return getRightCopy(SfSBRightCopy, { 
    createAccount: locale.rightArea.biz.createAccount,
    signUpText: locale.rightArea.biz.signUpText,
    signUp: locale.signUp,
    ssoRegisterLink: createRegisterLink(
      options,
      ssoTargetUrlOverride || scenarioCopy.biz.ssoTargetUrl())
  });
}

function getShippingManagerRightCopy(locale, options, ssoTargetUrlOverride) { 
  return getRightCopy(SfSBRightCopy, { 
    createAccount: locale.rightArea.shipping_manager.createAccount,
    signUpText: locale.rightArea.shipping_manager.signUpText,
    signUp: locale.signUp,
    ssoRegisterLink: createRegisterLink(
      options,
      ssoTargetUrlOverride || scenarioCopy.biz.ssoTargetUrl())
  });
}

function getFootnoteCopy(copyContainer, mode, locale) { 
  return copyContainer.footnote && copyContainer.footnote.copy ? 
    copyContainer.footnote.copy({
      footnote: locale.leftArea[mode].footnote
    }) : '';
}

function getSignInLeftCopy(copyContainer, locale) {
  return copyContainer.left.initializeCopy(locale);
}

function getDefaultLeftCopy(locale) { 
  return scenarioCopy.default.left.copy({
    signIn: locale.leftArea.default.title
  });
}

function getCommercialLeftCopy(locale) {
  return scenarioCopy.commercial.left.copy({
    signIn: locale.leftArea.commercial.title,
    overview: locale.leftArea.commercial.overview
  });
}

function getToolsLeftCopy(locale) {
  return scenarioCopy.tools.left.copy({
    signIn: locale.leftArea.tools.title,
    overview: locale.leftArea.tools.overview
  });
}

function getShippingLeftCopy(locale) {
  return scenarioCopy.ship_online.left.copy({
    signIn: locale.leftArea.default.title,
    overview: locale.leftArea.ship_online.overview
  });
}

function getSfSBLeftCopy(locale) {
  return scenarioCopy.biz.left.copy({
    signIn: locale.leftArea.biz.title,
    overview: locale.leftArea.biz.overview
  });
}

function getShippingManagerLeftCopy(locale) {
  return scenarioCopy.shipping_manager.left.copy({
    signIn: locale.leftArea.shipping_manager.title,
    overview: locale.leftArea.shipping_manager.overview
  });
}

function getModalCopy(mode = 'default') {
  return scenarioCopy[mode].modalCopy
    ? scenarioCopy[scenarioCopy[mode].modalCopy]
    : scenarioCopy[mode];
}

function getBusinessUrl(modalCopy, businessUrlOverride) {
  return (businessUrlOverride && businessUrlOverride.suppressBusinessUrl)
    ? ''
    : getUrl(modalCopy.businessUrl);
}

function getSsoTargetUrlOverride(mode, ssoTargetUrlOverride) {
  return ssoTargetUrlOverride || scenarioCopy[mode].ssoTargetUrl();
}

function ssoOption(mode, modalCopy, businessUrlOverride) {
  return {
    type: mode,
    businessUrl: getBusinessUrl(modalCopy, businessUrlOverride) 
  };
}

function createSsoSignInLink(mode = 'default', modalCopy, ssoTargetUrlOverride, businessUrlOverride) {
  return createLoginLink(
    ssoOption(mode, modalCopy, businessUrlOverride),
    getSsoTargetUrlOverride(mode, ssoTargetUrlOverride)
  );
}

function createSsoForgotUsernameLink(mode = 'default', modalCopy, ssoTargetUrlOverride, businessUrlOverride) {
  return createForgotUsernameLink(
    ssoOption(mode, modalCopy, businessUrlOverride),
    getSsoTargetUrlOverride(mode, ssoTargetUrlOverride)
  );
}

function createSsoForgotPasswordLink(mode = 'default', modalCopy, ssoTargetUrlOverride, businessUrlOverride) {
  return createForgotPasswordLink(
    ssoOption(mode, modalCopy, businessUrlOverride),
    getSsoTargetUrlOverride(mode, ssoTargetUrlOverride)
  );
}

function createSsoSignUpLink(mode = 'default', modalCopy, ssoTargetUrlOverride, businessUrlOverride) {
  return createRegisterLink(
    ssoOption(mode, modalCopy, businessUrlOverride),
    ssoTargetUrlOverride || scenarioCopy.default.ssoTargetUrl()
  );
}

export {
  initializeComponent,
  getHtmlTemplate,
  fillTemplate,
  getModalCopy,
  createSsoSignInLink,
  createSsoSignUpLink,
  createSsoForgotUsernameLink,
  createSsoForgotPasswordLink
};