import { getPageLanguage } from '../../../utils/language.data';
import DomainParser from '../../../utils/domain-utils';

/**
 * Retrieves the eShop site navigation object and transforms the nodes
 * specific to the cwc sitemap
 */
  
const domainParser = new DomainParser(window.location.href);
const subdomain = domainParser.getCanadaPostSubdomain();
const lang = getPageLanguage() === 'en' ? 'e' : 'f'; // temp workaround for getLANG returning blank causing FR failSafe to be set of links displayed on fresh cache load

const failSafeShopSiteNavigationEN = [
  {
    displayName: 'Mailing and shipping',
    description: 'Order packaging, envelopes, stamps, boxes and wraps.',
    overViewContent: {
      displayName: 'Mailing and shipping overview',
      url: '/store-boutique/en/1/c/mailing-and-shipping'
    },
    subNav: [
      {
        displayName: 'Postage stamps',
        url: '/store-boutique/en/5/c/postage-stamps',
        subNav: []
      },
      {
        displayName: 'Flat rate (prepaid) products',
        description: 'Discover prepaid envelopes and flat rate boxes, priced by region.',
        url: subdomain === 'stg12' ? '/store-boutique/en/6/c/flat-rate-products' :
          '/store-boutique/en/8/c/flat-rate-prepaid-products',
        subNav: [
          {
            displayName: 'Flat rate (prepaid) products and shipping regions',
            url: subdomain === 'stg12' ? '/store-boutique/en/39/c/flat-rate-products-and-shipping-regions' :
              '/store-boutique/en/38/c/flat-rate-prepaid-products-and-shipping-regions',
          }
        ]
      },
      {
        displayName: 'Shipping supplies',
        url: subdomain === 'stg12' ? '/store-boutique/en/7/c/packaging-supplies' : '/store-boutique/en/7/c/shipping-supplies',
        subNav: []
      }
    ]
  },
  {
    displayName: 'Stamp collecting',
    description: 'See the latest stamp collections and quality accessories.',
    overViewContent: {
      displayName: 'Stamp collecting overview',
      url: '/store-boutique/en/2/c/stamp-collecting'
    },
    subNav: [
      {
        displayName: 'New arrivals',
        url: '/store-boutique/en/9/c/new-arrivals',
        subNav: []
      },
      {
        displayName: 'Stamps and collectibles',
        url: subdomain === 'stg12' ? '/store-boutique/en/10/c/stamps-and-collectables' : '/store-boutique/en/10/c/stamps-and-collectibles',
        subNav: []
      },
      {
        displayName: 'Stamp collecting accessories',
        url: '/store-boutique/en/13/c/stamp-collecting-accessories',
        subNav: []
      },
      {
        displayName: 'Postcards',
        url: '/store-boutique/en/14/c/postcards',
        subNav: []
      }
    ]
  },
  {
    displayName: 'Coin collecting',
    description: 'View featured collectible coins released by the Canadian Mint.',
    overViewContent: {
      displayName: 'Coin collecting overview',
      url: '/store-boutique/en/3/c/coin-collecting'
    },
    subNav: [
      {
        displayName: 'New arrivals',
        url: '/store-boutique/en/15/c/new-arrivals',
        subNav: []
      },
      {
        displayName: 'Coins and coin sets',
        url: '/store-boutique/en/16/c/coins-and-collectables',
        subNav: []
      },
      {
        displayName: 'Coin albums and accessories',
        url: '/store-boutique/en/17/c/coin-collecting-accessories',
        subNav: []
      }
    ]
  },
  {
    displayName: 'Quick Order',
    url: '/quick-order'
  },
  {
    displayName: 'Favourites',
    url: '/favourites'
  }
];

const failSafeShopSiteNavigationFR = [
  {
    displayName: 'Marché de la Découverte',
    url: '/place-march-d-couverte'
  },
  {
    displayName: 'Expédition',
    description: "Commandez des fournitures d'emballage, des enveloppes, des timbres et des boîtes.",
    overViewContent: {
      displayName: 'Aperçu de la Expédition',
     
      url: '/store-boutique/fr/1/c/expedition'
    },
    subNav: [
      {
        displayName: 'Timbres',
        url: '/store-boutique/fr/5/c/timbres',
        subNav: []
      },
      {
        displayName: ' Produits à tarif fixe (prépayés)',
        description: 'Découvrez nos boîtes à tarif fixe et nos enveloppes prépayées, dont les tarifs diffèrent selon la région.',
        url: subdomain === 'stg12' ? '/store-boutique/fr/6/c/aper-u-de-l-exp-dition-tarif-fixe-produits-pr-pay-s' :
          '/store-boutique/fr/8/c/produits-a-tarif-fixe-prepayes',
        subNav: [
          {
            displayName: "Produits à tarif fixe et régions d'expédition (produits prépayé)",
            url: subdomain === 'stg12' ? '/store-boutique/fr/39/c/produits-tarif-fixe-et-r-gions-d-exp-dition-' : 
              '/store-boutique/fr/38/c/produits-a-tarif-fixe-et-regions-d-expedition',
          }
        ]
      },
      {
        displayName: "Fournitures d'expédition",
        url: subdomain === 'stg12' ? '/store-boutique/fr/7/c/articles-d-emballage' : '/store-boutique/fr/7/c/fournitures-d-expedition',
        subNav: []
      }
    ]
  },
  {
    displayName: 'Philatélie',
    description: 'Découvrez les dernières émissions de timbres et des accessoires de qualité.',
    overViewContent: {
      displayName: 'Aperçu de la Philatélie',
      url: '/store-boutique/fr/2/c/philatelie'
    },
    subNav: [
      {
        displayName: 'Timbres et articles de collection',
        url: '/store-boutique/fr/10/c/-timbres-et-articles-de-collection',
        subNav: []
      },
      {
        displayName: 'Accessoires de philatélie',
        url: '/store-boutique/fr/13/c/accessoires-de-philatelie',
        subNav: []
      },
      {
        displayName: 'Cartes postales',
        url: '/store-boutique/fr/14/c/cartes-postales',
        subNav: []
      }
    ]
  },
  {
    displayName: 'Collection de pièces de monnaie',
    description: 'Découvrez les dernières émissions de pièces de monnaie de collection de la Monnaie royale canadienne.',
    overViewContent: {
      displayName: 'Aperçu de la Collection de pièces de monnaie',
      url: '/store-boutique/fr/3/c/collection-de-pieces-de-monnaie'
    },
    subNav: [
      {
        displayName: 'Nouveautés',
        url: '/store-boutique/fr/15/c/nouveautes',
        subNav: []
      },
      {
        displayName: 'Pièces de monnaie et ensembles de pièces',
        url: '/store-boutique/fr/16/c/pieces-de-monnaie-et-ensembles-de-pieces',
        subNav: []
      },
      {
        displayName: 'Accessoires pour collection de pièces de monnaie',
        url: '/store-boutique/fr/17/c/accessoires-pour-collection-de-pieces-de-monnaie',
        subNav: []
      }
    ]
  },
  {
    displayName: 'Commande rapide',
    url: '/commande-rapide'
  },
  {
    displayName: 'Favoris',
    url: '/favoris'
  }
];

let cpShopSiteNavObj = window.cpShopSiteNavigation;

function deepMapKeys(obj, fn) {
  // eslint-disable-next-line no-nested-ternary
  return Array.isArray(obj)
    ? obj.map(val => deepMapKeys(val, fn))
    : typeof obj === 'object'
      ? Object.keys(obj).reduce((acc, current) => {
        const key = fn(current);
        const val = obj[current];
        acc[key] = val !== null && typeof val === 'object' ? deepMapKeys(val, fn) : val;
        return acc;
      }, {})
      : obj;
}

function getStoreSiteMapNodes() {
  if (!cpShopSiteNavObj) {
    cpShopSiteNavObj = (lang === 'e') ? failSafeShopSiteNavigationEN : failSafeShopSiteNavigationFR;
  }

  cpShopSiteNavObj = deepMapKeys(cpShopSiteNavObj, (key) => {
    switch (key) {
      case 'displayName':
        return 'label';
      case 'subNav':
        return 'nodes';
      case 'url':
        return 'link';
        
      default:
        return key;
    }
  });

  const shopSiteNav = {};
  shopSiteNav.nodes = [];
  cpShopSiteNavObj.forEach((node) => {
    shopSiteNav.nodes.push(node);
  });

  shopSiteNav.label = lang === 'e' ? 'Shop' : 'Boutique';
  shopSiteNav.description = lang === 'e' ? 'Shop for stamps, shipping supplies and collectibles.' : 'Voir nos timbres, fournitures d’expédition et articles de collection.';
  shopSiteNav.link = lang === 'e' ? '/store-boutique/en/home' : '/store-boutique/fr/accueil';

  return shopSiteNav;
}

export default getStoreSiteMapNodes;