/* eslint no-console: 0 */
/* eslint no-new: 0 */
/* eslint no-plusplus: 0 */
/* eslint no-underscore-dangle: 0 */
/* eslint object-shorthand: 0 */
/* eslint prefer-arrow-callback: 0 */
/* eslint no-unused-expressions: 0 */
/* eslint func-names: 0 */
/* eslint new-cap: 0 */

/**
 * TO DO: Refacter SSO modal for new location (under utils link)
 */
import { load } from 'recaptcha-v3';
import setLocaleEn from '../locale_en';
import setLocaleFr from '../locale_fr';
import { getUsername, setUsername } from '../../../../../utils/username.data';
import buildMobileNav from './mobile-nav-builder';
import { GlobalAlertBanner } from '../global-alert-banner';
import MessageBannerData from '../../../../../utils/message-banner.data';
import { getCookie, setCookie } from '../../../../../utils/cookies';
import { setOrUpdateHashParameter } from '../../../../../utils/url-utils';
import authModule from '../../../../../utils/auth.module';

const captchaKey = authModule.getCAPTCHKey();
const $ = window.jQuery;
const defaultOptions = {};
const largeDesktopLowerBound = 1025;

// The item should be highlight in this page
//

let headerText;

document.addEventListener('DOMContentLoaded', initializeComponent);
window.addEventListener('resize', removeMobileOverlayOnLarge);

function removeMobileOverlayOnLarge() {
  const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  const mobileAvtiveMenuBackgroundElem = document.querySelector('.mobile-active-menu-background');
  if (mobileAvtiveMenuBackgroundElem && vw >= largeDesktopLowerBound) {
    document.querySelector('.mobile-active-menu-background').classList.remove('active');
  }
}

function isUx() {
  const hostname = window.location.hostname;
  return /ux/.test(hostname);
}

function initializeComponent() {
  $.cpc.MobileNav = function navConstructor(el, options) {
    const base = this;
    const siteMapRoot = (el.getAttribute('data-sitemap') || 'business')
      .trim()
      .toLowerCase();

    // Access to jQuery and DOM versions of element
    base.$el = $(el);
    base.el = el;
    const currentPage = base.el.getAttribute('data-current-page') || '';
 
    if (currentPage === 'custom-mobile-nav') return;
    // Business or personal mode?
    // const highlightType = siteMapRoot === 'business' ? 'home' : 'personal';

    // Add a reverse reference to the DOM object

    base.$el.data('cpc.nav', base);
    base.init = () => {
      base.options = $.extend({}, $.cpc.MobileNav.defaultOptions, options);
      base.$mobilenav = $(buildMobileNav(base, siteMapRoot, currentPage));
      base.$el.not('.cpc-nav--custom-rawHTML').append(base.$mobilenav);

      new mlPushMenu(base, siteMapRoot);
      initializeEventListeners();
      loadRememberMe();
      setGlobalAlertBanner();
      addShoppingCartObserver();
    };

    function setGlobalAlertBanner() {
      const globalBanner = new GlobalAlertBanner(
        document.getElementById('mainNav')
      );
      new MessageBannerData().initialize(globalBanner);
    }

 
    function initializeEventListeners() {
      const ssoForm = document.getElementById('signinFormMobile');
      const mainMenu = document.querySelector('.menu-main-links');
      const logInMenu = document.getElementById('mobile-nav-section-log-in');
      const signInHeader = document.querySelector('.my-account-sign-in');
      const mainHeaderNav = document.querySelector('.main-nav-header');
      const mobileNavBack = document.querySelectorAll('.mobile-main-back');
      const mainL0Header = document.querySelector('.main-l0-header');
      const mainL1Header = document.querySelector('.main-l1-header');
      const mainL2Header = document.querySelector('.main-l2-header');
      const mobileUtilityNav = document.querySelector('.menu-utility-links'); 
      const mobileLangNav = document.querySelector('.menu-lang-toggle');
      const mobileContainer = document.querySelector('.mobile-container');
      const myAccountLogin = document.querySelector('.my-account-login');
      const hostname = window.location.hostname;
      const isEstore = () => /(stg1\.mozu\.com|sandbox\.mozu\.com|\.mozu\.com|boutique|store)/i.test(hostname);
      
      if (ssoForm) {
        ssoForm.addEventListener('submit', setRememberMeCookie);

        // Tab traversal for SSO form
        const focusableElementsString = `
      a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]
      `;
        const ssoFocusableEles = Array.prototype.slice.call(
          ssoForm.querySelectorAll(focusableElementsString)
        );
        const lastInput = ssoFocusableEles[ssoFocusableEles.length - 1];
        const ssoFormUserNameInput = ssoForm.querySelector('#username');
        lastInput.addEventListener('keydown', event =>
          tabKeyHandler(event, ssoFormUserNameInput)
        );
      }

      if (signInHeader) {
        signInHeader.addEventListener('click', () => {
          if (!getCookie('hc') && !isEstore()) {
            load(captchaKey).then((recaptcha) => {
              recaptcha.execute('submit').then((token) => {
                $.ajax({
                  url: '/cwc/components/rs/cwc/msform/auth',
                  type: 'POST',
                  data: `{ captchaResponse: ${token} }`,
                  dataType: 'json',
                  contentType: 'application/vnd.cpc.cwc-v1+json;',
                  success: (data) => {
                    cb(data);
                  }
                });
              });
            });
          } else if (getCookie('hc') > 0.6) {
            showBlock();
          } else {
            followHref();
          }

          function cb(data) {
            isUx() ? setCookie('hc', 0.7) : setCookie('hc', parseFloat(data.score));
            if (getCookie('hc') > 0.6) {
              showBlock();
            } else {
              followHref();
            }
          } 

          function showBlock() {
            if (isEstore()) {
              followHref();
            } else {
              mainL1Header.classList.remove('active');
              mainL0Header.classList.add('active');
              myAccountLogin.classList.toggle('active');
              signInHeader.classList.toggle('active');
              logInMenu.classList.toggle('active');
            }
          }

          function followHref() {
            let mobileNavSignInOrRegisterLink;
            if (isEstore()) {
              mobileNavSignInOrRegisterLink = document.getElementById('mobile-nav-section-log-in').querySelector('a[href]').getAttribute('href');
            } else {
              mobileNavSignInOrRegisterLink = document.getElementById('mobile-nav-section-log-in').querySelector('form[action]').getAttribute('action');
            }
            location.assign(mobileNavSignInOrRegisterLink);
          }
        });
      }

      if (mobileNavBack) {
        mobileNavBack.forEach(function (elem) {
          elem.addEventListener('click', () => {
            mainHeaderNav.classList.add('active');
            mainL1Header.classList.remove('active');
            mainL2Header.classList.remove('active');
            mainL0Header.classList.add('active');
            mobileUtilityNav.classList.add('active');
            mobileLangNav.classList.add('active');
            mainMenu.classList.add('active');
            // mobileHamburgerNav.focus();
          });
        });
      }
     
      
      // Tab traversal for Main menu - Lvl0
      const mainFocusableEles = Array.prototype.slice.call(
        mobileContainer.querySelectorAll('a[href]')
      );
      const lastMainFocusEles = mainFocusableEles[mainFocusableEles.length - 1];
      const mainCloseButton = document.getElementById('trigger-close');
      lastMainFocusEles.addEventListener('keydown', event => tabKeyHandler(event, mainCloseButton));
      
      function tabKeyHandler(e, focusElem) {
        if (e.which === 9 && !e.shiftKey) {
          e.preventDefault();
          if (focusElem.id === 'trigger-close') {
            focusElem.setAttribute('tabindex', 0);
          }
          focusElem.focus();
        }
      }
    }

    function addShoppingCartObserver() {
      const shoppingCartQtyElem = document.querySelector('.cart-qty-indicator');
      const config = { childList: true, subtree: true, characterData: true };
      const QtyObserver = new MutationObserver(callback);
  
      let cartQtyValue = getCookie('estorecartcount');

      function updateShoppingCartUrl(anchor) {
        const currentHref = anchor.href.split('#')[0];
        const newHref = setOrUpdateHashParameter(currentHref, 'qty', cartQtyValue);
        anchor.setAttribute('href', newHref);
      }

      if (!shoppingCartQtyElem) {
        QtyObserver.disconnect();
        return;
      } else if (cartQtyValue !== '0') {
        const headerPostesCanadaLinks = document.querySelectorAll('.cpc-nav a[href*="postescanada.ca"]');
        const footerPostesCanadaLinks = document.querySelectorAll('.cpc-footer-container a[href*="postescanada.ca"]');
        headerPostesCanadaLinks.forEach(anchor => updateShoppingCartUrl(anchor));
        footerPostesCanadaLinks.forEach(anchor => updateShoppingCartUrl(anchor));
      }

      QtyObserver.observe(shoppingCartQtyElem, config);
      function callback(mutationsList) {
        const headerPostesCanadaLinks = document.querySelectorAll('.cpc-nav a[href*="postescanada.ca"]');
        const footerPostesCanadaLinks = document.querySelectorAll('.cpc-footer-container a[href*="postescanada.ca"]');
        cartQtyValue = mutationsList[0].addedNodes[0].nodeValue;
        headerPostesCanadaLinks.forEach(anchor => updateShoppingCartUrl(anchor));
        footerPostesCanadaLinks.forEach(anchor => updateShoppingCartUrl(anchor));
      }
    }
    

    // This function needs to get invoked at a different time - rather than when
    // DOM is ready, it needs to get invoked when the modal is
    // first opened.
    function loadRememberMe() {
      const remembermeVal = getUsername();
      if (remembermeVal.length > 0) {
        let ele = document.getElementById('remembermeLarge');
        if (ele) ele.checked = true;
        ele = document.getElementById('usernameLarge');
        if (ele) ele.value = remembermeVal;
        ele = document.getElementById('rememberme');
        if (ele) ele.checked = true;
        ele = document.getElementById('username');
        if (ele) ele.value = remembermeVal;
      }
    }

    // create or delete remember me cookie
    function setRememberMeCookie() {
      // set cookie
      if (document.getElementById('rememberme').checked) {
        setUsername(document.getElementById('username').value);
      } else {
        document.cookie =
          'cpo_cpidRemember=; expires=Thu, 2 Dec 2010 12:00:00 UTC; path=/';
      }
    }

    base.init();
  };

  $.cpc.MobileNav.defaultOptions = defaultOptions;

  $.fn.cpc_MobileNav = function cpcnav(options) {
    return this.each(function eachEle() {
      new $.cpc.MobileNav(this, options);
    });
  };
}

ensureNamespace();

function ensureNamespace() {
  if (!$.cpc) {
    $.cpc = {};
  }
}


// returns the depth of the element "e" relative to element with id=id
// for this calculation only parents with classname = waypoint are considered
function getLevelDepth(e, id, waypoint, cnt) {
  let count = cnt || 0;
  if (e.id.indexOf(id) >= 0) return count;
  if (e.classList.contains(waypoint)) {
    ++count;
  }
  return e.parentNode && getLevelDepth(e.parentNode, id, waypoint, count);
}

// returns the closest element to 'e' that has class "classname"
function closest(e, classname) {
  if (e.classList.contains(classname)) {
    return e;
  }
  return e.parentNode && closest(e.parentNode, classname);
}

// returns the closest element to 'e' that has a data-level
function closestLevel(e, level) {
  if (Number(e.dataset.level) === Number(level)) {
    return e;
  }
  return e.parentNode && closestLevel(e.parentNode, level);
}

function mlPushMenu(navMenu, siteMapRoot) {
  this.navMenu = navMenu;
  this.siteMapRoot = siteMapRoot;
  this.el = document.getElementById('main-nav');
  this.trigger = document.getElementById('trigger');
  this.triggerClose = document.getElementById('trigger-close');
  this.subLevelBack = document.querySelector('.mobile-sublevel-back .menu-item-back');
  this.loginBack = document.querySelector('.my-account-login');


  this.options = this.defaults;
  this._init();
}

mlPushMenu.prototype = {
  defaults: {
    // classname for the element (if any) that when clicked closes the current level
    backClass: 'menu-item-back',
    itemClass: 'menu-item-level',
  },
  _init: function () {
    this.open = false;
    this.level = 0;

    // the moving wrapper
    this.wrapper = document.getElementById('main-nav');
    // the * elements
    this.levels = Array.prototype.slice.call(
      this.el.querySelectorAll(`.${this.options.itemClass}`)
    );

    this.mobileMainNav = document.querySelector('.menu-main-links');
    this.mainNavHeader = document.querySelector('.main-nav-header');
    this.mainL1Header = document.querySelector('.main-l1-header');
    this.menuItems = Array.prototype.slice.call(this.el.querySelectorAll('li'));
    this.levelBack = document.querySelector(`.${this.options.backClass}`);
    this.mobileNavBack = document.querySelectorAll('.mobile-main-back');
    this.loginBack = document.querySelector('.my-account-login');
    this.topSectionMain = document.querySelector('.top-section--main');
    this.topSection = document.querySelector('.top-section');
    this.mainL0header = document.querySelector('.main-l0-header');
    this.mobileMenuBackground = document.querySelector('.mobile-active-menu-background'); 
  

    // save the depth of each of these * elements
    const self = this;
    this.levels.forEach(function (el) {
      el.setAttribute(
        'data-level',
        getLevelDepth(el, self.el.id, self.options.itemClass)
      );
    });

    this.sectionToggle = Array.prototype.slice.call(
      document.querySelectorAll('.cpc-section-toggle')
    );

    this.trigger.setAttribute('aria-expanded', 'false');
    this.triggerClose.setAttribute('aria-expanded', 'false');

    this.eventtype = 'click';
    this.el.classList.add('menu-item--cover');

    // initialize / bind the necessary events
    this._initEvents();
  },
  _initEvents: function () {
    const self = this;
    const getIsAuthenticate = function () {
      return authModule.getIsAuthenticated();
    };


    this.mobileNavBack.forEach(function (elem) {
      elem.addEventListener(self.eventtype, function (evt) {
        evt.preventDefault();
        self._resetMainMenu();
      });
    });


    this.mobileMainNav.classList.add('active');
    this.mainNavHeader.classList.add('active');


    /*  ======================================= */
    // Login Back
    this.loginBack.addEventListener(this.eventtype, function (evt) {
      evt.preventDefault();
      this.classList.remove('active');
      document.querySelector('.my-account-sign-in').classList.add('active');
      document.querySelector('.my-account-login').classList.remove('active');
      document.querySelector('.menu-utility-links').classList.add('active');
      document.querySelector('.menu-lang-toggle').classList.add('active');
      document.querySelector('.menu-main-links').classList.add('active');
      document.querySelector('.main-l1-header').classList.remove('active');
      document.querySelector('.menu-log-in-links ').classList.remove('active');
    });

    /*  ======================================= */
    // subLevel Back 
    this.subLevelBack.addEventListener(this.eventtype, function (evt) {
      evt.preventDefault();
      let currentLevel = 0;

      Array.prototype.slice
        .call(document.querySelectorAll('.menu-item-level--open'))
        .forEach((elem) => {
          const val = elem.getAttribute('data-level');
          if (val > currentLevel) currentLevel = Number(val);
        });
  
      if (currentLevel > 3) {
        document.querySelector('.mobile-sublevel-back').focus();
    
        const activeMenu = document.querySelector('.menu-primary-links.active');
        const parentLevel = activeMenu.querySelector(`[data-level="${currentLevel - 2}"]`);
        const currentLabel = parentLevel.querySelector('.submenu-header').innerHTML;
        this.innerHTML = currentLabel;
      }

      if (currentLevel === 3) {
        document.querySelector('.mobile-main-back').focus();
        const activeMenu = document.querySelector('.menu-primary-links.active');
        const parentLevel = activeMenu.querySelector('.submenu-header');
        const currentLabel = parentLevel.innerHTML;
        this.innerHTML = currentLabel;
      }
    });

    /*  ======================================= */
    // toggle menu items
    this.sectionToggle.forEach(function (el) {
      el.addEventListener(self.eventtype, function (evt) {
        evt.preventDefault();
        this.mobileMainNav = document.querySelector('.menu-main-links');
        this.mainNavHeader = document.querySelector('.main-nav-header');
        this.mainL1Header = document.querySelector('.main-l1-header');
        this.mainL0Header = document.querySelector('.main-l0-header');
        this.mobileMainBackActive = document.querySelector('.mobile-main-back.active a');
        this.mobileUtilityNav = document.querySelector('.menu-utility-links');
        this.mobileLangNav = document.querySelector('.menu-lang-toggle');
        this.mobileHamburgerNav = document.querySelector(
          '.cpc-mobile--hamburger'
        );
        this.mobileMainNav.classList.remove('active');
        this.mainNavHeader.classList.remove('active');
        this.mainL0Header.classList.remove('active');
        this.mainL1Header.classList.add('active');
        if (this.mobileUtilityNav) { this.mobileUtilityNav.classList.remove('active'); }
       
        this.mobileLangNav.classList.remove('active');
        // this.mobileMainBackActive.setAttribute('tabindex', 0);
        this.mobileMainBackActive.focus();
        this.mobileMainBackActive.blur();

        const item = el;
        const sitemap = item.getAttribute('data-sitemap');
        const mainSubNav = document.querySelector(
          `.menu-primary-links[data-sitemap='${sitemap}']`
        );
 
        let mainMenuLinks = mainSubNav.querySelectorAll('a[href]');
  
        Array.prototype.slice.call(mainMenuLinks).forEach((mainNavLink) => {
          mainNavLink.setAttribute('tabindex', -1);
        });
   

        if (!item.classList.contains('active')) {
          const mobileNavBacks = document.querySelectorAll('.mobile-main-back');
          const cpcSections = document.querySelectorAll('.cpc-section-toggle');
          mainMenuLinks = document.querySelector(`.menu-primary-links[data-sitemap='${sitemap}']`).children;

          for (let i = 0; i < cpcSections.length; i++) {
            cpcSections[i].classList.remove('active');
          }

          item.classList.add('active');
          mobileNavBacks.forEach(function (elem) {
            elem.addEventListener('click', function () {
              evt.preventDefault();
              item.classList.remove('active');
              
              Array.prototype.slice.call(mainMenuLinks).forEach((mainNavLink) => {
                const mainNavAnchor = mainNavLink.querySelector('a[href]');
                if (mainNavAnchor != null) {
                  mainNavAnchor.setAttribute('tabindex', -1);
                }
              });
            });
          });
          // Tab traversal for Lvl1 Submenus
          const lastMainFocusEles = mainMenuLinks[mainMenuLinks.length - 1];
          lastMainFocusEles.addEventListener('keydown', event => tabKeyHandler(event, this.mobileMainBackActive));

          const tabKeyHandler = (e, focusElem) => {
            if (e.which === 9 && !e.shiftKey) {
              e.preventDefault();
              focusElem.focus();
            }
          };

          Array.prototype.slice.call(mainMenuLinks).forEach((mainNavLink) => {
            const mainNavAnchor = mainNavLink.querySelector('a[href]');
            if (mainNavAnchor != null) {
              mainNavAnchor.removeAttribute('tabindex');
            }
          });
        }
      
    
        if (sitemap) {
          self.level = 0;
          self._toggleLevels();
          mainSubNav.classList.add('active');
        }
      });
    });

    /*  ======================================= */
    // open (or close) the menu

    this.mobileMenuBackground.addEventListener(this.eventtype, triggerCloseMenu);
    this.triggerClose.addEventListener(this.eventtype, triggerCloseMenu);

    function triggerCloseMenu() {
      this.mobileUtilityNav = document.querySelector('.menu-utility-links'); 
      this.mobileLangNav = document.querySelector('.menu-lang-toggle');
      this.signInMenu = document.getElementById('mobile-nav-section-signed-in');
      this.signInHeader = document.querySelector('.my-account-sign-in');
      this.signOutHeader = document.querySelector('.my-account-signout');
      this.mainNavHeader = document.querySelector('.main-nav-header');
      this.mobileMenuBackground = document.querySelector('.mobile-active-menu-background'); 
      this.loginBack = document.querySelector('.my-account-login');
     
      if (this.mobileUtilityNav) { this.mobileUtilityNav.classList.add('active'); }
      if (this.mobileLangNav) { this.mobileLangNav.classList.add('active'); }
      this.mobileMenuBackground.classList.remove('active');
      this.loginBack.classList.remove('active');
      this.mainNavHeader.classList.add('active');
 
      if (getIsAuthenticate()) {
        this.signInMenu.classList.add('active');
        this.signInHeader.classList.remove('active');
        this.signOutHeader.classList.add('active');
      } else {
        this.signInMenu.classList.remove('active');
        this.signInHeader.classList.add('active');
        this.signOutHeader.classList.remove('active');
      }


      if (!headerText) {
        headerText = $.cpc.lang === 'fr' ? setLocaleFr() : setLocaleEn();
      }
     
      self._resetMenu(); 
      this.removeAttribute('tabindex');
      const signinModal = document.getElementById('signinFormDesktop');
      if (
        signinModal &&
          signinModal.classList.contains('tingle-modal--visible')
      ) {
        const closeBtn = document.querySelector(
          '.tingle-modal-box__close-icon'
        );
        if (closeBtn) {
          closeBtn.click();
          signinModal.parentElement.removeChild(signinModal);
        }
      }
    }

    this.trigger.addEventListener(this.eventtype, function () {
      this.mobileUtilityNav = document.querySelector('.menu-utility-links'); 
      this.mobileLangNav = document.querySelector('.menu-lang-toggle');
      this.signInMenu = document.getElementById('mobile-nav-section-signed-in');
      this.signInHeader = document.querySelector('.my-account-sign-in');
      this.signOutHeader = document.querySelector('.my-account-signout');
      this.mainNavHeader = document.querySelector('.main-nav-header');
      this.mobileMenuBackground = document.querySelector('.mobile-active-menu-background');
      this.loginMenu = document.querySelector('.my-account-login');
      
      document.querySelector('.my-account-login').classList.remove('active');

      if (this.mobileUtilityNav) { this.mobileUtilityNav.classList.add('active'); }
      this.mobileLangNav.classList.add('active');
      this.mobileMenuBackground.classList.add('active');
 
 
      if (getIsAuthenticate()) {
        this.signInMenu.classList.add('active');
        this.signInHeader.classList.remove('active');
        this.signOutHeader.classList.add('active');
      } else {
        this.signInMenu.classList.remove('active');
        this.signInHeader.classList.add('active');
        this.signOutHeader.classList.remove('active');
      }

      if (!headerText) {
        headerText = $.cpc.lang === 'fr' ? setLocaleFr() : setLocaleEn();
      }
    
      self._openMenu();
    });


    // opening a sub level menu
    this.menuItems.forEach(function (el) {
      // check if it has a sub level
      const subLevel = el.querySelector(`.${self.options.itemClass}`);
      
      if (subLevel) {
        const backLevelLabel = document.querySelector('.mobile-sublevel-back .menu-item-back');
    
        el.querySelector('a, span').addEventListener(
          self.eventtype,
          function (ev) {
            ev.preventDefault();
            let currentLevel = 0;
            Array.prototype.slice
              .call(document.querySelectorAll('.menu-item-level--open'))
              .forEach((elem) => {
                const val = elem.getAttribute('data-level');
                if (val > currentLevel) currentLevel = Number(val);
              });
            
            const level = Number(closest(el, self.options.itemClass).getAttribute(
              'data-level'
            ));
        
            if (level === 1) {
              ev.stopPropagation();
              const currentActiveMenu = closest(el, 'active');
              const currentLabel = currentActiveMenu.querySelector('.submenu-header').innerHTML;
              backLevelLabel.innerHTML = currentLabel;
            }

            if (level > 1) {
              ev.stopPropagation();
              const parentLevel = closestLevel(el, currentLevel);
              const currentLabel = parentLevel.querySelector('.submenu-header').innerHTML;
              backLevelLabel.innerHTML = currentLabel;
            }
          
            if (self.level <= level) {
              ev.stopPropagation();
              subLevel.classList.add('show-level');
              closest(el, self.options.itemClass).classList.add(
                `${self.options.itemClass}--overlay`
              );
              // keep slide in animation
              setTimeout(() => {
                self._openMenu(subLevel);
              }, 1);
              document.getElementById('main-nav').scrollTop = 0;
            }
          }
        );
      }
    });

    // moving back up
    if (this.levelBack) {
      const mainL1Header = document.querySelector('.main-l1-header');
      const mainL2Header = document.querySelector('.main-l2-header');
      this.levelBack.addEventListener(self.eventtype, function (ev) {
        ev.preventDefault();

        let currentLevel = 0;
        Array.prototype.slice
          .call(document.querySelectorAll('.menu-item-level--open'))
          .forEach((elem) => {
            const val = elem.getAttribute('data-level');
            if (val > currentLevel) currentLevel = Number(val);
          });

        if (currentLevel === 2) {
          mainL1Header.classList.add('active');
          mainL2Header.classList.remove('active');
        }

        if (self.level <= currentLevel) {
          // const currentOpenMenu = document.querySelector(`[data-level="${currentLevel}"]`);
          self.level = currentLevel - 1;
          self._closeMenu();
          document.getElementById('main-nav').scrollTop = 0;
        }
      });
    }

    window.addEventListener(
      'orientationchange',
      function () {
        // Announce the new orientation number
        // clearDefaultHeight();
        self._resetMenu();
      },
      false
    );
  },
  _openMenu: function (subLevel) {
    this.mainL0header = document.querySelector('.main-l0-header');
    this.mainL1header = document.querySelector('.main-l1-header');
    this.mainL2header = document.querySelector('.main-l2-header');
    this.trigger.setAttribute('aria-expanded', 'true');
    this.triggerClose.setAttribute('aria-expanded', 'true');
   

    Array.prototype.slice
      .call(document.querySelectorAll('.menu-primary-links a[href]'))
      .forEach((mainNav) => {
        mainNav.setAttribute('tabindex', -1);
      });

    if (this.level === 0) {
      document.querySelector('body').classList.add('no-scroll-nav');
      document.querySelector('.mobile-nav-myAccount').classList.add('active');
  
      this.levels[0].parentElement.classList.add('show-level');
      this.mobileMainNav.classList.add('active');
      this.topSectionMain.classList.remove('active');
      this.topSection.classList.add('active');
      this.mainL0header.classList.add('active');
    }

    // increment level depth
    this.level += 1;
    setTimeout(() => {
      this._setTransform(true);
    }, 1);
    this.mainL1Header.querySelector('.mobile-main-back').classList.add('active');
  
    if (subLevel) {
      // reset transform for sublevel
      this._setTransform(true, subLevel, true);
   
      const menuLevel = this.level;
      const mainL0header = document.querySelector('.main-l0-header');
      const mainL1header = document.querySelector('.main-l1-header');
      const mainL2header = document.querySelector('.main-l2-header');
      mainL0header.classList.remove('active');

      if (menuLevel === 1) {
        this.open = true;
      }
      

      if (menuLevel > 0) {
        if (this.siteMapRoot === 'campaign') {
          mainL2header.classList.remove('active');
          mainL1header.classList.add('active');
        } else {
          mainL2header.classList.add('active');
          mainL1header.classList.remove('active');
          this.mainL1Header.querySelector('.mobile-main-back').classList.remove('active');
          mainL2header.querySelector('.mobile-main-back').classList.add('active');
        }
      }

      for (let i = 0, len = menuLevel.length; i < len; ++i) {
        const levelEl = menuLevel[i];
        if (levelEl !== subLevel && !levelEl.classList.contains(`${this.options.itemClass}--open`)) {
          this._setTransform(false, levelEl);
        }
      }
    }

   
    // add class *--open to the opening level element
    if (subLevel) {
      subLevel.classList.add(`${this.options.itemClass}--open`);
    } else if (this.levels[0]) {
      // Goes here on initial load
      this.levels[0].classList.add(`${this.options.itemClass}--open`);
    }
  },
  _resetMainMenu: function () {
    document.querySelector('.menu-log-in-links').classList.remove('active');
    document.getElementById('trigger-close').focus();
    Array.prototype.slice
      .call(document.querySelectorAll('.menu-primary-links'))
      .forEach((mainNav) => {
        mainNav.classList.remove('active');
        this._resetSectionToggle();
        this.level = 1;
        this._toggleLevels();
        this.open = true;
      });
  },
  // close the menu
  _resetMenu: function () {
    document.querySelector('.menu-log-in-links').classList.remove('active');
    document.querySelector('.mobile-nav-myAccount').classList.remove('active');

    this.topSectionMain.classList.add('active');
    this.topSection.classList.remove('active');
    this.mainL0header.classList.remove('active');
    this.trigger.setAttribute('aria-expanded', 'false');
    this.triggerClose.setAttribute('aria-expanded', 'false');
    
    Array.prototype.slice
      .call(document.querySelectorAll('.menu-primary-links'))
      .forEach((mainNav) => {
        mainNav.classList.remove('active');
        this._resetSectionToggle();
        this._setTransform(false);
        this.level = 0;
        this._toggleLevels();
        this.open = false;
        this.trigger.classList.remove('active');
        document.querySelector('body').classList.remove('no-scroll-nav');
      });
  },
  _resetSectionToggle: function () {
    this.sectionToggle.forEach((el) => {
      const item = el;
      item.classList.remove('active');

      const itemSitemap = item.getAttribute('data-sitemap');
      if (itemSitemap && itemSitemap === this.siteMapRoot) {
        item.classList.add('active');
      }
    });
  },
  // close sub menus
  _closeMenu: function () {
    this._setTransform(true);
    this._toggleLevels();
  },
  // translate the el
  _setTransform: function (setOpen, el, reset) {
    // const ele = el || this.wrapper;
    const ele = el || this.wrapper;
    // Keep the translate3d y variable consistent with
    // mobile-nav.component.scss variable: $mobile-nav-top-offset
    let transformOpen = setOpen
      ? 'translate3d(0,0,0)'
      : 'translate3d(-100%,0,0)';
    if (reset) {
      transformOpen = '';
    }
    ele.style.WebkitTransform = transformOpen;
    ele.style.MozTransform = transformOpen;
    ele.style.transform = transformOpen;
  },
  // removes classes *--open from closing levels
  _toggleLevels: function () {
    const menuLevels = this.levels;
    const menuLevel = this.level;

    for (let i = 0, len = menuLevels.length; i < len; ++i) {
      const levelEl = menuLevels[i];
      if (levelEl.getAttribute('data-level') >= menuLevel + 1) {
        levelEl.classList.remove(`${this.options.itemClass}--open`);
        levelEl.classList.remove(`${this.options.itemClass}--overlay`);

        // keep slide out animation
        levelEl.classList.remove('show-level');
      } else if (Number(levelEl.getAttribute('data-level')) === menuLevel) {
        levelEl.classList.remove('menu-item-level--overlay');
      }
    }
  },
};

export default initializeComponent;
