const templates = { 
  utilityNav: { 
    lvl1: params => `
      <li><a href='${params.url}'>${params.label}</a></li>
    `,
    cart: params => `
      <a href='${params.lvl1Url}' class="cart"><span class="cart-qty-indicator">${params.cartQty}</span> <span class="cart-icon" aria-label="${params.cartLabel}" role="img">${params.cartSvg}</span></a>
    `    
  },
  mainBusinessNav: {
   
    campaignLvl0: params => ` 
      ${params.lvl1}
      ${params.cta}
       `,
    lvl0: params => ` 
      <div class="menu-overview">
      <a class="parent-title" href="${params.lvl0Link}" target="${params.lvl0Target}" >
      <h3 class="submenu-header">${params.lvl0Label}<span class="open-new-window"></span></h3>
      <p>${params.lvl0Description}</p>
      </a>
      </div> 
      ${params.lvl1}
       `,
    lvl1: params => `
          <li>
            <a href='${params.lvl1Link}' class='${params.isParentClass}' target="${params.lvl1Target}" alt="${params.linkAlt}">${params.lvl1Label}</a>
            <div class='${params.isChildClass} level1'>
              <div class="menu-overview">
              <a class="parent-title" href="${params.lvl1Link}" target="${params.lvl1Target}" alt="${params.linkAlt}">
              <h3 class="submenu-header">${params.lvl1Label}<span class="open-new-window"></span></h3>
                <p>${params.lvl1Description}</p>
              </a>
              </div> 
              <ul>
                ${params.lvl2}
              </ul>
            </div>
          </li>
    `,
    lvl2: params => `
          <li>
            <a href='${params.lvl2Link}' class='menu-title ${params.isParentClass}' target="${params.lvl2Target}" alt="${params.linkAlt}">${params.lvl2Label}</a>
          <div class='${params.isChildClass} level1'>
          <div class="menu-overview">
          <a class="parent-title" href="${params.lvl2Link}" target="${params.lvl2Target}" alt="${params.linkAlt}">
          <h3 class="submenu-header">${params.lvl2Label}<span class="open-new-window"></span></h3>
          <p>${params.lvl2Description}</p>
          </a>
          </div> 
          <ul>
          ${params.lvl3}
          </ul>
        </div>

        </li>
          

    `,
    lvl3: params => `          
    <li>
      <a href='${params.lvl3Link}' class='${params.isParentClass}' target="${params.lvl3Target}" alt="${params.linkAlt}">${params.lvl3Label}</a>
      <div class='${params.isChildClass} level4'>
      <div class="menu-overview">
        <a class="parent-title" href="${params.lvl3Link}" target="${params.lvl3Target}" alt="${params.linkAlt}">
        <h3 class="submenu-header">${params.lvl3Label}<span class="open-new-window"></span></h3>
        <p>${params.lvl3Description}</p>
        </a>
        </div> 
        <ul>
          ${params.lvl4}
        </ul>
      </div>
    </li>    
    `,
    lvl4: params => `
          <li>
          <a href='${params.lvl4Link}' class='${params.isParentClass}' target="${params.lvl4Target}" alt="${params.linkAlt}">${params.lvl4Label}</a>
          <div class='${params.isChildClass} level4'>
            <div class="menu-overview">
            <a class="parent-title" href="${params.lvl4Link}" target="${params.lvl4Target}" alt="${params.linkAlt}">
            <h3 class="submenu-header">${params.lvl4Label}<span class="open-new-window"></h3>
            <p>${params.lvl4Description}</span></p>
            </a>
            </div> 
            <ul>
            ${params.lvl5}
            </ul>
        </div>
        </li> 
    `,
    lvl5: params => `
    <li>
      <a href='${params.lvl5Link}' class='${params.isParentClass}' target="${params.lvl5Target}" alt="${params.linkAlt}">${params.lvl5Label}</a>
      <div class='${params.isChildClass} level4'>
        <a class="parent-title" href="${params.lvl5Link}" target="${params.lvl5Target}" alt="${params.linkAlt}">${params.lvl5Label}</a>
        <ul>
          ${params.lvl6}
        </ul>              
      </div>
    </li>   
    `,
    lvl6: params => `
    <li>
      <a href='${params.link}' class='${params.isParentClass}' target="${params.lvl5Target}" alt="${params.linkAlt}">${params.label}</a>
    </li>    
    `     
  },
  mobileContainer: 
    params => `
    <div class='mobile-container-wrapper show-for-medium-down'>
    <div class='top-section top-section--main'>
      ${params.mobileHeader}
    </div>
      ${params.mobileBody}
    </div>
    <div class="mobile-active-menu-background"></div>
    `,
  signedInNav: 
    params => `
      <li class="auth-link user-nav-wrapper">
        <h4 class='sso-username'>${params.welcome}, <strong>${params.userName}</strong></h4>
        <ul class='sso-user-nav menu--profile-links'>
          <li><a href='${params.cpDomain}/dash2-tableau2/${params.cpcLang}' class='parent-link'>${params.navDashboard}</a></li>
          <li><a href='${params.cpDomain}/pfe2-pap2/${params.cpcLang}'
          ' class='parent-link'>${params.navProfile}</a></li>
          ${params.additionalItems}
          <li><a href='${params.cpDomain}${params.linkMySupport}' class='parent-link'>${params.mysupport}</a></li>
        </ul>
        
      </li>
      <li class="auth-link sign-out"></li>
    `,
  
  logInNav: params => `
  <div class='my-account-wrapper'>
    <div class='my-account-form'>
      <form method="post" action="${params.ssoSignInLink}" stepup="1" name="headerSISU"
        class="cpidSignIn sso_form" businessurl="https://www.canadapost.ca/dash-tableau/${params.cpcLang}/"
        id="signinFormMobile">
        <div class='signin-account-container'>
          <h3 class="signin-header">${params.signInHeader}</h3>
          <label for="username" class='title'>${params.username}</label>
          <input id="username" placeholder="" name="username" type="text">
          <div class='rememberme-wrapper'>
            <input value="1" id="rememberme" name="rememberme" type="checkbox">
            <label for="rememberme">${params.rememberUsername}</label>
          </div>
          <label for="password" class='title'>${params.password}</label>
          <input id="password" placeholder="" name="password" type="password" autocomplete="nope">
          <input value="${params.signInButton}" class="button" type="submit">
          <div class='user-secure'>
          ${params.forgot} <a href="${params.ssoForgotUsernameLink}" class="sso_link"
              businessurl="https://www.canadapost.ca/dash-tableau/${params.cpcLang}/" tabindex="0">${params.forgotPasswordUsernameLabel}?</a> ${params.or}
            <a href="${params.ssoForgotPasswordLink}" class="sso_link"
              businessurl="https://www.canadapost.ca/dash-tableau/${params.cpcLang}/" tabindex="0">${params.forgotPasswordLabel}?</a>
          </div>
        </div>
        <div class='create-account-container'>
          <h3 class="signin-header">${params.createAccount}</h3>
          <p class="signup-text-mobile">
            ${params.signUpText}
          </p>
          <a href="${params.ssoSignUpLink}" stepup="1" class="button sso_link"
            businessurl="https://www.canadapost.ca/dash-tableau/${params.cpcLang}/" tabindex="0">${params.signUp}</a>
        </div>
      </form>
    </div>
  </div>`,
  header: {
    main: params => `
    <ul class="main-nav-header">
      <li class="cpc-mobile-menu">
        <div class="cpc-mobile-menu-trigger" aria-label="Menu" id="trigger" class="menu-trigger" tabindex="0"></div>
      </li>
      <li class='logo'>
        <a href='${params.cpDomain}/${params.cpcLang === 'fr' ? 'scp' : 'cpc'}/${params.cpcLang}/?name=tgt' class='${params.hideLinks}'><img src='${params.logoURL}'
            alt='${params.logoAltText}' /></a>
            <a href='${params.campaignLandingPageURL}' class='${params.showLinks}'><img src='${params.logoURL}'
            alt='${params.logoAltText}' /></a>
      </li>
      <li class="mobile-nav-top-r-links " >
        <div class="top-r-links-container">
          ${params.cartLink}
          <a href="#" class="menu-search ${params.hideLinks}" aria-label='${params.searchText}'>
            <img src='${params.searchURL}' alt='${params.searchLabel}' />
          </a>
          <a target="_blank" rel="noopener" href='${params.campaignCtaURL}' ${params.campaignCtaRevealId}  class='menu-campaign-cta ${params.showCtaCampaignLinks}'>${params.campaignCtaText}</a>
        </div>
      </li>
    </ul>
`, 
    lvl0: params => `
      <ul class="main-l0-header">
        <li class="cpc-mobile-menu">
          <div class="cpc-mobile--hamburger" aria-label="${params.close}" id="trigger-close" class="menu-trigger"  tabindex="0">
            <span></span>
            <span></span>
          </div>
        </li>
        <li class="mobile-nav-myAccount ${params.hideLinks}">
            <a href='${params.myAccountURL}'class='my-account-sign-in'>${params.signInRegister}</a>
            <a href='#' class='my-account-login'>${params.loginBack}</a>
            <a href='${params.myAccountURL}' class='my-account-signout'>${params.signOut}</a>
        </li>
        <li class="${params.showCtaCampaignLinks}">
        <a target="_blank" rel="noopener" href='${params.campaignCtaURL}' ${params.campaignCtaRevealId} class='campaign-cta'>${params.campaignCtaText}</a>
        </li>
      </ul>
  `,
    lvl1: params => `
        <ul class="main-l1-header ${params.hideLinks}" >
          <li class="mobile-main-back">
            <a  href="#" aria-label='${params.mainMenu}' tabindex='0'>${params.mainMenu} </a>
            </li>
        </ul>`,
    lvl2: params => `
    <ul class="main-l2-header ${params.hideLinks}">
      <li class="mobile-main-back">
        <a  href="#" aria-label='${params.backMainMenu}' tabindex='0'></a>
      </li>
      <li class="mobile-sublevel-back">
        <a  class="menu-item-back" href="#" aria-label='${params.backLabel}'>${params.backLabel} </a>
        </li>
       
    </ul>`
  },
  mainMenuNav: 
  params => `
  <ul>
    <li>
      <a id="mobile-nav-toggle-personal" href='#'
        class='my-personal-link cpc-section-toggle parent-link' data-sitemap="personal">${params.personal}</a>
    </li>
    <li>
      <a id="mobile-nav-toggle-business" href='#'
        class='my-business-link cpc-section-toggle parent-link'
        data-sitemap="business">${params.business}</a>
    </li>
    <li class="my-aboutus-list-item">
      <a id="mobile-nav-toggle-our-company" href='#'
        class='my-aboutus-link cpc-section-toggle parent-link'
        data-sitemap="our-company">${params.ourCompany}</a>
    <li>
    <li>
      <a id="mobile-nav-toggle-shop" href='#' class='my-shop-link  cpc-section-toggle parent-link'
        data-sitemap="shop">${params.store}</a>
    <li>
    <li>
      <a id="mobile-nav-toggle-support" href='${params.cpDomain}${params.supportUrl}' class='my-support-link'
        data-sitemap="support">${params.support}</a>
    <li>

  </ul>
  `,

  campaignMenu: 
  params => `
  <ul>
    ${params.campaignNav}
  </ul>
  `,
  quickLinkMenu:
  params => `

  <li class="search-modal-quick-link">
    <a href=' ${params.cpDomain}/track-reperage/${params.cpcLang}' class="search-modal-link">
    <span class="search-icon track"></span>
    <span class="search-modal-label">${params.track}</span>
    </a>
    </li>
    <li class="search-modal-quick-link">
      <a href="${params.cpDomain}${params.findPostalCodeURL}" class="search-modal-link">
        <span class="search-icon look-up-postal-code"></span>
        <span class="search-modal-label">${params.findPostalCode}</span>
      </a>
    </li>
    <li class="search-modal-quick-link">
      <a href="${params.cpDomain}${params.findRateURL}" class="search-modal-link">
        <span class="search-icon find-rate"></span>
        <span class="search-modal-label">${params.findRate}</span>
      </a>
    </li>
    <li class="search-modal-quick-link">
    <a href="${params.cpDomain}${params.moreToolsURL}" class="see-more-tools">${params.seeMoreTools}</a>
    </li>
    `,
  myAccountKibo: {
    lvl1: params => `
        <li>
          <a href="${params.signInLink}">${params.signInText}</a>
        </li>`,
  },
  body: {
    campaign: params => `
    <div class="mobile-container" id='main-nav-wrapper'>
    <nav id='main-nav' class='cpc-menu' role="navigation" aria-label='menu'>
     
      <div class='menu-item-level'>

      <div class='top-section'>
      ${params.mobileHeader}
      </div>
      
        <ul id="mobile-nav-section-log-in" class='menu-log-in-links'>
        ${params.logInMenu}
        </ul>
        <ul id="mobile-nav-section-signed-in" class='menu-signed-in-links'>
        ${params.signedInMenu}
        </ul>
        <ul id="mobile-nav-section-main" class='menu-main-links' data-sitemap="mainMenu">
          ${params.mainMenuNav}
        </ul>
        <ul id="mobile-nav-section-personal" class='menu-primary-links hide' data-sitemap="personal">
          ${params.personalMenu}
        </ul>
        <ul id="mobile-nav-section-business" class='menu-primary-links hide' data-sitemap="business">
          ${params.businessMenu}
        </ul>
        <ul id="mobile-nav-section-our-company" class='menu-primary-links hide'
          data-sitemap="our-company">
          ${params.aboutusMenu}
        </ul>
        <ul id="mobile-nav-section-shop" class='menu-primary-links hide' data-sitemap="shop">
        ${params.eshopMenu}
        </ul>
       <ul class='menu-lang-toggle'>
          <li class="language-toggle-mobile">
            <a href='#'>${params.langToggle}</a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
    `,
    main: params => `
    <div class="mobile-container" id='main-nav-wrapper'>
    <nav id='main-nav' class='cpc-menu' role="navigation" aria-label='menu'>
     
      <div class='menu-item-level'>

      <div class='top-section'>
      ${params.mobileHeader}
      </div>
      
        <ul id="mobile-nav-section-log-in" class='menu-log-in-links'>
        ${params.logInMenu}
        </ul>
        <ul id="mobile-nav-section-signed-in" class='menu-signed-in-links'>
        ${params.signedInMenu}
        </ul>
        <ul id="mobile-nav-section-main" class='menu-main-links ${params.showCampaignLinks}' data-sitemap="mainMenu">
          ${params.mainMenuNav}
        </ul>
        <ul id="mobile-nav-section-personal" class='menu-primary-links ${params.hideMenu}' data-sitemap="personal">
          ${params.personalMenu}
        </ul>
        <ul id="mobile-nav-section-business" class='menu-primary-links ${params.hideMenu}' data-sitemap="business">
          ${params.businessMenu}
        </ul>
        <ul id="mobile-nav-section-our-company" class='menu-primary-links ${params.hideMenu}'
          data-sitemap="our-company">
          ${params.aboutusMenu}
        </ul>
        <ul id="mobile-nav-section-shop" class='menu-primary-links ${params.hideMenu}' data-sitemap="shop">
        ${params.eshopMenu}
        </ul>

        <ul class='menu-utility-links ${params.hideMenu}'>
           ${params.quickLinkMenu}
       </ul>

       <ul class='menu-lang-toggle ${params.hideMenu}'>
          <li class="language-toggle-mobile">
            <a href='#'>${params.langToggle}</a>
          </li>
        </ul>

        
       <ul class='menu-cl-ctas ${params.showCampaignCtas}'>
       ${params.campaignCtaMenu}
      </ul>
        <ul class='menu-cl-utility ${params.showMenu}'>
        ${params.campaignUtilsMenu}
        </ul>
       
      </div>
    </nav>
  </div>
    `,
    logIn: params => `
    <ul id="mobile-nav-section-myAccount" class='menu--primary-links my-account-profile'>
    ${params.logInMenu}
    </ul>`
   

  }
};

export default templates;

