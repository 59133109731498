function getLocale() {
  return {
    closeLabel: 'Fermer la fen&ecirc;tre et revenir &agrave; la derni&egrave;re page',
    signInHeader: 'Acc&eacute;dez à votre compte',
    signInButton: 'Ouvrir une session ',
    signUp: 'Ouvrir un compte',
    username: "Nom d'utilisateur",
    rememberUsername: 'M&eacute;moriser mes coordonn&eacute;es',
    password: 'Mot de passe',
    forgotPasswordUsernameLabel: "nom d'utilisateur",
    forgotPasswordPasswordLabel: 'mot de passe',
    forgot: 'Oubli&eacute; votre ',
    or: 'ou',
    rightArea: { 
      default: { 
        createAccount: 'Ouvrez un compte en ligne',
        signUpText: 'Cr&eacute;ez un compte en ligne pour sauvegarder vos articles suivis, obtenez des r&eacute;ductions avantageuses pour votre entreprise et plus encore.',
        signUpPoint1: 'Acc&eacute;dez à votre tableau de bord personnalis&eacute; en quelques étapes faciles!',
        // signUpPoint2: "Utilisez nos outils d'exp&eacute;dition et de marketing en ligne",
        // signUpPoint3: 'Acc&eacute;dez &agrave; votre tableau de bord',
      },
      ship_online: {
        createAccount: "Nouvel utilisateur d'outils d'exp&eacute;dition en ligne?",
        signUpText: 'Ouvrez un compte en ligne pour utiliser l\'outil Expédier en ligne, sauvegarder vos articles repérables, recevoir vos factures et vos relevés par l\'entremise de postel et accéder à votre tableau de bord personnalisé. C\'est simple et rapide!'
      },
      shipping_manager: {
        createAccount: 'Ouvrez un compte en ligne',
        signUpText: 'Les clients commerciaux ont accès à de précieux outils d’expédition et à des réductions grâce à leur profil d’entreprise en ligne.'
      },
      biz: { 
        createAccount: 'Ouvrez un compte en ligne',
        signUpText: 'Inscrivez-vous &agrave; Solutions pour petites entreprises pour acc&eacute;der &agrave; des outils pratiques et des r&eacute;ductions tarifaires ou si vous &ecirc;tes d&eacute;j&agrave; titulaire d\'une carte, mais que vous n\'avez pas encore configur&eacute; votre compte en ligne.'
      }
    },
    leftArea: { 
      default: { 
        title: 'Acc&eacute;dez &agrave; votre compte'
      },
      ship_online: {
        title: "Pr&ecirc;t pour l'exp&eacute;dition?",
        overview: 'Ouvrez une session dans votre compte Postes Canada ou postel<sup>MC</sup> pour créer une étiquette d\'expédition à la maison. Utilisez le service Expédier en ligne pour envoyer un colis n\'importe où au Canada.',
        footnote: ''
      },
      biz: { 
        title: 'Accédez à votre compte',
        overview: 'Ouvrez une session si vous &ecirc;tes membre du programme Solutions pour petites entreprises* et que vous avez d&eacute;j&agrave; configur&eacute; votre compte en ligne.',
        footnote: '* Anciennement Entrepreneur<sup>MC</sup>'
      },
      commercial: {
        title: 'Ouvrir une session pour en savoir plus',
        overview: 'Le service de réacheminement du courrier sur mesure est offert aux clients commerciaux seulement.',
        footnote: ''
      },
      shipping_manager: {
        title: 'Accédez à votre compte',
        overview: 'Accédez à votre profil d’entreprise en ligne de Postes Canada.',
        footnote: ''
      },
      tools: {
        title: 'Acc&eacute;dez &agrave; votre compte',
        overview: 'Ouvrez une session si vous utilisez Solutions pour petites entreprises* et que votre compte en ligne est configur&eacute;.',
        footnote: '* Anciennement le programme Entrepreneur<sup>MC</sup>'
      }
    },
    dontHaveAccount: 'Vous n\'avez pas de compte?',
    registerNow: 'Ouvrir un compte.'
  };
}

export default getLocale;