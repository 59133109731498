/**
 * Simple API to get cart quantity, abstracts away from direct access to the persistence layer
 *
 * @example
 * 
 */
import { getCookie } from './cookies';

function getShoppingCartQuantity() {
  const quantity = +getCookie('estorecartcount');
  return quantity;
}

export default getShoppingCartQuantity;