export default {
  personnel: {
    receiving: 'Recevoir du courrier',
    sending: 'Expédier',
    moneyGovServices: 'Services financiers',
    stampsCoins: 'Timbres et collection'
  },
  entreprise: {
    shipping: 'Expédition',
    marketing: 'Marketing',
    eCommerce: 'Cybercommerce',
    smallBusiness: 'Petite entreprise',
    postalServices: 'Services postaux'
  }
};
