/* eslint no-console: 0 */
/* eslint no-undef: 0 */
/**
 * auth.module contains wrappers to get SSO authentication headers, as well as SSO login and logout
 * related support. In addition, SSO sub-domains are determined based on the domain that the given
 * page is running on.
 */

import { getCookie } from './cookies';

const localhostRegEx = /localhost/i;
const shopRegEx = /(sandbox\.mozu\.com|\.mozu\.com|boutique|store)/i;

const initModule = {
  getSSOGroup() {
    if (this.getIsAuthenticated()) {
      return document.head.querySelector('[name=sso-groups]').content;
    }
    return 'Unauthenticated';
  },

  getSSOToken() {
    return document.head.querySelector('[name=sso-token]').content;
  },

  getAccountGroup() {
    if (this.getIsAuthenticated()) {
      return (document.head.querySelector('[name=account-group]')) ? document.head.querySelector('[name=account-group]').content : '';
    }
    return '';
  },

  getCSRFToken() {
    if (this.getIsAuthenticated()) {
      return this.getSSOToken() ? window.btoa(this.getSSOToken()) : '';
    }
    return '';
  },

  getSSOUserName() {
    return document.head.querySelector('[name=sso-username]').content;
  },
  getSSOCustomerNumber() {
    return getCookie('acc');
  },

  getIsAuthenticated() {
    return (
      !!document.head.querySelector('[name=sso-token]') &&
      !!document.head.querySelector('[name=sso-token]').content &&
      this.getSSOUserName() !== 'Unauthenticated'
    );
  },

  getHostURL() {
    const host = window.location.host;
    switch (true) {
      case localhostRegEx.test(host):
        return 'https://dev10.canadapost-postescanada.ca';
      default:
        return window.location.href;
    }
  },

  getDomain() {
    const host = window.location.host;
    switch (true) {
      case localhostRegEx.test(host):
        return 'https://dev10.canadapost-postescanada.ca';
      default:
        return `${window.location.protocol}//${host}`;
    }
  },

  getSSODomain() {
    const host = window.location.host;
    switch (true) {
      case localhostRegEx.test(host):
      case /dev10/.test(host):
        return 'https://dev001-sso.epost.ca';
      case /dev11/.test(host):
        return 'https://dev001-sso-osu.canadapost-postescanada.ca';
      case /dev12/.test(host):
        return 'https://dev002-sso-osu.canadapost-postescanada.ca';
      case /dev13/.test(host):
        return 'https://dev003-sso-osu.canadapost-postescanada.ca';
      case /dev14/.test(host):
        return 'https://dev004-sso-osu.canadapost-postescanada.ca';
      case /stg10/.test(host):
        return 'https://stg001-sso-osu.canadapost-postescanada.ca';
      case /stg11/.test(host):
        return 'https://stg001-sso-osu.canadapost-postescanada.ca';
      case /stg12/.test(host):
        return 'https://stg002-sso-osu.canadapost-postescanada.ca';
      case /stg13/.test(host):
        return 'https://stg003-sso-osu.canadapost-postescanada.ca';
      case /stg14/.test(host):
        return 'https://stg004-sso-osu.canadapost-postescanada.ca';
      case /ux/.test(host):
        return 'https://stg002-sso-osu.canadapost-postescanada.ca';
      default:
        return 'https://sso-osu.canadapost-postescanada.ca';
    }
  },

  getStoreDomain(lang) {
    const host = window.location.host;
    const sandboxEnvEn = {
      local: 't29011-s47513.sandbox.mozu.com',
      dev10: 't29011-s47513.sandbox.mozu.com',
      dev11: 't29011-s47513.sandbox.mozu.com',
      dev12: 't29011-s47513.sandbox.mozu.com',
      dev13: 't29011-s47513.sandbox.mozu.com',
      dev14: 't29011-s47513.sandbox.mozu.com',
      stg10: 't30296-s50851.stg1.mozu.com',
      stg11: 't28065-s45123.sandbox.mozu.com',
      stg12: 't29025-s47547.sandbox.mozu.com',
      stg13: 't30296-s50851.stg1.mozu.com',
      stg14: 't30296-s50851.stg1.mozu.com',
    };

    const sandboxEnvFr = {
      local: 't29011-s47514.sandbox.mozu.com',
      dev10: 't29011-s47514.sandbox.mozu.com',
      dev11: 't29011-s47514.sandbox.mozu.com',
      dev12: 't29011-s47514.sandbox.mozu.com',
      dev13: 't29011-s47514.sandbox.mozu.com',
      dev14: 't29011-s47514.sandbox.mozu.com',
      stg10: 't30296-s50852.stg1.mozu.com',
      stg11: 't28065-s45124.sandbox.mozu.com',
      stg12: 't29025-s47548.sandbox.mozu.com',
      stg13: 't30296-s50852.stg1.mozu.com',
      stg14: 't30296-s50852.stg1.mozu.com',
    };

    const sandboxEnv = lang === 'en' ? sandboxEnvEn : sandboxEnvFr;
    let res = '';
    switch (true) {
      case localhostRegEx.test(host):
        res = `https://${sandboxEnv.local}`;
        break;
      case sandboxEnv.dev10 === host:
        res = `https://${sandboxEnv.dev10}`;
        break;
      case sandboxEnv.dev11 === host:
        res = `https://${sandboxEnv.dev11}`;
        break;
      case sandboxEnv.dev12 === host:
        res = `https://${sandboxEnv.dev12}`;
        break;
      case sandboxEnv.dev13 === host:
        res = `https://${sandboxEnv.dev13}`;
        break;
      case sandboxEnv.dev14 === host:
        res = `https://${sandboxEnv.dev14}`;
        break;
      case sandboxEnv.stg10 === host:
        res = `https://${sandboxEnv.stg10}`;
        break;
      case sandboxEnv.stg11 === host:
        res = `https://${sandboxEnv.stg11}`;
        break;
      case sandboxEnv.stg12 === host:
        res = `https://${sandboxEnv.stg12}`;
        break;
      case sandboxEnv.stg13 === host:
        res = `https://${sandboxEnv.stg13}`;
        break;
      case sandboxEnv.stg14 === host:
        res = `https://${sandboxEnv.stg14}`;
        break;
      default:
        res = lang === 'en' ? 'https://store.canadapost-postescanada.ca' : 'https://boutique.canadapost-postescanada.ca';
    }
    return res;
  },

  getEpostDomain() {
    const host = window.location.host;
    switch (true) {
      case localhostRegEx.test(host):
      case /dev10/.test(host):
      case /dev11/.test(host):
        return 'dev001-www.';
      case /dev12/.test(host):
        return 'dev002-www.';
      case /dev13/.test(host):
        return 'dev003-www.';
      case /stg10/.test(host):
        return 'stg10.canadapost-postescanada.ca';
      case /stg11/.test(host):
        return 'stg11.canadapost-postescanada.ca';
      case /stg12/.test(host):
        return 'stg12.canadapost-postescanada.ca';
      case /stg13/.test(host):
        return 'stg003-www.';
      case /stg14/.test(host):
        return 'stg004-www.';
      default:
        return 'www.canadapost-postescanada.ca';
    }
  },

  getCAPTCHKey() {
    const host = window.location.host;
    switch (true) {
      case localhostRegEx.test(host):
      case /dev10/.test(host):
      case /dev11/.test(host):
      case /dev12/.test(host):
      case /dev13/.test(host):
        return '6LeZ5rUcAAAAACBDhcwNAtIFHmgbs6rOFzywGhVm';
      case /dev14/.test(host):
      case /stg14/.test(host):
        return '6LcW57UcAAAAADcTsEphxbqUqmezYmY2TJd5to38';
      case /stg10/.test(host):
      case /stg11/.test(host):
      case /stg12/.test(host):
      case /stg13/.test(host):
      case /ux/.test(host):
        return '6LfA5bUcAAAAAH2aCDHaY6cBGHK9JsqVnjtioxhn';
      default:
        return '6LeWu7UcAAAAAEnO79OM2CsMvu2nbjmA4paHCOHJ';
    }
  },

  getESTSubdomain() {
    const host = window.location.hostname;
    if (localhostRegEx.test(host)) {
      return 'stg12.';
    }
    const hostSplit = host.split('.');
    if (hostSplit[0] === 'www') {
      return '';
    }
    return `${hostSplit[0]}.`;
  },

  getLogoutLink() {
    const host = window.location.hostname;
    if (shopRegEx.test(host)) {
      return `/cp/api/user/account/logout?token=${this.getSSOToken()}`;
    }
    return `${this.getDomain()}/pkmslogout?token=${this.getSSOToken()}`;
  },

};

export default initModule;
