import globals from '../../../../utils/globals';

import locale from './locale';

const feedbackModalId = 'qualtricsFeedbackModal';
function initialize(initOptions) {
  setFeedbackElement(initOptions && initOptions.isCommonComponent);
}

export default initialize;

function setFeedbackElement(isCommonComponent) {
  let domEleContainer = document.getElementById(feedbackModalId);

  // Assume that the modal DOM was provided as a part of an HTML response, or, prevent overwrite
  // of DOM element if this is called more than once
  if (domEleContainer) return;

  domEleContainer = document.createElement('div');
  domEleContainer.id = feedbackModalId;
  domEleContainer.className = 
    `cpc-modal__template ${isCommonComponent ? globals.cwc.componentClassName : ''}`;
  domEleContainer.setAttribute('data-cpc-modal-options', 
    `{"preserveOnClose":"true","id":"qualtricsFeedback","footer":false,"autoOpen":false,"closeLabel":"${locale.close}","closeMethods":["overlay","button", "escape"],"cssClass":["cpc-feedback-modal"]}`);
  domEleContainer.innerHTML = `
    <div class="cpc-modal-template-modal-body">
      <div class="row">
        <div class="large-12 columns cpc-feedback-modal-column-wrapper">
          <div id="cpc-qualtrics-feedback-modal" class="cpc-feedback-modal-iframe-container"></div>
        </div>
      </div>
    </div>`;
  
  document.body.appendChild(domEleContainer);
}