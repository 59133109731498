/**
 * Simple API to get/set username, abstracts away from direct access to the persistence layer
 *
 * @example
 * 
 */
import { getCookie, setCookie, getRootDomain } from './cookies';

const cookieName = 'cpo_cpidRemember';

function getUsername() {
  const username = getCookie(cookieName);
  return username;
}

function setUsername(username) {
  setCookie(cookieName, username, 9999, false, 
    getRootDomain(window.location));
}

function clearUsername() {
  setCookie(cookieName, '', -9999, false, 
    getRootDomain(window.location));
}

export { getUsername, setUsername, clearUsername };