// this stuff is for inlining the icons...Ideally a webpack loader is setup that allows 
// us to point to the actual SVG on the filesystem instead of a JS file that returns the SVG source
import facebookIcon from './facebook.icon';
import linkedInIcon from './linkedin.icon';
import twitterIcon from './twitter.icon';
import instagramIcon from './instagram.icon';
import youtubeIcon from './youtube.icon';

function getLocale() {
  return {
    connectWithUs: 'Connect with us',
    copyright: '© Canada Post Corporation',
    canada: 'The official website of the Government of Canada',
    ariaLandmarkLabel: 'Footer navigation',
    ariaContentInfoLandmarkLabel: 'Content info',
    support: 'Support',
    corporate: 'Corporate',
    blogs: 'Blogs',
    newWindow: 'Opens a new window',
    feedback: [
      {
        name: 'Website feedback',
        url: 'https://evaluation.canadapost-postescanada.ca/jfe/form/SV_71iOFlig0vNugpn?Q_lang=EN',
        iconClass: 'feedback',
      },
      {
        name: 'Accessibility feedback',
        url: '/cpc/en/our-company/about-us/corporate-sustainability/accessibility/accessibility-feedback-process.page#contactinformation',
        iconClass: 'accessibility',
      }
    ],
    socialMediaURLs: [
      { 
        name: 'Facebook',
        url: 'https://www.facebook.com/canadapost',
        icon: 'assets/cpc/img/logos/facebook.svg',
        iconSVG: facebookIcon
      },
      { 
        name: 'Twitter',
        url: 'https://twitter.com/canadapostcorp',
        icon: 'assets/cpc/img/logos/twitter.svg',
        iconSVG: twitterIcon
      },
      { 
        name: 'Instagram',
        url: 'https://www.instagram.com/canadapostagram/?hl=en',
        icon: 'assets/cpc/img/logos/instagram.svg',
        iconSVG: instagramIcon
      },
      { 
        name: 'LinkedIn',
        url: 'http://www.linkedin.com/company/canada-post?trk=company_name',
        icon: 'assets/cpc/img/logos/linkedin.svg',
        iconSVG: linkedInIcon
      },
      { 
        name: 'Youtube',
        url: 'https://www.youtube.com/user/postescanadapost',
        icon: 'assets/cpc/img/logos/youtube.svg',
        iconSVG: youtubeIcon
      }
    ]
  };
}

export default getLocale;