import { getPageLanguage } from '../../../utils/language.data';
import localEn from './locale.en';
import localFr from './locale.fr';

function SitemapLocale() {
  const lang = getPageLanguage();

  return {
    getString
  };

  function getString(name) {
    const str = (lang === 'fr') ? localFr[name] : localEn[name];
    return (str === undefined) ? '' : str;
  }
}

export default SitemapLocale();
